import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { showTitle } from "../../../../../actions";
const FollowersAudienceInterests = ({ reportData, ...props }) => {
  const interests = [
    { label: "Television & Film", value: 1 },
    { label: "Music", value: 3 },
    { label: "Shopping & Retail", value: 7 },
    { label: "Coffee, Tea & Beverages", value: 9 },
    { label: "Camera & Photography", value: 11 },
    { label: "Clothes, Shoes, Handbags & Accessories", value: 13 },
    { label: "Beer, Wine & Spirits", value: 19 },
    { label: "Sports", value: 21 },
    { label: "Electronics & Computers", value: 25 },
    { label: "Gaming", value: 30 },
    { label: "Activewear", value: 33 },
    { label: "Art & Design", value: 36 },
    { label: "Travel, Tourism & Aviation", value: 43 },
    { label: "Business & Careers", value: 58 },
    { label: "Beauty & Cosmetics", value: 80 },
    { label: "Healthcare & Medicine", value: 100 },
    { label: "Jewelry & Watches", value: 130 },
    { label: "Restaurants, Food & Grocery", value: 139 },
    { label: "Toys, Children & Baby", value: 190 },
    { label: "Fitness & Yoga", value: 196 },
    { label: "Wedding", value: 291 },
    { label: "Tobacco & Smoking", value: 405 },
    { label: "Pets", value: 673 },
    { label: "Healthy Lifestyle", value: 798 },
    { label: "Luxury Goods", value: 1500 },
    { label: "Home Decor, Furniture & Garden", value: 1560 },
    { label: "Friends, Family & Relationships", value: 1708 },
    { label: "Cars & Motorbikes", value: 1826 },
  ];

  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Audience Interests</CardTitle>
        <div className="card-content ch-8">
          <ListGroup className="icon-list">
            {reportData &&
            reportData.audience_followers &&
            reportData.audience_followers.data &&
            reportData.audience_followers.data.audience_interests ? (
              reportData.audience_followers.data.audience_interests.map(
                (interest, index) => {
                  if (index < 3) {
                    return (
                      <ListGroupItem
                        key={index}
                        className="d-flex align-items-center pl-0"
                      >
                        <div className="mr-3">
                          <img
                            loading="lazy"
                            src={props.generateImgUrl("interest", interest.id)}
                            alt="img"
                          />
                        </div>
                        <div
                          id={`followers_${interest.name}`}
                          title={showTitle(
                            `followers_${interest.name}`,
                            interest.name,
                          )}
                          className="text-wrap"
                        >
                          {
                            interests.find(
                              (option) => option.value === interest.id,
                            ).label
                          }
                        </div>
                      </ListGroupItem>
                    );
                  }
                  return "";
                },
              )
            ) : (
              <span className="text-center text-grey ">
                <p className="pt-50">No records</p>
              </span>
            )}
          </ListGroup>
        </div>
      </CardBody>
    </Card>
  );
};

export default FollowersAudienceInterests;
