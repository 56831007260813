import { useEffect } from "react";
import settings from "settings";
import Routers from "./routers/index";
import { Helmet } from "react-helmet";
import history from "./history";
import "./App.scss";

function App() {
  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (favicon) {
      favicon.href = settings?.favicon;
    }

    const onDeviceReady = () => {
      window.universalLinks.subscribe(null, (eventData) => {
        history.push(eventData.path);
      });
    };

    document.addEventListener("deviceready", onDeviceReady, false);
    return () => document.removeEventListener("deviceready", onDeviceReady);
  }, []);

  return (
    <>
      <Helmet>
        <title>{settings.versionName} - Creator Portal</title>
      </Helmet>
      <div className="app">
        <Routers />
      </div>
    </>
  );
}

export default App;
