import {
  actionCreator,
  checkHttpStatus,
  checkAuthTokenExpiry,
  publicHeaders,
  COMMON_ERROR_MESSAGE,
  showErrorMessage,
  showSuccessMessage,
  AUTHORIZATION_ERROR_MESSAGE,
} from "../../../actions";
import {
  loginActionTypes,
  LOGIN_URL,
  USER_READ_ONE_URL,
  AUTH_LINK_URL,
  AUTH_LINK_COMPLETE_URL,
  AUTH_LINK_VERIFY_ERROR_MESSAGE,
  INVALID_LOGIN_ERROR_MESSAGE,
  LOGIN_ERROR_MESSAGE,
  AUTH_LINK_SEND_ERROR_MESSAGE,
  AUTH_LINK_SEND_SUCCESS_MESSAGE,
  LOGOUT_ERROR_MESSAGE,
  FETCH_BALANCE_ERROR_MESSAGE,
  LOGOUT_SUCCESS_MESSAGE,
  LOGOUT_URL,
} from "../constants";

export const login = (credentials) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.login.REQUEST));

    function grecaptchaReady() {
      return new Promise((resolve, reject) => {
        window.grecaptcha.ready(() => {
          resolve();
        });
      });
    }
    await grecaptchaReady();
    const token = await window.grecaptcha.execute(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      {
        action: "login",
      },
    );

    credentials = { ...credentials, recaptchaToken: token };

    fetch(LOGIN_URL, {
      method: "POST",
      headers: publicHeaders,
      body: JSON.stringify(credentials),
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => {
        if (response.error) {
          showErrorMessage(LOGIN_ERROR_MESSAGE);
          dispatch(actionCreator(loginActionTypes.login.FAILURE));
        } else {
          dispatch(actionCreator(loginActionTypes.login.SUCCESS, response));
        }
      })
      .catch((error) => {
        if (error.statusCode === 401) {
          showErrorMessage(INVALID_LOGIN_ERROR_MESSAGE);
          dispatch(
            actionCreator(loginActionTypes.login.FAILURE, {
              invalidCredentials: true,
              failedLoginEmail: credentials?.email,
            }),
          );
          return;
        }
        showErrorMessage(LOGIN_ERROR_MESSAGE);
        dispatch(actionCreator(loginActionTypes.login.FAILURE));
      });
  };
};

export const verifyAuthLinkAndLogin = (id, token, newPassword) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.verifyAuthLink.REQUEST));
    fetch(AUTH_LINK_COMPLETE_URL, {
      method: "POST",
      headers: publicHeaders,
      body: JSON.stringify({ id, token, newPassword }),
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => {
        if (response.error) {
          showErrorMessage(AUTH_LINK_VERIFY_ERROR_MESSAGE);
          dispatch(actionCreator(loginActionTypes.verifyAuthLink.FAILURE));
        } else {
          dispatch(
            actionCreator(loginActionTypes.verifyAuthLink.SUCCESS, response),
          );
          window.location.href = "/";
        }
      })
      .catch((error) => {
        showErrorMessage(
          error?.statusCode === 401
            ? AUTH_LINK_VERIFY_ERROR_MESSAGE
            : COMMON_ERROR_MESSAGE,
        );
        dispatch(actionCreator(loginActionTypes.verifyAuthLink.FAILURE, error));
      });
  };
};

export const requestAuthLink = (email, linkType = "magic") => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.requestAuthLink.REQUEST));
    fetch(AUTH_LINK_URL, {
      method: "POST",
      headers: publicHeaders,
      body: JSON.stringify({ email, linkType }),
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => {
        if (response.error) {
          showErrorMessage(AUTH_LINK_SEND_ERROR_MESSAGE);
          dispatch(actionCreator(loginActionTypes.requestAuthLink.FAILURE));
        } else {
          showSuccessMessage(AUTH_LINK_SEND_SUCCESS_MESSAGE);
          dispatch(actionCreator(loginActionTypes.requestAuthLink.SUCCESS));
        }
      })
      .catch((error) => {
        showErrorMessage(AUTH_LINK_SEND_ERROR_MESSAGE);
        dispatch(
          actionCreator(loginActionTypes.requestAuthLink.FAILURE, error),
        );
      });
  };
};

export const setLoginUserFromCookie = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.fetchUser.REQUEST));
    let url = new URL(USER_READ_ONE_URL);
    const params = url.searchParams;
    params.append(
      "calculate",
      "availableBalance earningsHistory canDeleteUser agreementMetrics",
    );
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: publicHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => {
        if (response.error) {
          showErrorMessage(AUTHORIZATION_ERROR_MESSAGE);
          logout();
        } else {
          dispatch(actionCreator(loginActionTypes.fetchUser.SUCCESS, response));
        }
      })
      .catch((error) => {
        showErrorMessage(AUTHORIZATION_ERROR_MESSAGE);
        logout();
      });
  };
};

/**
 * Action creator function that logs out the user.
 * @returns {Function} - A redux-thunk function that dispatches actions to the store.
 */
export const logout = () => {
  return async (dispatch) => {
    dispatch(actionCreator(loginActionTypes.logout.REQUEST));
    try {
      const url = new URL(LOGOUT_URL);
      const logoutRequest = new Request(url, {
        method: "GET",
        credentials: "include",
        referrerPolicy: "origin",
        // headers: privateHeaders(),
      });
      const logoutResponse = await fetch(logoutRequest);
      if (logoutResponse?.status < 200 || logoutResponse?.status >= 300) {
        throw new Error();
      }
      dispatch(actionCreator(loginActionTypes.logout.SUCCESS));
      showSuccessMessage(LOGOUT_SUCCESS_MESSAGE);
    } catch (error) {
      console.error("Error logging out: ", error?.message || error);
      dispatch(actionCreator(loginActionTypes.logout.FAILURE));
      showErrorMessage(LOGOUT_ERROR_MESSAGE);
    }
  };
};

export const fetchAvailableBalance = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.fetchAvailableBalance.REQUEST));
    let url = new URL(USER_READ_ONE_URL);
    const params = url.searchParams;
    params.append(
      "calculate",
      "availableBalance earningsHistory canDeleteUser agreementMetrics",
    );
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: publicHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error) {
          showErrorMessage(FETCH_BALANCE_ERROR_MESSAGE);
          dispatch(
            actionCreator(loginActionTypes.fetchAvailableBalance.FAILURE),
          );
        } else {
          dispatch(
            actionCreator(
              loginActionTypes.fetchAvailableBalance.SUCCESS,
              response,
            ),
          );
        }
      })
      .catch((error) => {
        showErrorMessage(FETCH_BALANCE_ERROR_MESSAGE);
        dispatch(actionCreator(loginActionTypes.fetchAvailableBalance.FAILURE));
      });
  };
};

export const clearAccountErrors = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.clearAccountErrors.SUCCESS));
  };
};
