import { Col, Row } from "reactstrap";
import AgreementsList from "../../agreements/components/AgreementsList";
import { useState } from "react";

const CampaignAgreements = ({ campaign_id }) => {
  const [agreement_id, setAgreement_id] = useState(null);
  return (
    <Row>
      <Col>
        <h2 className="heading title">Agreements and Contracts</h2>
        <p className="text-grey mb-3">
          View and sign your agreements and contracts for this campaign.
        </p>
      </Col>
      <Col xs="12">
        <div className="container">
          <AgreementsList
            campaignAssignment_id={campaign_id}
            agreement_id={agreement_id}
            setAgreement_id={setAgreement_id}
          />
        </div>
      </Col>
    </Row>
  );
};

export default CampaignAgreements;
