import { useCallback } from "react";
import AgreementsList from "./components/AgreementsList";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

function Agreements({ ...props }) {
  const { agreement_id } = useParams();
  const history = useHistory();
  const navToAgreement = useCallback(
    (id) => {
      if (id) {
        history.push(`/agreements/${id}`);
      } else {
        history.push("/agreements");
      }
    },
    [history],
  );
  return (
    <div className="container agreements">
      <h1 className="heading title">Agreements</h1>
      <p className="subheading text-grey">
        Review and sign agreements and contracts.
      </p>
      <AgreementsList
        agreement_id={agreement_id}
        setAgreement_id={navToAgreement}
      />
    </div>
  );
}

export default Agreements;
