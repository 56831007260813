// Map metric types to display values

// formatType can be `compact`, `percent`, `currency`
// tooltipText is optional
const mappings = {
  followers: {
    label: "Followers",
    allowedNetworks: ["instagram", "tiktok", "youtube"],
    formatType: "compact",
    networkOverrides: {
      youtube: {
        label: "Subscribers",
      },
    },
  },
  avg_views: {
    label: "Avg Views",
    allowedNetworks: ["youtube", "tiktok"],
    formatType: "compact",
    tooltipText: "The average number of views over the last 30 posts.",
  },
  avg_likes: {
    label: "Avg Likes",
    allowedNetworks: ["instagram", "youtube", "tiktok"],
    formatType: "compact",
    tooltipText: "The average number of likes over the last 30 posts.",
  },
  avg_comments: {
    label: "Avg Comments",
    allowedNetworks: ["instagram", "youtube", "tiktok"],
    formatType: "compact",
    tooltipText: "The average number of comments over the last 30 posts.",
  },
  avg_reels_plays: {
    label: "Avg Reels Plays",
    allowedNetworks: ["instagram"],
    formatType: "compact",
    tooltipText: "The average number of plays over the last 30 Reels.",
  },
  paid_post_performance: {
    label: "Paid Post Performance",
    allowedNetworks: ["instagram"],
    formatType: "percent",
  },
  // TODO: location
  // Add more mappings for other metrics here
};

function metricDisplayMappings(metricName, network) {
  let metric = mappings[metricName];

  if (!metric) {
    console.warn(
      `Invalid metric name for metricDisplayMappings: ${metricName}`,
    );
    return null;
  }

  if (!metric.allowedNetworks?.includes(network)) {
    // console.warn(
    //   `Invalid network for metricDisplayMappings: ${network}`
    // );
    return { invalidNetwork: true };
  }

  if (metric.networkOverrides?.[network]) {
    metric = {
      ...metric,
      ...metric.networkOverrides[network],
    };
    delete metric.networkOverrides;
  }

  return metric;
}

export default metricDisplayMappings;
