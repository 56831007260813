import { Col, Card, CardBody, CardTitle } from "reactstrap";

const PopularMentions = ({ reportData, ...props }) => {
  return (
    <Col sm="6" className="d-flex align-items-stretch mb-4">
      <Card className="card-content-custom">
        <CardBody>
          <CardTitle>
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">Popular Mentions</span>
              <span
                href="#"
                id="popularMentionTooltip"
                className="tooltip-icon"
              ></span>
            </span>
          </CardTitle>
          <div className="popular-tags ch-5">
            <ul>
              {reportData?.user_profile?.top_mentions ? (
                reportData?.user_profile?.top_mentions?.map((tag, index) => {
                  if (index < 10 && tag.tag.length < 15) {
                    return <li key={index}>@{tag.tag}</li>;
                  }
                  return null;
                })
              ) : (
                <span className="text-center text-grey ">
                  <p className="pt-50">No mentions found</p>
                </span>
              )}
            </ul>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default PopularMentions;
