import { Badge } from "reactstrap";

const AgreementHasSignedBadge = ({
  hasSigned,
  signatureRequired,
  ...props
}) => {
  return (
    <>
      {signatureRequired ? (
        <>
          {hasSigned ? (
            <Badge color="success">Signed</Badge>
          ) : (
            <Badge color="danger">Not Signed</Badge>
          )}
        </>
      ) : (
        <Badge color="light">Not Required</Badge>
      )}
    </>
  );
};

export default AgreementHasSignedBadge;
