import {
  Col,
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
const CreatorInterests = ({ reportData, ...props }) => {
  const interests = [
    { label: "Television & Film", value: 1 },
    { label: "Music", value: 3 },
    { label: "Shopping & Retail", value: 7 },
    { label: "Coffee, Tea & Beverages", value: 9 },
    { label: "Camera & Photography", value: 11 },
    { label: "Clothes, Shoes, Handbags & Accessories", value: 13 },
    { label: "Beer, Wine & Spirits", value: 19 },
    { label: "Sports", value: 21 },
    { label: "Electronics & Computers", value: 25 },
    { label: "Gaming", value: 30 },
    { label: "Activewear", value: 33 },
    { label: "Art & Design", value: 36 },
    { label: "Travel, Tourism & Aviation", value: 43 },
    { label: "Business & Careers", value: 58 },
    { label: "Beauty & Cosmetics", value: 80 },
    { label: "Healthcare & Medicine", value: 100 },
    { label: "Jewelry & Watches", value: 130 },
    { label: "Restaurants, Food & Grocery", value: 139 },
    { label: "Toys, Children & Baby", value: 190 },
    { label: "Fitness & Yoga", value: 196 },
    { label: "Wedding", value: 291 },
    { label: "Tobacco & Smoking", value: 405 },
    { label: "Pets", value: 673 },
    { label: "Healthy Lifestyle", value: 798 },
    { label: "Luxury Goods", value: 1500 },
    { label: "Home Decor, Furniture & Garden", value: 1560 },
    { label: "Friends, Family & Relationships", value: 1708 },
    { label: "Cars & Motorbikes", value: 1826 },
  ];

  const generateImgUrl = (type, id) => {
    if (type === "country") {
      return `${process.env.REACT_APP_TENSOR_API_URL}/assets/images/country_flags/${id}`;
    } else if (type === "brand") {
      return `${process.env.REACT_APP_TENSOR_API_URL}/assets/images/brand_logo/${id}`;
    } else if (type === "interest") {
      return `${process.env.REACT_APP_TENSOR_API_URL}/assets/images/interests/${id}`;
    }
  };

  return (
    <Col sm="6">
      <Card className="card-content-custom">
        <CardBody>
          <CardTitle>Creator Interests</CardTitle>
          <ListGroup className="ba-list ch-6">
            {reportData &&
            reportData.user_profile &&
            reportData.user_profile.interests ? (
              reportData.user_profile.interests.map((interest, index) => {
                if (index < 5) {
                  return (
                    <ListGroupItem
                      key={index}
                      className="d-flex align-items-center"
                    >
                      <div className="mr-3">
                        <img
                          loading="lazy"
                          src={generateImgUrl("interest", interest.id)}
                          alt="img"
                        />
                      </div>
                      <div className="text-wrap">
                        {
                          interests.find(
                            (option) => option.value === interest.id,
                          ).label
                        }
                      </div>
                    </ListGroupItem>
                  );
                }
                return null;
              })
            ) : (
              <span className="text-center text-grey ">
                <p className="pt-50">No records</p>
              </span>
            )}
          </ListGroup>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CreatorInterests;
