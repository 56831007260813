// Displays a single social handle in a card. Can be used in a list or as a page header.
import { connect } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

import HandleProfileDisplay from "./HandleProfile";
import { useHistory } from "react-router-dom";
import { RenderButton } from "../../../components";

const SocialHandleListItem = ({ socialHandle, actionBtnEnabled, ...props }) => {
  const history = useHistory();
  return (
    <div className="handle-list-item" key={socialHandle?._id}>
      <Card className="card-custom text-left">
        <CardBody>
          <HandleProfileDisplay
            socialHandle={socialHandle}
            statusTextEnabled={true}
            key={socialHandle?._id}
          />
          {actionBtnEnabled && !socialHandle?.notFound && (
            <RenderButton
              onClick={() => {
                history.push(`/mediaKits/manage/${socialHandle?._id}`);
              }}
              className="btn btn-primary p-1"
              tabIndex={1}
            >
              <ChevronRightIcon strokeWidth={2} height={24} width={24} />
            </RenderButton>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

// export default SocialHandleListItem;
const mapStateToProps = (state) => {
  return {
    // managingMediaKit_id: state.mediaKits.managingMediaKit_id,
  };
};
export default connect(mapStateToProps, {
  // setManagingMediaKit,
})(SocialHandleListItem);
