import { Col, Row } from "reactstrap";

import handshake from "../../../assets/img/handshake.svg";
import message from "../../../assets/img/message.svg";
import target from "../../../assets/img/target.svg";

const StepThree = () => {
  const content = [
    {
      emoji: handshake,
      title: "Negotiations",
      description: "We’ll secure rates that are 2.5x the industry average.",
    },
    {
      emoji: message,
      title: "Communication",
      description:
        "We’ll ping you when there’s something worthwhile and handle all your deals.",
    },
    {
      emoji: target,
      title: "Simplification",
      description:
        "Your life, streamlined. We take care of invoicing, contracts, and even your taxes.",
    },
  ];

  const CardContent = content.map((item, index) => (
    <Row className="px-2" key={index}>
      <Col xs="2">
        <img src={item.emoji} width={30} alt="emoji" />
      </Col>
      <Col xs="10" className="px-0">
        <h3 className="subtitle mb-1">{item.title}</h3>
        <p className="description">{item.description}</p>
      </Col>
    </Row>
  ));

  return (
    <div className="step-three m-80 px-3">
      <h1 className="heading title">
        More Creating,
        <span className="gradient new-line">Less Micromanaging</span>
      </h1>
      <p className="description">
        We want you to focus on what you love: creating.
        <span className="new-line">Here&apos;s how we&apos;ll help you.</span>
      </p>
      {CardContent}
    </div>
  );
};

export default StepThree;
