import { Table, Row, Col } from "reactstrap";
import DeliverablesTableSkeleton from "./DeliverablesTableSkeleton";
import Paginator from "../../components/Paginator";
import DeliverablesTableRow from "./DeliverablesTableRow";
import DeliverableModal from "./DeliverableModal";
import { useCallback, useState } from "react";
import { getDeliverable } from "./actions";
import { connect } from "react-redux";

const DeliverablesTable = ({
  caption,
  deliverablesData,
  deliverablesCount,
  columns,
  headers,
  activePage,
  setActivePage,
  pageSize,
  setPageSize,
  loading,

  getDeliverable,

  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setIsModalOpen((prevIsModalOpen) => {
      if (prevIsModalOpen);
      return !prevIsModalOpen;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleSelectDeliverable = useCallback(
    (deliverable_id) => {
      getDeliverable(deliverable_id);
      setIsModalOpen(!!deliverable_id);
    },
    [getDeliverable],
  );

  const columnHeaders = columns.map((column, index) => (
    <th
      scope="col"
      key={index}
      className={headers[index].class}
      width={headers[index].width}
    >
      {headers[index].heading}
    </th>
  ));

  const tableData = deliverablesData.map((deliverable) => (
    <DeliverablesTableRow
      key={deliverable._id}
      deliverable={deliverable}
      handleSelectDeliverable={handleSelectDeliverable}
    ></DeliverablesTableRow>
  ));

  if (loading && !deliverablesData?.length)
    return <DeliverablesTableSkeleton headers={columnHeaders} />;

  return (
    <Row className="search-results-wrap">
      <Col md="12">
        <h3 className="heading title">{caption}</h3>
        <div className="table-responsive table-outer">
          <Table hover borderless>
            <caption className="sr-only">{caption}</caption>
            <thead>
              <tr>{columnHeaders}</tr>
            </thead>
            <tbody>
              {deliverablesData && deliverablesData.length ? (
                tableData
              ) : (
                <tr>
                  <td colSpan={columns.length} align="center">
                    <p className="mb-1 mt-5 text-grey">No deliverables.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {deliverablesCount > 10 && (
            <Paginator
              activePage={activePage}
              pageSize={pageSize}
              totalItems={deliverablesCount}
              handlePageChange={(pageNumber) => {
                setActivePage(pageNumber);
              }}
              handlePageSizeChange={(size) => {
                setPageSize(size);
              }}
            />
          )}
        </div>
      </Col>
      <DeliverableModal
        isOpen={isModalOpen}
        handleSelectDeliverable={handleSelectDeliverable}
        toggle={toggleModal}
      />
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.deliverables.loading,
  };
};

export default connect(mapStateToProps, {
  getDeliverable,
})(DeliverablesTable);
