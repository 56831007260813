import { Card, CardBody, Col, Row } from "reactstrap";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const OpportunityCardSkeleton = () => {
  return (
    <>
      {Array(3)
        .fill()
        .map((item, index) => (
          <Col lg="6" key={index}>
            <Card className="card-custom text-left">
              <CardBody>
                <Row>
                  <Col
                    xs="3"
                    className="d-flex flex-wrap align-items-center justify-content-center"
                  >
                    <Skeleton className="card-brand" height={75} width={75} />
                  </Col>
                  <Col xs="9" className="pl-0">
                    <Skeleton height={22} width={"60%"} />
                    <Skeleton className="my-2" height={24} width={"80%"} />
                    <Skeleton height={22} width={"50%"} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
    </>
  );
};

export default OpportunityCardSkeleton;
