import { Row, Col } from "reactstrap";
import { RenderButton, RenderCurrency } from "../../../../components";

export const CampaignInvitation = ({
  campaignName,
  campaignAssignment_id,
  answerCampaignInvitation,
  ...props
}) => {
  return (
    <>
      <Row className="justify-content-center">
        <Col xs="12" lg="8" className="text-center">
          <h1 className="heading text-center">
            You&apos;re invited to the campaign
            <br></br>
            <span className="bold">{campaignName}</span>
            <h2 className="subheading text-center">
              With a starting balance of{" "}
              <b className="bold">
                <RenderCurrency
                  currency={"USD"}
                  renderCurrencyCode={true}
                  amount={30}
                />
                /post
              </b>
            </h2>
          </h1>
          <p className="subheading subtitle text-center">
            Welcome and <span className="bold">congrats</span> on being approved
            to join our exclusive campaign with Lemon8, the exciting new social
            platform brought to you by the creators of TikTok! We’re looking for
            talented and engaging creators like you to post content on Lemon8.
            This is a great chance for you to showcase your skills and gain
            exposure on a rising social platform, all while earning some cash!
          </p>
          <p className="subtitle text-center mb-5">
            To get started, just click Join Campaign below!
          </p>
        </Col>
        {/* BUG: button height does not grow if text wraps to multiple lines. Fixed it with Bootstrap here, but really the SCSS needs to be fixed. It's also missing styling for button variants and hardcodes shit instead of using maps */}
      </Row>
      {/* <Col xs="10" sm="8" lg="6"> */}

      <Row className="justify-content-center gap-2">
        <Col xs="12" sm="5" md="4" lg="3" className="order-sm-2">
          <RenderButton
            onClick={() => {
              answerCampaignInvitation(campaignAssignment_id, true);
            }}
            className="btn-primary btn-wide m-1"
          >
            Join Campaign
          </RenderButton>
        </Col>
        <Col xs="12" sm="5" md="4" lg="3">
          <RenderButton
            style={{
              "border-radius": "8px",
            }}
            onClick={() => {
              answerCampaignInvitation(campaignAssignment_id, false);
            }}
            outline
            color="danger"
            className="btn-wide btn-outline-danger m-1"
          >
            Reject Invitation
          </RenderButton>
        </Col>
      </Row>
    </>
  );
};
