import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap";
import { capitalize } from "../../../../../actions";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
highchartsMore(Highcharts);
solidGauge(Highcharts);
const gaugeOptions = (reportData) => {
  const data =
    reportData &&
    reportData.audience_followers &&
    reportData.audience_followers.data &&
    reportData.audience_followers.data.audience_genders
      ? reportData.audience_followers.data.audience_genders.sort(
          (a, b) => b.weight - a.weight,
        )
      : [];
  const seriesData = data.map((obj, index) => ({
    name: capitalize(obj.code),
    radius: "100%",
    color: obj.code.toLowerCase() === "female" ? "#ff5537" : "#1f87ee",
    innerRadius: "70%",
    y:
      index === 0
        ? Math.round((obj.weight + data[index + 1].weight) * 100)
        : Math.round(obj.weight * 100),
  }));
  return {
    chart: {
      type: "solidgauge",
      height: 150,
      backgroundColor: null,
    },

    title: null,

    pane: {
      size: "100%",
      background: [
        {
          outerRadius: "100%",
          innerRadius: "70%",
          backgroundColor: "transparent",
          borderWidth: 0,
        },
      ],
    },

    tooltip: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        animation: false,
        stickyTracking: true,
        rounded: false,
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        data: seriesData,
      },
    ],
  };
};

const genderOptions = {
  male: "Male",
  female: "Female",
  male_female: "Male or Female",
  neutral: "Theme/Business Account",
};
const FollowersGenderSplit = ({ reportData, ...props }) => {
  return (
    <Card className="card-content-custom gender">
      <CardBody>
        <CardTitle>
          <span className="tooltip-button-wrap">
            <span className="text-tooltip">Gender Split</span>
            <span
              href="#"
              id="followersGenderSplit"
              className="tooltip-icon"
            ></span>
          </span>
        </CardTitle>
        <div className="card-content">
          <div className="graph-wrap">
            <HighchartsReact
              highcharts={Highcharts}
              options={gaugeOptions(reportData)}
            />
          </div>
          <ListGroup flush className="legend-gender custom-bedge-list">
            {reportData &&
            reportData.audience_followers &&
            reportData.audience_followers.data &&
            reportData.audience_followers.data.audience_genders ? (
              reportData.audience_followers.data.audience_genders.map(
                (gender, index) => (
                  <ListGroupItem
                    key={index}
                    className={
                      gender.code.toLowerCase() === "female"
                        ? "female d-flex justify-content-between align-items-center"
                        : "d-flex justify-content-between align-items-center"
                    }
                  >
                    {genderOptions[gender.code.toLowerCase()]}
                    <Badge className="text-grey ml-2" color="">
                      {(gender.weight * 100).toFixed(2) + "%"}
                    </Badge>
                  </ListGroupItem>
                ),
              )
            ) : (
              <></>
            )}
          </ListGroup>
        </div>
      </CardBody>
    </Card>
  );
};

export default FollowersGenderSplit;
