import StepZilla from "react-stepzilla";
import "./index.scss";

const ProgressStepper = ({ steps, disableNextButton, ...customProps }) => (
  <div className="progress-stepper-nr">
    <StepZilla
      steps={steps}
      nextButtonCls="btn btn-primary float-right btn-stepper stepper-nav-btn"
      backButtonCls="btn btn-outline-primary float-left btn-stepper stepper-nav-btn"
      stepsNavigation={false}
      preventEnterSubmission={true}
      {...customProps}
    />
  </div>
);
export default ProgressStepper;
