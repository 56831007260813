import {
  actionCreator,
  checkHttpStatus,
  checkAuthTokenExpiry,
  privateHeaders,
  showErrorMessage,
  showSuccessMessage,
} from "../../../actions";
import { logout } from "../../login/actions";
import {
  mediaKitsActionTypes,
  MEDIA_KITS_API_URL,
  GET_MEDIA_KITS_ERROR,
  GET_MEDIA_KIT_ERROR,
  CONFIRM_MEDIA_KIT_ERROR,
  USER_API_URL,
} from "../constants";

export const getMediaKits = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(mediaKitsActionTypes.getMediaKits.REQUEST));
    let url = new URL(USER_API_URL);
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: privateHeaders(getState),
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.result) {
          showErrorMessage(GET_MEDIA_KITS_ERROR);
          dispatch(actionCreator(mediaKitsActionTypes.getMediaKits.FAILURE));
        } else {
          dispatch(
            actionCreator(
              mediaKitsActionTypes.getMediaKits.SUCCESS,
              response.result?.socialHandles,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(mediaKitsActionTypes.getMediaKits.FAILURE));
        showErrorMessage(GET_MEDIA_KITS_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

// This endpoint currently only works for confirmed media kits. For unconfirmed or unknown media kits, use the `getSocialHandle` action
export const getMediaKit = (id) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(mediaKitsActionTypes.getMediaKit.REQUEST));
    let url = new URL(`${MEDIA_KITS_API_URL}/${id}`);
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: privateHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response?.error || !response?.result) {
          showErrorMessage(GET_MEDIA_KIT_ERROR);
          dispatch(actionCreator(mediaKitsActionTypes.getMediaKit.FAILURE));
        } else {
          dispatch(
            actionCreator(
              mediaKitsActionTypes.getMediaKit.SUCCESS,
              response.result,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(mediaKitsActionTypes.getMediaKit.FAILURE));
        showErrorMessage(GET_MEDIA_KIT_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

// This endpoint can be used to retrieve a handles/mediaKit regardless of confirmed status.
export const getSocialHandle = (id) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(mediaKitsActionTypes.getSocialHandle.REQUEST));
    let url = new URL(`${USER_API_URL}`);
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: privateHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response?.error || !response?.result?.socialHandles) {
          showErrorMessage(GET_MEDIA_KIT_ERROR);
          dispatch(actionCreator(mediaKitsActionTypes.getSocialHandle.FAILURE));
        } else {
          const mediaKit = response?.result?.socialHandles?.find(
            (obj) => obj._id === id,
          );
          dispatch(
            actionCreator(
              mediaKitsActionTypes.getSocialHandle.SUCCESS,
              mediaKit,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(mediaKitsActionTypes.getSocialHandle.FAILURE));
        showErrorMessage(GET_MEDIA_KIT_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const confirmSocialHandle = ({ user_id, socialHandle_id }) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(mediaKitsActionTypes.confirmSocialHandle.REQUEST));
    let url = new URL(
      `${USER_API_URL}/${user_id}/editSocialHandle/${socialHandle_id}`,
    );
    url = url.toString();
    fetch(url, {
      method: "PUT",
      headers: privateHeaders(getState),
      credentials: "include",
      body: JSON.stringify({ hasConfirmedOwnership: true }),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.result) {
          showErrorMessage(CONFIRM_MEDIA_KIT_ERROR);
          dispatch(
            actionCreator(mediaKitsActionTypes.confirmSocialHandle.FAILURE),
          );
        } else {
          const mediaKit = response?.result?.socialHandles?.find(
            (obj) => obj._id === socialHandle_id,
          );
          showSuccessMessage("Social Handle ownership has been confirmed");
          dispatch(
            actionCreator(
              mediaKitsActionTypes.confirmSocialHandle.SUCCESS,
              mediaKit,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(mediaKitsActionTypes.confirmSocialHandle.FAILURE),
        );
        showErrorMessage(CONFIRM_MEDIA_KIT_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};
