import { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import BalanceTile from "./components/BalanceTile";
import EarningsTile from "./components/EarningsTile";
import {
  clearErrorMessages,
  clearSuccessMessages,
  createInstantPayout,
} from "./actions/index";
import { fetchAvailableBalance } from "../login/actions/index";

import "./index.scss";
import { connect } from "react-redux";
import { showErrorMessage, showSuccessMessage } from "../../actions";
import AccountCompleteSetup from "./AccountCompleteSetup";
import CampaignsTable from "../campaigns/components/CampaignsTable";
import LoginUserAgreementsActionRequiredBanner from "../login/components/LoginUserAgreementsActionRequiredBanner";

const Dashboard = ({
  createInstantPayout,
  fetchAvailableBalance,
  loginUser,
  errorMessage,
  clearErrorMessages,
  successMessage,
  clearSuccessMessages,
  ...props
}) => {
  useEffect(() => {
    fetchAvailableBalance();
  }, [fetchAvailableBalance]);

  useEffect(() => {
    if (errorMessage) {
      showErrorMessage(errorMessage, () => {
        clearErrorMessages();
      });
    }
  }, [errorMessage, clearErrorMessages]);

  useEffect(() => {
    if (successMessage) {
      showSuccessMessage(
        successMessage,
        () => {
          clearSuccessMessages();
        },
        "payout_success",
      );
    }
  }, [successMessage, clearSuccessMessages]);

  useEffect(() => {
    const onDeviceReady = () => {
      //Prompts the user for notification permissions.
      window.plugins.OneSignal.promptForPushNotificationsWithUserResponse();

      window.plugins.OneSignal.setAppId(process.env.REACT_APP_ONESIGNAL_APP_ID);
      window.plugins.OneSignal.setExternalUserId(loginUser._id);
    };

    document.addEventListener("deviceready", onDeviceReady, false);
    return () => document.removeEventListener("deviceready", onDeviceReady);
  }, [loginUser]);

  const handleInstantPayout = () => {
    createInstantPayout();
  };

  const payoutsDisabled =
    !loginUser.emailVerified ||
    loginUser.needsStripeOnboarding ||
    loginUser.needsStripeFinancialConnection;

  return (
    <>
      <Container className="main-site-content dashboard-splash">
        {!payoutsDisabled ? (
          <Row>
            <Col sm="5" className="mb-4">
              <BalanceTile
                handleInstantPayout={handleInstantPayout}
                loginUser={loginUser}
              />
            </Col>
            <Col sm="7">
              <EarningsTile loginUser={loginUser} />
            </Col>
          </Row>
        ) : null}

        <AccountCompleteSetup />

        <LoginUserAgreementsActionRequiredBanner />
        <CampaignsTable />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    errorMessage: state.dashboard.errorMessage,
    successMessage: state.dashboard.successMessage,
  };
};

export default connect(mapStateToProps, {
  createInstantPayout,
  fetchAvailableBalance,
  clearErrorMessages,
  clearSuccessMessages,
  showErrorMessage,
  showSuccessMessage,
})(Dashboard);
