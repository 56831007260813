import { Col, Container, Row } from "reactstrap";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const DetailedViewSkeleton = () => {
  return (
    <Container className="main-site-content deals-splash detailed-view d-flex flex-column">
      <Skeleton className="mt-5" height={75} width={75} />
      <Skeleton className="heading title" height={44} width={"20%"} />
      <Row>
        <Col xs="12">
          <h2 className="mb-3">
            <Skeleton height={32} width={"40%"} />
          </h2>
        </Col>
        <Col xs="12" md="6">
          <Skeleton className="mb-2" count={5} height={24} width={"100%"} />
        </Col>
      </Row>

      <div className="sticky-container mt-auto">
        <div className="button-container">
          <Skeleton className="p-3 mb-3" height={56} width={"100%"} />
          <Skeleton className="p-3 mb-3" height={56} width={"100%"} />
        </div>
      </div>
    </Container>
  );
};

export default DetailedViewSkeleton;
