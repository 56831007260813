import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMediaKit } from "../actions";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink as NavLinkBoot,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import HandleProfileDisplay from "./HandleProfile";
import NumericStatCard from "./NumericStatCard";
import { NavLink } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  ShareIcon,
} from "@heroicons/react/20/solid";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { formatNumbersToUnits, showSuccessMessage } from "../../../actions";
import anonymousAvatar from "../../../assets/img/user_icon_circle.svg";
import LineGraphCard from "./CreatorMetrics/LineGraphCard";
import EngagementSpread from "./CreatorMetrics/EngagementSpread";
import PopularHashtags from "./CreatorMetrics/PopularHashtags";
import PopularMentions from "./CreatorMetrics/PopularMentions";
import CreatorBrandAffinity from "./CreatorMetrics/CreatorBrandAffinity";
import CreatorInterests from "./CreatorMetrics/CreatorInterests";
import AudiencesSection from "./AudiencesSection";
import { RenderButton } from "../../../components";

const MediaKit = ({
  id,
  getMediaKit,
  mediaKit,
  loginUser,
  loading,
  ...props
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [activeTabId, setActiveTabId] = useState(0);

  useEffect(() => {
    getMediaKit(id);
  }, [getMediaKit, id]);

  const reportData = mediaKit?.reportData;
  const historicalData = mediaKit?.reportData?.user_profile?.stat_history.slice(
    -2,
    -1,
  )[0];

  const handleCopyText = (e) => {
    const textField = document.createElement("textarea");
    textField.innerText = `${process.env.REACT_APP_URL}/mediaKits/${id}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    showSuccessMessage("Copied share link!");
  };

  // TODO: Copied from Tensor. Refactor
  const generateImgUrl = (type, id) => {
    if (type === "country") {
      return `${process.env.REACT_APP_TENSOR_API_URL}/assets/images/country_flags/${id}`;
    } else if (type === "brand") {
      return `${process.env.REACT_APP_TENSOR_API_URL}/assets/images/brand_logo/${id}`;
    } else if (type === "interest") {
      return `${process.env.REACT_APP_TENSOR_API_URL}/assets/images/interests/${id}`;
    }
  };
  const div1Width =
    isFinite(reportData?.user_profile?.trending_analysis) &&
    reportData?.user_profile?.trending_analysis < 0
      ? 50 -
        Math.abs(reportData?.user_profile?.trending_analysis * 100) / 2 +
        "%"
      : 50 +
        Math.abs(reportData?.user_profile?.trending_analysis * 100) / 2 +
        "%";
  const div2Width =
    isFinite(reportData?.user_profile?.trending_analysis) &&
    reportData?.user_profile?.trending_analysis < 0
      ? 100 -
        Math.abs(
          50 - Math.abs(reportData?.user_profile?.trending_analysis * 100) / 2,
        ) +
        "%"
      : 100 -
        (50 + Math.abs(reportData?.user_profile?.trending_analysis * 100) / 2) +
        "%";

  const data = reportData?.extra?.engagement_rate_histogram
    ? reportData?.extra?.engagement_rate_histogram?.map((eng, index) => {
        let isUserSlot = false;

        if (eng.median) {
          isUserSlot = false;
        } else if (
          eng.max &&
          !eng.min &&
          reportData?.user_profile?.engagement_rate <= eng.max
        ) {
          isUserSlot = true;
        } else if (
          !eng.max &&
          eng.min &&
          reportData?.user_profile?.engagement_rate >= eng.max
        ) {
          isUserSlot = true;
        } else if (
          eng.max &&
          eng.min &&
          reportData?.user_profile?.engagement_rate >= eng.min &&
          reportData?.user_profile?.engagement_rate <= eng.max
        ) {
          isUserSlot = true;
        }
        return {
          x: index + 1,
          id: `tick_${index + 1}`,
          xAxisLabelName: eng.median ? "<b>Similar Accounts</b>" : "",
          isUserSlot: isUserSlot,
          y: eng.total,
          name: !eng.min
            ? `<${(eng.max * 100).toFixed(2)}%`
            : !eng.max
              ? `>${(eng.min * 100).toFixed(2)}%`
              : `${(eng.min * 100).toFixed(2)}-${(eng.max * 100).toFixed(2)}%`,
        };
      })
    : [];
  const tickPositions = [];
  const userProfilePicPath = reportData?.user_profile?.picture
    ? reportData?.user_profile?.picture
    : null;
  if (reportData?.extra?.engagement_rate_histogram) {
    reportData?.extra?.engagement_rate_histogram.map((eng, index) => {
      if (eng.median) {
        return tickPositions.push(index + 1);
      }
      return null;
    });
  }
  const chartOptions = {
    chart: {
      height: 200,
      animation: false,
      backgroundColor: null,
    },
    title: {
      text: "",
    },
    xAxis: {
      lineColor: "#009494b3",
      lineWidth: 5,
      tickPositions: tickPositions,
      labels: {
        enabled: true,
        formatter: function () {
          return (
            data.find((eng) => eng.x === this.value) &&
            data.find((eng) => eng.x === this.value).xAxisLabelName
          );
        },
      },
    },
    yAxis: {
      lineWidth: 0,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      stackLabels: {
        enabled: true,
        useHTML: true,
        formatter: function () {
          return data.find((eng) => eng.y === this.total) &&
            data.find((eng) => eng.y === this.total).xAxisLabelName !== ""
            ? `<span class="img-graph-bg"><img width="50px" src=${anonymousAvatar} /></span>`
            : data.find((eng) => eng.y === this.total).isUserSlot
              ? `<span class="img-graph-bg"><img width="50px" src=${userProfilePicPath} /></span>`
              : "";
        },
      },

      title: {
        text: null,
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        animation: false,
      },
    },
    tooltip: {
      enabled: true,
      outside: true,
      backgroundColor: "#17222d",
      borderColor: "transparent",
      borderRadius: 10,
      shadow: false,
      style: {
        color: "white",
        fontSize: "11px",
      },
      formatter: function () {
        return `<b>${this.point.name}<br/>${formatNumbersToUnits(
          this.y,
        )} Accounts`;
      },
    },
    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        type: "column",
        name: "Max",
        states: {
          hover: {
            color: "#ddd",
          },
        },
        color: "#e6e6e6",
        borderColor: "transparent",
        data: data,
      },
    ],
  };

  return (
    <div className="media-kit" key={id}>
      {loginUser && (
        <NavLink to="/mediaKits" className="nav-back-arrow">
          <div>
            <ArrowLeftIcon height={24} width={24} />
          </div>
        </NavLink>
      )}
      {/* Profile Header */}
      <Row>
        <Col xs="12" md="10" lg="6" className="mx-auto media-kit-header">
          <Card className="card-custom text-left">
            <CardBody>
              <HandleProfileDisplay socialHandle={mediaKit} />
              {/* editable bio */}
              <div>
                <RenderButton
                  onClick={handleCopyText}
                  className="btn btn-primary p-2"
                  tabIndex={1}
                  title="Copy link to clipboard"
                >
                  <ShareIcon width={20} height={20} />
                </RenderButton>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" className="mx-auto">
          {/* Tabbed report sections */}
          <div className="nav-tabbed-custom no-well mt-4">
            <Nav pills className="justify-content-center">
              <NavItem className="mx-3">
                <NavLinkBoot
                  className={activeTabId === 0 ? "active mb-2" : "mb-2"}
                  onClick={() => {
                    setActiveTabId(0);
                  }}
                >
                  Creator Stats
                </NavLinkBoot>
              </NavItem>
              <NavItem className="mx-3">
                <NavLinkBoot
                  className={activeTabId === 1 ? "active mb-2" : "mb-2"}
                  onClick={() => {
                    setActiveTabId(1);
                  }}
                >
                  Audience Stats
                </NavLinkBoot>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTabId}>
              <TabPane tabId={0}>
                <div className="numeric-stats-grid mb-4">
                  <NumericStatCard
                    key={`${id}-followers`}
                    metricName="followers"
                    network={mediaKit?.network}
                    value={reportData?.user_profile?.followers}
                    valuePast={historicalData?.followers}
                  />
                  <NumericStatCard
                    key={`${id}-avg_views`}
                    metricName="avg_views"
                    network={mediaKit?.network}
                    value={reportData?.user_profile?.avg_views}
                    valuePast={historicalData?.avg_views}
                  />
                  <NumericStatCard
                    key={`${id}-avg_likes`}
                    metricName="avg_likes"
                    network={mediaKit?.network}
                    value={reportData?.user_profile?.avg_likes}
                    valuePast={historicalData?.avg_likes}
                  />
                  <NumericStatCard
                    key={`${id}-avg_comments`}
                    metricName="avg_comments"
                    network={mediaKit?.network}
                    value={reportData?.user_profile?.avg_comments}
                    valuePast={historicalData?.avg_comments}
                  />
                  <NumericStatCard
                    key={`${id}-avg_reels_plays`}
                    metricName="avg_reels_plays"
                    network={mediaKit?.network}
                    value={reportData?.user_profile?.avg_reels_plays}
                  />
                  <NumericStatCard
                    key={`${id}-paid_post_performance`}
                    metricName="paid_post_performance"
                    network={mediaKit?.network}
                    value={
                      reportData?.user_profile?.paid_post_performance * 100
                    }
                  />
                </div>
                <div className="engagement-grid">
                  {isFinite(reportData?.user_profile?.trending_analysis) &&
                  reportData?.user_profile?.trending_analysis > 0 ? (
                    <Card className="card-content-custom trending-analysis">
                      <CardBody>
                        <CardTitle>
                          <span className="tooltip-button-wrap">
                            <span className="text-tooltip">
                              Trending Analysis
                            </span>
                            <span
                              id="trendingAnalysisTooltip"
                              className="tooltip-icon"
                            ></span>
                          </span>
                          {/* {props.tooltip && (
                  <UncontrolledTooltip
                    className="tooltip-content"
                    target="trendingAnalysisTooltip"
                  >
                    {showTooltip("trendingAnalysisTooltip")}
                  </UncontrolledTooltip>
                )} */}
                        </CardTitle>
                        <h2 className="d-inline-flex justify-content-center bold mb-5">
                          {reportData?.user_profile?.trending_analysis}
                          <span></span>
                          <Badge
                            className="trend-badge ml-2"
                            color={
                              reportData?.user_profile?.trending_analysis > 0
                                ? "success"
                                : "danger"
                            }
                          >
                            {reportData?.user_profile?.trending_analysis > 0 ? (
                              <ArrowSmallUpIcon height="20px" width="20px" />
                            ) : (
                              <ArrowSmallDownIcon height="20px" width="20px" />
                            )}
                          </Badge>
                        </h2>
                        <div className="pt-90">
                          <div className="trending-analysis-labels">
                            <span>-1</span>
                            <span>0</span>
                            <span>1</span>
                          </div>
                          <div className="progress trending-analysis-value">
                            <div
                              className="trending-overlap-div"
                              style={{ minWidth: div1Width }}
                            ></div>
                            <div
                              className="trending-overlap-transparent-div"
                              style={{ minWidth: "2px" }}
                            ></div>
                            <div
                              className="trending-overlap-div"
                              style={{ minWidth: div2Width }}
                            ></div>

                            <div
                              className="progress-bar trending-analysis-progress-bar"
                              role="progressbar"
                              style={{ width: "100%" }}
                            ></div>
                          </div>
                          <div className="progress trending-analysis-progress">
                            <div
                              id="progress-1"
                              className="progress-bar trending-analysis-progress-bar"
                              role="progressbar"
                              style={{ width: "100%" }}
                            ></div>
                          </div>
                          <div className="trending-analysis-labels">
                            <span>Bad</span>
                            <span>Normal</span>
                            <span>Good</span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  ) : null}
                  {reportData?.user_profile?.engagement_rate &&
                  reportData?.extra?.engagement_rate_histogram ? (
                    <Card className="card-content-custom engagement">
                      <CardBody>
                        <CardTitle>
                          <span className="tooltip-button-wrap">
                            <span className="text-tooltip">
                              Engagement Rate
                            </span>
                            <span
                              id="engagementTooltip"
                              className="tooltip-icon"
                            ></span>
                          </span>
                          {/* {props.tooltip && (
                            <UncontrolledTooltip
                              className="tooltip-content"
                              target="engagementTooltip"
                            >
                              {showTooltip("engagementTooltip")}
                            </UncontrolledTooltip>
                          )} */}
                        </CardTitle>
                        <h2 className="bold">
                          {reportData?.user_profile?.engagement_rate
                            ? (
                                reportData?.user_profile?.engagement_rate * 100
                              ).toFixed(2) + "%"
                            : ""}
                        </h2>

                        <div
                          id="customChart"
                          className="graph-wrap card-content position-relative"
                        >
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  ) : null}
                  {/* {props.cloudTags &&
                  props.cloudTags.data &&
                  props.cloudTags.data.length ? (
                    <SocialCloud {...props} />
                  ) : null} */}
                  {/* {reportData?.user_profile?.similar_users?.length && (
                      <LookalikesSection reportData={reportData} />
                    )} */}
                </div>
                <LineGraphCard
                  reportData={reportData}
                  historicalData={historicalData}
                  network={mediaKit?.network}
                />
                <EngagementSpread reportData={reportData} />
                <Row className="row-xs">
                  {reportData?.user_profile?.top_hashtags?.length && (
                    <PopularHashtags reportData={reportData} />
                  )}
                  {reportData?.user_profile?.top_mentions?.length && (
                    <PopularMentions reportData={reportData} />
                  )}
                </Row>
                <Row className="row-xs">
                  {reportData?.user_profile?.brand_affinity && (
                    <CreatorBrandAffinity reportData={reportData} />
                  )}
                  {reportData?.user_profile?.interests && (
                    <CreatorInterests reportData={reportData} />
                  )}
                </Row>
              </TabPane>
              <TabPane tabId={1}>
                {mediaKit && reportData && (
                  <AudiencesSection
                    reportData={reportData}
                    generateImgUrl={generateImgUrl}
                  />
                )}
              </TabPane>
            </TabContent>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    mediaKit: state.mediaKits.mediaKit,
    loginUser: state.login.loginUser,
    loading: state.mediaKits.loading,
  };
};
export default connect(mapStateToProps, {
  getMediaKit,
})(MediaKit);
