import { useState } from "react";
import { connect } from "react-redux";
import { Field, Formik, Form } from "formik";
import { Col, FormGroup, Row, Spinner } from "reactstrap";
import { RenderTextField } from "../../../../components";
import { IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { addExternalAccount } from "../../actions";

const InternationalAccountForm = ({
  isReplaceAccountForm,

  loginUser,
  loading,

  addExternalAccount,
  ...props
}) => {
  const [ibanSubmitError, setIbanSubmitError] = useState(null);
  const [cadAccountSubmitError, setCadAccountSubmitError] = useState(null);
  const [gbpAccountSubmitError, setGbpAccountSubmitError] = useState(null);
  const [audAccountSubmitError, setAudAccountSubmitError] = useState(null);
  const [hkdAccountSubmitError, setHkdAccountSubmitError] = useState(null);
  const [nzdAccountSubmitError, setNzdAccountSubmitError] = useState(null);
  const [mxnAccountSubmitError, setMxnAccountSubmitError] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleExternalAccountSubmit = async () => {
    setIbanSubmitError(null);
    if (!elements) {
      return;
    }
    const ibanElement = elements.getElement(IbanElement);
    if (!ibanElement) {
      return;
    }

    stripe
      .createToken(ibanElement, { currency: "eur" })
      .then(async (result) => {
        if (result.error) {
          if (result.error.type === "validation_error") {
            setIbanSubmitError(result.error.message);
          } else {
            setIbanSubmitError("IBAN is invalid");
          }
        } else {
          setIbanSubmitError(null);
          addExternalAccount(result?.token?.id);
        }
      });
  };

  const handleCAExternalAccountSubmit = async ({
    transit_number,
    institution_number,
    ...values
  }) => {
    values.routing_number = `${transit_number?.trim()}-${institution_number?.trim()}`;
    stripe
      .createToken("bank_account", {
        ...values,
        currency: "cad",
        country: "CA",
      })
      .then(async (result) => {
        if (result.error) {
          if (result.error.type === "validation_error") {
            setCadAccountSubmitError(result.error.message);
          } else {
            setCadAccountSubmitError("Account is invalid");
          }
        } else {
          setCadAccountSubmitError(null);
          addExternalAccount(result?.token?.id);
        }
      });
  };

  const handleAUExternalAccountSubmit = async ({ bsb_code, ...values }) => {
    values.routing_number = bsb_code;
    stripe
      .createToken("bank_account", {
        ...values,
        currency: "aud",
        country: "AU",
      })
      .then(async (result) => {
        if (result.error) {
          if (result.error.type === "validation_error") {
            setAudAccountSubmitError(result.error.message);
          } else {
            setAudAccountSubmitError("Account is invalid");
          }
        } else {
          setAudAccountSubmitError(null);
          addExternalAccount(result?.token?.id);
        }
      });
  };
  const handleHKExternalAccountSubmit = async ({
    clearing_code,
    branch_code,
    ...values
  }) => {
    values.routing_number = `${clearing_code?.trim()}-${branch_code?.trim()}`;
    stripe
      .createToken("bank_account", {
        ...values,
        currency: "hkd",
        country: "HK",
      })
      .then(async (result) => {
        if (result.error) {
          if (result.error.type === "validation_error") {
            setHkdAccountSubmitError(result.error.message);
          } else {
            setHkdAccountSubmitError("Account is invalid");
          }
        } else {
          setHkdAccountSubmitError(null);
          addExternalAccount(result?.token?.id);
        }
      });
  };

  const handleGBExternalAccountSubmit = async (values) => {
    if (values.sort_code) {
      values.routing_number = values?.sort_code;
    } else {
      delete values.routing_number;
    }
    stripe
      .createToken("bank_account", {
        ...values,
        currency: "gbp",
        country: "GB",
      })
      .then(async (result) => {
        if (result.error) {
          if (result.error.type === "validation_error") {
            setGbpAccountSubmitError(result.error.message);
          } else {
            setGbpAccountSubmitError("Account is invalid");
          }
        } else {
          setGbpAccountSubmitError(null);
          addExternalAccount(result?.token?.id);
        }
      });
  };

  const handleNZExternalAccountSubmit = async (values) => {
    if (values.routing_number) {
      delete values.routing_number;
    }
    stripe
      .createToken("bank_account", {
        ...values,
        currency: "nzd",
        country: "NZ",
      })
      .then(async (result) => {
        if (result.error) {
          if (result.error.type === "validation_error") {
            setNzdAccountSubmitError(result.error.message);
          } else {
            setNzdAccountSubmitError("Account is invalid");
          }
        } else {
          setNzdAccountSubmitError(null);
          addExternalAccount(result?.token?.id);
        }
      });
  };

  const handleMXExternalAccountSubmit = async (values) => {
    if (values.routing_number) {
      delete values.routing_number;
    }
    stripe
      .createToken("bank_account", {
        ...values,
        currency: "mxn",
        country: "MX",
      })
      .then(async (result) => {
        if (result.error) {
          if (result.error.type === "validation_error") {
            setMxnAccountSubmitError(result.error.message);
          } else {
            setMxnAccountSubmitError("Account is invalid");
          }
        } else {
          setMxnAccountSubmitError(null);
          addExternalAccount(result?.token?.id);
        }
      });
  };

  const handleFormSubmit = async (values) => {
    if (loginUser.country === "CA") {
      handleCAExternalAccountSubmit(values);
    } else if (loginUser.country === "GB") {
      handleGBExternalAccountSubmit(values);
    } else if (loginUser.country === "AU") {
      handleAUExternalAccountSubmit(values);
    } else if (loginUser.country === "HK") {
      handleHKExternalAccountSubmit(values);
    } else if (loginUser.country === "NZ") {
      handleNZExternalAccountSubmit(values);
    } else if (loginUser.country === "MX") {
      handleMXExternalAccountSubmit(values);
    } else {
      handleExternalAccountSubmit();
    }
  };

  const initialValuesCanada = {
    transit_number: "",
    institution_number: "",
    account_number: "",
    sort_code: "",
    bsb_code: "",
    clearing_code: "",
    branch_code: "",
  };

  return (
    <>
      <div className="subheading text-grey">
        <p className="mb-2">
          Enter your bank information below to{" "}
          {isReplaceAccountForm ? "change" : "link"} the{" "}
          {loginUser.country.toUpperCase() || "non-US"} bank account where your
          payouts will be deposited.
        </p>
        {loginUser.country === "CA" && (
          <p>Payouts are currently available in CAD.</p>
        )}
        {loginUser.country === "GB" && (
          <p>Payouts are currently available in GBP.</p>
        )}
        {loginUser.country === "AU" && (
          <p>Payouts are currently available in AUD.</p>
        )}
        {loginUser.country === "HK" && (
          <p>Payouts are currently available in HKD.</p>
        )}
        {loginUser.country === "NZ" && (
          <p>Payouts are currently available in NZD.</p>
        )}
        {loginUser.country === "MX" && (
          <p>Payouts are currently available in MXN.</p>
        )}
        {loginUser.country !== "NZ" &&
          loginUser.country !== "HK" &&
          loginUser.country !== "GB" &&
          loginUser.country !== "AU" &&
          loginUser.country !== "CA" &&
          loginUser.country !== "MX" && (
            <p>Payouts are currently available in EUR.</p>
          )}
      </div>

      <Formik onSubmit={handleFormSubmit} initialValues={initialValuesCanada}>
        <Form className="form-group">
          <FormGroup>
            {loginUser.country === "AU" && (
              <>
                <Row>
                  <Col xs="12" md="10">
                    <label htmlFor="bsb_code" className="form-label">
                      BSB Code
                    </label>
                    <Field
                      id="bsb_code"
                      name="bsb_code"
                      className="form-control"
                      component={RenderTextField}
                      disabled={loginUser.needsStripeOnboarding}
                    />
                  </Col>
                  <Col xs="12" md="10">
                    <label htmlFor="account_number" className="form-label">
                      Account Number
                    </label>
                    <Field
                      id="account_number"
                      name="account_number"
                      className="form-control"
                      component={RenderTextField}
                      disabled={loginUser.needsStripeOnboarding}
                    />
                  </Col>
                </Row>
                {audAccountSubmitError && (
                  <p className="required">{audAccountSubmitError}</p>
                )}
              </>
            )}
            {loginUser.country === "CA" && (
              <>
                <Row>
                  <Col xs="12" md="10">
                    <label htmlFor="transit_number" className="form-label">
                      Transit Number
                    </label>
                    <Field
                      id="transit_number"
                      name="transit_number"
                      className="form-control"
                      component={RenderTextField}
                      disabled={loginUser.needsStripeOnboarding}
                    />
                  </Col>
                  <Col xs="12" md="10">
                    <label htmlFor="institution_number" className="form-label">
                      Institution Number
                    </label>
                    <Field
                      id="institution_number"
                      name="institution_number"
                      className="form-control"
                      component={RenderTextField}
                      disabled={loginUser.needsStripeOnboarding}
                    />
                  </Col>
                  <Col xs="12" md="10">
                    <label htmlFor="account_number" className="form-label">
                      Account Number
                    </label>
                    <Field
                      id="account_number"
                      name="account_number"
                      className="form-control"
                      component={RenderTextField}
                      disabled={loginUser.needsStripeOnboarding}
                    />
                  </Col>
                </Row>
                {cadAccountSubmitError && (
                  <p className="required">{cadAccountSubmitError}</p>
                )}
              </>
            )}
            {loginUser.country === "HK" && (
              <>
                <Row>
                  <Col xs="12" md="10">
                    <label htmlFor="clearing_code" className="form-label">
                      Clearing Code
                    </label>
                    <Field
                      id="clearing_code"
                      name="clearing_code"
                      className="form-control"
                      component={RenderTextField}
                      disabled={loginUser.needsStripeOnboarding}
                    />
                  </Col>
                  <Col xs="12" md="10">
                    <label htmlFor="branch_code" className="form-label">
                      Branch Code
                    </label>
                    <Field
                      id="branch_code"
                      name="branch_code"
                      className="form-control"
                      component={RenderTextField}
                      disabled={loginUser.needsStripeOnboarding}
                    />
                  </Col>
                  <Col xs="12" md="10">
                    <label htmlFor="account_number" className="form-label">
                      Account Number
                    </label>
                    <Field
                      id="account_number"
                      name="account_number"
                      className="form-control"
                      component={RenderTextField}
                      disabled={loginUser.needsStripeOnboarding}
                    />
                  </Col>
                </Row>
                {hkdAccountSubmitError && (
                  <p className="required">{hkdAccountSubmitError}</p>
                )}
              </>
            )}
            {loginUser.country === "GB" && (
              <>
                <Row>
                  <Col xs="12" md="10">
                    <label htmlFor="sort_code" className="form-label">
                      Sort Code
                    </label>
                    <Field
                      id="sort_code"
                      name="sort_code"
                      className="form-control"
                      component={RenderTextField}
                      disabled={loginUser.needsStripeOnboarding}
                    />
                  </Col>
                  <Col xs="12" md="10">
                    <label htmlFor="account_number" className="form-label">
                      Account Number
                    </label>
                    <Field
                      id="account_number"
                      name="account_number"
                      className="form-control"
                      component={RenderTextField}
                      disabled={loginUser.needsStripeOnboarding}
                    />
                  </Col>
                </Row>
                {gbpAccountSubmitError && (
                  <p className="required">{gbpAccountSubmitError}</p>
                )}
              </>
            )}
            {loginUser.country === "NZ" && (
              <>
                <Row>
                  <Col xs="12" md="10">
                    <label htmlFor="account_number" className="form-label">
                      Account Number
                    </label>
                    <Field
                      id="account_number"
                      name="account_number"
                      className="form-control"
                      component={RenderTextField}
                      disabled={loginUser.needsStripeOnboarding}
                    />
                  </Col>
                </Row>
                {nzdAccountSubmitError && (
                  <p className="required">{nzdAccountSubmitError}</p>
                )}
              </>
            )}
            {loginUser.country === "MX" && (
              <>
                <Row>
                  <Col xs="12" md="10">
                    <label htmlFor="account_number" className="form-label">
                      CLABE Number
                    </label>
                    <Field
                      id="account_number"
                      name="account_number"
                      className="form-control"
                      component={RenderTextField}
                      disabled={loginUser.needsStripeOnboarding}
                    />
                  </Col>
                </Row>
                {mxnAccountSubmitError && (
                  <p className="required">{mxnAccountSubmitError}</p>
                )}
              </>
            )}
            {loginUser.country !== "GB" &&
              loginUser.country !== "NZ" &&
              loginUser.country !== "HK" &&
              loginUser.country !== "CA" &&
              loginUser.country !== "AU" &&
              loginUser.country !== "US" &&
              loginUser.country !== "MX" && (
                <>
                  <Row>
                    <Col xs="12" md="10">
                      <label htmlFor="iban" className="form-label">
                        IBAN
                        {ibanSubmitError && (
                          <span className="required ml-2">
                            {ibanSubmitError}
                          </span>
                        )}
                      </label>
                      <IbanElement
                        options={{
                          supportedCountries: ["SEPA"],
                          placeholderCountry: loginUser.country,
                          style: {
                            base: {
                              fontFamily:
                                "HKGrotesk-Regular, Arial, sans-serif",
                            },
                          },
                          disabled: loginUser.needsStripeOnboarding,
                          classes: { base: "form-control stripe-iban-input" },
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            <button
              className="btn btn-default btn-primary mt-2"
              type="submit"
              disabled={
                !stripe ||
                !elements ||
                loginUser.needsStripeOnboarding ||
                loading
              }
            >
              {loading ? (
                <Spinner size="sm" />
              ) : (
                <>
                  {isReplaceAccountForm
                    ? "Update Linked Account"
                    : "Add Account and Save"}
                </>
              )}
            </button>
          </FormGroup>
        </Form>
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    loading: state.settings.loading,
  };
};
export default connect(mapStateToProps, {
  addExternalAccount,
})(InternationalAccountForm);
