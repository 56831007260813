import {
  actionCreator,
  checkHttpStatus,
  checkAuthTokenExpiry,
  privateHeaders,
  showErrorMessage,
} from "../../../actions";
import { logout } from "../../login/actions";
import {
  agreementsActionTypes,
  GET_AGREEMENT_ERROR,
  GET_AGREEMENTS_ERROR,
  READ_MANY_AGREEMENTS_URL,
  READ_ONE_AGREEMENT_URL,
  UPDATE_AGREEMENT_ERROR,
  UPDATE_AGREEMENT_URL,
} from "../constants/index.js";

export const getAgreements = ({
  pageNumber = 1,
  pageSize = 10,
  assignment_id,
  ...params
}) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(agreementsActionTypes.getAgreements.REQUEST));
    try {
      let url = new URL(READ_MANY_AGREEMENTS_URL);
      const params = url.searchParams;
      params.append("sortByUserActionRequired", true);
      if (assignment_id) {
        params.append("assignment_id", assignment_id);
      }
      params.append("adminHasSent", true);
      // params.append("sortKey", "updated");
      // params.append("sortValue", -1);
      params.append("populate", "assignment_id");
      params.append("limit", pageSize);
      const skip = pageNumber > 1 ? (pageNumber - 1) * pageSize : 0;
      params.append("skip", skip);
      url = url.toString();
      const request = new Request(url, {
        method: "GET",
        headers: privateHeaders,
        credentials: "include",
      });
      const response = await fetch(request);
      const data = await response.json();
      if (response.ok && data?.results) {
        dispatch(
          actionCreator(agreementsActionTypes.getAgreements.SUCCESS, data),
        );
        return Promise.resolve(data);
      } else {
        showErrorMessage(GET_AGREEMENTS_ERROR);
        dispatch(actionCreator(agreementsActionTypes.getAgreements.FAILURE));
        return Promise.reject(data?.error);
      }
    } catch (error) {
      dispatch(actionCreator(agreementsActionTypes.getAgreements.FAILURE));
      showErrorMessage(GET_AGREEMENTS_ERROR);
      if (error?.statusCode === 401) {
        dispatch(logout(error));
      }
      return Promise.reject(error);
    }
  };
};

export const getAgreement = (agreement_id) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(agreementsActionTypes.getAgreement.REQUEST));
    let url = new URL(READ_ONE_AGREEMENT_URL);
    const params = url.searchParams;
    params.append("_id", agreement_id);
    params.append("populate", "assignment_id");
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: privateHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response) {
          showErrorMessage(GET_AGREEMENT_ERROR);
          dispatch(actionCreator(agreementsActionTypes.getAgreement.FAILURE));
        } else {
          dispatch(
            actionCreator(agreementsActionTypes.getAgreement.SUCCESS, response),
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(agreementsActionTypes.getAgreement.FAILURE));
        showErrorMessage(GET_AGREEMENT_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const updateAgreement = (agreement_id, data = {}, showError = true) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(agreementsActionTypes.updateAgreement.REQUEST));
    try {
      const request = new Request(UPDATE_AGREEMENT_URL, {
        method: "PUT",
        headers: privateHeaders,
        credentials: "include",
        body: JSON.stringify({ _id: agreement_id, ...data }),
      });
      const response = await fetch(request);
      const result = await response.json();
      if (response.ok && !result.error) {
        dispatch(
          actionCreator(agreementsActionTypes.updateAgreement.SUCCESS, result),
        );
        return Promise.resolve(result);
      } else {
        if (showError) {
          showErrorMessage(UPDATE_AGREEMENT_ERROR);
        }
        dispatch(actionCreator(agreementsActionTypes.updateAgreement.FAILURE));
        return Promise.reject(result.error);
      }
    } catch (error) {
      dispatch(actionCreator(agreementsActionTypes.updateAgreement.FAILURE));
      showErrorMessage(UPDATE_AGREEMENT_URL);
      if (error?.statusCode === 401) {
        dispatch(logout(error));
      }
      return Promise.reject(error);
    }
  };
};
