import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Field } from "formik";
import "../index.scss";
import { showErrorMessage } from "../../../actions";
import { Modal, ModalHeader } from "reactstrap";
import { RenderButton } from "../../../components";
import {
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

const ImageUploader = ({
  uploadedImageFiles = [],
  existingImageUrls = [],
  setUploadedImageFiles,
  setExistingImageUrls,
  maxLimit = 10,
  disabled = false,
  ...props
}) => {
  const memoizedUploadedImageFiles = useMemo(() => {
    return uploadedImageFiles || [];
  }, [uploadedImageFiles]);
  const [uploadedImageDataUrls, setUploadedImageDataUrls] = useState([]);
  const [expandedImage, setExpandedImage] = useState(null);
  const [expandedImageModalOpen, setExpandedImageModalOpen] = useState(false);
  const [shouldRemoveImageOnClosed, setShouldRemoveImageOnClosed] =
    useState(false); // Wait to remove the image until modal close animation is complete to avoid layout shift while modal is still visible

  const defaultFileInputRef = useRef(null);

  const toggleExpandedImageModal = () => {
    setExpandedImageModalOpen(false);
  };

  const convertImageBlobsToDataUrls = useCallback(async () => {
    if (uploadedImageFiles?.length) {
      const promises = uploadedImageFiles.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64 = e.target.result;
            resolve(base64);
          };
          reader.readAsDataURL(file);
        });
      });
      const convertedImages = await Promise.all(promises);
      if (convertedImages?.length) {
        setUploadedImageDataUrls(convertedImages);
        return;
      }
    }
    setUploadedImageDataUrls([]);
  }, [uploadedImageFiles, setUploadedImageDataUrls]);

  const filterAndSetImageArray = ({ index, type = "new" }) => {
    const imageArray = type === "new" ? uploadedImageFiles : existingImageUrls;
    const setArrayFunc =
      type === "new" ? setUploadedImageFiles : setExistingImageUrls;
    const remainingImages = imageArray.filter((image, i) => i !== index);
    setArrayFunc(remainingImages);
  };

  const handleRemoveImage = ({ index, type = "new" }) => {
    if (expandedImageModalOpen) {
      setShouldRemoveImageOnClosed(true);
      toggleExpandedImageModal();
    } else {
      filterAndSetImageArray({ index, type });
    }
  };

  const handleUploadImages = async (e) => {
    const newImageBlobs = Array.from(e.currentTarget?.files);

    if (
      newImageBlobs.length +
        uploadedImageFiles?.length +
        existingImageUrls?.length >
      maxLimit
    ) {
      newImageBlobs.splice(
        maxLimit - (uploadedImageFiles?.length + existingImageUrls?.length),
      );
      showErrorMessage(
        `Posts are limited to ${maxLimit} images. Only the first ${newImageBlobs.length} uploaded images were added.`,
        null,
        null,
        10000,
      );
    }
    setUploadedImageFiles([...uploadedImageFiles, ...newImageBlobs]);
  };

  const handleOnModalClosed = () => {
    if (shouldRemoveImageOnClosed) {
      setShouldRemoveImageOnClosed(false);
      filterAndSetImageArray(expandedImage);
    }
    setExpandedImage(null);
  };

  const getExpandedImageSrc = () => {
    const imageArray =
      expandedImage?.type === "new" ? uploadedImageDataUrls : existingImageUrls;
    return imageArray?.[expandedImage?.index];
  };

  useEffect(() => {
    convertImageBlobsToDataUrls();
  }, [memoizedUploadedImageFiles, convertImageBlobsToDataUrls]);

  return (
    <>
      <Field
        name="images"
        type="file"
        accept="image/*"
        multiple
        innerRef={defaultFileInputRef}
        onChange={handleUploadImages}
        className="file-input-hidden"
      />
      <Modal
        isOpen={expandedImageModalOpen}
        className="expanded-image-modal"
        tabIndex="-1"
        aria-hidden="true"
        onClosed={handleOnModalClosed}
        toggle={toggleExpandedImageModal}
        returnFocusAfterClose={false}
      >
        {expandedImage !== null && (
          <>
            <ModalHeader
              toggle={toggleExpandedImageModal}
              close={
                <RenderButton
                  outline
                  className="btn btn-outline"
                  onClick={() => toggleExpandedImageModal()}
                >
                  <MagnifyingGlassMinusIcon
                    width={20}
                    height={20}
                    strokeWidth={2}
                  />
                  Close Preview
                </RenderButton>
              }
              className="d-flex justify-content-space-between"
            >
              {!disabled && (
                <RenderButton
                  outline
                  color="danger"
                  className="btn btn-outline-danger"
                  onClick={() => handleRemoveImage(expandedImage)}
                >
                  <TrashIcon width={20} height={20} strokeWidth={2} />
                  Remove
                </RenderButton>
              )}
            </ModalHeader>
            <img
              src={getExpandedImageSrc()}
              alt="Expanded media preview"
              className="expanded-image img-fluid"
            />
          </>
        )}
      </Modal>
      <div className="thumbnails">
        {/* existing images */}
        {existingImageUrls?.map((image, index) => (
          <div key={`existing-${index}`}>
            <button
              type="button"
              className="expand-image-btn"
              aria-label="View larger image"
              onClick={() => {
                setExpandedImage({ index, type: "existing" });
                setExpandedImageModalOpen(true);
              }}
            >
              <img
                src={image}
                alt="Thumbnail of uploaded content"
                className="img-fluid"
              />
              <MagnifyingGlassPlusIcon width="24px" height="24px" />
            </button>
            {!disabled && (
              <button
                type="button"
                className="remove-image-btn"
                aria-label="Remove image"
                title="Remove image"
                onClick={() => {
                  handleRemoveImage({ index, type: "existing" });
                }}
              >
                <XMarkIcon width="15px" height="15px" />
              </button>
            )}
          </div>
        ))}
        {/* new images */}
        {uploadedImageDataUrls?.map((image, index) => (
          <div key={`new-${index}`}>
            <button
              type="button"
              className="expand-image-btn"
              aria-label="View larger image"
              onClick={() => {
                setExpandedImage({ index, type: "new" });
                setExpandedImageModalOpen(true);
              }}
            >
              <img
                src={image}
                alt="Thumbnail of uploaded content"
                className="img-fluid"
              />
              <MagnifyingGlassPlusIcon width="24px" height="24px" />
            </button>
            {!disabled && (
              <button
                type="button"
                className="remove-image-btn"
                aria-label="Remove image"
                title="Remove image"
                onClick={() => {
                  handleRemoveImage({ index, type: "new" });
                }}
              >
                <XMarkIcon width="15px" height="15px" />
              </button>
            )}
          </div>
        ))}
        {uploadedImageDataUrls?.length + existingImageUrls?.length < 10 &&
          !disabled && (
            <button
              type="button"
              aria-label="Upload images"
              title="Upload images"
              onClick={() => defaultFileInputRef.current.click()}
              className="upload-photo-btn"
            >
              <PlusIcon
                width="26px"
                height="26px"
                role="img"
                focusable="false"
              />
            </button>
          )}
      </div>
    </>
  );
};

export default ImageUploader;
