import { loginActionTypes } from "../constants";
const initialState = {
  loading: false,
  loginUser: null,
  verificationFailed: false,
  invalidCredentials: false,
  failedLoginEmail: null,
};
const loginReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case loginActionTypes.login.REQUEST:
      return {
        ...state,
        loading: true,
        invalidCredentials: false,
        failedLoginEmail: null,
      };
    case loginActionTypes.login.SUCCESS:
      return {
        ...state,
        loginUser: payload?.result,
        loading: false,
        invalidCredentials: false,
        failedLoginEmail: null,
      };
    case loginActionTypes.login.FAILURE:
      return {
        ...state,
        loading: false,
        loginUser: null,
        invalidCredentials: !!payload?.invalidCredentials,
        failedLoginEmail: payload?.failedLoginEmail || null,
      };
    case loginActionTypes.storeUpdatedLoginUser.SUCCESS:
      return {
        ...state,
        loginUser: payload,
      };
    case loginActionTypes.logout.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginActionTypes.logout.SUCCESS:
      return {
        ...state,
        loginUser: null,
        loading: false,
      };
    case loginActionTypes.logout.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case loginActionTypes.fetchAvailableBalance.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginActionTypes.fetchAvailableBalance.SUCCESS:
      return {
        ...state,
        loading: false,
        loginUser: payload?.result,
      };
    case loginActionTypes.fetchAvailableBalance.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case loginActionTypes.fetchUser.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginActionTypes.fetchUser.SUCCESS:
      return {
        ...state,
        loading: false,
        loginUser: payload?.result,
      };
    case loginActionTypes.fetchUser.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case loginActionTypes.requestAuthLink.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginActionTypes.requestAuthLink.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case loginActionTypes.requestAuthLink.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case loginActionTypes.verifyAuthLink.REQUEST:
      return {
        ...state,
        loading: true,
        verificationFailed: false,
      };
    case loginActionTypes.verifyAuthLink.SUCCESS:
      return {
        ...state,
        loading: false,
        loginUser: payload?.result,
        verificationFailed: false,
      };
    case loginActionTypes.verifyAuthLink.FAILURE:
      return {
        ...state,
        loading: false,
        verificationFailed: true,
      };
    case loginActionTypes.clearAccountErrors.SUCCESS:
      return {
        ...state,
        invalidCredentials: false,
        failedLoginEmail: null,
      };
    default:
      return state;
  }
};

export default loginReducers;
