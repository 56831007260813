import AgreementModal from "./AgreementModal";
import AgreementsTable from "./AgreementsTable";

const AgreementsList = ({
  agreement_id,
  setAgreement_id,
  campaignAssignment_id,
}) => {
  return (
    <>
      <AgreementsTable
        campaignAssignment_id={campaignAssignment_id}
        agreement_id={agreement_id}
        setAgreement_id={setAgreement_id}
      />
      <AgreementModal
        agreement_id={agreement_id}
        setAgreement_id={setAgreement_id}
      />
    </>
  );
};

export default AgreementsList;
