import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { capitalize, showTitle } from "../../../../../actions";

const FollowersNotableFollowers = ({ reportData, ...props }) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Notable Followers</CardTitle>
        <div className="card-content ch-9">
          <ListGroup className="card-list-items">
            {reportData &&
            reportData.audience_followers &&
            reportData.audience_followers.data &&
            reportData.audience_followers.data.notable_users ? (
              reportData.audience_followers.data.notable_users.map(
                (follower, index) => {
                  if (index < 3) {
                    return (
                      <ListGroupItem key={index}>
                        <div className="avatar">
                          <img
                            loading="lazy"
                            src={follower.picture}
                            alt="img"
                          />
                        </div>
                        {reportData.user_profile &&
                        reportData.user_profile.type === "youtube" ? (
                          <div
                            id={`followers_${follower.fullname}`}
                            title={showTitle(
                              `followers_${follower.fullname}`,
                              follower.fullname,
                            )}
                            className="list-content youtube"
                          >
                            {" "}
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={follower.url}
                            >
                              <p>{capitalize(follower.fullname)}</p>
                            </a>
                          </div>
                        ) : (
                          <div
                            id={`followers_${follower.fullname}`}
                            title={showTitle(
                              `followers_${follower.fullname}`,
                              follower.fullname,
                            )}
                            className="list-content"
                          >
                            {" "}
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={follower.url}
                            >
                              {"@" + follower.username}
                            </a>
                            <p>{capitalize(follower.fullname)}</p>
                          </div>
                        )}
                      </ListGroupItem>
                    );
                  }
                  return null;
                },
              )
            ) : (
              <span className="text-center text-grey ">
                <p className="pt-50">No records</p>
              </span>
            )}
          </ListGroup>
        </div>
      </CardBody>
    </Card>
  );
};

export default FollowersNotableFollowers;
