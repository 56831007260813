import settings from "settings";
import { Field, Formik } from "formik";
import { Card, CardBody, Col, Form, FormGroup, Row } from "reactstrap";
import {
  RenderButton,
  RenderPasswordField,
  SubmitButton,
} from "../../components";
import { completeInvitation } from "./actions/index";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PublicCardTitleSection from "../../components/PublicCardTitleSection";

const validate = (values) => {
  const errors = {};

  if (!values.newPassword || values.newPassword.trim() === "") {
    errors.newPassword = "This field is required";
  } else if (values.newPassword.trim().length < 8) {
    errors.newPassword = "Must have at least 8 characters";
  }

  if (!values.newPasswordConfirm || values.newPasswordConfirm.trim() === "") {
    errors.newPasswordConfirm = "This field is required";
  } else if (
    values.newPassword &&
    values.newPasswordConfirm &&
    values.newPassword.trim() !== values.newPasswordConfirm.trim()
  ) {
    errors.newPasswordConfirm = "Password doesn't match";
  }

  return errors;
};

const CreatorInvitation = ({
  stripeRedirectUrl,
  invitationFailed,
  duplicateAccountError,
  completeInvitation,
  ...props
}) => {
  const { id, token } = useParams();

  return (
    <>
      <div className="container public-page-container">
        <Row>
          <Col xs="12" md="10" lg="6">
            <Card className="card-custom px-md-4 px-s-1">
              <CardBody>
                <PublicCardTitleSection
                  title="Create password"
                  subtitle={`You've been invited to the ${settings.versionName} Creator Portal. Create a password to get started.`}
                />
                <Row>
                  <Col>
                    <Formik
                      initialValues={{
                        newPassword: "",
                        newPasswordConfirm: "",
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                        const { newPassword: password } = values;
                        completeInvitation({ id, token, password });
                        setSubmitting(false);
                      }}
                      validate={validate}
                      validateOnBlur={true}
                    >
                      {({ touched, errors, ...props }) => (
                        <Form onSubmit={props.handleSubmit}>
                          <FormGroup>
                            <Row>
                              <Col>
                                <label
                                  htmlFor="newPassword"
                                  className="form-label"
                                >
                                  Password
                                  {errors.newPassword &&
                                    touched.newPassword && (
                                      <span className="required ml-2">
                                        *
                                        {errors?.newPassword ||
                                          "A valid password is required"}
                                      </span>
                                    )}
                                </label>
                                <Field
                                  name="newPassword"
                                  className={
                                    errors.newPassword && touched.newPassword
                                      ? "error"
                                      : ""
                                  }
                                  component={RenderPasswordField}
                                  autoComplete="new-password"
                                />

                                <label
                                  htmlFor="newPasswordConfirm"
                                  className="form-label"
                                >
                                  Confirm Password
                                  {errors.newPasswordConfirm &&
                                    touched.newPasswordConfirm && (
                                      <span className="required ml-2">
                                        *
                                        {errors?.newPasswordConfirm ||
                                          "Passwords must match"}
                                      </span>
                                    )}
                                </label>
                                <Field
                                  name="newPasswordConfirm"
                                  className={
                                    errors.newPasswordConfirm &&
                                    touched.newPasswordConfirm
                                      ? "error"
                                      : ""
                                  }
                                  component={RenderPasswordField}
                                  autoComplete="new-password"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-center">
                                <SubmitButton
                                  className="btn btn-primary btn-wide mt-4"
                                  disabled={props.isSubmitting}
                                >
                                  Create Password
                                </SubmitButton>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="text-center">
                                {stripeRedirectUrl && !invitationFailed && (
                                  <RenderButton
                                    onClick={() => {
                                      window.location.replace(
                                        stripeRedirectUrl,
                                      );
                                    }}
                                    className="btn btn-primary btn-wide mt-5"
                                  >
                                    Continue with Stripe
                                  </RenderButton>
                                )}
                                {invitationFailed && !duplicateAccountError && (
                                  <>
                                    <p className="text-grey mt-5">
                                      Error configuring account
                                    </p>
                                    <RenderButton
                                      onClick={() => {
                                        window.location.replace("/signup");
                                      }}
                                      className="btn btn-primary btn-wide mt-3"
                                    >
                                      Return to signup
                                    </RenderButton>
                                  </>
                                )}
                                {invitationFailed && duplicateAccountError && (
                                  <>
                                    <p className="text-grey mt-5">
                                      Account already exists
                                    </p>
                                    <RenderButton
                                      onClick={() => {
                                        window.location.replace("/");
                                      }}
                                      className="btn btn-primary btn-wide mt-3 mb-4"
                                    >
                                      Return to login
                                    </RenderButton>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </FormGroup>
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    stripeRedirectUrl: state.signup.stripeRedirectUrl,
    invitationFailed: state.signup.invitationFailed,
    duplicateAccountError: state.signup.duplicateAccountError,
  };
};

export default connect(mapStateToProps, {
  completeInvitation,
})(CreatorInvitation);
