import { settingsActionTypes } from "../constants";
import { COMMON_ERROR_MESSAGE } from "../../../actions";
const initialState = {
  loading: false,
  stripeFinancialToken: null,
  stripeOnboardingUrl: null,
  externalAccount: null,
  replaceAccountFormVisible: null,
  errorMessage: null,
  successMessage: null,
  shouldUpdateUser: null,
  showConfirmation: false,
};

const settingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case settingsActionTypes.addExternalAccount.REQUEST:
      return {
        ...state,
        loading: true,
        shouldUpdateUser: null,
        errorMessage: null,
        successMessage: null,
      };
    case settingsActionTypes.addExternalAccount.FAILURE:
      return {
        ...state,
        loading: false,
        shouldUpdateUser: null,
        errorMessage: COMMON_ERROR_MESSAGE,
        successMessage: null,
      };
    case settingsActionTypes.addExternalAccount.SUCCESS:
      return {
        ...state,
        loading: false,
        shouldUpdateUser: true,
        errorMessage: null,
        successMessage: "An external account has been linked successfully",
      };
    case settingsActionTypes.readExternalAccount.REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case settingsActionTypes.readExternalAccount.SUCCESS:
      return {
        ...state,
        loading: false,
        externalAccount: payload?.result,
      };
    case settingsActionTypes.readExternalAccount.FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: COMMON_ERROR_MESSAGE,
        externalAccount: null,
      };
    case settingsActionTypes.updatePayoutUser.REQUEST:
      return {
        ...state,
        loading: true,
        shouldUpdateUser: null,
        errorMessage: null,
        successMessage: null,
        showConfirmation: false,
      };
    case settingsActionTypes.updatePayoutUser.SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: "Account successfully updated.",
        shouldUpdateUser: true,
        showConfirmation: true,
      };
    case settingsActionTypes.updatePayoutUser.FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: COMMON_ERROR_MESSAGE,
        successMessage: null,
        shouldUpdateUser: null,
        showConfirmation: false,
      };
    case settingsActionTypes.deleteAccount.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case settingsActionTypes.deleteAccount.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case settingsActionTypes.deleteAccount.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case settingsActionTypes.refreshStripeOnboardingUrl.REQUEST:
      return {
        ...state,
        loading: true,
        stripeOnboardingUrl: null,
        errorMessage: null,
      };
    case settingsActionTypes.refreshStripeOnboardingUrl.SUCCESS:
      return {
        ...state,
        loading: false,
        stripeOnboardingUrl: payload?.result?.stripeRedirectUrl,
        errorMessage: null,
      };
    case settingsActionTypes.refreshStripeOnboardingUrl.FAILURE:
      return {
        ...state,
        loading: false,
        stripeOnboardingUrl: null,
        errorMessage: COMMON_ERROR_MESSAGE,
      };
    case settingsActionTypes.generateFinancialConnectionToken.REQUEST:
      return {
        ...state,
        loading: true,
        stripeFinancialToken: null,
        errorMessage: null,
      };
    case settingsActionTypes.generateFinancialConnectionToken.SUCCESS:
      return {
        ...state,
        loading: false,
        stripeFinancialToken: payload?.result,
        errorMessage: null,
      };
    case settingsActionTypes.generateFinancialConnectionToken.FAILURE:
      return {
        ...state,
        loading: false,
        stripeFinancialToken: null,
        errorMessage: COMMON_ERROR_MESSAGE,
      };
    case settingsActionTypes.setReplaceAccountFormVisible.SUCCESS:
      return {
        ...state,
        replaceAccountFormVisible: !!payload,
      };
    case settingsActionTypes.clearErrorMessages.SUCCESS:
      return {
        ...state,
        errorMessage: null,
      };
    case settingsActionTypes.clearSuccessMessages.SUCCESS:
      return {
        ...state,
        successMessage: null,
      };
    default: {
      return state;
    }
  }
};

export default settingsReducer;
