import heroImg from "../../../assets/img/step_one_hero.png";

const StepOne = () => {
  return (
    <div className="step-one m-80">
      <img src={heroImg} className="hero-img" alt="hero img" />
      <h1 className="heading title mt-0">
        We&apos;re thrilled to{" "}
        <span className="gradient new-line">have you here!</span>
      </h1>
      <p className="description px-3">
        Together, we&apos;ll elevate you as a creator. You&apos;re in the
        company of the best brands, the top influencers, and the worlds leading
        social media platforms. Welcome aboard!
      </p>
    </div>
  );
};

export default StepOne;
