import { API_URL, createRequestActionTypes } from "../../../actions/index";

export const READ_ONE_HUBSPOT_CREATOR_URL = `${API_URL}/api/v1/hubspot`;
export const CREATE_TALENT_EMAIL_URL = `${API_URL}/api/v11/talent/email`;

export const GET_HUBSPOT_CREATOR_ERROR = "Error retrieving creator account";

export const CREATE_EMAIL_ERROR = "Error creating email";
export const EMAIL_UNAVAILABLE_ERROR = "Email Address Unavailable";

export const onboardingActionTypes = {
  getHubspotCreator: createRequestActionTypes("GET_HUBSPOT_CREATOR"),
  createTalentEmail: createRequestActionTypes("CREATE_TALENT_EMAIL"),
};
