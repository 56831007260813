import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  CheckIcon,
  CreditCardIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/24/outline";
import { titlelize } from "../../../../actions";
import { RenderButton } from "../../../../components";
import NeedsFinancialAccount from "./NeedsFinancialAccount";
import {
  addExternalAccount,
  generateFinancialConnectionToken,
  readExternalAccount,
  setReplaceAccountFormVisible,
} from "../../actions";
import { useStripe } from "@stripe/react-stripe-js";
import "./index.scss";
import { Col, Row } from "reactstrap";
import LinkedBankAccountSkeleton from "./LinkedBankAccountSkeleton";
import InternationalAccountForm from "./InternationalAccountForm";

const FinancialAccountSection = ({
  externalAccount,
  readExternalAccount,
  addExternalAccount,
  stripeFinancialToken,
  generateFinancialConnectionToken,
  setReplaceAccountFormVisible,
  replaceAccountFormVisible,
  loginUser,
  ...props
}) => {
  const [pendingOpenModal, setPendingOpenModal] = useState(false);
  const stripe = useStripe();
  const openModalWithToken = (token) => {
    setPendingOpenModal(false);
    stripe.collectBankAccountToken({ clientSecret: token }).then((result) => {
      if (result) {
        const token = result?.token?.id;
        if (token) addExternalAccount(token);
      }
    });
  };

  useEffect(() => {
    if (!loginUser.needsStripeFinancialConnection) {
      readExternalAccount();
    }
  }, [loginUser.needsStripeFinancialConnection, readExternalAccount]);

  useEffect(() => {
    if (stripeFinancialToken && pendingOpenModal) {
      openModalWithToken(stripeFinancialToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeFinancialToken, openModalWithToken]);

  const initiateFinancialConnectionsModal = async () => {
    setPendingOpenModal(true);
    await generateFinancialConnectionToken();
  };

  const isLoading = useSelector((state) => state.settings.loading);
  if (isLoading) {
    return <LinkedBankAccountSkeleton />;
  }

  return (
    <>
      {loginUser.needsStripeFinancialConnection && (
        <NeedsFinancialAccount
          initiateFinancialConnectionsModal={initiateFinancialConnectionsModal}
        />
      )}
      {!loginUser.needsStripeFinancialConnection && (
        <Row className="stripe-account">
          <Col xs="12">
            <div className="subheading d-flex align-items-center justify-content-between">
              <h2 className="title">Bank Account</h2>
              <h3 className="ml-2">
                <span className={"badge badge-success"}>
                  <CheckIcon
                    width={20}
                    height={20}
                    strokeWidth={1.4}
                    className="mr-1"
                  />
                  Linked
                </span>
              </h3>
            </div>
          </Col>
          <Col xs="12" md="8">
            {externalAccount?.object === "bank_account" &&
              externalAccount?.bank_name &&
              externalAccount?.last4 && (
                <div className="d-flex align-items-center external-account mb-4">
                  <span className="icon-container mr-3">
                    <BuildingLibraryIcon
                      className="link-icon"
                      width={40}
                      height={40}
                      strokeWidth={1.5}
                    />
                  </span>
                  <div>
                    <h3 className="bold m-0">
                      {titlelize(externalAccount?.bank_name)}
                    </h3>
                    <p className="text-grey mb-0">
                      Account ending in {externalAccount?.last4}
                    </p>
                  </div>
                </div>
              )}
            {externalAccount?.object === "card" &&
              externalAccount?.brand &&
              externalAccount?.funding &&
              externalAccount?.last4 &&
              externalAccount?.exp_month &&
              externalAccount?.exp_year && (
                <div className="d-flex align-items-center mb-4">
                  <span className="icon-container mr-3">
                    <CreditCardIcon
                      className="link-icon"
                      width={40}
                      height={40}
                      strokeWidth={1.5}
                    />
                  </span>
                  <div>
                    <h3>
                      {titlelize(
                        `${externalAccount?.brand} ${externalAccount?.funding} (...${externalAccount?.last4})`,
                      )}
                    </h3>
                    <p className="mb-0">
                      Exp {externalAccount?.exp_month}/
                      {externalAccount?.exp_year}
                    </p>
                  </div>
                </div>
              )}

            {!loginUser.country || loginUser.country === "US" ? (
              <RenderButton
                onClick={() => {
                  initiateFinancialConnectionsModal();
                }}
                className="btn btn-primary btn mt-1 mb-1"
              >
                Manage in Stripe
              </RenderButton>
            ) : (
              <InternationalAccountForm isReplaceAccountForm={true} />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    externalAccount: state.settings.externalAccount,
    stripeFinancialToken: state.settings.stripeFinancialToken,
    replaceAccountFormVisible: state.settings.replaceAccountFormVisible,
  };
};
export default connect(mapStateToProps, {
  addExternalAccount,
  readExternalAccount,
  generateFinancialConnectionToken,
  setReplaceAccountFormVisible,
})(FinancialAccountSection);
