import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

import chart from "../../../assets/img/chart.svg";
import lightbulb from "../../../assets/img/lightbulb.svg";
import paper from "../../../assets/img/paper.svg";
import handshake from "../../../assets/img/handshake.svg";
import frame from "../../../assets/img/frame.svg";
import rainbow from "../../../assets/img/rainbow.svg";
import star from "../../../assets/img/star.svg";
import target from "../../../assets/img/target.svg";
import rocket from "../../../assets/img/rocket.svg";
import lightning from "../../../assets/img/lightning.svg";
import { RenderCurrency } from "../../../components";

const StepFour = () => {
  const cards = [
    {
      name: "NeoReach Spark",
      description: "Start Your Journey confidently.",
      threshold: 600,
      content: [
        {
          emoji: chart,
          title: "NeoReach Email Address",
          description:
            "Elevate your brand with an email that instantly signals your NeoReach affiliation.",
        },
        {
          emoji: lightbulb,
          title: "Portal Access",
          description:
            "Vew and manage your earnings, metrics, and upcoming opportunities.",
        },
        {
          emoji: paper,
          title: "Dedicated Email Manager",
          description:
            "We identify the most valuable brand collaborations and secure you the best rates.",
        },
      ],
    },
    {
      name: "NeoReach Pulse",
      description: "All Spark features, plus...",
      threshold: 5000,
      content: [
        {
          emoji: handshake,
          title: "Priority Inbox Management",
          description:
            "Get real-time, prioritized management of your NeoReach email inbox.",
        },
        {
          emoji: frame,
          title: "Negotiating Experts",
          description:
            "Our experts will ensure you get the most value out of your brand partnerships.",
        },
        {
          emoji: rainbow,
          title: "Invoicing & Tax Support",
          description:
            "Navigate the complexities of invoicing & taxes with expert advice tailored for creators.",
        },
      ],
    },
    {
      name: "NeoReach Premiere",
      description: "All Pulse features, plus...",
      threshold: 25000,
      content: [
        {
          emoji: star,
          title: "Elevated Management",
          description:
            "Geared towards full-time creators who want to dominate their niche.",
        },
        {
          emoji: target,
          title: "Brand Strategy Excellence",
          description:
            "We go the extra mile to align you with brands that fit your audience and persona.",
        },
        {
          emoji: rocket,
          title: "Spotlight Features",
          description:
            "Get the chance to be featured in our high-profile campaigns.",
        },
      ],
    },
  ];

  const CardContent = (content) =>
    content.map((item, index) => (
      <Row className="mt-3 px-0" key={index}>
        <Col xs="2">
          <img src={item.emoji} width={26} alt="emoji" />
        </Col>
        <Col xs="10" className="px-0">
          <h3 className="subtitle mb-1">{item.title}</h3>
          <p className="content-description mb-0">{item.description}</p>
        </Col>
      </Row>
    ));

  const TalentTiers = cards.map((tier, index) => (
    <Card
      className="card-content-custom talent-tier-card mb-3"
      key={`${tier.name}-${index}`}
    >
      <CardBody className="p-0">
        <CardTitle>
          <div className="card-icon mb-3">
            <img src={lightning} alt="talent tier icon" />
          </div>
          <h2 className="title">{tier.name}</h2>
          <p className="description">{tier.description}</p>
        </CardTitle>
        <h1 className="heading m-0">
          <RenderCurrency amount={tier.threshold} />
        </h1>
        <p className="description mb-0">Earning Milestone</p>
        <hr className="my-4" />
        {CardContent(tier.content)}
      </CardBody>
    </Card>
  ));

  return (
    <div className="step-four px-3">
      <h1 className="heading title">
        Unlock Your Path
        <span className="gradient new-line">To Creator Mastery</span>
      </h1>
      <p className="description mb-4">
        As you grow, we grow with you. Each tier offers unique benefits designed
        to help you achieve your full potential.
      </p>
      {TalentTiers}
    </div>
  );
};

export default StepFour;
