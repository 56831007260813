import { useState, useEffect } from "react";
import { RenderButton } from "../../../components";

function DelayedButton({
  buttonText,
  buttonStyle,
  buttonAction,
  delaySeconds = 60,
  skipInitialDelay,
  runButtonActionOnMount,
}) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [timer, setTimer] = useState(null);

  const startTimer = () => {
    setIsDisabled(true);
    setSecondsLeft(delaySeconds);
    setTimer(Date.now() + delaySeconds * 1000);
  };

  useEffect(() => {
    if (runButtonActionOnMount) {
      buttonAction();
    }
    if (!skipInitialDelay) {
      startTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDisabled) {
      const countdownTimer = setInterval(() => {
        const remaining = Math.ceil((timer - Date.now()) / 1000);
        setSecondsLeft(remaining);
        if (remaining <= 0) {
          setIsDisabled(false);
          clearInterval(countdownTimer);
        }
      }, 1000);
      return () => {
        clearInterval(countdownTimer);
      };
    }
  }, [isDisabled, timer]);

  const handleClick = () => {
    clearTimeout(timer);
    startTimer();
    buttonAction();
  };

  return (
    <RenderButton
      className={`btn ${
        buttonStyle === "outline" ? "btn-outline-primary" : "btn-primary"
      } btn-wide mt-3`}
      onClick={handleClick}
      disabled={isDisabled}
    >
      {isDisabled ? `${buttonText} (${secondsLeft} seconds)` : buttonText}
    </RenderButton>
  );
}

export default DelayedButton;
