import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Nav,
  NavItem,
  TabContent,
  Row,
  Col,
  FormGroup,
  NavLink as NavLinkBoot,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  RenderCheckBox,
  RenderCurrency,
  RenderTextField,
  SubmitButton,
} from "../../components";
import history from "../../history";
import {
  answerCampaignInvitation,
  getCampaign,
  getContents,
  updateCampaignAssignment,
} from "./actions";
import CampaignContentTabPane from "./components/CampaignContentTabPane";
import AlertBanner from "../../components/AlertBanner";
import Deliverables from "../deliverables";
import CampaignAgreements from "./components/CampaignAgreements";
import { CampaignBrief, CampaignInvitation } from "./components/CampaignBrief";

const statuses = [
  {
    tab: "draft",
    filterValues: "draft",
  },
  {
    tab: "rejected",
    filterValues: "rejected",
  },
  {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    tab: "awaiting approval",
    filterValues: "awaiting approval",
  },
  {
    tab: "approved",
    filterValues: "approved,link rejected",
  },
  {
    tab: "complete",
    filterValues: "awaiting link approval,complete",
  },
];

const Campaign = ({
  campaignAssignment_id,

  campaign,
  reloadContents,

  getCampaign,
  getContents,
  answerCampaignInvitation,
  updateCampaignAssignment,
  ...props
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [activeTabId, setActiveTabId] = useState("draft");
  const [briefIsOpen, setBriefIsOpen] = useState(false);

  useEffect(() => {
    if (
      campaign &&
      (campaign?.campaignInvitationStatus === "declined" ||
        campaign?.campaignInvitationStatus === "rejected" ||
        (!campaign?.allowContentApproval &&
          !campaign?.__deliverableMetrics?.total &&
          !campaign?.__agreementMetrics?.total))
    ) {
      // send user back to campaigns page
      history.push("/campaigns");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign?.allowContentApproval, campaign?.campaignInvitationStatus]);

  useEffect(() => {
    if (campaignAssignment_id) {
      getCampaign(campaignAssignment_id);
    }
  }, [campaignAssignment_id, getCampaign]);

  useEffect(() => {
    if (campaignAssignment_id) {
      getContents({
        assignment_id: campaignAssignment_id,
        // get matching filterValue from statuses by tab value
        status: statuses.find((status) => status.tab === activeTabId)
          ?.filterValues,
      });
    }
  }, [activeTabId, campaignAssignment_id, getContents]);

  useEffect(() => {
    if (reloadContents) {
      getCampaign(campaignAssignment_id);
      getContents({
        assignment_id: campaignAssignment_id,
        status: activeTabId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getContents, reloadContents]);

  const handleToggleModal = () => {
    setBriefIsOpen(!briefIsOpen);
  };

  return (
    <>
      {campaign && (
        <div className="container campaign-container">
          <NavLink to="/campaigns" className="nav-back-arrow">
            <div>
              <ArrowLeftIcon height={24} width={24} />
            </div>
          </NavLink>
          {campaign?.allowContentApproval &&
          campaign?.campaignInvitationStatus === "pending" ? (
            <CampaignInvitation
              campaignAssignment_id={campaignAssignment_id}
              campaignName={campaign.campaignName}
              answerCampaignInvitation={answerCampaignInvitation}
            />
          ) : (
            <>
              {campaign?.allowContentApproval &&
              campaign?.campaignInvitationStatus === "accepted" &&
              !campaign?.completedCampaignBrief ? (
                <Formik
                  initialValues={{
                    lemon8Username: "",
                    acceptTerms: false,
                    fullName: "",
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    updateCampaignAssignment(
                      {
                        _id: campaignAssignment_id,
                        completedCampaignBrief: true,
                        lemon8Username: values?.lemon8Username,
                        fullName: values?.fullName,
                      },
                      true,
                    );
                    setSubmitting(false);
                  }}
                  validateOnBlur={true}
                  validateOnChange={false}
                  validate={(values) => {
                    const errors = {};
                    if (!values.lemon8Username)
                      errors.lemon8Username =
                        "A valid Lemon8 username is required";
                    if (!values.fullName)
                      errors.fullName = "Your full name is required";
                    if (!values.acceptTerms)
                      errors.acceptTerms =
                        "You must accept the contract terms before continuing";
                    return errors;
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <Form className="accept-brief-form" onSubmit={handleSubmit}>
                      <FormGroup className="px-3">
                        <Col xs="12" lg="10" xl="8">
                          <h1 className="heading">
                            You&apos;re joining{" "}
                            <span className="bold">
                              {campaign.campaignName}
                            </span>
                          </h1>
                          <h2 className="subheading">
                            Rate:{" "}
                            <b className="bold">
                              <RenderCurrency
                                currency={"USD"}
                                renderCurrencyCode={true}
                                amount={30}
                              />
                              /post
                            </b>
                          </h2>

                          <h2 className="heading title">Next Steps</h2>
                          <p>
                            {/* TODO: #contentapproval add support link */}
                            Please review the campaign brief below, enter your
                            Lemon8 username, and accept the campaign agreement.
                          </p>
                          <p>
                            After accepting the campaign agreement you can start
                            submitting content by uploading screenshots of your
                            Lemon8 drafts (created on the Lemon8 app) to this
                            Creator Portal. Then the Approval Team will review
                            the content for final approval.
                          </p>
                          <p>
                            Once a draft is approved you will publish it on
                            Lemon8 then provide the post&apos;s link/URL for our
                            system to verify.{" "}
                            <span className="bold">
                              You must publish the approved content to Lemon8 in
                              order to be paid.
                            </span>
                          </p>
                          <p className="subheading">
                            {" "}
                            If you need assistance or have any questions, please
                            don&apos;t hesitate to contact your campaign
                            manager.
                          </p>

                          <p className="bold">
                            Be sure to read the brief for your specific post
                            category and make sure that you are meeting all of
                            the guidelines.
                          </p>

                          <h2 className="heading title">
                            Campaign Brief and Content Requirements
                          </h2>
                          <CampaignBrief />

                          <h2 className="heading title">
                            Enter Lemon8 Username
                          </h2>
                          <p>
                            To find your Lemon8 username simply go to your
                            profile and find your @username at the top. If you
                            change your username please do so before
                            registering, or reach out to let us know.
                          </p>
                          <Row>
                            <Col xs="12" sm="10" md="8">
                              <label
                                htmlFor="lemon8Username"
                                className="form-label"
                              >
                                Lemon8 Username
                                {errors.lemon8Username &&
                                  touched.lemon8Username && (
                                    <span className="required ml-2">
                                      *
                                      {errors?.lemon8Username ||
                                        "A valid Lemon8 username is required"}
                                    </span>
                                  )}
                              </label>
                              <Field
                                name="lemon8Username"
                                className={
                                  errors.lemon8Username &&
                                  touched.lemon8Username
                                    ? "error"
                                    : ""
                                }
                                component={RenderTextField}
                              />
                            </Col>
                          </Row>

                          <h2 className="heading title">Campaign Rate/Terms</h2>
                          <p>
                            In exchange for posting content that meets all of
                            the requirements above{" "}
                            <span className="bold">
                              you will be paid{" "}
                              <RenderCurrency
                                currency={"USD"}
                                renderCurrencyCode={true}
                                amount={30}
                              />
                              /post
                            </span>
                            . Payment terms are Net 60, at the latest.
                          </p>
                          <Row>
                            <Col xs="12" sm="10" md="8">
                              <label htmlFor="fullName" className="form-label">
                                Full Legal Name
                                {errors.fullName && touched.fullName && (
                                  <span className="required ml-2">
                                    *
                                    {errors?.fullName ||
                                      "Your full name is required"}
                                  </span>
                                )}
                              </label>
                              <Field
                                name="fullName"
                                className={
                                  errors.fullName && touched.fullName
                                    ? "error"
                                    : ""
                                }
                                component={RenderTextField}
                              />
                            </Col>
                          </Row>
                          <div
                            style={{
                              marginLeft: "1.25rem",
                            }}
                          >
                            <Field
                              name="acceptTerms"
                              id="acceptTerms"
                              type="checkbox"
                              onChange={(e) =>
                                setFieldValue("acceptTerms", e.target.checked)
                              }
                              checked={values.acceptTerms}
                              component={RenderCheckBox}
                              className="styled-checkbox"
                            />
                            <label htmlFor="acceptTerms" className="form-label">
                              I have read and accept the{" "}
                              <a
                                className="link-more"
                                href="https://docs.google.com/document/d/1opqaisb6MjGCshdggUbVYShru0i-42Ei3qZObnImTlY/edit"
                              >
                                Lemon8 Agreement.
                              </a>
                              {errors.acceptTerms && touched.acceptTerms && (
                                <span className="required ml-2">
                                  *
                                  {errors?.acceptTerms ||
                                    "You must accept the contract terms to continue"}
                                </span>
                              )}
                            </label>
                          </div>
                        </Col>
                        <Col xs="12" sm="10" md="6" xl="4">
                          <SubmitButton
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary btn-wide mt-5 mb-5"
                          >
                            {isSubmitting ? "Submitting..." : "Submit"}
                          </SubmitButton>
                        </Col>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              ) : (
                <>
                  <Row>
                    <Col>
                      <h1 className="heading title">{campaign.campaignName}</h1>
                    </Col>
                  </Row>
                  {!!campaign?.__agreementMetrics?.total && (
                    <CampaignAgreements campaign_id={campaign._id} />
                  )}
                  {!!campaign?.__deliverableMetrics?.total && (
                    <Row>
                      <Col xs="12">
                        <h2 className="heading title">Deliverables</h2>
                        <p className="text-grey mb-1">
                          Post, submit proof, and get paid! After your content
                          goes live, provide the URL here to complete your
                          Deliverable.
                        </p>
                        <p className="text-grey mb-1">
                          Please contact your campaign manager if you need
                          assistance or have any questions.
                        </p>
                      </Col>
                      <Col xs="12">
                        <Deliverables assignment_id={campaignAssignment_id} />
                      </Col>
                    </Row>
                  )}
                  {campaign?.allowContentApproval && (
                    <>
                      <Row>
                        <Col>
                          <h2 className="heading title">Content Submission</h2>
                          <p className="text-grey mb-1">
                            Submit your draft content for approval. After
                            approval, publish it and submit the live URL here to
                            get paid.
                          </p>
                          <p className="text-grey mb-3">
                            Please contact your campaign manager if you need
                            assistance or have any questions.
                          </p>
                        </Col>
                        <Col xs="12">
                          {/* <h1 className="heading title">
                            {campaign.campaignName}
                          </h1> */}
                          {/* <p className="subheading subtitle text-grey">
                            Content requirements and examples can be found in the
                            campaign brief.{" "}
                            <span
                              onClick={() => {
                                setBriefIsOpen(true);
                              }}
                              className="link-more bold ml-2"
                            >
                              View Full Brief
                            </span>
                          </p> */}
                          <AlertBanner
                            message="Before uploading any content be sure to read the campaign brief and guidelines for your content topics."
                            button={
                              <span
                                onClick={() => {
                                  setBriefIsOpen(true);
                                }}
                                className="link-more bold ml-2"
                              >
                                View Full Brief
                              </span>
                            }
                          />
                          {/* TODO: #contentapproval add support link */}
                          {/* <p>
                            Please <a href="">contact the Campaign Ops team</a> if
                            className="link-more"
                            you need assistance or have any questions.{" "}
                          </p> */}
                        </Col>
                        <Col xs="12" className="nav-tabbed-custom">
                          <Nav pills fill>
                            <NavItem>
                              <NavLinkBoot
                                className={
                                  activeTabId === "draft"
                                    ? "active mb-3"
                                    : "mb-3"
                                }
                                onClick={() => {
                                  setActiveTabId("draft");
                                }}
                              >
                                Drafts
                                {/* TODO: notification dots once calcs are done */}
                              </NavLinkBoot>
                            </NavItem>
                            <NavItem>
                              <NavLinkBoot
                                className={
                                  activeTabId === "awaiting approval"
                                    ? "active"
                                    : ""
                                }
                                onClick={() => {
                                  setActiveTabId("awaiting approval");
                                }}
                              >
                                Pending
                              </NavLinkBoot>
                            </NavItem>
                            {campaign?.__contentCounts?.rejected > 0 ? (
                              <NavItem>
                                <NavLinkBoot
                                  className={
                                    activeTabId === "rejected" ? "active" : ""
                                  }
                                  onClick={() => {
                                    setActiveTabId("rejected");
                                  }}
                                >
                                  Rejected
                                  {campaign?.__contentCounts?.rejected > 0 && (
                                    <div className="notification-dot">
                                      <span className="small">
                                        {campaign?.__contentCounts?.rejected}
                                      </span>
                                    </div>
                                  )}
                                </NavLinkBoot>
                              </NavItem>
                            ) : null}
                            <NavItem>
                              <NavLinkBoot
                                className={
                                  activeTabId === "approved" ? "active" : ""
                                }
                                onClick={() => {
                                  setActiveTabId("approved");
                                }}
                              >
                                Approved
                                {campaign?.__contentCounts?.approved > 0 && (
                                  <div className="notification-dot"></div>
                                )}
                              </NavLinkBoot>
                            </NavItem>
                            <NavItem>
                              <NavLinkBoot
                                className={
                                  activeTabId === "complete"
                                    ? "active mb-3"
                                    : "mb-3"
                                }
                                onClick={() => {
                                  setActiveTabId("complete");
                                }}
                              >
                                Posted
                              </NavLinkBoot>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={activeTabId}>
                            {statuses.map((status, index) => (
                              <CampaignContentTabPane
                                tabId={status.tab}
                                key={index}
                                campaignAssignment_id={campaignAssignment_id}
                              />
                            ))}
                          </TabContent>
                        </Col>
                      </Row>
                      <Modal
                        id="campaign-brief-modal"
                        isOpen={briefIsOpen}
                        toggle={handleToggleModal}
                        className="modal-dialog-centered modal-lg campaign-brief-modal"
                      >
                        <ModalHeader toggle={handleToggleModal}>
                          <h2 className="title ml-4 mt-2">
                            Campaign Brief and Instructions
                          </h2>
                        </ModalHeader>
                        <ModalBody>
                          <Col xs="12" className="px-3">
                            <CampaignBrief />
                          </Col>
                        </ModalBody>
                      </Modal>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    campaign: state.campaigns.campaign,
    reloadContents: state.campaigns.reloadContents,
  };
};
export default connect(mapStateToProps, {
  getCampaign,
  getContents,
  answerCampaignInvitation,
  updateCampaignAssignment,
})(Campaign);
