import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { setLoginUserFromCookie } from "../modules/login/actions";

const PrivateRoute = ({
  component: Component,
  loginUser,
  userCookie,
  setLoginUserFromCookie,
  ...rest
}) => {
  if (userCookie && !loginUser) {
    setLoginUserFromCookie();
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userCookie || !loginUser) {
          return <Redirect to="/" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
  };
};

export default connect(mapStateToProps, {
  setLoginUserFromCookie,
})(PrivateRoute);
