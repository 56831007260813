import "./index.scss";

const PhoneContainer = ({ children, ...props }) => {
  return (
    <figure className="iphone">
      <div className="phone">
        <div className="island"></div>
        {children}
        <div className="home"></div>
      </div>
    </figure>
  );
};
export default PhoneContainer;
