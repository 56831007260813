import { Card, CardBody, CardTitle } from "reactstrap";
import "./index.scss";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const BalanceTileSkeleton = () => {
  return (
    <Card className="card-content-custom balance-tile">
      <CardBody>
        <CardTitle className="mb-0">
          <h2 className="title">
            <Skeleton height={26} width={"40%"} />
          </h2>
        </CardTitle>
        <h2 className="balance">
          <Skeleton width={"80%"} />
        </h2>
        <CardTitle className="available-balance">
          <Skeleton height={22} width={"60%"} />
        </CardTitle>
        <Skeleton height={45} width={155} />
      </CardBody>
    </Card>
  );
};

export default BalanceTileSkeleton;
