import { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { Card, CardBody, Col, Form, FormGroup, Row } from "reactstrap";
import { RenderTextField, SubmitButton } from "../../components";
import { requestAuthLink } from "./actions/index";
import { connect } from "react-redux";
import AltAuthOptionLinks from "./components/AltAuthOptionLinks";
import EmailSendConfirmation from "./components/EmailSendConfirmation";
import PublicCardTitleSection from "../../components/PublicCardTitleSection";
import { NavLink } from "react-router-dom";

const validate = (values) => {
  const errors = {};

  if (!values.email || values.email.trim() === "") {
    errors.email = "This field is required";
  } else if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i.test(values.email)
  ) {
    errors.email = "Invalid email";
  }

  return errors;
};

const LoginMagicLink = ({ requestAuthLink, ...props }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [email, setEmail] = useState("");
  const [prefilledEmail, setPrefilledEmail] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const email = query.get("email");
    setPrefilledEmail(email);
  }, [props.location.search]);
  const getInitialValues = () => {
    return {
      email: prefilledEmail || "",
    };
  };

  return (
    <>
      <div className="container public-page-container">
        <Row>
          <Col xs="12" md="8" lg="6">
            <Card className="card-custom px-md-4 px-s-1">
              <CardBody>
                <PublicCardTitleSection
                  title="Login with magic link"
                  subtitle={"Welcome back to the Creator Portal"}
                />
                <Row>
                  <Col>
                    {showConfirmation ? (
                      <EmailSendConfirmation
                        sendAction={() => {
                          requestAuthLink(email);
                        }}
                      />
                    ) : (
                      <Formik
                        enableReinitialize={true}
                        initialValues={getInitialValues()}
                        onSubmit={async (values, { setSubmitting }) => {
                          await requestAuthLink(values.email);
                          setEmail(values.email);
                          setSubmitting(false);
                          setShowConfirmation(true);
                        }}
                        validate={validate}
                        validateOnBlur={true}
                      >
                        {({ touched, errors, ...props }) => (
                          <Form onSubmit={props.handleSubmit}>
                            <FormGroup>
                              <Row>
                                <Col>
                                  <label htmlFor="email" className="form-label">
                                    Email
                                    {errors.email && touched.email && (
                                      <span className="required ml-2">
                                        *
                                        {errors?.email ||
                                          "A valid email is required"}
                                      </span>
                                    )}
                                  </label>
                                  <Field
                                    name="email"
                                    className={
                                      errors.email && touched.email
                                        ? "error"
                                        : ""
                                    }
                                    component={RenderTextField}
                                    autoComplete="email"
                                  />
                                  <NavLink to="/" className="link-more ml-1">
                                    Use password instead
                                  </NavLink>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="text-center">
                                  <SubmitButton
                                    className="btn btn-primary btn-wide mt-4 mb-4"
                                    disabled={props.isSubmitting}
                                  >
                                    Send Magic Link
                                  </SubmitButton>
                                  <AltAuthOptionLinks
                                    hideAuthTypes={["magicLink", "recovery"]}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
  };
};

export default connect(mapStateToProps, {
  requestAuthLink,
})(LoginMagicLink);
