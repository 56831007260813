import {
  actionCreator,
  privateHeaders,
  checkHttpStatus,
  checkAuthTokenExpiry,
} from "../../../actions";
import {
  UserBalanceDataActionTypes,
  GET_USER_BALANCE_DATA_URL,
} from "../constants";

export const getUserBalanceData = (userId) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(UserBalanceDataActionTypes.getUserBalanceData.REQUEST),
    );
    fetch(`${GET_USER_BALANCE_DATA_URL}${userId}/balance`, {
      method: "GET",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            UserBalanceDataActionTypes.getUserBalanceData.SUCCESS,
            response,
          ),
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(UserBalanceDataActionTypes.getUserBalanceData.FAILURE),
        );
      });
  };
};
