import { Table, Row, Col } from "reactstrap";
import RecentDepositsRowSkeleton from "./RecentDepositsRowSkeleton";

const RecentDepositsTableSkeleton = () => {
  return (
    <Row className="search-results-wrap">
      <Col md="12">
        <div className="table-responsive table-outer user-profile-table">
          <Table borderless size="sm" className="payouts-table">
            <thead>
              <tr>
                <th width="10%"></th>
                <th width="60%"></th>
                <th width="20%"></th>
              </tr>
            </thead>
            <tbody>
              <RecentDepositsRowSkeleton />
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default RecentDepositsTableSkeleton;
