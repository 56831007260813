import { Card, CardBody, CardTitle } from "reactstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsGrouped from "highcharts-grouped-categories";
import HighchartsBorder from "highcharts-border-radius";

import moment from "moment";
import { groupBy } from "lodash";
import { formatNumbersToCommaSeparated } from "../../../../actions";

HighchartsBorder(Highcharts);
HighchartsGrouped(Highcharts);

const EngagementSpread = ({ reportData, ...props }) => {
  return (
    <>
      {!!reportData?.user_profile?.recent_posts?.length && (
        <div className="engagement-spread mb-4">
          <Card className="card-content-custom block-last-posts">
            <CardBody>
              <CardTitle>
                Engagement Spread for Last Posts
                <ul className="like-comment">
                  <li className="like">Likes</li>
                  <li className="comment">Comments</li>
                </ul>
              </CardTitle>
              <div className="graph-wrap ptop15">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={engagementSpreadChartOptions(reportData)}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
};

function engagementSpreadChartOptions(data) {
  const monthName = (item) =>
    moment(item.created, "YYYY-MM-DD").format("MMM, YYYY");

  const recent_posts = data?.user_profile?.recent_posts?.map((post) => ({
    created: post.created,
    likes: post.stat.likes,
    comments: post.stat.comments,
    views: post.stat.views,
  }));

  const result = groupBy(recent_posts, monthName);

  if (!recent_posts) {
    return;
  }

  const emptySpace = Math.ceil(
    (recent_posts?.length - Object.entries(result).length) / 2,
  );

  const monthCategories = [];
  const recentPostsLikesData = [];
  const recentPostsCommentsData = [];
  // eslint-disable-next-line sonarjs/no-unused-collection
  const recentPostsViewsData = [];

  const highchartsSpacing = () => {
    recentPostsLikesData.push(...Array.from(" ".repeat(emptySpace)));
    recentPostsCommentsData.push(...Array.from(" ".repeat(emptySpace)));
    recentPostsViewsData.push(...Array.from(" ".repeat(emptySpace)));
  };

  for (const [month, post] of Object.entries(result)) {
    const category = {
      name: month,
      categories: [
        ...Array.from(" ".repeat(emptySpace)),
        ...post.map((value) => moment(value.created).format("MMM DD, YYYY")),
        ...Array.from(" ".repeat(emptySpace)),
      ],
    };

    monthCategories.push(category);
    highchartsSpacing();

    post.forEach((item) => {
      recentPostsLikesData.push({
        y: item.likes,
        month: moment(item.created).format("MMM DD, YYYY"),
      });
      recentPostsCommentsData.push({
        y: item.comments,
        month: moment(item.created).format("MMM DD, YYYY"),
      });
      recentPostsViewsData.push({
        y: item.views,
        month: moment(item.created).format("MMM DD, YYYY"),
      });
    });
    highchartsSpacing();
  }

  return {
    chart: {
      type: "column",
      height: 250,
      backgroundColor: null,
    },
    title: null,
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#898e94",
        },
      },
      gridLineColor: "#e6e6e6",
    },
    tooltip: {
      backgroundColor: "#17222d",
      borderColor: "transparent",
      borderRadius: 10,
      shadow: false,
      style: {
        color: "white",
        fontSize: "11px",
      },
      formatter: function () {
        return (
          "<b>" +
          this.series.name +
          "</b><br/>" +
          this.point.month +
          ": " +
          formatNumbersToCommaSeparated(this.y)
        );
      },
    },
    xAxis: {
      title: {
        text: null,
      },
      labels: {
        rotation: 0,
        groupedOptions: [
          {
            style: {
              fontSize: 11,
            },
          },
        ],
        style: {
          fontSize: 0,
          color: "#898e94",
        },
      },
      reversed: true,
      type: "category",
      tickColor: "transparent",
      lineColor: "#1f87ee",
      categories: monthCategories,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
        stacking: "normal",
        pointPadding: 0,
        groupPadding: 0,
      },
      column: {
        pointWidth: window.innerWidth < 1024 ? 15 : 20,
      },
    },

    series: [
      {
        name: "Likes",
        color: "#1f87ee",
        data: recentPostsLikesData,
        borderRadiusTopLeft: 10,
        borderRadiusTopRight: 10,
        borderColor: "transparent",
      },
      {
        name: "Comments",
        color: "#ff5537",
        data: recentPostsCommentsData,
        borderColor: "transparent",
      },
    ],
    credits: {
      enabled: false,
    },
  };
}

export default EngagementSpread;
