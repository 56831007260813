import {
  actionCreator,
  checkHttpStatus,
  checkAuthTokenExpiry,
  privateHeaders,
  showErrorMessage,
  showSuccessMessage,
} from "../../../actions";
import { logout } from "../../login/actions";
import {
  GET_DELIVERABLES_ERROR,
  READ_MANY_DELIVERABLES_URL,
  READ_ONE_DELIVERABLE_URL,
  SAVE_DELIVERABLE_ERROR,
  SAVE_DELIVERABLE_SUCCESS,
  SUBMIT_DELIVERABLE_SUCCESS,
  UPDATE_ONE_DELIVERABLE_URL,
  deliverablesActionTypes,
} from "../constants";

export const getDeliverable = (deliverable_id) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(deliverablesActionTypes.getDeliverable.REQUEST));
    if (!deliverable_id) {
      dispatch(actionCreator(deliverablesActionTypes.getDeliverable.FAILURE));
      return;
    }
    let url = new URL(READ_ONE_DELIVERABLE_URL);
    const params = url.searchParams;
    params.append("_id", deliverable_id);
    // params.append("calculate", "deliverableMetrics");
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: privateHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response) {
          showErrorMessage(GET_DELIVERABLES_ERROR);
          dispatch(
            actionCreator(deliverablesActionTypes.getDeliverable.FAILURE),
          );
        } else {
          dispatch(
            actionCreator(
              deliverablesActionTypes.getDeliverable.SUCCESS,
              response,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(deliverablesActionTypes.getDeliverable.FAILURE));
        showErrorMessage(GET_DELIVERABLES_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const getCompleteDeliverables = ({
  pageNumber = 1,
  pageSize = 10,
  assignment_id,
  ...rest
}) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(deliverablesActionTypes.getCompleteDeliverables.REQUEST),
    );
    let url = new URL(READ_MANY_DELIVERABLES_URL);
    const params = url.searchParams;
    params.append("limit", pageSize);
    const skip = pageNumber > 1 ? (pageNumber - 1) * pageSize : 0;
    params.append("skip", skip);
    params.append("isComplete", true);
    assignment_id && params.append("assignment_id", assignment_id);
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: privateHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.results) {
          showErrorMessage(GET_DELIVERABLES_ERROR);
          dispatch(
            actionCreator(
              deliverablesActionTypes.getCompleteDeliverables.FAILURE,
            ),
          );
        } else {
          dispatch(
            actionCreator(
              deliverablesActionTypes.getCompleteDeliverables.SUCCESS,
              response,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(deliverablesActionTypes.getCompleteDeliverables.FAILURE),
        );
        showErrorMessage(GET_DELIVERABLES_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};
export const getIncompleteDeliverables = ({
  pageNumber = 1,
  pageSize = 10,
  assignment_id,
  ...rest
}) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(deliverablesActionTypes.getIncompleteDeliverables.REQUEST),
    );
    let url = new URL(READ_MANY_DELIVERABLES_URL);
    const params = url.searchParams;
    params.append("limit", pageSize);
    const skip = pageNumber > 1 ? (pageNumber - 1) * pageSize : 0;
    params.append("skip", skip);
    params.append("isComplete", false);
    assignment_id && params.append("assignment_id", assignment_id);
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: privateHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.results) {
          showErrorMessage(GET_DELIVERABLES_ERROR);
          dispatch(
            actionCreator(
              deliverablesActionTypes.getIncompleteDeliverables.FAILURE,
            ),
          );
        } else {
          dispatch(
            actionCreator(
              deliverablesActionTypes.getIncompleteDeliverables.SUCCESS,
              response,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(
            deliverablesActionTypes.getIncompleteDeliverables.FAILURE,
          ),
        );
        showErrorMessage(GET_DELIVERABLES_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const updateDeliverable = (deliverableBody) => {
  return async (dispatch, getState) => {
    console.log(typeof deliverableBody);
    dispatch(actionCreator(deliverablesActionTypes.updateDeliverable.REQUEST));
    // prompt for confirmation if deliverable completion status is being changed to true
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (deliverableBody?.isComplete) {
      if (
        !window.confirm(
          "Are you sure you want to mark this Deliverable as complete? Once it is completed it will be submitted for approval.",
        )
      ) {
        dispatch(
          actionCreator(deliverablesActionTypes.updateDeliverable.FAILURE),
        );
        return Promise.reject(new Error("User cancelled"));
      }
    }
    try {
      if (!deliverableBody?._id) throw new Error("no deliverable _id");
      const formData = new FormData();
      Object.keys(deliverableBody).forEach((key) => {
        if (
          Array.isArray(deliverableBody[key]) &&
          deliverableBody[key]?.length
        ) {
          deliverableBody[key].forEach((item, index) => {
            formData.append(key === "images" ? "images" : `${key}[]`, item);
          });
        } else {
          formData.append(key, deliverableBody[key]);
        }
      });
      const updateRequest = new Request(UPDATE_ONE_DELIVERABLE_URL, {
        method: "PUT",
        headers: privateHeaders(getState, "none"),
        credentials: "include",
        body: formData,
      });
      let response = await fetch(updateRequest);
      if (!response?.ok) {
        throw new Error(
          "Server Error: " + response?.error?.message || "Unknown Error",
        );
      }
      response = await response.json();
      if (response?.error || !response?.result) {
        throw new Error(
          "Server Error: " + response?.error?.message || "Unknown Error",
        );
      } else {
        dispatch(
          actionCreator(
            deliverablesActionTypes.updateDeliverable.SUCCESS,
            response.result,
          ),
        );
        showSuccessMessage(SAVE_DELIVERABLE_SUCCESS);
        if (deliverableBody.isComplete) {
          showSuccessMessage(SUBMIT_DELIVERABLE_SUCCESS);
        }
        // TODO: this will cause issues if we ever have a view for deliverables that is not filtered by assignment
        // TODO: also potential issue with syncing pagination state (controlled locally in component)
        dispatch(
          getIncompleteDeliverables({
            assignment_id: response.result?.assignment_id,
          }),
        );
        dispatch(
          getCompleteDeliverables({
            assignment_id: response.result?.assignment_id,
          }),
        );
        return Promise.resolve(response.result);
      }
    } catch (error) {
      showErrorMessage(SAVE_DELIVERABLE_ERROR);
      console.error("updateDeliverable", error);
      dispatch(
        actionCreator(deliverablesActionTypes.updateDeliverable.FAILURE),
      );
      return Promise.reject(error);
    }
  };
};

// Content

// export const createContent = (content) => {
//   return async (dispatch, getState) => {
//     dispatch(actionCreator(deliverablesActionTypes.createContent.REQUEST));
//     const formData = new FormData();
//     Object.keys(content).forEach((key) => {
//       if (Array.isArray(content[key])) {
//         content[key].forEach((item, index) => {
//           formData.append(key, item);
//         });
//       } else {
//         formData.append(key, content[key]);
//       }
//     });
//     fetch(CREATE_ONE_CONTENT_URL, {
//       method: "POST",
//       headers: privateHeaders(getState, "none"),
//       credentials: "include",
//       body: formData, //TODO: do anything special here for images?
//     })
//       .then(checkHttpStatus)
//       .then((response) => checkAuthTokenExpiry(response))
//       .then((response) => {
//         if (response.error || !response?.result) {
//           showErrorMessage(SAVE_CONTENT_ERROR);
//           dispatch(actionCreator(deliverablesActionTypes.createContent.FAILURE));
//         } else {
//           dispatch(
//             actionCreator(deliverablesActionTypes.createContent.SUCCESS, response)
//           );
//         }
//       })
//       .catch((error) => {
//         dispatch(actionCreator(deliverablesActionTypes.createContent.FAILURE));
//         showErrorMessage(SAVE_CONTENT_ERROR);
//         if (error?.statusCode === 401) {
//           dispatch(logout(error));
//         }
//       });
//   };
// };

// // Used for content approval share links. Anyone with the share link can see the content preview without requiring authentication
// export const getContentPreview = (campaignContent_id) => {
//   return (dispatch, getState) => {
//     dispatch(actionCreator(deliverablesActionTypes.getContentPreview.REQUEST));
//     let url = new URL(READ_ONE_CONTENT_PREVIEW_URL);
//     let params = url.searchParams;
//     params.append("_id", campaignContent_id);
//     url = url.toString();
//     fetch(url, {
//       method: "GET",
//       headers: privateHeaders,
//       credentials: "include",
//     })
//       .then(checkHttpStatus)
//       .then((response) => checkAuthTokenExpiry(response))
//       .then((response) => {
//         if (response.error || !response) {
//           showErrorMessage(GET_CONTENT_ERROR);
//           dispatch(actionCreator(deliverablesActionTypes.getContentPreview.FAILURE));
//         } else {
//           dispatch(
//             actionCreator(deliverablesActionTypes.getContentPreview.SUCCESS, response)
//           );
//         }
//       })
//       .catch((error) => {
//         dispatch(actionCreator(deliverablesActionTypes.getContentPreview.FAILURE));
//         showErrorMessage(GET_CONTENT_ERROR);
//         if (error?.statusCode === 401) {
//           dispatch(logout(error));
//         }
//       });
//   };
// };

// export const getContents = ({ assignment_id, status }) => {
//   return (dispatch) => {
//     dispatch(actionCreator(deliverablesActionTypes.getContents.REQUEST));
//     let url = new URL(READ_MANY_CONTENTS_URL);
//     let params = url.searchParams;
//     params.append("assignment_id", assignment_id);
//     params.append("status", status);
//     params.append("limit", 27);
//     params.append("sortKey", "updated");
//     params.append("sortValue", -1);
//     url = url.toString();
//     fetch(url, {
//       method: "GET",
//       headers: privateHeaders,
//       credentials: "include",
//     })
//       .then(checkHttpStatus)
//       .then((response) => checkAuthTokenExpiry(response))
//       .then((response) => {
//         if (response.error || !response?.results) {
//           showErrorMessage(GET_CONTENTS_ERROR);
//           dispatch(actionCreator(deliverablesActionTypes.getContents.FAILURE));
//         } else {
//           dispatch(
//             actionCreator(deliverablesActionTypes.getContents.SUCCESS, response)
//           );
//         }
//       })
//       .catch((error) => {
//         dispatch(actionCreator(deliverablesActionTypes.getContents.FAILURE));
//         showErrorMessage(GET_CONTENTS_ERROR);
//         if (error?.statusCode === 401) {
//           dispatch(logout(error));
//         }
//       });
//   };
// };

// export const updateContent = (content) => {
//   return async (dispatch, getState) => {
//     dispatch(actionCreator(deliverablesActionTypes.updateContent.REQUEST));
//     const formData = new FormData();
//     Object.keys(content).forEach((key) => {
//       if (Array.isArray(content[key])) {
//         content[key].forEach((item, index) => {
//           formData.append(key === "images" ? "images" : `${key}[]`, item);
//         });
//       } else {
//         formData.append(key, content[key]);
//       }
//     });
//     fetch(UPDATE_ONE_CONTENT_URL, {
//       method: "PUT",
//       headers: privateHeaders(getState, "none"),
//       credentials: "include",
//       body: formData,
//     })
//       .then(checkHttpStatus)
//       .then((response) => checkAuthTokenExpiry(response))
//       .then((response) => {
//         if (response.error || !response?.result) {
//           showErrorMessage(SAVE_CONTENT_ERROR);
//           dispatch(actionCreator(deliverablesActionTypes.updateContent.FAILURE));
//         } else {
//           dispatch(actionCreator(deliverablesActionTypes.updateContent.SUCCESS));
//           if (content.submitForApproval) {
//             showSuccessMessage(SUBMIT_APPROVAL_SUCCESS);
//           } else {
//             showSuccessMessage(SAVE_CONTENT_SUCCESS);
//           }

//           dispatch(setEditPostModalVisibility(false));
//         }
//       })
//       .catch((error) => {
//         dispatch(actionCreator(deliverablesActionTypes.updateContent.FAILURE));
//         showErrorMessage(SAVE_CONTENT_ERROR);
//         if (error?.statusCode === 401) {
//           dispatch(logout(error));
//         }
//       });
//   };
// };
