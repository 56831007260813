import { useEffect } from "react";
import { connect } from "react-redux";
import { Table, Row, Col } from "reactstrap";
import { RenderButton } from "../../../components";
import history from "../../../history";
import { getCampaignInvitations } from "../actions";

const CampaignInvitationsTable = ({
  campaignInvitations,
  getCampaignInvitations,
}) => {
  useEffect(() => {
    getCampaignInvitations();
  }, [getCampaignInvitations]);

  const columns = ["campaignName", "action"];

  return (
    <>
      {campaignInvitations?.length ? (
        <Row>
          <Col md="12">
            <h1 className="heading title">Pending Campaign Invitations</h1>
            <p className="subheading">
              You have been invited to join the following campaigns.
            </p>
          </Col>
          <Col xs="12" md="8" lg="6">
            <div className="table-responsive table-outer">
              <Table borderless size="sm">
                <tbody>
                  {campaignInvitations?.length ? (
                    campaignInvitations?.map((campaign) => (
                      <tr key={campaign._id}>
                        <th scope="row" className="tb-head">
                          {campaign.campaignName}
                        </th>
                        <td className="text-right">
                          <RenderButton
                            className="btn btn-outline-primary"
                            onClick={() => {
                              history.push(`/campaigns/${campaign._id}`);
                            }}
                          >
                            View Invitation
                          </RenderButton>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-grey">
                      <td colSpan={columns.length} align="center">
                        No invitations found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    campaignInvitations: state.campaigns.campaignInvitations,
  };
};
export default connect(mapStateToProps, {
  getCampaignInvitations,
})(CampaignInvitationsTable);
