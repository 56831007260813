import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "reactstrap";
import { RenderButton, RenderCurrency } from "../../../../components";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BalanceTileSkeleton from "./BalanceTileSkeleton";
import "./index.scss";

const BalanceTile = ({
  handleInstantPayout,
  hidePayoutButton,
  loginUser,
  className,
}) => {
  const isLoading = useSelector((state) => state.dashboard.loading);
  if (
    isLoading ||
    (!!loginUser?.__totalBalance && !loginUser?.__totalBalance < 0)
  ) {
    return <BalanceTileSkeleton />;
  }

  return (
    <Card className={`card-content-custom balance-tile ${className}`}>
      <CardBody>
        <CardTitle className="mb-0">
          <h2 className="title">Total Balance</h2>
        </CardTitle>
        <h2 className="balance">
          {loginUser.__totalBalance >= 0 ? (
            <RenderCurrency
              amount={loginUser.__totalBalance}
              renderCurrencyCode={loginUser.country !== "US"}
            />
          ) : (
            <Skeleton width={"80%"} />
          )}
        </h2>
        <CardTitle className="available-balance">
          Available to Payout:
          {loginUser.__availableBalanceInStripe >= 0 ? (
            <RenderCurrency
              className="ml-1"
              amount={loginUser.__availableBalanceInStripe}
              renderCurrencyCode={loginUser.country !== "US"}
              renderConversionTooltip={true}
              currency={loginUser.__availableBalanceCurrency}
              country={loginUser.country}
            />
          ) : (
            <Skeleton className="ml-1" height={22} width={"30%"} />
          )}
        </CardTitle>
        {!hidePayoutButton && (
          <RenderButton
            className="mt-1"
            color="primary"
            onClick={() => handleInstantPayout()}
            disabled={
              loginUser.__availableBalanceInStripe <= 0 ||
              !loginUser.emailVerified ||
              loginUser.needsStripeOnboarding ||
              loginUser.needsStripeFinancialConnection
            }
          >
            Payout Balance
          </RenderButton>
        )}
      </CardBody>
    </Card>
  );
};

export default BalanceTile;
