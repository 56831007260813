import { useState } from "react";
import settings from "settings";
import { Alert, Card, CardBody, Col, FormGroup, Row } from "reactstrap";
import { verifyAuthLinkAndLogin } from "./actions/index";
import { connect } from "react-redux";
import AltAuthOptionLinks from "./components/AltAuthOptionLinks";
import PublicCardTitleSection from "../../components/PublicCardTitleSection";
import { Field, Form, Formik } from "formik";
import {
  RenderButton,
  RenderPasswordField,
  SubmitButton,
} from "../../components";

const validate = (values) => {
  const errors = {};

  if (!values.newPassword || values.newPassword.trim() === "") {
    errors.newPassword = "This field is required";
  } else if (values.newPassword.trim().length < 8) {
    errors.newPassword = "Must have at least 8 characters";
  }

  if (!values.newPasswordConfirm || values.newPasswordConfirm.trim() === "") {
    errors.newPasswordConfirm = "This field is required";
  } else if (
    values.newPassword &&
    values.newPasswordConfirm &&
    values.newPassword.trim() !== values.newPasswordConfirm.trim()
  ) {
    errors.newPasswordConfirm = "Password doesn't match";
  }

  return errors;
};

const AccountRecoveryComplete = ({
  loading,
  verifyAuthLinkAndLogin,
  ...props
}) => {
  const { id, token } = props.match.params;

  const [pageNumber, setPageNumber] = useState(0);

  return (
    <>
      <div className="container public-page-container">
        <Row>
          <Col xs="12" md="8" lg="6">
            <Card className="card-custom px-md-4 px-s-1">
              {pageNumber === 0 && (
                <CardBody>
                  <PublicCardTitleSection
                    title="Reset password"
                    subtitle={"Login or update your password"}
                  />
                  <Row className="d-flex align-items-center">
                    {loading ? (
                      <Col>
                        <p className="text-center mb-4">
                          Attempting to log you in with your password reset
                          link...
                        </p>
                      </Col>
                    ) : (
                      <>
                        <Col md="12">
                          <RenderButton
                            className="btn btn-outline-primary btn-wide mt-4 mb-4"
                            onClick={() => {
                              setPageNumber(1);
                              verifyAuthLinkAndLogin(id, token);
                            }}
                          >
                            Login without updating
                          </RenderButton>
                          <hr className="divider mb-5" />
                        </Col>
                        <Col md="12">
                          <Formik
                            initialValues={{
                              newPassword: "",
                              newPasswordConfirm: "",
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                              verifyAuthLinkAndLogin(
                                id,
                                token,
                                values.newPassword,
                              );
                              setPageNumber(1);
                              setSubmitting(false);
                            }}
                            validate={validate}
                            validateOnBlur={true}
                          >
                            {({ touched, errors, ...props }) => (
                              <Form onSubmit={props.handleSubmit}>
                                <FormGroup>
                                  <Row>
                                    <Col>
                                      <label
                                        htmlFor="newPassword"
                                        className="form-label"
                                      >
                                        New Password
                                        {errors.newPassword &&
                                          touched.newPassword && (
                                            <span className="required ml-2">
                                              *
                                              {errors?.newPassword ||
                                                "A valid password is required"}
                                            </span>
                                          )}
                                      </label>
                                      <Field
                                        name="newPassword"
                                        className={
                                          errors.newPassword &&
                                          touched.newPassword
                                            ? "error"
                                            : ""
                                        }
                                        component={RenderPasswordField}
                                        autoComplete="new-password"
                                      />

                                      <label
                                        htmlFor="newPasswordConfirm"
                                        className="form-label"
                                      >
                                        Confirm Password
                                        {errors.newPasswordConfirm &&
                                          touched.newPasswordConfirm && (
                                            <span className="required ml-2">
                                              *
                                              {errors?.newPasswordConfirm ||
                                                "Confirm new password"}
                                            </span>
                                          )}
                                      </label>
                                      <Field
                                        name="newPasswordConfirm"
                                        className={
                                          errors.newPasswordConfirm &&
                                          touched.newPasswordConfirm
                                            ? "error"
                                            : ""
                                        }
                                        component={RenderPasswordField}
                                        autoComplete="new-password"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="text-center">
                                      <SubmitButton
                                        className="btn btn-primary btn-wide mt-4 mb-3"
                                        disabled={props.isSubmitting}
                                      >
                                        Update Password
                                      </SubmitButton>
                                    </Col>
                                  </Row>
                                </FormGroup>
                              </Form>
                            )}
                          </Formik>
                        </Col>
                      </>
                    )}
                  </Row>
                </CardBody>
              )}
              {pageNumber === 1 && (
                <CardBody>
                  <PublicCardTitleSection
                    title="Verifying password reset link"
                    subtitle={`Welcome back to the ${settings.versionName} Creator Portal.`}
                  />
                  <Row>
                    <Col>
                      {loading ? (
                        <>
                          <p className="text-center mb-4">
                            Attempting to log you in with your password reset
                            link...
                          </p>
                        </>
                      ) : (
                        <>
                          <Alert color="danger">
                            <h4 className="alert-heading">
                              Could not verify password reset link.
                            </h4>
                            <p className="mb-4">
                              This password reset link is expired, invalid, or
                              has already been used.
                            </p>
                          </Alert>
                          {/* TODO: #authlinks fallback for nonexistent accounts */}
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="text-center">
                      <AltAuthOptionLinks />
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    loading: state.login.loading,
  };
};

export default connect(mapStateToProps, {
  verifyAuthLinkAndLogin,
})(AccountRecoveryComplete);
