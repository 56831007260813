import { API_URL, createRequestActionTypes } from "../../../actions/index";

export const READ_MANY_AGREEMENTS_URL = `${API_URL}/api/v1/agreements`;
export const READ_ONE_AGREEMENT_URL = `${API_URL}/api/v1/agreement`;
export const UPDATE_AGREEMENT_URL = `${API_URL}/api/v1/agreement`;

export const GET_AGREEMENTS_ERROR = "Error reading agreements";
export const GET_AGREEMENT_ERROR = "Error reading agreement";
export const UPDATE_AGREEMENT_ERROR = "Error updating agreement";

export const AGREEMENTS_TABLE_HEADERS = [
  { heading: "Title", width: "20%", class: "" },
  { heading: "Status", width: "10%", class: "" },
  { heading: "Campaign", width: "20%", class: "" },
  { heading: "File", width: "10%", class: "" },
  { heading: "Action Required", width: "10%", class: "text-right" },
];

export const agreementsActionTypes = {
  getAgreements: createRequestActionTypes("GET_AGREEMENTS"),
  getAgreement: createRequestActionTypes("GET_AGREEMENT"),
  updateAgreement: createRequestActionTypes("UPDATE_AGREEMENT"),
};

// TODO: remove test data
export const sampleAgreements = [
  {
    _id: "1",
    title: "Test Assignment Agreement",
    campaign_id: {
      _id: "64062da5dee9c665cc3c313c",
      name: "Rino Toys 2023 Q3",
    },
    status: "Unsigned",
    dueDate: "2024-04-20T00:00:00.000Z",
    __actionRequired: true,
  },
  {
    _id: "2",
    title: "Test Talent Agreement",
    campaign_id: null,
    status: "Unsigned",
    dueDate: "2024-04-20T00:00:00.000Z",
    __actionRequired: true,
  },
];
