import { campaignsActionTypes } from "../constants";
const initialState = {
  loading: false,
  campaigns: [],
  totalCampaignsCount: null,
  campaignInvitations: [],
  campaign: null,
  editPostModalVisibility: false,
  contents: [],
  contentPreview: null,
  contentBeingEdited: null,
  reloadContents: false,
};
const campaignsReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    // Campaign Assignments

    case campaignsActionTypes.getCampaigns.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.getCampaigns.SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: payload?.results,
        totalCampaignsCount: payload?.count,
        reloadContents: false,
      };
    case campaignsActionTypes.getCampaigns.FAILURE:
      return {
        ...state,
        loading: false,
        campaigns: [],
      };
    case campaignsActionTypes.getCampaignInvitations.REQUEST:
      return {
        ...state,
        loading: true,
        campaignInvitations: [],
      };
    case campaignsActionTypes.getCampaignInvitations.SUCCESS:
      return {
        ...state,
        loading: false,
        campaignInvitations: payload?.results,
      };
    case campaignsActionTypes.getCampaignInvitations.FAILURE:
      return {
        ...state,
        loading: false,
        campaignInvitations: [],
      };

    case campaignsActionTypes.getCampaign.REQUEST:
      return {
        ...state,
        loading: true,
        campaign: null,
      };
    case campaignsActionTypes.getCampaign.SUCCESS:
      return {
        ...state,
        loading: false,
        campaign: payload,
      };
    case campaignsActionTypes.getCampaign.FAILURE:
      return {
        ...state,
        loading: false,
        campaign: null,
      };
    case campaignsActionTypes.answerCampaignInvitation.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.answerCampaignInvitation.SUCCESS:
      return {
        ...state,
        loading: false,
        campaign: payload,
      };
    case campaignsActionTypes.answerCampaignInvitation.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case campaignsActionTypes.updateCampaignAssignment.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.updateCampaignAssignment.SUCCESS:
      return {
        ...state,
        loading: false,
        campaign: payload,
      };
    case campaignsActionTypes.updateCampaignAssignment.FAILURE:
      return {
        ...state,
        loading: false,
      };

    // Content

    case campaignsActionTypes.createContent.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.createContent.SUCCESS:
      return {
        ...state,
        loading: false,
        contents: [payload?.result, ...state.contents],
        editPostModalVisibility: false,
      };
    case campaignsActionTypes.createContent.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case campaignsActionTypes.updateContent.REQUEST:
      return {
        ...state,
        loading: true,
        reloadContents: false,
      };
    case campaignsActionTypes.updateContent.SUCCESS:
      return {
        ...state,
        loading: false,
        reloadContents: true,
      };
    case campaignsActionTypes.updateContent.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case campaignsActionTypes.getContents.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.getContents.SUCCESS:
      return {
        ...state,
        loading: false,
        contents: payload?.results,
      };
    case campaignsActionTypes.getContents.FAILURE:
      return {
        ...state,
        loading: false,
        contents: [],
      };
    case campaignsActionTypes.getContentPreview.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.getContentPreview.SUCCESS:
      return {
        ...state,
        loading: false,
        contentPreview: payload?.result,
      };
    case campaignsActionTypes.getContentPreview.FAILURE:
      return {
        ...state,
        loading: false,
        contentPreview: null,
      };

    // Uploads

    case campaignsActionTypes.uploadMedia.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.uploadMedia.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case campaignsActionTypes.uploadMedia.FAILURE:
      return {
        ...state,
        loading: false,
      };

    // UI States

    case campaignsActionTypes.setContentBeingEdited.REQUEST:
      return {
        ...state,
        contentBeingEdited: null,
      };
    case campaignsActionTypes.setContentBeingEdited.SUCCESS:
      return {
        ...state,
        contentBeingEdited: payload,
      };

    case campaignsActionTypes.setEditPostModalVisibility.SUCCESS:
      return {
        ...state,
        editPostModalVisibility: !!payload,
        // contentBeingEdited: !!payload ? state.contentBeingEdited : null,
      };
    default:
      return state;
  }
};

export default campaignsReducers;
