import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap";
import { formatNumbersToUnits } from "../../../actions";
import metricDisplayMappings from "../constants/metricDisplayMappings";
import Skeleton from "react-loading-skeleton";
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
} from "@heroicons/react/20/solid";

const NumericStatCard = ({
  metricName,
  network,
  value,
  valuePast,
  ...props
}) => {
  const metric = getMappings(metricName, network);

  const { trendRate, trendDirection } = calculateTrend(value, valuePast);

  return (
    <>
      {metric?.invalidNetwork ? (
        <></>
      ) : (
        <Card className="numeric-stat-card card-content-custom">
          <CardBody>
            <h2 className="bold d-inline mr-2">
              {metric ? getFormattedValueString(value, metric) : <Skeleton />}
            </h2>
            {typeof trendRate === "number" && (
              <Badge
                className="d-inline-flex align-items-center bold trend-badge"
                color={"success"}
              >
                {trendDirection > 0 ? (
                  <ArrowSmallUpIcon height="20px" width="20px" />
                ) : (
                  <ArrowSmallDownIcon height="20px" width="20px" />
                )}
                {formatValue(trendRate, "percent")}
              </Badge>
            )}
            <CardTitle>
              <span className="tooltip-button-wrap">
                <span className="text-tooltip">
                  {metric?.label || <Skeleton />}
                </span>
                <span id="reportAvgViews" className="tooltip-icon"></span>
              </span>
              {metric?.tooltipText && (
                <UncontrolledTooltip
                  className="tooltip-content"
                  target="reportAvgViews"
                >
                  {/* show tooltip reportAvgViews */}
                </UncontrolledTooltip>
              )}
            </CardTitle>
          </CardBody>
        </Card>
      )}
    </>
  );
};

function getMappings(metricName, network) {
  if (!metricName || !network) {
    return null;
  }
  return metricDisplayMappings(metricName, network);
}

function getFormattedValueString(value, metric) {
  // formatting logic
  // conditions: formatType and isRange
  const isRange =
    typeof value === "object" &&
    Object.keys(value).includes("min") &&
    Object.keys(value).includes("max");

  if (
    typeof value !== "number" &&
    (typeof value?.min !== "number" || typeof value?.max !== "number")
  ) {
    // console.warn(`Invalid value: ${value} | Must be a number or range object`);
    return null;
  }

  return isRange
    ? `${formatValue(value?.min, metric.formatType)} - ${formatValue(
        value?.max,
        metric.formatType,
      )}`
    : formatValue(value, metric.formatType);
}

function formatValue(value, formatType) {
  let formattedValue;
  switch (formatType) {
    case "compact":
      formattedValue = formatNumbersToUnits(value);
      break;
    case "percent":
      formattedValue = `${value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}%`;
      break;
    default:
      break;
  }
  return formattedValue;
}

function calculateTrend(curr, prev) {
  if (typeof curr !== "number" || typeof prev !== "number") return {};
  const trendRate = ((curr - prev) / prev) * 100;
  const trendDirection = trendRate > 0 ? 1 : -1;
  return { trendRate: Math.abs(trendRate), trendDirection };
}

export default NumericStatCard;
