import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Badge,
  Progress,
} from "reactstrap";
import { capitalize, showTitle } from "../../../../../actions";

const FollowersLocationByCountry = ({ reportData, ...props }) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Location By Country</CardTitle>
        <div className="card-content ">
          <ListGroup flush className="country-list with-progress">
            {reportData &&
            reportData.audience_followers &&
            reportData.audience_followers.data &&
            reportData.audience_followers.data.audience_geo &&
            reportData.audience_followers.data.audience_geo.countries ? (
              reportData.audience_followers.data.audience_geo.countries.map(
                (country, index) => {
                  if (index < 3) {
                    return (
                      <ListGroupItem key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="country-text-wrap">
                            <div className="flag-img">
                              <img
                                loading="lazy"
                                src={props.generateImgUrl(
                                  "country",
                                  country.code,
                                )}
                                alt="img"
                              />
                            </div>
                            <div
                              id={`followers_${country.name}`}
                              title={showTitle(
                                `followers_${country.name}`,
                                country.name,
                              )}
                              className="country-name"
                            >
                              {capitalize(country.name)}
                            </div>
                          </div>
                          <Badge className="text-grey" color="">
                            {(country.weight * 100).toFixed(2) + "%"}
                          </Badge>
                        </div>
                        <Progress
                          value={(country.weight * 100).toFixed(2)}
                          className="progress-bar-custom"
                        />
                      </ListGroupItem>
                    );
                  }
                  return null;
                },
              )
            ) : (
              <span className="text-center text-grey ">
                <p className="pt-50">No records</p>
              </span>
            )}
          </ListGroup>
        </div>
      </CardBody>
    </Card>
  );
};

export default FollowersLocationByCountry;
