const ErrorComponent = () => {
  return (
    <div className="error-component">
      <div className="main-site-content container">
        <div className="user-form-wrap">
          <div className="page-head">
            <img
              alt=""
              src="https://image.flaticon.com/icons/svg/148/148766.svg"
              className="response-svg"
            />
            <h1>Error</h1>
            <p className="sub-text">Page not found</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorComponent;
