import { useEffect } from "react";
import { Field, Formik } from "formik";
import { Card, CardBody, Col, Form, FormGroup, Row, Spinner } from "reactstrap";
import {
  RenderPasswordField,
  RenderTextField,
  SubmitButton,
} from "../../components";
import { clearAccountErrors, login } from "./actions/index";
import { connect, useSelector } from "react-redux";
import AltAuthOptionLinks from "./components/AltAuthOptionLinks";
import PublicCardTitleSection from "../../components/PublicCardTitleSection";
import AlertBanner from "../../components/AlertBanner";
import { NavLink } from "react-router-dom";

const validate = (values) => {
  const errors = {};

  if (!values.email || values.email.trim() === "") {
    errors.email = "This field is required";
  } else if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i.test(values.email)
  ) {
    errors.email = "Invalid email";
  }

  if (!values.password || values.password.trim() === "") {
    errors.password = "This field is required";
  }

  return errors;
};

const Login = ({
  login,
  clearAccountErrors,
  invalidCredentials,
  failedLoginEmail,
  ...props
}) => {
  const isLoading = useSelector((state) => state.login.loading);

  useEffect(() => {
    clearAccountErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const accountRecoveryLink = () => {
    return (
      <NavLink
        to={`/recovery?email=${encodeURIComponent(failedLoginEmail)}`}
        className="link-more"
      >
        reset your password
      </NavLink>
    );
  };
  const magicLoginLink = () => {
    return (
      <NavLink
        to={`/magicLink?email=${encodeURIComponent(failedLoginEmail)}`}
        className="link-more"
      >
        logging in with a magic link
      </NavLink>
    );
  };

  const RecoveryMessage = () => {
    return (
      <>
        Incorrect email or password. If you&apos;re having trouble logging in
        you can {accountRecoveryLink()} or try {magicLoginLink()}.
      </>
    );
  };

  return (
    <>
      <div className="container public-page-container">
        <Row>
          <Col xs="12" md="8" lg="6">
            <Card className="card-custom px-md-4 px-s-1">
              <CardBody>
                <PublicCardTitleSection
                  title="Login with password"
                  subtitle={"Welcome back to the Creator Portal"}
                />
                <Row>
                  <Col>
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                        await login(values);
                        setSubmitting(false);
                      }}
                      validate={validate}
                      validateOnBlur={true}
                    >
                      {({ touched, errors, ...props }) => (
                        <Form onSubmit={props.handleSubmit}>
                          <FormGroup>
                            <Row>
                              <Col className="mb-4">
                                <label htmlFor="email" className="form-label">
                                  Email
                                  {errors.email && touched.email && (
                                    <span className="required ml-2">
                                      *
                                      {errors?.email ||
                                        "A valid email is required"}
                                    </span>
                                  )}
                                </label>
                                <Field
                                  name="email"
                                  className={
                                    errors.email && touched.email ? "error" : ""
                                  }
                                  component={RenderTextField}
                                  autoComplete="email"
                                />

                                <label
                                  htmlFor="password"
                                  className="form-label"
                                >
                                  Password
                                  {errors.password && touched.password && (
                                    <span className="required ml-2">
                                      *
                                      {errors?.password ||
                                        "A valid password is required"}
                                    </span>
                                  )}
                                </label>
                                <Field
                                  name="password"
                                  className={
                                    errors.password && touched.password
                                      ? "error"
                                      : ""
                                  }
                                  component={RenderPasswordField}
                                  autoComplete="current-password"
                                />
                                <NavLink
                                  to="/magicLink"
                                  className="link-more ml-1"
                                >
                                  Use a magic link instead
                                </NavLink>
                              </Col>
                            </Row>
                            {invalidCredentials && failedLoginEmail && (
                              <Row>
                                <Col className="mb-0">
                                  <AlertBanner message={RecoveryMessage()} />
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col className="text-center">
                                <SubmitButton
                                  className="btn btn-primary btn-wide mb-4"
                                  disabled={props.isSubmitting}
                                >
                                  {isLoading ? <Spinner size="sm" /> : "Login"}
                                </SubmitButton>
                                <AltAuthOptionLinks
                                  hideAuthTypes={["password"]}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    failedLoginEmail: state.login.failedLoginEmail,
    invalidCredentials: state.login.invalidCredentials,
  };
};

export default connect(mapStateToProps, {
  login,
  clearAccountErrors,
})(Login);
