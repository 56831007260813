import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { getOpportunities } from "../actions";

import dealsHeroSvg from "../../../assets/img/deals_hero.svg";
import OpportunityCard from "./OpportunityCard";
import SplashScreenSkeleton from "./SplashScreenSkeleton";

const SplashScreen = ({ opportunities, loginUser, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOpportunities());
  }, [dispatch]);

  const isLoading = useSelector((state) => state.opportunities.loading);
  if (isLoading) return <SplashScreenSkeleton />;

  if (opportunities?.length > 0) {
    return (
      <Container className="main-site-content deals-splash">
        <Row>
          <Col className="mb-5">
            <h1 className="heading title">Curated Deals</h1>
            <p className="subheading text-grey">
              Tap on listed deals below to view more information.
            </p>
            <Row>
              {opportunities.map((item) => (
                <OpportunityCard key={item._id} opportunity={item} />
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="main-site-content deals-splash">
      <Row className="px-3 py-5">
        <Col xs="12" md="8" lg="6" xl="5" className="mx-auto mb-4">
          <img
            src={dealsHeroSvg}
            alt="deals hero banner"
            className="img-fluid"
          />
        </Col>
        <Col
          xs="12"
          md="10"
          lg="8"
          className="offset-md-1 offset-lg-2 text-center"
        >
          <h1 className="heading title">NeoReach Deals</h1>
          <p className="subheading text-grey">
            Welcome to NeoReach Deals! You&apos;ll be notified when new deals
            are available.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities.opportunities,
    loginUser: state.login.loginUser,
  };
};

export default connect(mapStateToProps, {})(SplashScreen);
