import { API_URL, createRequestActionTypes } from "../../../actions/index";
export const CLIENT_TOKEN_URL = `${API_URL}/api/v1/user/stripeFinancialToken`;
export const ADD_EXTERNAL_ACCOUNT_URL = `${API_URL}/api/v1/user/addExternalAccount`;
export const READ_EXTERNAL_ACCOUNT_URL = `${API_URL}/api/v1/user/linkedExternalAccount`;
export const USER_UPDATE_URL = `${API_URL}/api/v1/user`;
export const REFRESH_STRIPE_ONBOARDING_URL = `${API_URL}/api/v1/user/refreshStripeUrl`;
export const settingsActionTypes = {
  addExternalAccount: createRequestActionTypes("ADD_EXTERNAL_ACCOUNT"),
  readExternalAccount: createRequestActionTypes("READ_EXTERNAL_ACCOUNT"),
  updatePayoutUser: createRequestActionTypes("UPDATE_USER"),
  getCampaigns: createRequestActionTypes("GET_CAMPAIGNS"),
  deleteAccount: createRequestActionTypes("DELETE_ACCOUNT"),
  refreshStripeOnboardingUrl: createRequestActionTypes(
    "REFRESH_STRIPE_ONBOARDING_URL",
  ),
  generateFinancialConnectionToken: createRequestActionTypes(
    "GENERATE_FINANCIAL_CONNECTION_TOKEN",
  ),
  setReplaceAccountFormVisible: createRequestActionTypes(
    "SET_REPLACE_ACCOUNT_FORM_VISIBLE",
  ),
  clearErrorMessages: createRequestActionTypes("CLEAR_SETTINGS_ERROR_MSG"),
  clearSuccessMessages: createRequestActionTypes("CLEAR_SETTINGS_SUCCESS_MSG"),
};
