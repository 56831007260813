import { connect } from "react-redux";
import { getAgreement, updateAgreement } from "../actions";
import { Alert, Col, Row } from "reactstrap";
import Loader from "../../../components/Loader";
import AgreementStatusChips from "./AgreementStatusChips";
import AgreementLabel from "./AgreementLabel";
import AgreementHasSignedBadge from "./AgreementHasSignedBadge";
import ViewAgreementFileButton from "./ViewAgreementFileButton";
import CampaignLink from "../../campaigns/components/CampaignLink";

function AgreementRecord({
  agreement,
  loading,
  getAgreement,
  updateAgreement,

  ...props
}) {
  return (
    <>
      {loading && <Loader className="loader position-absolute" />}

      <Row>
        <Col></Col>
        <Col xs="12">
          <dl className="row" style={{ rowGap: ".5rem" }}>
            {/* <AgreementLabel className="align-self-center">
              Agreement
            </AgreementLabel>
            <dd className="col-sm-8">
              <h1 className="fs-6">{agreement?.title}</h1>
            </dd> */}
            {agreement?.campaign_id && (
              <>
                <AgreementLabel>Campaign</AgreementLabel>
                <dd className="col-sm-8">
                  <CampaignLink
                    campaign_id={agreement?.assignment_id?._id}
                    campaignName={agreement?.assignment_id?.campaignName}
                  />
                </dd>
              </>
            )}
            <AgreementLabel>Status</AgreementLabel>
            <dd className="col-sm-8">
              {agreement?.status === "InProgress" ? (
                <>
                  {!agreement?.requiresCreatorSignature ||
                  agreement?.creatorHasSigned ? (
                    <Alert color="info">
                      <Row>
                        <Col>
                          <p>
                            <strong>Finalizing Agreement</strong>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            This agreement is being reviewed by our team. No
                            further action is currently required on your part.
                            The agreement status will be updated soon.
                          </p>
                        </Col>
                      </Row>
                    </Alert>
                  ) : (
                    <AgreementStatusChips agreement={agreement} />
                  )}
                </>
              ) : (
                <AgreementStatusChips agreement={agreement} />
              )}
            </dd>
            <AgreementLabel>Creator Signature</AgreementLabel>
            <dd className="col-sm-8">
              <AgreementHasSignedBadge
                hasSigned={agreement?.creatorHasSigned}
                signatureRequired={agreement?.requiresCreatorSignature}
              />
            </dd>
            <AgreementLabel>Admin Signature</AgreementLabel>
            <dd className="col-sm-8">
              <AgreementHasSignedBadge
                hasSigned={agreement?.adminHasSigned}
                signatureRequired={agreement?.requiresAdminSignature}
              />
            </dd>
            <AgreementLabel>File</AgreementLabel>
            <dd className="col-sm-8">
              <ViewAgreementFileButton agreement_id={agreement?._id} />
            </dd>
            <AgreementLabel>Message</AgreementLabel>
            <dd className="col-sm-8">
              {agreement?.message || (
                <span className="text-muted small">No message</span>
              )}
            </dd>
          </dl>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    agreement: state.agreements.agreement,
    loading: state.agreements.loading,
  };
};
export default connect(mapStateToProps, {
  getAgreement,
  updateAgreement,
})(AgreementRecord);
