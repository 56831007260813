import { connect } from "react-redux";
import { RenderButton } from "../../../../components";
import "./index.scss";
import { MagnifyingGlassMinusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { Modal, ModalHeader } from "reactstrap";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";

const MediaPreviewModal = ({
  previewModalOpen,
  togglePreviewModal,
  media,
  mediaThumbnail,
  handleRemoveMedia,
  handleModalClosed,
  ...props
}) => {
  const videoSource = {
    type: "video",
    sources: [
      {
        src: media?.url,
        type: "video/mp4",
      },
    ],
    poster: mediaThumbnail?.url,
  };

  return (
    <Modal
      isOpen={previewModalOpen}
      className="expanded-image-modal"
      tabIndex="-1"
      aria-hidden="true"
      onClosed={handleModalClosed}
      toggle={togglePreviewModal}
      returnFocusAfterClose={false}
    >
      {media && (
        <>
          <ModalHeader
            toggle={togglePreviewModal}
            close={
              <RenderButton
                outline
                className="btn btn-outline"
                onClick={() => togglePreviewModal()}
              >
                <MagnifyingGlassMinusIcon
                  width={20}
                  height={20}
                  strokeWidth={2}
                />
                Close Preview
              </RenderButton>
            }
            className="d-flex justify-content-space-between"
          >
            <RenderButton
              outline
              color="danger"
              className="btn btn-outline-danger"
              onClick={() => {
                togglePreviewModal();
                handleRemoveMedia([media, mediaThumbnail]);
              }}
            >
              <TrashIcon width={20} height={20} strokeWidth={2} />
              Remove
            </RenderButton>
          </ModalHeader>
          {media?.mediaType === "image" && (
            <img
              src={media?.url}
              alt="Expanded media preview"
              className="expanded-image img-fluid"
            />
          )}
          {media?.mediaType === "video" && (
            <Plyr id="video-preview" source={videoSource} />
          )}
        </>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {})(MediaPreviewModal);
