// Display a social handle's profile picture, name, handle, network
import AvatarImageRound from "../../../components/AvatarImageRound";
import {
  ArrowTopRightOnSquareIcon,
  ClockIcon,
} from "@heroicons/react/20/solid";
import { formatDate } from "../../../actions";
import Skeleton from "react-loading-skeleton";
import { Alert } from "reactstrap";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const HandleProfile = ({ socialHandle, statusTextEnabled, ...props }) => {
  const avatarUrl =
    socialHandle?.network === "instagram"
      ? `${process.env.REACT_APP_API_URL}/api/v1/user/socialHandleImage.json?imageUrl=${encodeURIComponent(socialHandle?.picture || socialHandle?.profileImageUrl)}}`
      : socialHandle?.picture || socialHandle?.profileImageUrl;

  return (
    <div className="handle-profile" key={socialHandle?._id}>
      {/* <p>{JSON.stringify(socialHandle)}</p> */}
      <div className="avatar-section">
        <AvatarImageRound
          key={socialHandle?._id}
          picture={socialHandle?.notFound ? "not-found" : avatarUrl}
          network={socialHandle?.network}
        />
      </div>
      <div className="info-section py-1">
        {socialHandle?.notFound ? (
          <Alert color="warning" className="mb-3">
            <span>
              <ExclamationTriangleIcon height="24" width="24" />
            </span>
            <span>We were unable to find an account with this handle.</span>
          </Alert>
        ) : (
          <h2>{socialHandle?.name || <Skeleton width="50%" />}</h2>
        )}
        {!socialHandle?.handle ? (
          <p>
            <Skeleton width="40%" />
          </p>
        ) : (
          <>
            {socialHandle?.profileUrl && !socialHandle?.notFound ? (
              <a
                href={socialHandle?.profileUrl}
                tabIndex={0}
                target="_blank"
                rel="noopener noreferrer"
                className="text-link d-inline-flex align-items-center"
              >
                @{socialHandle?.handle}
                <ArrowTopRightOnSquareIcon
                  height="18"
                  width="18"
                  className="ml-1"
                />
              </a>
            ) : (
              <p>@{socialHandle?.handle}</p>
            )}
          </>
        )}
        <p className="text-grey italic small mb-0">
          {getStatusText(socialHandle)}
        </p>
        <p className="text-grey italic small">
          {getLastUpdatedText(socialHandle)}
        </p>
      </div>
    </div>
  );
};

function getLastUpdatedText(handle) {
  if (!handle) return <Skeleton width="40%" />;
  if (handle?.reportUpdatedDate) {
    return (
      <>
        <ClockIcon width="18" height="18" className="mr-1" />
        Last updated{" "}
        {formatDate(handle?.reportUpdatedDate, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </>
    );
  }
}

function getStatusText(handle, showErrorStatus) {
  if (!handle) return <Skeleton width="40%" />;
  if (handle?.notFound) {
    return <></>;
  }
  if (!handle?.hasConfirmedOwnership && !handle?.reportUpdatedDate) {
    return <>Confirm ownership to generate Media Kit</>;
  }
}

export default HandleProfile;
