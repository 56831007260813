import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const RecentDepositsRowSkeleton = () => {
  return (
    <>
      {Array(5)
        .fill()
        .map((item, index) => (
          <tr key={index}>
            <td>
              <span>
                <Skeleton
                  height={20}
                  width={"100%"}
                  style={{ marginBottom: "0.5rem" }}
                />
              </span>
            </td>
            <td>
              <span>
                <Skeleton height={20} width={"80%"} />
              </span>
            </td>
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
        ))}
    </>
  );
};

export default RecentDepositsRowSkeleton;
