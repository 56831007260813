import Pagination from "react-js-pagination";
import { Col, FormGroup, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import "./index.scss";
import { RenderSingleSelect } from "..";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";

const itemsPerPageOptions = [
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];

const Paginator = ({
  activePage = 1,
  pageSize = 10,
  totalItems,
  handlePageChange,
  handlePageSizeChange,
  allowSelectPageSize = false,
  ...props
}) => {
  const activeOption = itemsPerPageOptions.find(
    (option) => option.value === pageSize,
  );

  const changePageSize = (option) => {
    handlePageSizeChange(option.value);
  };

  return (
    <Row className="paginator-custom my-3" noGutters={true}>
      <Col xs="12" sm="8" className="paginator-section offset-sm-2">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={pageSize}
          totalItemsCount={totalItems}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          linkClass="page-link"
          activeLinkClass="active"
          itemClass="page-item"
          itemClassLast="last"
          itemClassFirst="first"
          itemClassPrev="prev"
          itemClassNext="next"
          firstPageText={<ChevronDoubleLeftIcon width={18} height={18} />}
          lastPageText={<ChevronDoubleRightIcon width={18} height={18} />}
          prevPageText={<ChevronLeftIcon width={18} height={18} />}
          nextPageText={<ChevronRightIcon width={18} height={18} />}
        />
      </Col>
      <Col sm="2" className="paginator-size-select d-none d-sm-block">
        {/* CSS for Single Select is broken right now */}
        {allowSelectPageSize && (
          <Formik>
            <Form>
              <FormGroup className="paginator-section page-size-field">
                <Field
                  component={RenderSingleSelect}
                  name="itemsPerPage"
                  value={activeOption}
                  onChange={changePageSize}
                  options={itemsPerPageOptions}
                />
              </FormGroup>
            </Form>
          </Formik>
        )}
      </Col>
    </Row>
  );
};
export default Paginator;
