import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { showTitle } from "../../../../../actions";
const FollowersAudienceBrandAffinity = ({ reportData, ...props }) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Audience Brand Affnity</CardTitle>
        <div className="card-content ch-8">
          <ListGroup className="ba-list">
            {reportData &&
            reportData.audience_followers &&
            reportData.audience_followers.data &&
            reportData.audience_followers.data.audience_brand_affinity &&
            reportData.audience_followers.data.audience_brand_affinity ? (
              reportData.audience_followers.data.audience_brand_affinity.map(
                (affinity, index) => {
                  if (index < 3) {
                    return (
                      <ListGroupItem
                        key={index}
                        className="d-flex align-items-center"
                      >
                        <div className="img-col-left">
                          <img
                            loading="lazy"
                            src={props.generateImgUrl("brand", affinity.id)}
                            alt="img"
                          />
                        </div>
                        <div
                          id={`followers_${affinity.name}`}
                          title={showTitle(
                            `followers_${affinity.name}`,
                            affinity.name,
                          )}
                          className="text-wrap"
                        >
                          {affinity.name}
                        </div>
                      </ListGroupItem>
                    );
                  }
                  return null;
                },
              )
            ) : (
              <span className="text-center text-grey ">
                <p className="pt-50">No records</p>
              </span>
            )}
          </ListGroup>
        </div>
      </CardBody>
    </Card>
  );
};

export default FollowersAudienceBrandAffinity;
