import useLoginUserAgreementsActionRequiredCount from "../hooks/useLoginUserAgreementsActionRequiredCount";

/**
 * Displays a notification dot if the user needs to take action on an agreement.
 * Intended use is on a global level to indicate any agreement actions for the user.
 *
 * @returns {JSX.Element|null} The notification dot element or null if there are no incomplete agreements.
 */
const LoginUserAgreementsActionRequiredDot = () => {
  const incompleteAgreements = useLoginUserAgreementsActionRequiredCount();
  if (!incompleteAgreements) {
    return null;
  }
  return <div className="notification-dot" />;
};

export default LoginUserAgreementsActionRequiredDot;
