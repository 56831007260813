import { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { getAgreement, updateAgreement } from "../actions";
import AgreementRecord from "./AgreementRecord";

function AgreementModal({
  agreement_id,
  setAgreement_id,

  agreement,
  getAgreement,
  updateAgreement,

  ...props
}) {
  // useEsigEvent("documentSigned", async () => {
  // TODO: Disabling the update action on signature for now. Weird API issue where I'm sending over _id, but get 400 back saying _id is required. But the same request works from admin side. A workaround is in place to force refresh agreements when signature modal closes.
  // await updateAgreement(agreement_id, { creatorHasSigned: true });
  //   setAgreement_id(null);
  // });

  const onClosed = useCallback(() => {
    // TODO: this is a workaround for the issue mentioned above. Setting agreement_id to null triggers refetch in AgreementsTable
    // TODO: related issue - global agreements action required is only refreshed on a user read one, which is not triggered by the above fix.
    setAgreement_id(null);
  }, [setAgreement_id]);

  useEffect(() => {
    if (agreement_id) {
      getAgreement(agreement_id);
    }
  }, [agreement_id, getAgreement]);

  const handleCloseModal = () => {
    setAgreement_id(null);
  };

  if (!agreement_id) {
    return null;
  }

  return (
    <Modal
      isOpen={!!agreement_id}
      toggle={handleCloseModal}
      size={agreement?.embeddedSigningUrl ? "xl" : "md"}
      onClosed={onClosed}
    >
      {agreement?.embeddedSigningUrl ? (
        <>
          <ModalHeader toggle={handleCloseModal}>
            <span className="bold fs-4">
              Review and Sign - {agreement?.title}
            </span>
          </ModalHeader>
          <ModalBody>
            <iframe
              title={`E-Signature Agreement - ${agreement?.title}`}
              src={agreement?.embeddedSigningUrl}
              width="100%"
              height="800px"
              frameBorder={0}
            />
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHeader toggle={handleCloseModal}>
            <span className="bold text-muted">
              View Agreement -&nbsp;
              <span className="text-body">{agreement?.title}</span>
            </span>
          </ModalHeader>
          <ModalBody>
            <AgreementRecord agreement={agreement} />
          </ModalBody>
        </>
      )}
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    agreement: state.agreements.agreement,
  };
};
export default connect(mapStateToProps, {
  getAgreement,
  updateAgreement,
})(AgreementModal);
