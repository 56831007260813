import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Badge,
  Progress,
} from "reactstrap";
import { capitalize, showTitle } from "../../../../../actions";
const FollowersEthnicity = ({ reportData, ...props }) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Ethnicity</CardTitle>
        <div className="card-content ch-10">
          <ListGroup flush className="country-list with-progress">
            {reportData &&
            reportData.audience_followers &&
            reportData.audience_followers.data &&
            reportData.audience_followers.data.audience_ethnicities ? (
              reportData.audience_followers.data.audience_ethnicities.map(
                (ethnicity, index) => {
                  if (index < 3) {
                    return (
                      <ListGroupItem key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="country-text-wrap">
                            <div
                              id={`followers_${ethnicity.name}`}
                              title={showTitle(
                                `followers_${ethnicity.name}`,
                                ethnicity.name,
                              )}
                              className="country-name"
                            >
                              {capitalize(ethnicity.name)}
                            </div>
                          </div>
                          <Badge className="text-grey" color="">
                            {(ethnicity.weight * 100).toFixed(2) + "%"}
                          </Badge>
                        </div>
                        <Progress
                          value={(ethnicity.weight * 100).toFixed(2)}
                          className="progress-bar-custom"
                        />
                      </ListGroupItem>
                    );
                  }
                  return null;
                },
              )
            ) : (
              <span className="text-center text-grey ">
                <p className="pt-50">No records</p>
              </span>
            )}
          </ListGroup>
        </div>
      </CardBody>
    </Card>
  );
};

export default FollowersEthnicity;
