import { connect, useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap";
import { getAssignmentOverview } from "../../actions";
import { useEffect } from "react";
import { RenderCurrency } from "../../../../components";
import "./PayoutOverview.scss";
import PayoutOverviewSkeleton from "./PayoutOverviewSkeleton";

const PayoutOverview = ({
  getAssignmentOverview,
  assignment,
  loginUser,
  ...props
}) => {
  useEffect(() => {
    getAssignmentOverview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = useSelector((state) => state.payouts.loading);
  if (isLoading && !assignment) {
    return <PayoutOverviewSkeleton />;
  }

  return (
    <>
      <h1 className="heading title">Payouts</h1>
      <Row>
        <Col sm="12" md="10" lg="8">
          <Card className="card-custom">
            <CardBody>
              <CardTitle tag="h2" className="title">
                Overview
              </CardTitle>
              <Table borderless size="sm" className="payouts-table">
                <tbody>
                  <tr className="regular-text bold">
                    <td>
                      <span>Available to Payout</span>
                    </td>
                    <td className="numeric">
                      <RenderCurrency
                        amount={assignment?.__availableBalanceInStripe}
                        renderCurrencyCode={loginUser?.country !== "US"}
                        currency={assignment?.__availableBalanceCurrency}
                        country={loginUser?.country}
                      />
                    </td>
                  </tr>
                  {assignment?.__pendingAvailableBalance > 0 && (
                    <tr className="light-text bold">
                      <td>
                        <span>In-transit Deposits</span>
                      </td>
                      <td className="numeric">
                        <RenderCurrency
                          amount={assignment?.__pendingAvailableBalance}
                          renderCurrencyCode={loginUser?.country !== "US"}
                          currency={
                            assignment?.__pendingAvailableBalanceCurrency
                          }
                          country={loginUser?.country}
                        />
                      </td>
                    </tr>
                  )}
                  <tr className="light-text bold">
                    <td>Funds on hold</td>
                    <td className="numeric">
                      <RenderCurrency
                        amount={assignment?.__holdBalance}
                        renderCurrencyCode={loginUser.country !== "US"}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    assignment: state.payouts.assignment,
  };
};
export default connect(mapStateToProps, {
  getAssignmentOverview,
})(PayoutOverview);
