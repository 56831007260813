import { agreementsActionTypes } from "../constants";

const initialState = {
  loading: false,
  agreements: [],
  totalAgreementsCount: null,
  agreement: null,
};

const agreementsReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case agreementsActionTypes.getAgreements.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case agreementsActionTypes.getAgreements.SUCCESS:
      return {
        ...state,
        loading: false,
        agreements: payload?.results,
        totalAgreementsCount: payload?.count,
      };
    case agreementsActionTypes.getAgreements.FAILURE:
      return {
        ...state,
        loading: false,
        agreements: [],
        totalAgreementsCount: null,
      };
    case agreementsActionTypes.getAgreement.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case agreementsActionTypes.getAgreement.SUCCESS:
      return {
        ...state,
        loading: false,
        agreement: payload?.result,
      };
    case agreementsActionTypes.getAgreement.FAILURE:
      return {
        ...state,
        loading: false,
        agreement: null,
      };
    case agreementsActionTypes.updateAgreement.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case agreementsActionTypes.updateAgreement.SUCCESS:
      return {
        ...state,
        loading: false,
        agreement: payload?.result,
      };
    case agreementsActionTypes.updateAgreement.FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default agreementsReducers;
