import { useState } from "react";
import DelayedButton from "./DelayedButton";
import AlertBanner from "../../../components/AlertBanner";
import mailOpenImage from "../../../assets/img/opened_mail_letter.svg";

function EmailSendConfirmation({
  sendAction,
  promptInitialSend,
  emailType = "magic",
  showDuplicateAccountMessage,
}) {
  const [hasSentInitial, setHasSentInitial] = useState(false);

  const handleSendAction = () => {
    sendAction();
    setHasSentInitial(true);
  };

  return (
    <div className="mb-4">
      {showDuplicateAccountMessage && (
        <div className="mb-3">
          <AlertBanner
            alertType="info"
            message="An account with that email already exists, so we've sent you a link to login."
          />
        </div>
      )}
      <img
        src={mailOpenImage}
        className="mx-auto d-block"
        alt="An opened envelope containing a message"
        width="25%"
        height="25%"
      />
      <h3 className="text-center text-grey mt-4 mb-3">
        {emailType === "magic" && "Check your email for a magic link to login."}
        {emailType === "recovery" &&
          "Check your email for a password reset link."}
        {emailType === "verify" &&
          (!promptInitialSend || hasSentInitial) &&
          "Check your inbox for an email verification link."}
        {emailType === "verify" &&
          promptInitialSend &&
          !hasSentInitial &&
          "Send a verification link to your email address."}
      </h3>
      {(!promptInitialSend || hasSentInitial) && (
        <p className="text-center text-grey mb-4 font-p">
          {emailType === "magic" &&
            "If you do not receive an email, please check your spam folder. Magic links are only valid for 10 minutes."}
          {(emailType === "recovery" || emailType === "verify") &&
            "If you do not receive an email, please check your spam folder. Links are only valid for 1 hour."}
        </p>
      )}
      <DelayedButton
        buttonText={
          promptInitialSend && !hasSentInitial ? "Send Email" : "Resend Email"
        }
        buttonStyle={promptInitialSend && hasSentInitial && "outline"}
        buttonAction={handleSendAction}
        skipInitialDelay={promptInitialSend}
      />
    </div>
  );
}

export default EmailSendConfirmation;
