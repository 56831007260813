import {
  actionCreator,
  checkHttpStatus,
  checkAuthTokenExpiry,
  privateHeaders,
  publicHeaders,
  showErrorMessage,
  showSuccessMessage,
} from "../../../actions";
import { objectUrlToFile } from "../../../utils/objectUrlToFile";
import { logout } from "../../login/actions";
import {
  ASSIGNMENT_SAVE_ERROR,
  campaignsActionTypes,
  CAMPAIGN_INVITE_ERROR,
  CAMPAIGN_JOIN_SUCCESS,
  CREATE_ONE_CONTENT_URL,
  GET_CAMPAIGNS_ERROR,
  GET_CAMPAIGN_ERROR,
  GET_CONTENTS_ERROR,
  GET_CONTENT_ERROR,
  READ_MANY_CAMPAIGNS_URL,
  READ_MANY_CONTENTS_URL,
  READ_ONE_CAMPAIGN_URL,
  READ_ONE_CONTENT_PREVIEW_URL,
  SAVE_CONTENT_ERROR,
  SAVE_CONTENT_SUCCESS,
  SUBMIT_APPROVAL_SUCCESS,
  UPDATE_ONE_ASSIGNMENT_URL,
  UPDATE_ONE_CONTENT_URL,
  UPLOAD_MEDIA_ERROR,
  UPLOAD_IMAGE_ERROR,
} from "../constants";
import { uploadMediaItem } from "../services/uploadMedia";

// Campaign Assignments

export const getCampaign = (assignment_id) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(campaignsActionTypes.getCampaign.REQUEST));
    // TODO: #content-approval: add calculates
    let url = new URL(READ_ONE_CAMPAIGN_URL);
    const params = url.searchParams;
    params.append("_id", assignment_id);
    params.append(
      "calculate",
      "contentCounts deliverableMetrics agreementMetrics",
    );
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: privateHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response) {
          showErrorMessage(GET_CAMPAIGN_ERROR);
          dispatch(actionCreator(campaignsActionTypes.getCampaign.FAILURE));
        } else {
          dispatch(
            actionCreator(campaignsActionTypes.getCampaign.SUCCESS, response),
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(campaignsActionTypes.getCampaign.FAILURE));
        showErrorMessage(GET_CAMPAIGN_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const getCampaigns = ({
  inviteStates = ["accepted", "null"],
  pageNumber = 1,
  pageSize = 10,
  ...rest
}) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(campaignsActionTypes.getCampaigns.REQUEST));
    let url = new URL(READ_MANY_CAMPAIGNS_URL);
    const params = url.searchParams;
    params.append("campaignInvitationStatus", inviteStates);
    params.append("sortByAnyUserActionRequired", true);
    params.append("sortKey", "updated");
    params.append("sortValue", -1);
    params.append(
      "calculate",
      "contentCounts deliverableMetrics agreementMetrics",
    );
    params.append("limit", pageSize);
    const skip = pageNumber > 1 ? (pageNumber - 1) * pageSize : 0;
    params.append("skip", skip);
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: privateHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.results) {
          showErrorMessage(GET_CAMPAIGNS_ERROR);
          dispatch(actionCreator(campaignsActionTypes.getCampaigns.FAILURE));
        } else {
          dispatch(
            actionCreator(campaignsActionTypes.getCampaigns.SUCCESS, response),
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(campaignsActionTypes.getCampaigns.FAILURE));
        showErrorMessage(GET_CAMPAIGNS_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const getCampaignInvitations = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(campaignsActionTypes.getCampaignInvitations.REQUEST),
    );
    let url = new URL(READ_MANY_CAMPAIGNS_URL);
    const params = url.searchParams;
    params.append("campaignInvitationStatus", "pending");
    params.append(
      "calculate",
      "contentCounts deliverableMetrics agreementMetrics",
    );
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: privateHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.results) {
          dispatch(
            actionCreator(campaignsActionTypes.getCampaignInvitations.FAILURE),
          );
        } else {
          dispatch(
            actionCreator(
              campaignsActionTypes.getCampaignInvitations.SUCCESS,
              response,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(campaignsActionTypes.getCampaignInvitations.FAILURE),
        );
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const answerCampaignInvitation = (
  assignment_id,
  acceptInvitation = true,
) => {
  return async (dispatch, getState) => {
    dispatch(
      actionCreator(campaignsActionTypes.answerCampaignInvitation.REQUEST),
    );

    if (!assignment_id) {
      dispatch(
        actionCreator(campaignsActionTypes.answerCampaignInvitation.FAILURE),
      );
      showErrorMessage(CAMPAIGN_INVITE_ERROR);
      console.log("Assignment _id is required");
      return;
    }

    fetch(UPDATE_ONE_ASSIGNMENT_URL, {
      method: "PUT",
      headers: publicHeaders,
      credentials: "include",
      body: JSON.stringify({
        _id: assignment_id,
        campaignInvitationStatus: acceptInvitation ? "accepted" : "declined",
      }),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.result) {
          showErrorMessage(CAMPAIGN_INVITE_ERROR);
          dispatch(
            actionCreator(campaignsActionTypes.answerCampaignInvitation.FAILURE),
          );
        } else {
          dispatch(
            actionCreator(
              campaignsActionTypes.answerCampaignInvitation.SUCCESS,
              response.result,
            ),
          );
          // showSuccessMessage(
          //   acceptInvitation
          //     ? CAMPAIGN_INVITE_JOIN_SUCCESS
          //     : CAMPAIGN_INVITE_REJECT_SUCCESS
          // );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(campaignsActionTypes.answerCampaignInvitation.FAILURE),
        );
        showErrorMessage(CAMPAIGN_INVITE_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const updateCampaignAssignment = (
  { _id, ...assignmentBody },
  showJoinSuccessMessage = false,
) => {
  return async (dispatch, getState) => {
    dispatch(
      actionCreator(campaignsActionTypes.updateCampaignAssignment.REQUEST),
    );
    if (!_id) {
      showErrorMessage(ASSIGNMENT_SAVE_ERROR);
      console.log("Assignment _id is required");
      return;
    }

    fetch(UPDATE_ONE_ASSIGNMENT_URL, {
      method: "PUT",
      headers: publicHeaders,
      credentials: "include",
      body: JSON.stringify({
        _id,
        ...assignmentBody,
      }),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.result) {
          showErrorMessage(ASSIGNMENT_SAVE_ERROR);
          dispatch(
            actionCreator(campaignsActionTypes.updateCampaignAssignment.FAILURE),
          );
        } else {
          dispatch(
            actionCreator(
              campaignsActionTypes.updateCampaignAssignment.SUCCESS,
              response.result,
            ),
          );
          if (showJoinSuccessMessage) {
            showSuccessMessage(CAMPAIGN_JOIN_SUCCESS);
          }
          // showSuccessMessage(ASSIGNMENT_SAVE_SUCCESS);
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(campaignsActionTypes.updateCampaignAssignment.FAILURE),
        );
        showErrorMessage(ASSIGNMENT_SAVE_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

// Content

export const createContent = (content) => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return async (dispatch, getState) => {
    dispatch(actionCreator(campaignsActionTypes.createContent.REQUEST));
    const formData = new FormData();
    for (const key of Object.keys(content)) {
      if (Array.isArray(content[key])) {
        if (!content[key].length) {
          formData.append(key, "");
        } else {
          for (const item of content[key]) {
            try {
              let formDataKey = key;
              let formDataValue = item;
              if (item?.mediaType === "image") {
                if (!item?.url) {
                  throw new Error("No image object URL provided");
                }
                formDataKey = "images";
                formDataValue = await objectUrlToFile(
                  item?.url,
                  `img-${content.assignment_id}`,
                );
              } else {
                formDataKey = `${formDataKey}[]`;
              }
              formData.append(formDataKey, formDataValue);
            } catch (error) {
              // * don't fail the entire save action, just alert the user to a failed file upload
              console.error("Error uploading content media:", error);
              showErrorMessage(UPLOAD_IMAGE_ERROR);
            }
          }
        }
      } else {
        formData.append(key, content[key]);
      }
    }
    fetch(CREATE_ONE_CONTENT_URL, {
      method: "POST",
      headers: privateHeaders(getState, "none"),
      credentials: "include",
      body: formData,
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.result) {
          showErrorMessage(SAVE_CONTENT_ERROR);
          dispatch(actionCreator(campaignsActionTypes.createContent.FAILURE));
        } else {
          dispatch(
            actionCreator(campaignsActionTypes.createContent.SUCCESS, response),
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(campaignsActionTypes.createContent.FAILURE));
        showErrorMessage(SAVE_CONTENT_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

// Used for content approval share links. Anyone with the share link can see the content preview without requiring authentication
export const getContentPreview = (campaignContent_id) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(campaignsActionTypes.getContentPreview.REQUEST));
    let url = new URL(READ_ONE_CONTENT_PREVIEW_URL);
    const params = url.searchParams;
    params.append("_id", campaignContent_id);
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: privateHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response) {
          showErrorMessage(GET_CONTENT_ERROR);
          dispatch(
            actionCreator(campaignsActionTypes.getContentPreview.FAILURE),
          );
        } else {
          dispatch(
            actionCreator(
              campaignsActionTypes.getContentPreview.SUCCESS,
              response,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(campaignsActionTypes.getContentPreview.FAILURE));
        showErrorMessage(GET_CONTENT_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const getContents = ({ assignment_id, status }) => {
  return (dispatch) => {
    dispatch(actionCreator(campaignsActionTypes.getContents.REQUEST));
    let url = new URL(READ_MANY_CONTENTS_URL);
    const params = url.searchParams;
    params.append("assignment_id", assignment_id);
    params.append("status", status);
    params.append("limit", 27);
    params.append("sortKey", "updated");
    params.append("sortValue", -1);
    url = url.toString();
    fetch(url, {
      method: "GET",
      headers: privateHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.results) {
          showErrorMessage(GET_CONTENTS_ERROR);
          dispatch(actionCreator(campaignsActionTypes.getContents.FAILURE));
        } else {
          dispatch(
            actionCreator(campaignsActionTypes.getContents.SUCCESS, response),
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(campaignsActionTypes.getContents.FAILURE));
        showErrorMessage(GET_CONTENTS_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const updateContent = (content) => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return async (dispatch, getState) => {
    dispatch(actionCreator(campaignsActionTypes.updateContent.REQUEST));
    const formData = new FormData();
    for (const key of Object.keys(content)) {
      if (Array.isArray(content[key])) {
        if (!content[key].length) {
          formData.append(key, "");
        } else {
          for (const item of content[key]) {
            try {
              let formDataKey = key;
              let formDataValue = item;
              if (item?.mediaType === "image") {
                if (!item?.url) {
                  throw new Error("No image object URL provided");
                }
                formDataKey = "images";
                formDataValue = await objectUrlToFile(
                  item?.url,
                  `img-${content.assignment_id}`,
                );
              } else {
                formDataKey = `${formDataKey}[]`;
              }
              formData.append(formDataKey, formDataValue);
            } catch (error) {
              // * don't fail the entire save action, just alert the user to a failed file upload
              console.error("Error uploading content media:", error);
              showErrorMessage(UPLOAD_IMAGE_ERROR);
            }
          }
        }
      } else {
        formData.append(key, content[key]);
      }
    }
    fetch(UPDATE_ONE_CONTENT_URL, {
      method: "PUT",
      headers: privateHeaders(getState, "none"),
      credentials: "include",
      body: formData,
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.result) {
          showErrorMessage(SAVE_CONTENT_ERROR);
          dispatch(actionCreator(campaignsActionTypes.updateContent.FAILURE));
        } else {
          dispatch(actionCreator(campaignsActionTypes.updateContent.SUCCESS));
          if (content.submitForApproval) {
            showSuccessMessage(SUBMIT_APPROVAL_SUCCESS);
          } else {
            showSuccessMessage(SAVE_CONTENT_SUCCESS);
          }

          dispatch(setEditPostModalVisibility(false));
        }
      })
      .catch((error) => {
        dispatch(actionCreator(campaignsActionTypes.updateContent.FAILURE));
        showErrorMessage(SAVE_CONTENT_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

// Uploads and Media

// upload an array of media items to S3 and save CDN URLs to content_id
// on success close edit post modal and show success message
export const uploadMedia = async ({ content_id, media = [] }) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(campaignsActionTypes.uploadMedia.REQUEST));
    try {
      let updatedContent;
      for (const mediaItem of media) {
        updatedContent = await uploadMediaItem({ content_id, mediaItem });
        if (!updatedContent) {
          throw new Error("Error uploading media");
        }
      }
      dispatch(actionCreator(campaignsActionTypes.uploadMedia.SUCCESS));
      return updatedContent;
    } catch (error) {
      dispatch(actionCreator(campaignsActionTypes.uploadMedia.FAILURE));
      showErrorMessage(UPLOAD_MEDIA_ERROR);
      console.error("Error uploading media:", error);
    }
  };
};

// UI Actions

export const setContentBeingEdited = (content) => {
  return (dispatch) => {
    dispatch(
      actionCreator(campaignsActionTypes.setContentBeingEdited.SUCCESS, content),
    );
  };
};

export const setEditPostModalVisibility = (modalState) => {
  return (dispatch) => {
    dispatch(
      actionCreator(
        campaignsActionTypes.setEditPostModalVisibility.SUCCESS,
        modalState,
      ),
    );
  };
};
