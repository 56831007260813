import youtube from "../assets/img/youtube.svg";
import instagram from "../assets/img/insta_icon.svg";
import tiktok from "../assets/img/tiktok.svg";

const networkImage = (network) => {
  switch (network) {
    case "youtube":
      return (
        <img className="social-icon-img youtube" src={youtube} alt={network} />
      );
    case "tiktok":
      return (
        <img className="social-icon-img tiktok" src={tiktok} alt={network} />
      );
    case "instagram":
    default:
      return (
        <img
          className="social-icon-img instagram"
          src={instagram}
          alt={network}
        />
      );
  }
};

export default networkImage;
