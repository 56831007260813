import { NavLink } from "react-router-dom";

const AltAuthOptionLinks = ({ hideAuthTypes = [], ...props }) => {
  return (
    <>
      {/* {hideAuthTypes?.includes("password") ? null : (
        <p className="text-grey mt-2">
          Prefer to use your password?
          <NavLink to="/" className="link-more ml-1">
            Login with password
          </NavLink>
        </p>
      )}
      {hideAuthTypes?.includes("magicLink") ? null : (
        <p className="text-grey mt-2">
          Prefer to use a magic link?
          <NavLink to="/magicLink" className="link-more ml-1">
            Login with magic link
          </NavLink>
        </p>
      )} */}
      {hideAuthTypes?.includes("recovery") ? null : (
        <p className="text-grey mt-2">
          Forgot password?
          <NavLink to="/recovery" className="link-more ml-1">
            Recover Account
          </NavLink>
        </p>
      )}
      {hideAuthTypes?.includes("signup") ? null : (
        <p className="text-grey mt-2 mb-0">
          Don&apos;t have an account?
          <NavLink to="/signup" className="link-more ml-1">
            Sign up
          </NavLink>
        </p>
      )}
    </>
  );
};

export default AltAuthOptionLinks;
