import { Col, Row, Table } from "reactstrap";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DeliverableRowSkeleton from "./DeliverableRowSkeleton";

const DeliverablesTableSkeleton = ({ headers, ...props }) => {
  const columnHeaders = headers.map((column, index) => (
    <th
      className={headers[index].class}
      width={headers[index].width}
      key={index}
    >
      <Skeleton width={headers[index].skeleton} height={16} />
    </th>
  ));

  return (
    <Row className="search-results-wrap">
      <Col md="12">
        <div className="table-responsive table-outer user-profile-table">
          <Table hover borderless>
            <thead>
              <tr>{columnHeaders}</tr>
            </thead>
            <tbody>
              <DeliverableRowSkeleton />
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default DeliverablesTableSkeleton;
