import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const DeliverableRowSkeleton = ({ ...props }) => {
  return (
    <>
      {Array(3)
        .fill()
        .map((item, index) => (
          <tr className="activity-row" key={index}>
            <th>
              <h4 className="m-0">
                <Skeleton height={24} />
              </h4>
            </th>
          </tr>
        ))}
    </>
  );
};

export default DeliverableRowSkeleton;
