import { checkHttpStatus, checkAuthTokenExpiry } from "../../../actions";
import {
  GET_PRESIGNED_UPLOAD_URL,
  COMPLETE_PRESIGNED_UPLOAD_URL,
} from "../constants";

export async function uploadMediaItem({ mediaItem, content_id }) {
  const blob = await dataURLToBlob(mediaItem?.url);
  const chunks = chunkData(blob);
  const { uploadType, result: upload } = await getPresignedUpload({
    content_id,
    mediaType: mediaItem?.mediaType,
    chunkCount: chunks?.length,
  });
  if (chunks?.length !== upload?.presignedUrls?.length) {
    throw new Error("Error getting presigned urls. Chunk count mismatch");
  }
  const uploadPromises = chunks.map(async (chunk, index) => {
    const part = upload?.presignedUrls?.[index];
    return uploadChunk({ part, chunk, uploadType });
  });
  const uploaded = await Promise.all(uploadPromises);
  await completePresignedUpload({
    content_id,
    mediaType: mediaItem?.mediaType,
    uploaded,
    uploadType,
  });
}

async function uploadChunk({ part, chunk, uploadType }) {
  const response = await fetchWithChecks(part?.url, {
    method: "PUT",
    body: chunk,
  });
  if (response?.error) {
    throw response?.error;
  }
  if (!response?.result) {
    throw new Error("Error uploading part");
  }
  if (uploadType !== "multipart") {
    return response;
  }
  const tag = await response?.headers?.get("ETag");
  return { ...response, etag: tag };
}

async function completePresignedUpload({
  content_id,
  mediaType,
  uploadType,
  uploaded,
}) {
  // ## PUT `.../api/v1/campaignContent/completeMultipartUpload`

  // Completes the upload and updates the campaign content document with the new media.

  // Parameters

  // | Parameter Name                  | Type       | Default | Required | Description                                                                        |
  // | :------------------------------ | :--------- | :------ | :------- | :--------------------------------------------------------------------------------- |
  // | `_id`                           | `ObjectId` |         | `true`   |                                                                                    |
  // | `uploadId`                      | `String`   |         | `true`   |                                                                                    |
  // | `bucket`                        | `String`   |         | `true`   |                                                                                    |
  // | `key`                           | `String`   |         | `true`   |                                                                                    |
  // | `mediaType`                     | `String`   |         | `true`   | Can be `image`, `video`, or `thumbnail`. Not to be confused with `postContentType` |
  // | `uploadType`                     | `String`   |         | `true`   | Can be `single`, or `multipart`                                      |
  // | `uploadedParts`                 | `[Object]` |         | `true`   | Array of responses obtained from uploading each part.                              |
  // | `uploadedParts[...].ETag`       | `String`   |         | `true`   |                                                                                    |
  // | `uploadedParts[...].PartNumber` | `Number`   |         | `true`   |                                                                                    |
  // | `populate`                      | `String`   |         | `false`  |                                                                                    |

  const response = await fetchWithChecks(COMPLETE_PRESIGNED_UPLOAD_URL, {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify({
      _id: content_id,
      uploadId: uploaded?.[0]?.uploadId,
      bucket: uploaded?.[0]?.bucket,
      key: uploaded?.[0]?.key,
      mediaType,
      uploadType,
      uploadedParts: uploaded,
    }),
  });
  const data = await response?.json();
  return data?.presignedUrls;
}

async function getPresignedUpload({ content_id, mediaType, chunkCount }) {
  const response = await fetchWithChecks(GET_PRESIGNED_UPLOAD_URL, {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify({
      _id: content_id,
      chunkCount,
      mediaType,
    }),
  });
  const data = await response?.json();
  if (data?.error) {
    throw data?.error;
  }
  if (!data?.result) {
    throw new Error("Error getting presigned urls");
  }
  return data?.result;
}

function chunkData(blob) {
  // each chunk should be between 20 MB to 200 MB
  // 1 MB = 1048576 bytes
  if (blob.size < 1048576 * 200) {
    return [blob];
  }
  const chunkSize = 1048576 * 100;
  const totalChunks = Math.ceil(blob.size / chunkSize);
  const chunks = [];
  for (let i = 0; i < totalChunks; i++) {
    const start = i * chunkSize;
    const end = Math.min(blob.size, (i + 1) * chunkSize);
    chunks.push(blob.slice(start, end));
  }
  return chunks;
}

async function dataURLToBlob(dataURL) {
  const response = await fetch(dataURL);
  return await response.blob();
}

function fetchWithChecks(url, requestOptions) {
  return fetch(url, requestOptions)
    .then(checkHttpStatus)
    .then((response) => checkAuthTokenExpiry(response));
}
