import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Row, Col } from "reactstrap";
import AlertBanner from "../../../components/AlertBanner";
import AdditionalInfoForm from "./AdditionalInfoForm";
import "./index.scss";

// eslint-disable-next-line react/display-name
const AdditionalInfoStep = forwardRef(
  ({ setNextButtonType, closeConfiguration, ...props }, ref) => {
    const formikRef = useRef(null);

    const accountFields =
      props.loginUser?.phone && props.loginUser.socialHandles?.length > 0;

    useEffect(() => {
      setNextButtonType(accountFields ? "finish" : "save");
    }, [accountFields, setNextButtonType]);

    useImperativeHandle(ref, () => ({
      isValidated() {
        if (formikRef.current) {
          formikRef.current.handleSubmit();
        }
        closeConfiguration();
      },
    }));
    return (
      <div className="additional-info">
        <Row>
          <>
            <Col xs="12">
              <div className="subheading">
                <h3 className="title">Finish account configuration</h3>
                <p className="text-grey mb-0">
                  Provide any remaining information and finish account
                  configuration.
                </p>
              </div>
            </Col>
            <Col xs="12">
              {accountFields ? (
                <AlertBanner
                  message="All required account information has been collected."
                  alertType="success"
                />
              ) : (
                <AdditionalInfoForm ref={formikRef} {...props} />
              )}
            </Col>
          </>
        </Row>
      </div>
    );
  },
);

export default AdditionalInfoStep;
