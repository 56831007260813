import { NavLink } from "react-router-dom";
import AgreementStatusChips from "./AgreementStatusChips";
import ViewAgreementFileButton from "./ViewAgreementFileButton";
import AgreementActionRequiredIcon from "./AgreementActionRequiredIcon";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "reactstrap";
import { useCallback } from "react";
import CampaignLink from "../../campaigns/components/CampaignLink";

// TODO: better implementation of is mobile
const AgreementsTableRow = ({
  agreement,
  setAgreement_id,
  isMobile,
  ...props
}) => {
  const { path } = useRouteMatch();
  const selectAgreement = useCallback(() => {
    setAgreement_id(agreement._id);
  }, [agreement._id, setAgreement_id]);
  return (
    <tr className="activity-row">
      <th scope="row" className="tb-head text-grey">
        {path.includes("/agreements/:") ? (
          <NavLink className="link-more" to={`/agreements/${agreement._id}`}>
            {agreement.title}
          </NavLink>
        ) : (
          <Button
            color="link"
            className="link-more text-left"
            onClick={selectAgreement}
          >
            {agreement.title}
          </Button>
        )}
      </th>
      <td>
        <div className="text-grey">
          <h4>
            <AgreementStatusChips agreement={agreement} />
          </h4>
        </div>
      </td>
      <td className="tb-head text-grey">
        {agreement.assignment_id && (
          <CampaignLink
            campaign_id={agreement.assignment_id?._id}
            campaignName={agreement.assignment_id?.campaignName}
          />
        )}
      </td>

      <td className="tb-head text-grey">
        {!agreement.__isCreatorActionRequired && (
          <ViewAgreementFileButton agreement_id={agreement._id} size="sm">
            View
          </ViewAgreementFileButton>
        )}
      </td>

      <td>
        <AgreementActionRequiredIcon agreement={agreement} />
      </td>
    </tr>
  );
};

export default AgreementsTableRow;
