import { useDispatch, useSelector } from "react-redux";
import { updateOpportunityStatus } from "../actions";

import { RenderButton } from "../../../components";
import history from "../../../history";

const ActionButtons = ({ _id, status, ...props }) => {
  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.login.loginUser);

  const handleAction = (approvedByCreator) => {
    dispatch(updateOpportunityStatus({ _id, approvedByCreator }));
  };

  //TODO: Email body & subject
  const contactManager = () => {
    const to = loginUser.talentManagementEmail ?? "team@neoreach.com";
    const subject = "NeoReach Deals";
    const body = "I need support with my deal";
    const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  };

  if (status === "pending") {
    return (
      <div className="button-container">
        <RenderButton
          className="btn btn-primary btn w-100 p-3 mb-3"
          onClick={() => handleAction(true)}
        >
          I’m Interested
        </RenderButton>
        <RenderButton
          className="btn btn-outline-primary btn w-100 p-3"
          onClick={() => handleAction(false)}
        >
          Reject Deal
        </RenderButton>
      </div>
    );
  }

  return (
    <div className="button-container">
      <RenderButton
        className="btn btn-primary btn w-100 p-3 mb-3"
        onClick={() => history.push("/deals")}
      >
        Back to Deals
      </RenderButton>
      <RenderButton
        className="btn btn-outline-primary btn w-100 p-3"
        onClick={contactManager}
      >
        Contact my Manager
      </RenderButton>
    </div>
  );
};

export default ActionButtons;
