import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { NavLink } from "react-router-dom";

const OnboardingReturn = ({ isAuthenticated, ...props }) => {
  return (
    <>
      <div className="container public-page-container">
        <Row>
          <Col xs="12" md="8" lg="6">
            <Card className="card-custom px-md-4 px-s-1">
              <CardBody>
                <Row className="justify-content-center">
                  <Col xs="12" className="mb-5 text-center">
                    <CardTitle tag="h1" className="heading title">
                      Stripe Onboarding Complete
                    </CardTitle>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <div className="text-grey mt-2">
                      <p>You are being redirected.</p>
                      <NavLink to="/" className="link-more ml-1">
                        <p>
                          If you are not automatically redirected click here to
                          continue to the Creator Portal login.
                        </p>
                      </NavLink>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OnboardingReturn;
