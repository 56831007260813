import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, Row, Col } from "reactstrap";
import { getAgreements } from "../actions";
import Paginator from "../../../components/Paginator";
import AgreementsTableRow from "./AgreementsTableRow";
import { AGREEMENTS_TABLE_HEADERS } from "../constants";
import CampaignTableSkeleton from "../../campaigns/CampaignsTableSkeleton";

const AgreementsTable = ({
  caption,
  campaignAssignment_id,
  agreement_id,
  setAgreement_id,
  agreements,
  totalAgreementsCount,
  getAgreements,
  loading,
  ...props
}) => {
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // TODO: mobile responsive everything

  const fetchAgreements = useCallback(() => {
    const query = { pageNumber: activePage, pageSize };
    if (campaignAssignment_id) {
      query.assignment_id = campaignAssignment_id;
    }
    getAgreements({
      pageNumber: activePage,
      pageSize,
      assignment_id: campaignAssignment_id,
    });
  }, [activePage, campaignAssignment_id, getAgreements, pageSize]);

  useEffect(() => {
    if (!agreement_id) {
      fetchAgreements();
    }
  }, [fetchAgreements, agreement_id]);

  const columnHeaders = AGREEMENTS_TABLE_HEADERS.map((header, index) => (
    <th scope="col" key={index} className={header.class} width={header.width}>
      {header.heading}
    </th>
  ));

  const tableData = agreements.map((agreement) => (
    <AgreementsTableRow
      key={agreement._id}
      agreement={agreement}
      agreement_id={agreement_id}
      setAgreement_id={setAgreement_id}
    ></AgreementsTableRow>
  ));

  // const loading = useSelector((state) => state.agreements.loading);

  // TODO: loading skeleton
  if (loading) return <CampaignTableSkeleton />;

  return (
    <Row className="search-results-wrap">
      <Col md="12">
        {caption && <h3 className="heading title">{caption}</h3>}
        <div className="table-responsive table-outer">
          <Table hover borderless>
            {caption && <caption className="sr-only">{caption}</caption>}
            <thead>
              <tr>{columnHeaders}</tr>
            </thead>
            <tbody>
              {agreements && agreements.length ? (
                tableData
              ) : (
                <tr>
                  <td colSpan={AGREEMENTS_TABLE_HEADERS.length} align="center">
                    <h3 className="title bold mb-1 mt-5">
                      No agreements have been created yet.
                    </h3>
                    <p className="text-grey mb-5">
                      Agreements will be displayed here once they are added.
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {totalAgreementsCount > 10 && (
            <Paginator
              activePage={activePage}
              pageSize={pageSize}
              totalItems={totalAgreementsCount}
              handlePageChange={(pageNumber) => {
                setActivePage(pageNumber);
              }}
              handlePageSizeChange={(size) => {
                setPageSize(size);
              }}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    agreements: state.agreements.agreements,
    totalAgreementsCount: state.agreements.totalAgreementsCount,
    loading: state.agreements.loading,
  };
};
export default connect(mapStateToProps, {
  getAgreements,
})(AgreementsTable);
