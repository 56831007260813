import { useEffect } from "react";
import { Alert, Card, CardBody, Col, Row } from "reactstrap";
import { verifyAuthLinkAndLogin } from "./actions/index";
import { connect } from "react-redux";
import PublicCardTitleSection from "../../components/PublicCardTitleSection";
import { NavLink } from "react-router-dom";

const VerifyAuthLink = ({
  loading,
  verificationFailed,
  verifyAuthLinkAndLogin,
  ...props
}) => {
  const { id, token } = props.match.params;

  useEffect(() => {
    verifyAuthLinkAndLogin(id, token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token]);

  return (
    <>
      <div className="container public-page-container">
        <Row>
          <Col xs="12" md="8" lg="6">
            <Card className="card-custom px-md-4 px-s-1">
              <CardBody>
                <PublicCardTitleSection title="Verifying link" />
                <Row>
                  <Col>
                    {loading ? (
                      <>
                        <p className="text-center text-grey mb-5 font-p">
                          Attempting to verify your authentication link...
                        </p>
                      </>
                    ) : (
                      <>
                        {verificationFailed ? (
                          <Alert color="danger">
                            <h4 className="alert-heading">
                              Could not verify this authentication link.
                            </h4>
                            <p>
                              The link is expired, invalid, or has already been
                              used.
                            </p>
                          </Alert>
                        ) : (
                          <Alert color="success">
                            <h4 className="alert-heading">
                              Link has been verified.
                            </h4>
                          </Alert>
                        )}
                        {/* TODO: #authlinks fallback specifically for nonexistent accounts */}
                      </>
                    )}
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col className="text-center">
                    <p className="text-grey mt-2">
                      <NavLink to="/" className="link-more ml-1">
                        Return Home
                      </NavLink>
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    loading: state.login.loading,
    verificationFailed: state.login.verificationFailed,
  };
};

export default connect(mapStateToProps, {
  verifyAuthLinkAndLogin,
})(VerifyAuthLink);
