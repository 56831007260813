import { combineReducers } from "@reduxjs/toolkit";

import headerReducer from "../modules/header/reducers/index";
import signupReducer from "../modules/signup/reducers/index";
import loginReducer from "../modules/login/reducers/index";
import settingsReducer from "../modules/settings/reducers/index";
import dashboardReducer from "../modules/dashboard/reducers/index";
import payoutsReducer from "../modules/payouts/reducers/index";
import campaignsReducer from "../modules/campaigns/reducers/index";
import mediaKitsReducer from "../modules/mediaKits/reducers";
import opportunityReducer from "../modules/deals/reducers";
import onboardingReducer from "../modules/onboarding/reducers";
import deliverablesReducers from "../modules/deliverables/reducers";
import agreementsReducers from "../modules/agreements/reducers";

export default combineReducers({
  header: headerReducer,
  signup: signupReducer,
  login: loginReducer,
  settings: settingsReducer,
  dashboard: dashboardReducer,
  deliverables: deliverablesReducers,
  payouts: payoutsReducer,
  agreements: agreementsReducers,
  campaigns: campaignsReducer,
  mediaKits: mediaKitsReducer,
  opportunities: opportunityReducer,
  onboarding: onboardingReducer,
});
