import { mediaKitsActionTypes } from "../constants";
const initialState = {
  loading: false,
  mediaKits: null,
  mediaKit: null,
};
const mediaKitsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case mediaKitsActionTypes.getMediaKits.REQUEST:
      return {
        ...state,
        mediaKits: null,
        loading: true,
      };
    case mediaKitsActionTypes.getMediaKits.SUCCESS:
      return {
        ...state,
        loading: false,
        mediaKits: payload,
      };
    case mediaKitsActionTypes.getMediaKits.FAILURE:
      return {
        ...state,
        loading: false,
        mediaKits: null,
      };
    case mediaKitsActionTypes.getMediaKit.REQUEST:
      return {
        ...state,
        mediaKit: null,
        loading: true,
      };
    case mediaKitsActionTypes.getMediaKit.SUCCESS:
      return {
        ...state,
        loading: false,
        mediaKit: payload,
      };
    case mediaKitsActionTypes.getMediaKit.FAILURE:
      return {
        ...state,
        loading: false,
        mediaKit: null,
      };
    case mediaKitsActionTypes.getSocialHandle.REQUEST:
      return {
        ...state,
        loading: true,
        mediaKit: null,
      };
    case mediaKitsActionTypes.getSocialHandle.SUCCESS:
      return {
        ...state,
        loading: false,
        mediaKit: payload,
      };
    case mediaKitsActionTypes.getSocialHandle.FAILURE:
      return {
        ...state,
        loading: false,
        mediaKit: null,
      };
    case mediaKitsActionTypes.confirmSocialHandle.REQUEST:
      return {
        ...state,
        loading: true,
        mediaKit: null,
      };
    case mediaKitsActionTypes.confirmSocialHandle.SUCCESS:
      return {
        ...state,
        loading: false,
        mediaKit: payload,
      };
    case mediaKitsActionTypes.confirmSocialHandle.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default mediaKitsReducer;
