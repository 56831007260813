import { useEffect, useRef, useState, useCallback } from "react";
import { connect } from "react-redux";
import {
  MagnifyingGlassPlusIcon,
  PhotoIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { RenderButton } from "../../../../components";
import "./index.scss";
import { Field } from "formik";
import { showErrorMessage } from "../../../../actions";
import { PlayIcon, XMarkIcon } from "@heroicons/react/20/solid";
import MediaPreviewModal from "./MediaPreviewModal";
//@ts-ignore
import ImageBlobReduce from "image-blob-reduce";
//@ts-ignore
import Pica from "pica";
import Skeleton from "react-loading-skeleton";
import { Alert } from "reactstrap";

const pica = Pica({ features: ["js", "wasm", "cib"] }); // Fix for production mode error. See https://github.com/nodeca/image-blob-reduce/issues/17#issuecomment-751550135
const reduce = new ImageBlobReduce({ pica });

const ContentUpload = ({
  savedImageUrls = [],
  setSavedImageUrls,
  savedVideoUrl = null,
  setSavedVideoUrl,
  savedThumbnailUrl = null,
  setSavedThumbnailUrl,

  unsavedMediaBlobUrls = [],
  setUnsavedMediaBlobUrls,
  ...props
}) => {
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const [previewMedia, setPreviewMedia] = useState(null);
  const [shouldDeletePreviewMedia, setShouldDeletePreviewMedia] = useState([]);
  const [combinedImages, setCombinedImages] = useState([]);

  const player = useRef();
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const thumbnailInputRef = useRef(null);

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  // useEffect(() => {
  //   return () => {
  //     // Revoke all unsaved image data URLs
  //     unsavedMediaBlobUrls.forEach((dataUrl) => URL.revokeObjectURL(dataUrl));
  //     console.debug("revoked unsaved media data URLs");
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const [newVideo] = unsavedMediaBlobUrls.filter(
    (media) => media.mediaType === "video",
  );
  const [newThumbnail] = unsavedMediaBlobUrls.filter(
    (media) => media.mediaType === "thumbnail",
  );
  useEffect(() => {
    setCombinedImages(() => {
      const newImages = unsavedMediaBlobUrls.filter(
        (media) => media.mediaType === "image",
      );
      return [
        ...savedImageUrls.map((x) => {
          return { mediaType: "image", uploaded: 2, url: x };
        }),
        ...newImages,
      ];
    });
  }, [savedImageUrls, unsavedMediaBlobUrls]);

  const combinedVideo =
    newVideo ||
    (savedVideoUrl
      ? {
          mediaType: "video",
          uploaded: 2,
          url: savedVideoUrl,
        }
      : null);
  const combinedThumbnail =
    newThumbnail ||
    (savedThumbnailUrl
      ? {
          mediaType: "thumbnail",
          uploaded: 2,
          url: savedThumbnailUrl,
        }
      : null);
  const hasMedia = !!combinedImages?.length || !!combinedVideo;
  // const hasThumbnail = !!combinedThumbnail;
  const hasVideo = !!combinedVideo;
  // const hasImages = !!combinedImages?.length;

  const processImagesSequentially = useCallback(async (images, mediaType) => {
    try {
      const imageDataUrls = [];
      // Make sure to process sequentially as per Pica docs
      for (const img of images) {
        if (!img.type.startsWith("image/")) {
          continue;
        }

        try {
          const processed = await resizeImage(img);
          const url = URL.createObjectURL(processed);
          imageDataUrls.push({ mediaType, uploaded: 0, url });
        } catch (error) {
          console.error("Error processing image:", error);
          showErrorMessage("Error processing image upload");
        }
      }
      return imageDataUrls;
    } catch (error) {
      console.error("Error processing images:", error);
    }
  }, []);

  const handleUpload = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async (e, mediaType) => {
      try {
        const { name, files } = e.target;
        const newFiles = Array.from(files);
        if (!newFiles?.length) {
          return;
        }
        setLoadingCount(newFiles?.length);
        // images
        if (name === "images") {
          const imageMaxLimit = 10;
          if (newFiles?.length + combinedImages?.length > imageMaxLimit) {
            newFiles.splice(imageMaxLimit - combinedImages?.length);
            let message = `Posts are limited to ${imageMaxLimit} images.`;
            if (newFiles?.length) {
              message += ` Only the first ${newFiles?.length} selected images were added.`;
            } else {
              message += " No images were added.";
            }
            showErrorMessage(message, null, null, 10000);
          }

          try {
            const newImageDataUrls = await processImagesSequentially(
              newFiles,
              "image",
            );
            setUnsavedMediaBlobUrls([
              ...unsavedMediaBlobUrls,
              ...newImageDataUrls,
            ]);
          } catch (error) {
            console.error("Error processing images:", error);
          }
        }
        // video
        if (name === "video") {
          if (newFiles?.length > 1) {
            newFiles.splice(1);
            showErrorMessage(
              "Posts are limited to one video. Only the first selected video was added.",
              null,
              null,
              10000,
            );
          }
          try {
            const newVideoDataUrl = await processVideo(newFiles[0]);
            setUnsavedMediaBlobUrls([
              ...unsavedMediaBlobUrls,
              ...newVideoDataUrl,
            ]);
          } catch (error) {
            console.error("Error processing video:", error);
          }
        }

        // * video thumbnails not supported currently
        if (name === "thumbnail") {
          if (newFiles?.length > 1) {
            newFiles.splice(1);
            showErrorMessage(
              "Posts are limited to one video thumbnail. Only the first selected video thumbnail was added.",
              null,
              null,
              10000,
            );
          }
          try {
            const newThumbnailDataUrls = await processImagesSequentially(
              newFiles,
              "thumbnail",
            );
            setUnsavedMediaBlobUrls([
              ...unsavedMediaBlobUrls,
              newThumbnailDataUrls[0],
            ]);
          } catch (error) {
            console.error("Error processing video thumbnail:", error);
          }
        }
        setLoadingCount(0);
      } catch (error) {
        console.error("Error in handleUpload:", error);
      }
    },
    [
      combinedImages?.length,
      processImagesSequentially,
      setUnsavedMediaBlobUrls,
      unsavedMediaBlobUrls,
    ],
  );

  async function resizeImage(img) {
    return await reduce.toBlob(img, {
      max: 1080,
      unsharpAmount: 80,
      unsharpRadius: 0.6,
      unsharpThreshold: 2,
    });
  }

  async function processVideo(video) {
    setIsVideoLoaded(false);
    if (!video) {
      return [];
    }
    if (!video?.type?.startsWith("video/")) {
      return [];
    }
    const url = URL.createObjectURL(video);

    return [{ mediaType: "video", uploaded: 0, url }];
  }

  const handlePreviewOpen = (media) => {
    setPreviewMedia(media);
    setPreviewModalOpen(true);
  };

  const togglePreviewModal = () => {
    setPreviewModalOpen(!previewModalOpen);
  };

  const handleRemoveMedia = (mediaToRemove = []) => {
    if (!mediaToRemove?.length) {
      return;
    }
    if (previewModalOpen) {
      setShouldDeletePreviewMedia(mediaToRemove);
      togglePreviewModal();
      return;
      // * Wait to remove the image until modal close animation is complete to avoid layout shift while modal is still visible
    }

    let unsavedMedia = unsavedMediaBlobUrls;
    let savedImages = savedImageUrls;

    mediaToRemove?.forEach((removedItem) => {
      if (!removedItem?.url) {
        return;
      }
      if (removedItem?.uploaded === 0) {
        unsavedMedia = unsavedMedia.filter(
          (unsaved) => unsaved?.url !== removedItem.url,
        );
        URL.revokeObjectURL(removedItem.url);
      } else {
        switch (removedItem?.mediaType) {
          case "image":
            savedImages = savedImages.filter(
              (image) => image !== removedItem.url,
            );
            break;
          case "video":
            setSavedVideoUrl(null);
            setSavedThumbnailUrl(null);
            break;
          case "thumbnail":
            setSavedThumbnailUrl(null);
            break;
          default:
            console.error("Invalid  media type to remove");
            return;
        }
      }
    });

    setUnsavedMediaBlobUrls(unsavedMedia);
    setSavedImageUrls(savedImages);
    setPreviewMedia(null);
  };

  const handleModalClosed = () => {
    if (shouldDeletePreviewMedia?.length) {
      handleRemoveMedia(shouldDeletePreviewMedia);
      setShouldDeletePreviewMedia([]);
    }
  };

  return (
    <div className="content-upload">
      {!!loadingCount ? (
        <Skeleton
          inline={true}
          count={loadingCount + combinedImages?.length}
          containerClassName={"thumbnails"}
          className="thumbnails-skeleton thumbnail-item"
        />
      ) : (
        <>
          {hasMedia ? (
            <div className="has-media">
              {!!combinedVideo && (
                <div className="video-thumbnails">
                  <div className="thumbnail-item" key={"video-thumbnail"}>
                    <button
                      type="button"
                      className="expand-media-btn"
                      aria-label="View larger video"
                      onClick={() => {
                        handlePreviewOpen(combinedVideo);
                      }}
                    >
                      <video
                        style={{
                          visibility: isVideoLoaded ? "visible" : "hidden",
                        }}
                        src={combinedVideo?.url}
                        className="img-fluid"
                        controls={false}
                        ref={player}
                        muted
                        preload="metadata"
                        onLoadedMetadata={() => setIsVideoLoaded(true)}
                      />
                      <PlayIcon width="24px" height="24px" strokeWidth={2} />
                    </button>
                    <button
                      type="button"
                      className="remove-media-btn"
                      aria-label="Remove video"
                      title="Remove video"
                      onClick={() => {
                        handleRemoveMedia([combinedVideo, combinedThumbnail]);
                      }}
                    >
                      <XMarkIcon width="15px" height="15px" />
                    </button>
                    {/* <button
                      type="button"
                      aria-label="Add cover"
                      title="Add cover"
                      onClick={() => thumbnailInputRef.current.click()}
                      className="replace-thumb-btn btn"
                    >
                      <PhotoIcon
                        width="20px"
                        height="20px"
                        role="img"
                        className="mr-1"
                        focusable="false"
                      />
                      Add cover
                    </button> */}
                  </div>
                </div>
              )}
              {!!combinedImages?.length && (
                <div className="thumbnails">
                  {combinedImages?.map((image, index) => (
                    <div className="thumbnail-item" key={`image-${index}`}>
                      <button
                        type="button"
                        className="expand-media-btn"
                        aria-label="View larger image"
                        onClick={() => {
                          handlePreviewOpen(image);
                        }}
                      >
                        <img
                          src={image?.url}
                          alt="Thumbnail of uploaded content"
                          className="img-fluid"
                        />
                        <MagnifyingGlassPlusIcon
                          width="24px"
                          height="24px"
                          strokeWidth={2}
                        />
                      </button>
                      <button
                        type="button"
                        className="remove-media-btn"
                        aria-label="Remove image"
                        title="Remove image"
                        onClick={() => {
                          handleRemoveMedia([image]);
                        }}
                      >
                        <XMarkIcon width="15px" height="15px" />
                      </button>
                    </div>
                  ))}
                  {combinedImages?.length < 10 && (
                    <button
                      type="button"
                      aria-label="Upload images"
                      title="Upload images"
                      onClick={() => imageInputRef.current.click()}
                      className="upload-photo-btn thumbnail-item"
                    >
                      <PlusIcon
                        width="26px"
                        height="26px"
                        role="img"
                        focusable="false"
                      />
                    </button>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="no-media">
              <RenderButton
                type="button"
                aria-label="Upload images"
                title="Upload images"
                onClick={() => imageInputRef.current.click()}
                className="upload-media-btn btn-outline-primary"
              >
                <PhotoIcon
                  width="35px"
                  height="35px"
                  role="img"
                  focusable="false"
                />
                Select Images
              </RenderButton>
              {/* //* Disabling video for now until video content requirements are provided */}
              {/* <RenderButton
                type="button"
                aria-label="Upload video"
                title="Upload video"
                onClick={() => videoInputRef.current.click()}
                className="upload-media-btn btn-outline-primary"
              >
                <FilmIcon
                  width="35px"
                  height="35px"
                  role="img"
                  focusable="false"
                />
                Video
              </RenderButton> */}
            </div>
          )}
        </>
      )}

      <Field
        name="images"
        type="file"
        accept="image/*"
        multiple
        innerRef={imageInputRef}
        onChange={handleUpload}
        className="file-input-hidden"
      />
      <Field
        name="video"
        type="file"
        accept="video/*"
        innerRef={videoInputRef}
        onChange={handleUpload}
        className="file-input-hidden"
      />
      <Field
        name="thumbnail"
        type="file"
        accept="image/*"
        innerRef={thumbnailInputRef}
        onChange={handleUpload}
        className="file-input-hidden"
      />
      <MediaPreviewModal
        previewModalOpen={previewModalOpen}
        togglePreviewModal={togglePreviewModal}
        media={previewMedia}
        mediaThumbnail={combinedThumbnail}
        handleRemoveMedia={handleRemoveMedia}
        handleModalClosed={handleModalClosed}
      />
      {!hasVideo && (
        <Alert color="info" className="mt-3">
          <span className="smaller">
            Tip: All images <strong>must have a 3:4 aspect ratio</strong> in
            order for your post to be approved!
          </span>
        </Alert>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {})(ContentUpload);
