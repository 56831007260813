import { Player } from "@lottiefiles/react-lottie-player";

import lottie from "../../../assets/lottie/notification.json";
import heroImg from "../../../assets/img/step_two_hero.png";

const StepTwo = ({ lottieRef, ...props }) => {
  return (
    <div className="step-two m-80">
      <div className="lottie-container">
        <img src={heroImg} className="hero-img" alt="hero img" />
        <Player
          ref={lottieRef}
          keepLastFrame
          speed={0.75}
          src={lottie}
        ></Player>
      </div>
      <div className="content-container">
        <h1 className="heading title mt-0 pt-3">
          Your First $50, <span className="gradient">On Us!</span>
        </h1>
        <p className="description px-3">
          We’ll delve into how it all works, and finally guide you on how to hit
          the ground running. Upon completion, you’ll be taken to your Creator
          Portal where your first $50 is waiting.
        </p>
      </div>
    </div>
  );
};

export default StepTwo;
