import { API_URL, createRequestActionTypes } from "../../../actions/index";

export const READ_MANY_OPPORTUNITIES_URL = `${API_URL}/api/v1/opportunitys`;
export const READ_ONE_OPPORTUNITY_URL = `${API_URL}/api/v1/opportunity`;
export const UPDATE_ONE_OPPORTUNITY_URL = `${API_URL}/api/v1/opportunity`;

export const SUBMIT_APPROVAL_SUCCESS = "Deal approved!";
export const SUBMIT_REJECTION_SUCCESS = "Deal rejected";
export const STATUS_UPDATE_ERROR = "Error updating deal status";

export const GET_OPPORTUNITIES_ERROR = "Error fetching deals";
export const GET_OPPORTUNITY_ERROR = "Error fetching deal status";

export const opportunityActionTypes = {
  getOpportunities: createRequestActionTypes("GET_OPPORTUNITIES"),
  getOpportunity: createRequestActionTypes("GET_OPPORTUNITY"),
  updateOpportunity: createRequestActionTypes("UPDATE_OPPORTUNITY"),
};
