import networkImage from "./NetworkImage";
import fallbackAvatar from "../assets/img/profile_picture_placeholder.svg";
import Skeleton from "react-loading-skeleton";

const AvatarImageRound = ({ picture, network, size = "large", ...props }) => {
  return (
    <div className="avatar-image-round">
      <div className={`avatar avatar-${size}`}>
        {!picture ? (
          <Skeleton circle={true} className="avatar-skeleton" />
        ) : (
          <img
            className="img-fluid"
            src={picture}
            onError={(e) => (e.target.src = fallbackAvatar)}
            alt="Social media handle avatar"
          />
        )}
      </div>
      {(network === "instagram" ||
        network === "tiktok" ||
        network === "youtube") &&
        networkImage(network)}
    </div>
  );
};

export default AvatarImageRound;
