import {
  Col,
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { showTitle } from "../../../../actions";
const CreatorBrandAffinity = ({ reportData, ...props }) => {
  const generateImgUrl = (type, id) => {
    if (type === "country") {
      return `${process.env.REACT_APP_TENSOR_API_URL}/assets/images/country_flags/${id}`;
    } else if (type === "brand") {
      return `${process.env.REACT_APP_TENSOR_API_URL}/assets/images/brand_logo/${id}`;
    } else if (type === "interest") {
      return `${process.env.REACT_APP_TENSOR_API_URL}/assets/images/interests/${id}`;
    }
  };

  return (
    <Col sm="6">
      <Card className="card-content-custom">
        <CardBody>
          <CardTitle>Creator Brand Affinity</CardTitle>
          <ListGroup className="ba-list ch-6">
            {reportData &&
            reportData.user_profile &&
            reportData.user_profile.brand_affinity ? (
              reportData.user_profile.brand_affinity.map((brand, index) => {
                if (index < 4) {
                  return (
                    <ListGroupItem
                      key={index}
                      className="d-flex align-items-center"
                    >
                      <div className="img-col-left">
                        <img
                          loading="lazy"
                          src={generateImgUrl("brand", brand.id)}
                          alt="img"
                        />
                      </div>
                      <div
                        id={`influencer_${brand.name}`}
                        title={showTitle(
                          `influencer_${brand.name}`,
                          brand.name,
                        )}
                        className="text-wrap"
                      >
                        {brand.name}
                      </div>
                    </ListGroupItem>
                  );
                }
                return null;
              })
            ) : (
              <span className="text-center text-grey ">
                <p className="pt-50">No records</p>
              </span>
            )}
          </ListGroup>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CreatorBrandAffinity;
