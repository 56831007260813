import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import FollowersGenderSplit from "./byFollowers/followersGenderSplit";
import FollowersAgeAndGenderSplit from "./byFollowers/followersAgeAndGenderSplit";
import FollowersLocationByCity from "./byFollowers/followersLocationByCity";
import FollowersLocationByCountry from "./byFollowers/followersLocationByCountry";
import FollowersEthnicity from "./byFollowers/followersEthnicity";
// import FollowersLanguage from "./byFollowers/followersLanguage";
import FollowersAudienceBrandAffinity from "./byFollowers/followersAudienceBrandAffinity";
import FollowersAudienceInterests from "./byFollowers/followersAudienceInterests";
import FollowersNotableFollowers from "./byFollowers/followersNotableFollowers";
// import LikesLanguage from "./byLikes/likesLanguage";

// import LikesAudienceLookAlikes from './byLikes/likesAudienceLookAlikes';
import FollowersAudienceReachability from "./byFollowers/followersAudienceReachability";
import "./tensor.scss";
import "./index.scss";
const AudiencesSection = ({ reportData, ...props }) => {
  const followersLikeIconClass =
    reportData &&
    reportData.audience_followers &&
    reportData.audience_followers.data &&
    reportData.audience_followers.data.credibility_class
      ? reportData.audience_followers.data.credibility_class.toLowerCase() ===
          "high" ||
        reportData.audience_followers.data.credibility_class.toLowerCase() ===
          "best"
        ? "icon-liked"
        : reportData.audience_followers.data.credibility_class.toLowerCase() ===
            "bad"
          ? "icon-unlike"
          : "icon-neutral"
      : "";

  return (
    <div className="section section-au" id="Audience">
      {reportData.user_profile &&
      (reportData.user_profile.type === "youtube" ||
        reportData.user_profile.type === "tiktok") ? (
        <></>
      ) : (
        <Row className="row-xs">
          <Col sm="6">
            <Card className="card-content-custom ch-179">
              <CardBody>
                <h2 className={followersLikeIconClass}>
                  {reportData &&
                    reportData.audience_followers &&
                    reportData.audience_followers.data &&
                    reportData.audience_followers.data.audience_credibility &&
                    (
                      reportData.audience_followers.data.audience_credibility *
                      100
                    ).toFixed(2) + "%"}
                </h2>
                <CardTitle>
                  <span className="tooltip-button-wrap">
                    <span className="text-tooltip">Followers Credibility</span>
                    <span
                      href="#"
                      id="followersFollowersCredibility"
                      className="tooltip-icon"
                    ></span>
                  </span>
                </CardTitle>
              </CardBody>
            </Card>
            <Card className="card-content-custom ch-179">
              <CardBody>
                <h2>
                  {reportData &&
                    reportData.audience_followers &&
                    reportData.audience_followers.data &&
                    reportData.audience_followers.data.notable_users_ratio &&
                    (
                      reportData.audience_followers.data.notable_users_ratio *
                      100
                    ).toFixed(2) + "%"}
                </h2>
                <CardTitle>
                  <span className="tooltip-button-wrap">
                    <span className="text-tooltip">Notable Followers</span>
                    <span
                      href="#"
                      id="notableUsers"
                      className="tooltip-icon"
                    ></span>
                  </span>
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6">
            <FollowersAudienceReachability reportData={reportData} {...props} />
          </Col>
        </Row>
      )}

      <div className="grid-gender">
        {!!reportData?.audience_followers?.data?.audience_genders?.length && (
          <FollowersGenderSplit reportData={reportData} {...props} />
        )}
        {!!reportData?.audience_followers?.data?.audience_genders_per_age
          ?.length && (
          <FollowersAgeAndGenderSplit reportData={reportData} {...props} />
        )}
      </div>
      <div className="grid-general">
        {!!reportData?.audience_followers?.data?.audience_geo?.cities
          ?.length && (
          <FollowersLocationByCity reportData={reportData} {...props} />
        )}
        {!!reportData?.audience_followers?.data?.audience_brand_affinity
          ?.length && (
          <FollowersAudienceBrandAffinity reportData={reportData} {...props} />
        )}
        {!!reportData?.audience_followers?.data?.audience_interests?.length && (
          <FollowersAudienceInterests reportData={reportData} {...props} />
        )}
        {!!reportData?.audience_followers?.data?.audience_geo?.countries
          ?.length && (
          <FollowersLocationByCountry reportData={reportData} {...props} />
        )}
        {!!reportData?.audience_followers?.data?.audience_ethnicities
          ?.length && <FollowersEthnicity reportData={reportData} {...props} />}
        {/* {!!reportData?.audience_followers?.data?.audience_lookalikes
          ?.length && (
          <FollowersAudienceLookAlikes reportData={reportData} {...props} />
        )} */}
        {!!reportData?.audience_followers?.data?.notable_users?.length && (
          <FollowersNotableFollowers reportData={reportData} {...props} />
        )}
      </div>
    </div>
  );
};
export default AudiencesSection;
