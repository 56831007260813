import { useEffect } from "react";
import { connect } from "react-redux";
import { showErrorMessage } from "../../actions";
import { clearErrorMessages } from "./actions";
import PayoutOverview from "./components/PayoutOverview";
import RecentDeposits from "./components/RecentDeposits/RecentDeposits";
import "./index.scss";

// define functional component
function PayoutsComponent({ errorMessage, clearErrorMessages, ...props }) {
  useEffect(() => {
    if (errorMessage) {
      showErrorMessage(errorMessage, () => {
        clearErrorMessages();
      });
    }
  }, [errorMessage, clearErrorMessages]);

  return (
    <div className="container">
      <PayoutOverview />
      <RecentDeposits />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    errorMessage: state.payouts.errorMessage,
  };
};
export default connect(mapStateToProps, {
  clearErrorMessages,
})(PayoutsComponent);
