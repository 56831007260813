import { Row, Col } from "reactstrap";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const StripeOnboardingSkeleton = () => {
  return (
    <div className="stripe-onboarding-section">
      <Row>
        <Col xs={12}>
          <div className="subheading">
            <h2 className="title">
              <Skeleton
                height={32}
                width={"75%"}
                style={{ marginBottom: "0.5rem" }}
              />
            </h2>
          </div>
          <Skeleton height={48} width={156} />
        </Col>
      </Row>
    </div>
  );
};

export default StripeOnboardingSkeleton;
