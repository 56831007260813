import { connect } from "react-redux";

import "./index.scss";
import { useParams } from "react-router-dom";
import SocialHandlesList from "./components/SocialHandlesList";
import ManageMediaKit from "./components/ManageMediaKit";
import MediaKit from "./components/MediaKit";

const MediaKits = ({ loading, loginUser, ...props }) => {
  const { mediaKit_id } = useParams();
  const { managingMediaKit_id } = useParams();

  const pageContent = () => {
    if (mediaKit_id) {
      return <MediaKit id={mediaKit_id} />;
    }
    if (managingMediaKit_id) {
      return <ManageMediaKit id={managingMediaKit_id} />;
    }
    return <SocialHandlesList />;
  };

  return <div className="container media-kits">{pageContent()}</div>;
};

const mapStateToProps = (state) => {
  return {
    loading: state.mediaKits.loading,
    loginUser: state.login.loginUser,
  };
};
export default connect(mapStateToProps, {})(MediaKits);
