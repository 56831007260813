import { opportunityActionTypes } from "../constants";
const initialState = {
  loading: false,
  opportunities: [],
  opportunity: null,
};
const opportunityReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case opportunityActionTypes.getOpportunities.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case opportunityActionTypes.getOpportunities.SUCCESS:
      return {
        ...state,
        loading: false,
        opportunities: payload?.results,
      };
    case opportunityActionTypes.getOpportunities.FAILURE:
      return {
        ...state,
        loading: false,
        opportunities: [],
      };
    case opportunityActionTypes.getOpportunity.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case opportunityActionTypes.getOpportunity.SUCCESS:
      return {
        ...state,
        loading: false,
        opportunity: payload?.result,
      };
    case opportunityActionTypes.getOpportunity.FAILURE:
      return {
        ...state,
        loading: false,
        opportunity: null,
      };
    case opportunityActionTypes.updateOpportunity.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case opportunityActionTypes.updateOpportunity.SUCCESS:
      return {
        ...state,
        loading: false,
        opportunity: payload?.result,
      };
    case opportunityActionTypes.updateOpportunity.FAILURE:
      return {
        ...state,
        loading: false,
        opportunity: null,
      };
    default:
      return state;
  }
};

export default opportunityReducers;
