import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";
import { Field, Formik } from "formik";
import { Player } from "@lottiefiles/react-lottie-player";
import { RenderButton, RenderSplitTextField } from "../../../components";
import { Square2StackIcon } from "@heroicons/react/20/solid";
import { createTalentEmail } from "../actions";
import lottie from "../../../assets/lottie/magnifier.json";
import heroImg from "../../../assets/img/step_five_hero.png";

const StepFive = ({ _next, talentEmailRef, ...props }) => {
  const { hubspotCreatorId } = useParams();

  const dispatch = useDispatch();

  const creator = useSelector((state) => state.onboarding.creator);

  const replaceTalentDomain = (email) => {
    return email?.replace(/@talent.neoreach.com/i, "");
  };

  const talentEmail = "@talent.neoreach.com";
  const talenEmailExists = !!creator?.talentManagementEmailExists;
  const username = !!creator?.talentManagementEmailExists
    ? replaceTalentDomain(creator?.talentManagementEmail)
    : replaceTalentDomain(creator?.suggestedTalentManagementEmail);

  const handleCopyText = (values) => {
    navigator.clipboard.writeText(`${values.username?.trim()}${talentEmail}`);
  };

  const validate = (values) => {
    const errors = {};

    if (!values?.username || values?.username.trim() === "") {
      errors.username = "This field is required";
    } else if (values?.username && !/^[A-Z0-9._%+-]+/i.test(values?.username)) {
      errors.username = "Invalid email";
    }

    return errors;
  };

  return (
    <div className="step-five m-80">
      <div className="lottie-container">
        <img src={heroImg} className="hero-img" alt="hero img" />
        <Player autoplay loop speed={0.6} src={lottie}></Player>
      </div>
      <div className="content px-3">
        <h1 className="heading title mt-0">Let’s Make It Official!</h1>
        <p className="description">
          Here’s your custom @talent.neoreach.com email.{" "}
          {talenEmailExists
            ? "Paste it into your social media bios."
            : "Feel free to pick a different one below. Then paste it into your social media bios."}
        </p>
        <Formik
          innerRef={talentEmailRef}
          initialValues={{
            username: username || "",
          }}
          enableReinitialize={true}
          initialErrors={{
            username: "This field is required",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            dispatch(
              createTalentEmail(
                {
                  talentManagementEmail: `${values.username?.trim()}${talentEmail}`,
                  hubspotCreatorId,
                  firstName: creator?.firstName,
                  lastName: creator?.lastName,
                },
                _next,
              ),
            );
            setSubmitting(false);
          }}
          validate={validate}
          validateOnBlur={true}
          validateOnMount={true}
        >
          {({
            touched,
            errors,
            validateForm,
            setTouched,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            ...props
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Field
                  name="username"
                  className={errors.username && touched.username ? "error" : ""}
                  component={RenderSplitTextField}
                  disabled={!!creator?.talentManagementEmailExists}
                  autoComplete="off"
                >
                  {talentEmail}
                </Field>
                <RenderButton
                  className="btn btn-primary btn-wide mt-3"
                  onClick={() => handleCopyText(values)}
                >
                  <Square2StackIcon
                    className="mr-2"
                    width={24}
                    height={24}
                    strokeWidth={2}
                  />
                  Copy Email Address
                </RenderButton>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default StepFive;
