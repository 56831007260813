import { ChevronDownIcon, LightBulbIcon } from "@heroicons/react/24/outline";
import { useLayoutEffect, useRef, useState, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselControl,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import { formatDate } from "../../../actions";
import { RenderButton } from "../../../components";
import {
  setContentBeingEdited,
  setEditPostModalVisibility,
  updateContent,
} from "../actions";
import { contentCategoryOptions } from "../constants/contentCategoryOptions";

const CampaignContentCard = ({
  content,
  isPreview,
  updateContent,
  setContentBeingEdited,
  setEditPostModalVisibility,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [expandedCaption, setExpandedCaption] = useState(false);
  const [expandedFeedback, setExpandedFeedback] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const feedbackRef = useRef(null);

  const handleEditContent = useCallback(() => {
    setContentBeingEdited(content);
    setEditPostModalVisibility(true);
  }, [setContentBeingEdited, setEditPostModalVisibility, content]);

  const handleSubmitForApproval = useCallback(() => {
    setIsConfirmModalOpen(true);
  }, []);

  const confirmSubmitForApproval = useCallback(() => {
    updateContent({
      _id: content?._id,
      submitForApproval: true,
    });
    setIsConfirmModalOpen(false);
  }, [content?._id, updateContent]);

  const toggleExpandedFeedback = useCallback(() => {
    setExpandedCaption(false);
    setExpandedFeedback((prev) => !prev);
  }, []);

  const closeExpandedCaption = useCallback(() => {
    setExpandedCaption(false);
  }, []);

  const openExpandedCaption = useCallback(() => {
    setExpandedCaption(true);
  }, []);

  const toggleConfirmModal = useCallback(() => {
    setIsConfirmModalOpen((prev) => !prev);
  }, []);

  const slides = content?.imageUrls?.map((url, index) => (
    <CarouselItem
      key={`carousel-item-${index}`}
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
    >
      <div
        className="blurred-backdrop"
        style={{
          backgroundImage: `url(${url})`,
        }}
      />
      <img
        className="d-block w-100"
        src={url}
        onLoad={(e) => {
          e.target.classList.add("loaded");
        }}
        alt={"Uploaded media"}
      />
      <div className="img-skeleton">
        <Skeleton height="100%" width="100%" />
      </div>
    </CarouselItem>
  ));

  const next = useCallback(() => {
    if (animating) return;
    setActiveIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1,
    );
  }, [animating, slides]);

  const previous = useCallback(() => {
    if (animating) return;
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1,
    );
  }, [animating, slides]);

  const goToIndex = useCallback(
    (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    },
    [animating],
  );

  const handleFocus = useCallback(() => {
    const feedback = feedbackRef?.current;
    if (feedback) {
      feedback.scrollTop = feedback.clientHeight;
    }
  }, []);

  useLayoutEffect(() => {
    if (expandedFeedback) {
      handleFocus();
    }
  }, [expandedFeedback, handleFocus]);

  return (
    <div className="campaign-content-card-wrapper">
      <Card className={`campaign-content-card ${isPreview ? "preview" : ""}`}>
        {content?.status !== "draft" && !isPreview && (
          <CardHeader>
            <button
              className="see-more bold my-2 text-left expand-toggle"
              onClick={toggleExpandedFeedback}
            >
              {expandedFeedback ? "Hide" : "View"} Admin Feedback{" "}
              <ChevronDownIcon
                height="20"
                width="20"
                className={expandedFeedback ? "expanded" : "collapsed"}
              />
            </button>
          </CardHeader>
        )}
        <CardBody className="content-body">
          <div
            className={`feedback-overlay ${
              expandedFeedback ? "expanded" : "collapsed"
            }`}
            ref={feedbackRef}
          >
            <div className="feedback-container">
              {content?.feedback?.length ? (
                <>
                  {content?.feedback?.map((feedback, index) => {
                    return (
                      <div className="feedback-item" key={index}>
                        <p className="message mb-1">{feedback.message}</p>
                        <p className="date small">
                          {formatDate(feedback.created, {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </p>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className="no-feedback">No feedback has been added yet.</p>
              )}
            </div>
          </div>
          <div
            className={`expanded-caption-overlay ${
              expandedCaption ? "expanded" : "d-none"
            }`}
          >
            <p>{content?.caption}</p>
            <button
              className="see-less bold mb-3 text-left mx-3"
              onClick={closeExpandedCaption}
            >
              Close
            </button>
          </div>
          <div className="content-image-container">
            <Carousel
              enableTouch={true}
              interval={false}
              activeIndex={activeIndex}
              next={next}
              previous={previous}
            >
              {slides?.length > 1 && (
                <CarouselIndicators
                  items={slides}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
              )}
              {slides}
              {slides?.length > 1 && (
                <>
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                  />
                </>
              )}
            </Carousel>
          </div>
          {content?.caption || content?.title ? (
            <>
              <div className="content-text-container">
                {content?.category && (
                  <Badge
                    color="neutral"
                    className={"badge-soft-neutral mx-3 mb-3"}
                  >
                    {
                      contentCategoryOptions.find(
                        (obj) => obj.value === content?.category,
                      )?.label
                    }
                  </Badge>
                )}
                {content?.title && <h4 className="mx-3">{content?.title}</h4>}
                <p className="mx-3 mb-1">{content?.caption}</p>
                <button
                  className="see-more bold mb-3 text-left mr-3"
                  onClick={openExpandedCaption}
                >
                  See More
                </button>
              </div>
            </>
          ) : (
            <div className="content-text-skeleton mx-3">
              <Skeleton className="mb-1" height="20%" />
              <Skeleton height="60%" />
            </div>
          )}
          {!isPreview && (
            <>
              {content?.status === "draft" && (
                <Row>
                  <Col className="mx-3 mt-2 button-split">
                    <RenderButton
                      className="btn btn-outline-primary btn-default modal-button mt-1"
                      onClick={handleEditContent}
                    >
                      Edit Post
                    </RenderButton>

                    <RenderButton
                      title="Submit post for approval"
                      className="btn btn-primary btn-default modal-button mt-1"
                      onClick={handleSubmitForApproval}
                    >
                      Submit
                    </RenderButton>
                  </Col>
                </Row>
              )}
              {content?.status === "rejected" && (
                <Row>
                  <Col className="mx-3 mt-2 button-split">
                    <RenderButton
                      className="btn btn-outline-primary btn-default modal-button mt-1"
                      onClick={handleEditContent}
                    >
                      Edit Post
                    </RenderButton>

                    <RenderButton
                      title="Resubmit post for approval"
                      className="btn btn-primary btn-default modal-button mt-1"
                      onClick={handleSubmitForApproval}
                    >
                      Resubmit
                    </RenderButton>
                  </Col>
                </Row>
              )}
              {content?.status === "approved" && (
                <Row>
                  <Col className="mx-3 mt-2">
                    <RenderButton
                      title="Enter URL to Live Post"
                      className="btn btn-primary btn-default modal-button mt-1 float-right"
                      onClick={handleEditContent}
                    >
                      Complete
                    </RenderButton>
                  </Col>
                </Row>
              )}
              {content?.status === "link rejected" && (
                <Row>
                  <Col className="mx-3 mt-2">
                    <RenderButton
                      title="Enter URL to Live Post"
                      className="btn btn-primary btn-default modal-button mt-1 float-right"
                      onClick={handleEditContent}
                    >
                      Update Link
                    </RenderButton>
                  </Col>
                </Row>
              )}
            </>
          )}
        </CardBody>
      </Card>
      {/* Add the confirmation modal */}
      <Modal isOpen={isConfirmModalOpen} toggle={toggleConfirmModal}>
        <ModalHeader toggle={toggleConfirmModal}>
          Review and Confirm Submission Criteria
        </ModalHeader>
        <ModalBody>
          <Alert color="info" className="d-flex align-items-center">
            <span>
              <LightBulbIcon height="24" width="24" />
            </span>
            <span>
              Before submitting, please confirm the following or your content
              may be rejected.
            </span>
          </Alert>
          <ol>
            {/* TODO: add link to view brief */}
            <li>
              Content is in line with the brief and examples for your vertical
            </li>
            <li>Content is unique, not too similar to previous submissions</li>
            <li>
              Confirming your pictures are original, content topic is not
              duplicative to previous submissions
            </li>
            <li>
              Captions are not generated using ChatGPT/Gemini or any text-AI
              tool
            </li>
            <li>
              There is text on Images that speak to the content and its theme
            </li>
            <li>
              Please also make sure that the cover image has no transparent
              mask/layer and is not blurred out
            </li>
            <li>
              Confirm all locations are tagged in the caption using the
              &quot;Places&quot; (applicable for travel creators)
            </li>
          </ol>
        </ModalBody>
        <ModalFooter>
          <RenderButton
            color="primary"
            outline
            className="btn-default modal-button btn-wide"
            onClick={toggleConfirmModal}
          >
            Cancel
          </RenderButton>
          <RenderButton
            color="primary"
            className="btn-default modal-button btn-wide"
            onClick={confirmSubmitForApproval}
          >
            Confirm and Submit
          </RenderButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  setEditPostModalVisibility,
  setContentBeingEdited,
  updateContent,
})(CampaignContentCard);
