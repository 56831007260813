import { Button } from "reactstrap";
import { READ_ONE_AGREEMENT_URL } from "../constants";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

const ViewAgreementFileButton = ({ agreement_id, children, ...props }) => {
  const downloadAgreement = async () => {
    try {
      const url = `${READ_ONE_AGREEMENT_URL}/${agreement_id}/download`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      if (!response.ok) throw new Error("Error downloading agreement");
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      window.open(downloadUrl, "_blank", "noopener, noreferrer");
      window.URL.revokeObjectURL(downloadUrl);
    } catch (err) {
      console.error("Download error:", err);
    }
  };
  return (
    <Button
      color="primary"
      outline
      className="d-flex align-items-center"
      onClick={downloadAgreement}
      {...props}
    >
      <ArrowTopRightOnSquareIcon
        className="mr-2"
        height={16}
        width={16}
        strokeWidth={2}
      />
      {children || "Open Document"}
    </Button>
  );
};

export default ViewAgreementFileButton;
