import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CampaignRowSkeleton = () => {
  return (
    <>
      {Array(3)
        .fill()
        .map((item, index) => (
          <tr className="activity-row" key={index}>
            <td className="tb-head">
              <h4 className="m-0">
                <Skeleton height={24} width={"80%"} />
              </h4>
            </td>
            <td className="text-right">
              <div className="balance">
                <Skeleton height={24} width={"90%"} />
              </div>
            </td>
            <td className="text-right">
              <Skeleton height={24} width={"80%"} />
            </td>
          </tr>
        ))}
    </>
  );
};

export default CampaignRowSkeleton;
