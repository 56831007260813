import { deliverablesActionTypes } from "../constants";
const initialState = {
  loading: false,
  incompleteDeliverables: [],
  incompleteDeliverablesCount: 0,
  completeDeliverables: [],
  completeDeliverablesCount: 0,
  deliverable: null,
};
const deliverablesReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case deliverablesActionTypes.getCompleteDeliverables.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case deliverablesActionTypes.getCompleteDeliverables.SUCCESS:
      return {
        ...state,
        loading: false,
        completeDeliverables: payload?.results,
        completeDeliverablesCount: payload?.count,
      };
    case deliverablesActionTypes.getCompleteDeliverables.FAILURE:
      return {
        ...state,
        loading: false,
        completeDeliverables: [],
        completeDeliverablesCount: 0,
      };
    case deliverablesActionTypes.getIncompleteDeliverables.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case deliverablesActionTypes.getIncompleteDeliverables.SUCCESS:
      return {
        ...state,
        loading: false,
        incompleteDeliverables: payload?.results,
        incompleteDeliverablesCount: payload?.count,
      };
    case deliverablesActionTypes.getIncompleteDeliverables.FAILURE:
      return {
        ...state,
        loading: false,
        incompleteDeliverables: [],
        incompleteDeliverablesCount: 0,
      };
    case deliverablesActionTypes.getDeliverable.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case deliverablesActionTypes.getDeliverable.SUCCESS:
      return {
        ...state,
        loading: false,
        deliverable: payload?.result,
      };
    case deliverablesActionTypes.getDeliverable.FAILURE:
      return {
        ...state,
        loading: false,
        deliverable: null,
      };
    case deliverablesActionTypes.updateDeliverable.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case deliverablesActionTypes.updateDeliverable.SUCCESS:
      return {
        ...state,
        loading: false,
        deliverable: payload?.result,
      };
    case deliverablesActionTypes.updateDeliverable.FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default deliverablesReducers;
