import { useEffect } from "react";
import { Col } from "reactstrap";
import Row from "reactstrap/lib/Row";
import AlertBanner from "../../../components/AlertBanner";
import EmailSendConfirmation from "../../login/components/EmailSendConfirmation";

function EmailVerification({ requestAuthLink, loginUser, setNextButtonType }) {
  useEffect(() => {
    setNextButtonType(!!loginUser?.emailVerified ? "next" : "skip");
  }, [loginUser?.emailVerified, setNextButtonType]);

  const isFirstLogin = () => {
    if (loginUser.firstLogin !== loginUser.lastLogin) {
      return false;
    }
    // check if firstLogin ISO timestamp is within 1 minute of current time
    const firstLogin = new Date(loginUser.firstLogin);
    const currentTime = new Date();
    return currentTime - firstLogin < 60000;
  };

  return (
    <div className="email-verification">
      <Row
        className={
          !loginUser.emailVerified ? "d-flex justify-content-center" : ""
        }
      >
        <Col xs="12">
          <div className="subheading">
            <h3
              className={
                !loginUser.emailVerified
                  ? "d-flex justify-content-center title"
                  : "title"
              }
            >
              Verify Email Address
            </h3>
          </div>
        </Col>
        <Col xs="12" md="8" lg="6" className="mx-lg-1">
          {loginUser.emailVerified ? (
            <AlertBanner
              message="Your email address is verified."
              alertType="success"
            />
          ) : (
            <EmailSendConfirmation
              sendAction={() => {
                requestAuthLink(loginUser.email, "verify");
              }}
              emailType="verify"
              promptInitialSend={!isFirstLogin()}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default EmailVerification;
