import { useState } from "react";
import settings from "settings";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  CogIcon,
  BanknotesIcon,
  HomeIcon,
  Bars3Icon,
  PhotoIcon,
  XMarkIcon,
  ChevronLeftIcon,
  PresentationChartBarIcon,
  InboxIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import { connect } from "react-redux";
import { getUserBalanceData } from "./actions/index";
import { RenderButton } from "../../components";
import { logout } from "../login/actions/index";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import { capitalize } from "../../actions";
import LoginUserAgreementsActionRequiredDot from "../login/components/LoginUserAgreementsActionRequiredDot";

const Sidebar = ({ loginUser, logout, ...props }) => {
  const [collapsed, setCollapsed] = useState(true);
  const history = useHistory();
  const location = useLocation();

  const paths = location.pathname.split("/");
  paths.shift();

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <>
      <div className="d-xl-none mobile-header-container">
        <Navbar className="mobile-top-nav">
          <NavbarBrand>
            {paths.length > 1 ? (
              <span
                className="icon-container text-grey-bold small"
                onClick={() => history.goBack()}
              >
                <ChevronLeftIcon
                  className="link-icon"
                  height={16}
                  width={16}
                  strokeWidth={2}
                />
                <span className="ml-2">{capitalize(paths[0])}</span>
              </span>
            ) : (
              <img
                src={settings.mainbrand}
                alt="homepage"
                style={{
                  height: 25,
                  width: "auto",
                  margin: "5px",
                }}
              />
            )}
          </NavbarBrand>
          <NavbarToggler className="me-2" onClick={toggleNavbar}>
            <span className="icon-container">
              {collapsed ? (
                <Bars3Icon
                  className="link-icon"
                  width={25}
                  height={25}
                  strokeWidth={1.5}
                />
              ) : (
                <XMarkIcon
                  className="link-icon"
                  width={25}
                  height={25}
                  strokeWidth={1.5}
                />
              )}
            </span>
          </NavbarToggler>
        </Navbar>
        <Collapse
          id="mobile-sidebar"
          className="mobile-sidebar"
          isOpen={!collapsed}
        >
          <Nav vertical className="link-container">
            <div className="link-wrapper">
              <NavLink
                to="/dashboard"
                activeClassName="active"
                className="link"
                onClick={toggleNavbar}
              >
                <span className="icon-container">
                  <HomeIcon
                    className="link-icon"
                    width={30}
                    height={30}
                    strokeWidth={2}
                  />
                </span>

                <span className="sidebar-page">Home</span>
              </NavLink>

              <NavLink
                to="/campaigns"
                activeClassName="active"
                className="link"
                onClick={toggleNavbar}
              >
                <span className="icon-container">
                  <PhotoIcon
                    className="link-icon"
                    width={30}
                    height={30}
                    strokeWidth={2}
                  />
                </span>
                <span className="sidebar-page">Campaigns</span>
              </NavLink>

              <NavLink
                to="/payouts"
                activeClassName="active"
                className="link"
                onClick={toggleNavbar}
              >
                <span className="icon-container">
                  <BanknotesIcon
                    className="link-icon"
                    width={30}
                    height={30}
                    strokeWidth={2}
                  />
                </span>
                <span className="sidebar-page">Payouts</span>
              </NavLink>

              <NavLink
                to="/agreements"
                activeClassName="active"
                className="link"
                onClick={toggleNavbar}
              >
                <span className="icon-container">
                  <DocumentTextIcon
                    className="link-icon"
                    width={30}
                    height={30}
                    strokeWidth={2}
                  />
                </span>
                <span className="sidebar-page d-flex align-items-center">
                  Agreements <LoginUserAgreementsActionRequiredDot />
                </span>
              </NavLink>

              <NavLink
                to="/mediaKits"
                activeClassName="active"
                className="link"
                onClick={toggleNavbar}
              >
                <span className="icon-container">
                  <PresentationChartBarIcon
                    className="link-icon"
                    width={30}
                    height={30}
                    strokeWidth={2}
                  />
                </span>
                <span className="sidebar-page">Media Kits</span>
              </NavLink>

              {!!loginUser?.talentManagementEmail && (
                <NavLink
                  to="/deals"
                  activeClassName="active"
                  className="link"
                  onClick={toggleNavbar}
                >
                  <span className="icon-container">
                    <InboxIcon
                      className="link-icon"
                      width={30}
                      height={30}
                      strokeWidth={2}
                    />
                  </span>
                  <span className="sidebar-page">Deals</span>
                </NavLink>
              )}

              <NavLink
                to="/settings"
                activeClassName="active"
                className="link"
                onClick={toggleNavbar}
              >
                <span className="icon-container">
                  <CogIcon
                    className="link-icon"
                    width={30}
                    height={30}
                    strokeWidth={2}
                  />
                </span>
                <span className="sidebar-page">Settings</span>
              </NavLink>
            </div>

            <div className="logout">
              <hr />
              <RenderButton
                outline
                color="danger"
                onClick={() => {
                  toggleNavbar();
                  logout();
                }}
              >
                Log out
              </RenderButton>
            </div>
          </Nav>
        </Collapse>
      </div>
      <div className="header-container d-none d-xl-block">
        <div className="search-icon" onClick={props.handleToggler}>
          <img
            src={settings.mainbrand}
            className="top_logo logo_pic"
            alt="homepage"
          />
        </div>
        <div className="link-container header-inner-container">
          <NavLink to="/dashboard" activeClassName="active" className="link">
            <span className="icon-container">
              <HomeIcon
                className="link-icon"
                width={25}
                height={25}
                strokeWidth={2}
              />
            </span>
            <span className="sidebar-page">Home</span>
          </NavLink>

          <NavLink to="/campaigns" activeClassName="active" className="link">
            <span className="icon-container">
              <PhotoIcon
                className="link-icon"
                width={25}
                height={25}
                strokeWidth={2}
              />
            </span>
            <span className="sidebar-page">Campaigns</span>
          </NavLink>

          <NavLink to="/payouts" activeClassName="active" className="link">
            <span className="icon-container">
              <BanknotesIcon
                className="link-icon"
                width={25}
                height={25}
                strokeWidth={2}
              />
            </span>
            <span className="sidebar-page">Payouts</span>
          </NavLink>

          <NavLink to="/agreements" activeClassName="active" className="link">
            <span className="icon-container">
              <DocumentTextIcon
                className="link-icon"
                width={25}
                height={25}
                strokeWidth={2}
              />
            </span>
            <span className="sidebar-page d-flex align-items-center">
              Agreements <LoginUserAgreementsActionRequiredDot />
            </span>
          </NavLink>

          <NavLink to="/mediaKits" activeClassName="active" className="link">
            <span className="icon-container">
              <PresentationChartBarIcon
                className="link-icon"
                width={25}
                height={25}
                strokeWidth={2}
              />
            </span>
            <span className="sidebar-page">Media Kits</span>
          </NavLink>

          {!!loginUser?.talentManagementEmail && (
            <NavLink to="/deals" activeClassName="active" className="link">
              <span className="icon-container">
                <InboxIcon
                  className="link-icon"
                  width={25}
                  height={25}
                  strokeWidth={2}
                />
              </span>
              <span className="sidebar-page">Deals</span>
            </NavLink>
          )}

          <NavLink to="/settings" activeClassName="active" className="link">
            <span className="icon-container">
              <CogIcon
                className="link-icon"
                width={25}
                height={25}
                strokeWidth={2}
              />
            </span>
            <span className="sidebar-page">Settings</span>
          </NavLink>
        </div>

        <div className="profile-main">
          <div className="profile-main-container mb-3">
            <RenderButton outline color="danger" onClick={() => logout()}>
              Log out
            </RenderButton>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    UserBalanceData: state.header.UserBalanceData,
  };
};

export default connect(mapStateToProps, {
  getUserBalanceData,
  logout,
})(Sidebar);
