// Manage Media Kit page. This page is used to confirm ownership of a social handle, display error details, and link to the Media Kit.
import { useEffect } from "react";
import { connect } from "react-redux";
import { confirmSocialHandle, getSocialHandle } from "../actions";
import SocialHandleListItem from "./SocialHandleListItem";
import { RenderButton } from "../../../components";
import { Col, Row } from "reactstrap";
import history from "../../../history";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

const ManageMediaKit = ({
  id,

  loginUser,
  mediaKit,
  loading,

  getSocialHandle,
  confirmSocialHandle,
  ...props
}) => {
  useEffect(() => {
    if (id) getSocialHandle(id);
  }, [getSocialHandle, id]);

  const { headline, subHeadline } = getHeadlineText(mediaKit);

  const getButtons = {
    back: () => {
      return (
        <RenderButton
          className="btn-outline-primary btn-wide"
          onClick={() => {
            history.push("/mediaKits");
          }}
        >
          Go Back
        </RenderButton>
      );
    },
    settings: () => {
      return (
        <RenderButton
          className="btn-primary btn-wide"
          onClick={() => {
            history.push("/settings");
          }}
        >
          Go to Settings
        </RenderButton>
      );
    },
    confirm: () => {
      return (
        <RenderButton
          className="btn-primary btn-wide"
          onClick={() => {
            confirmSocialHandle({
              user_id: loginUser?._id,
              socialHandle_id: id,
            });
          }}
          disabled={loading}
        >
          Confirm Ownership
        </RenderButton>
      );
    },
    view: () => {
      return (
        <RenderButton
          className="btn-primary btn-wide"
          onClick={() => {
            history.push(`/mediaKits/${id}`);
          }}
        >
          View Media Kit
        </RenderButton>
      );
    },
  };

  const createButtons = () => {
    if (!mediaKit) return;
    const buttonsToRender = [];
    buttonsToRender.push(getButtons.back());
    if (mediaKit?.notFound) {
      buttonsToRender.push(getButtons.settings());
    } else if (!mediaKit?.hasConfirmedOwnership) {
      buttonsToRender.push(getButtons.confirm());
    } else if (mediaKit?.hasConfirmedOwnership) {
      buttonsToRender.push(getButtons.view());
    }
    return (
      <>
        {buttonsToRender.map((button, index) => (
          <Col xs="6" md="5" lg="4" xl="3" key={index}>
            {button}
          </Col>
        ))}
      </>
    );
  };

  return (
    <div className="manage-media-kit">
      <NavLink to="/mediaKits" className="nav-back-arrow">
        <div>
          <ArrowLeftIcon height={24} width={24} />
        </div>
      </NavLink>
      <Row className="text-center">
        <Col xs="12">
          <h1 className="heading title">
            {headline || <Skeleton height={43} width="300px" />}
          </h1>
        </Col>
        <Col xs="12" md="10" lg="8" xl="6" className="mx-auto">
          <p className="subheading text-grey">
            {subHeadline || <Skeleton height={24} />}
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="10" lg="8" className="mx-auto">
          <SocialHandleListItem
            socialHandle={mediaKit}
            statusTextEnabled={true}
            key={mediaKit?._id}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center mt-5">
        {createButtons()}
      </Row>
    </div>
  );
};

function getHeadlineText(handle) {
  if (!handle) return {};
  if (handle?.notFound) {
    return {
      headline: "Account Not Found",
      subHeadline: "We were unable to find the below account.",
    };
  } else if (!handle?.hasConfirmedOwnership) {
    return {
      headline: "Confirm Account Ownership",
      subHeadline:
        "Please confirm the below account is yours. If this account is not yours, your Media Kit request may be rejected and your NeoReach account will be suspended.",
    };
  } else if (handle?.hasConfirmedOwnership) {
    return {
      headline: "Manage Media Kit",
      subHeadline:
        "A Media Kit has been successfully generated for this handle.",
    };
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    mediaKit: state.mediaKits.mediaKit,
    loading: state.mediaKits.loading,
  };
};
export default connect(mapStateToProps, {
  getSocialHandle,
  confirmSocialHandle,
})(ManageMediaKit);
