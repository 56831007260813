import {
  actionCreator,
  checkHttpStatus,
  checkAuthTokenExpiry,
  publicHeaders,
  COMMON_ERROR_MESSAGE,
  showErrorMessage,
  showSuccessMessage,
} from "../../../actions";
import { logout } from "../../login/actions";
import { loginActionTypes } from "../../login/constants";
import {
  settingsActionTypes,
  CLIENT_TOKEN_URL,
  ADD_EXTERNAL_ACCOUNT_URL,
  READ_EXTERNAL_ACCOUNT_URL,
  USER_UPDATE_URL,
  REFRESH_STRIPE_ONBOARDING_URL,
} from "../constants";

export const refreshStripeOnboardingUrl = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(settingsActionTypes.refreshStripeOnboardingUrl.REQUEST),
    );
    fetch(REFRESH_STRIPE_ONBOARDING_URL, {
      method: "GET",
      headers: publicHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error) {
          const errorMsg =
            response.error[Object.keys(response.error)[0]] ||
            COMMON_ERROR_MESSAGE;
          dispatch(
            actionCreator(
              settingsActionTypes.refreshStripeOnboardingUrl.FAILURE,
              {
                errorMsg: errorMsg,
              },
            ),
          );
        } else {
          dispatch(
            actionCreator(
              settingsActionTypes.refreshStripeOnboardingUrl.SUCCESS,
              response,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(settingsActionTypes.refreshStripeOnboardingUrl.FAILURE),
        );
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const handleDeleteAccount = (id) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(settingsActionTypes.deleteAccount.REQUEST));
    fetch(`${USER_UPDATE_URL}/${id}`, {
      method: "DELETE",
      headers: publicHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error) {
          showErrorMessage(
            "There was an error deleting your account. Please try again later or contact team@neoreach.com for assistance.",
          );
          dispatch(actionCreator(settingsActionTypes.deleteAccount.FAILURE));
        } else {
          dispatch(actionCreator(settingsActionTypes.deleteAccount.SUCCESS));
          showSuccessMessage(
            "Your account has been deleted. We are sorry to see you go.",
          );
          dispatch(logout());
        }
      })
      .catch((error) => {
        dispatch(actionCreator(settingsActionTypes.deleteAccount.FAILURE));
        showErrorMessage(
          "There was an error deleting your account. Please try again later or contact team@neoreach.com for assistance.",
        );
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const addExternalAccount = (cardToken) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(settingsActionTypes.addExternalAccount.REQUEST));
    fetch(ADD_EXTERNAL_ACCOUNT_URL, {
      method: "PUT",
      headers: publicHeaders,
      credentials: "include",
      body: JSON.stringify({ token: cardToken }),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error) {
          const errorMsg =
            response.error[Object.keys(response.error)[0]] ||
            COMMON_ERROR_MESSAGE;
          dispatch(
            actionCreator(settingsActionTypes.addExternalAccount.FAILURE, {
              errorMsg: errorMsg,
            }),
          );
        } else {
          dispatch(
            actionCreator(
              settingsActionTypes.addExternalAccount.SUCCESS,
              response,
            ),
          );
          dispatch(readExternalAccount());
          dispatch(refreshStripeOnboardingUrl());
          dispatch(setReplaceAccountFormVisible(false));
        }
      })
      .catch((error) => {
        dispatch(actionCreator(settingsActionTypes.addExternalAccount.FAILURE));
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};
export const readExternalAccount = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(settingsActionTypes.readExternalAccount.REQUEST));
    fetch(READ_EXTERNAL_ACCOUNT_URL, {
      method: "GET",
      headers: publicHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error) {
          const errorMsg =
            response.error[Object.keys(response.error)[0]] ||
            COMMON_ERROR_MESSAGE;
          dispatch(
            actionCreator(settingsActionTypes.readExternalAccount.FAILURE, {
              errorMsg: errorMsg,
            }),
          );
        } else {
          dispatch(
            actionCreator(
              settingsActionTypes.readExternalAccount.SUCCESS,
              response,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(settingsActionTypes.readExternalAccount.FAILURE),
        );
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};
export const generateFinancialConnectionToken = () => {
  return async (dispatch, getState) => {
    dispatch(
      actionCreator(
        settingsActionTypes.generateFinancialConnectionToken.REQUEST,
      ),
    );
    let response;
    try {
      response = await fetch(CLIENT_TOKEN_URL, {
        method: "GET",
        headers: publicHeaders,
        credentials: "include",
      });
    } catch (error) {
      const errorMsg =
        response.error[Object.keys(response.error)[0]] || COMMON_ERROR_MESSAGE;
      dispatch(
        actionCreator(
          settingsActionTypes.generateFinancialConnectionToken.FAILURE,
          errorMsg,
        ),
      );
      if (error?.statusCode === 401) {
        dispatch(logout(error));
      }
    }
    if (response) {
      response = await response.json();
      dispatch(
        actionCreator(
          settingsActionTypes.generateFinancialConnectionToken.SUCCESS,
          response,
        ),
      );
    } else {
      dispatch(
        actionCreator(
          settingsActionTypes.generateFinancialConnectionToken.FAILURE,
          COMMON_ERROR_MESSAGE,
        ),
      );
    }
  };
};
export const updatePayoutUser = (user) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(settingsActionTypes.updatePayoutUser.REQUEST));
    fetch(USER_UPDATE_URL, {
      method: "PUT",
      headers: publicHeaders,
      credentials: "include",
      body: JSON.stringify({
        ...user,
        calculate: "availableBalance canDeleteUser",
      }),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error) {
          const errorMsg =
            response.error[Object.keys(response.error)[0]] ||
            COMMON_ERROR_MESSAGE;
          dispatch(
            actionCreator(settingsActionTypes.updatePayoutUser.FAILURE, {
              errorMsg: errorMsg,
            }),
          );
        } else {
          dispatch(
            actionCreator(
              loginActionTypes.storeUpdatedLoginUser.SUCCESS,
              response?.result,
            ),
          );
          dispatch(actionCreator(settingsActionTypes.updatePayoutUser.SUCCESS));
          dispatch(refreshStripeOnboardingUrl());
        }
      })
      .catch((error) => {
        dispatch(actionCreator(settingsActionTypes.updatePayoutUser.FAILURE));
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};
export const setReplaceAccountFormVisible = (isVisible) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(
        settingsActionTypes.setReplaceAccountFormVisible.SUCCESS,
        isVisible,
      ),
    );
  };
};
export const clearErrorMessages = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(settingsActionTypes.clearErrorMessages.SUCCESS));
  };
};
export const clearSuccessMessages = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(settingsActionTypes.clearSuccessMessages.SUCCESS));
  };
};
