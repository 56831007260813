import { Badge, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import {
  formatName,
  formatNumbersToCommaSeparated,
  formatNumbersToUnits,
} from "../../../../actions";
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  MinusSmallIcon,
} from "@heroicons/react/20/solid";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const LineGraphCard = ({ reportData, historicalData, network, ...props }) => {
  const trends = {
    followers: calculateTrend(
      reportData?.user_profile?.followers,
      historicalData?.followers,
    ),
    following: calculateTrend(
      reportData?.user_profile?.following,
      historicalData?.following,
    ),
    avg_views: calculateTrend(
      reportData?.user_profile?.avg_views,
      historicalData?.avg_views,
    ),
    avg_likes: calculateTrend(
      reportData?.user_profile?.avg_likes,
      historicalData?.avg_likes,
    ),
    avg_comments: calculateTrend(
      reportData?.user_profile?.avg_comments,
      historicalData?.avg_comments,
    ),
  };

  const chartOptions = (type) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const data =
      reportData &&
      reportData.user_profile &&
      reportData.user_profile.stat_history
        ? reportData.user_profile.stat_history.map((option) => {
            return option[type];
          })
        : [];
    // data = data.reverse();

    const categories =
      reportData &&
      reportData.user_profile &&
      reportData.user_profile.stat_history
        ? reportData.user_profile.stat_history.map((option) => {
            return `${months[parseInt(option.month.split("-").pop(), 10) - 1]}`;
          })
        : [];

    data.shift();
    categories.shift();

    return {
      chart: {
        height: 200,
        backgroundColor: null,
      },
      tooltip: {
        backgroundColor: "#17222d",
        borderColor: "transparent",
        borderRadius: 10,
        shadow: false,
        style: {
          color: "white",
          fontSize: "11px",
        },
        positioner: function (labelWidth, labelHeight, point) {
          return { x: point.plotX, y: 0 };
        },
        formatter: function () {
          return [formatNumbersToCommaSeparated(this.y)];
        },
      },

      title: "",
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          style: {
            color: "#898e94",
          },
        },
        gridLineColor: "#66666640",
      },
      xAxis: {
        crosshair: {
          width: 1,
          color: "#dee2e6",
          label: {
            enabled: true,
            align: "right",
          },
        },
        categories: categories,
        title: {
          text: null,
        },
        labels: {
          style: {
            color: "#898e94",
          },
        },
        lineColor: "#1f87ee",
        tickColor: "transparent",
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        spline: {
          width: 1,
          color: "#1f87ee",
          linejoin: "round",
          marker: {
            enabled: false,
          },
        },
      },

      series: [
        {
          type: "spline",
          name: formatName(type),
          data: data,
        },
      ],
      credits: {
        enabled: false,
      },
    };
  };

  return (
    <>
      {reportData?.user_profile && (
        <Row className="row-xs line-graph">
          {/* FOLLOWERS (ALL) */}
          <Col md="4">
            <Card className="card-content-custom">
              <CardBody>
                <CardTitle className="d-inline-flex align-items-center">
                  <span className="mb-1">
                    {network === "youtube" ? "Subscribers" : "Followers"}
                  </span>
                  <TrendBadge
                    trendRate={trends.followers.trendRate}
                    trendDirection={trends.followers.trendDirection}
                  />
                </CardTitle>
                <div className="graph-wrap ptop15 ch-4">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions("followers")}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          {/* FOLLOWING (INSTA, TIKTOK) */}
          {reportData?.user_profile &&
            (network === "instagram" || network === "tiktok") && (
              <Col md="4">
                <Card className="card-content-custom">
                  <CardBody>
                    <CardTitle className="d-inline-flex align-items-center">
                      <span className="mb-1">Following</span>
                      <TrendBadge
                        trendRate={trends.following.trendRate}
                        trendDirection={trends.following.trendDirection}
                      />
                    </CardTitle>
                    <div className="graph-wrap ptop15 ch-4">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions("following")}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}

          {/* AVG VIEWS (YT, TIKTOK) */}
          {reportData?.user_profile &&
            (network === "youtube" || network === "tiktok") && (
              <Col md="4">
                <Card className="card-content-custom">
                  <CardBody>
                    <CardTitle className="d-inline-flex align-items-center">
                      <span className="mb-1">Views</span>
                      <TrendBadge
                        trendRate={trends.avg_views.trendRate}
                        trendDirection={trends.avg_views.trendDirection}
                      />
                    </CardTitle>
                    <div className="graph-wrap ptop15 ch-4">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions("avg_views")}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}

          {/* AVG LIKES (ALL) */}
          <Col md="4">
            <Card className="card-content-custom">
              <CardBody>
                <CardTitle className="d-inline-flex align-items-center">
                  <span className="mb-1">Likes</span>
                  <TrendBadge
                    trendRate={trends.avg_likes.trendRate}
                    trendDirection={trends.avg_likes.trendDirection}
                  />
                </CardTitle>
                <div className="graph-wrap ptop15 ch-4">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions("avg_likes")}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          {/* AVG COMMENTS (YT, TIKTOK) */}
          {reportData?.user_profile &&
            (network === "youtube" || network === "tiktok") && (
              <Col md="4">
                <Card className="card-content-custom">
                  <CardBody>
                    <CardTitle className="d-inline-flex align-items-center">
                      <span className="mb-1">Comments</span>
                      <TrendBadge
                        trendRate={trends.avg_comments.trendRate}
                        trendDirection={trends.avg_comments.trendDirection}
                      />
                    </CardTitle>
                    <div className="graph-wrap ptop15 ch-4">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions("avg_comments")}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
        </Row>
      )}
    </>
  );
};

function TrendBadge({ trendDirection, trendRate }) {
  if (trendDirection < 0) {
    return <></>;
  }
  if (typeof trendDirection !== "number" || typeof trendRate !== "number") {
    return <></>;
  }
  return (
    <Badge
      className="trend-badge bold ml-2"
      color={getBadgeColor(trendDirection)}
    >
      <span>{getTrendArrow(trendDirection)}</span>
      <span>{formatValue(trendRate, "percent")}</span>
      <span className="ml-1">this month</span>
    </Badge>
  );
}

function getTrendArrow(trendDirection) {
  let arrow = <MinusSmallIcon height="20px" width="20px" />;
  if (trendDirection > 0)
    arrow = <ArrowSmallUpIcon height="20px" width="20px" />;
  if (trendDirection < 0)
    arrow = <ArrowSmallDownIcon height="20px" width="20px" />;
  return arrow;
}

function getBadgeColor(trendDirection) {
  let color = "secondary";
  if (trendDirection > 0) color = "success";
  if (trendDirection < 0) color = "danger";
  return color;
}

function formatValue(value, formatType) {
  if (typeof value !== "number") return null;
  let formattedValue;
  switch (formatType) {
    case "compact":
      formattedValue = formatNumbersToUnits(value);
      break;
    case "percent":
      formattedValue = `${value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}%`;
      break;
    default:
      formattedValue = null;
      break;
  }
  return formattedValue;
}

function calculateTrend(curr, prev) {
  if (typeof curr !== "number" || typeof prev !== "number") return {};
  const trendRate = ((curr - prev) / prev) * 100;
  let trendDirection = 0;
  if (trendRate > 0) trendDirection = 1;
  else if (trendRate < 0) trendDirection = -1;
  return { trendRate: Math.abs(trendRate), trendDirection };
}

export default LineGraphCard;
