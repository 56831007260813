import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Badge } from "reactstrap";

const ActionRequiredIndicator = () => {
  return (
    // wrap in NavLink if you want it to be a button
    // <NavLink to={href}>
    <Badge color="warning" pill className="p-0">
      {/* // TODO: make this more visually noticable */}
      <ExclamationTriangleIcon
        className="text-warning"
        height="28"
        width="22"
        strokeWidth={3}
        style={{ margin: ".1rem .3rem", aspectRatio: "1 / 1" }}
      />
    </Badge>
    // </NavLink>
  );
};

export default ActionRequiredIndicator;
