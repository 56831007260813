import { useEffect } from "react";
import { logout } from "./actions/index";
import { connect } from "react-redux";
import Loader from "../../components/Loader";

const Logout = ({ logout, ...props }) => {
  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container public-page-container">
        <Loader></Loader>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  logout,
})(Logout);
