import { Card, CardBody, CardTitle } from "reactstrap";
import "./EarningsTile.scss";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const EarningsTileSkeleton = () => {
  return (
    <Card className="card-content-custom earnings-tile">
      <CardBody>
        <CardTitle>
          <Skeleton height={30} width={"40%"} />
        </CardTitle>
        <div className="graph-wrap">
          <Skeleton height={165} />
        </div>
      </CardBody>
    </Card>
  );
};

export default EarningsTileSkeleton;
