import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import AlertBanner from "../../../components/AlertBanner";
import useLoginUserAgreementsActionRequiredCount from "../hooks/useLoginUserAgreementsActionRequiredCount";

/**
 * Renders a notification if there are any agreements for the logged in user that require action.
 *
 * @returns {JSX.Element} The rendered component.
 */
const LoginUserAgreementsActionRequiredBanner = () => {
  const incompleteAgreements = useLoginUserAgreementsActionRequiredCount();
  if (!incompleteAgreements) {
    return null;
  }
  return (
    <AlertBanner>
      You have unsigned agreements.{" "}
      <NavLink className="link-more" to={"/agreements"}>
        View Agreements
      </NavLink>
    </AlertBanner>
  );
};

export default LoginUserAgreementsActionRequiredBanner;
