import IncompleteDeliverablesTable from "./IncompleteDeliverablesTable";
import CompleteDeliverablesTable from "./CompleteDeliverablesTable";

const Deliverables = ({ assignment_id, ...props }) => {
  return (
    <div className="container">
      <IncompleteDeliverablesTable assignment_id={assignment_id} />
      <CompleteDeliverablesTable assignment_id={assignment_id} />
    </div>
  );
};

export default Deliverables;
