import { useParams } from "react-router-dom";

import "./index.scss";
import DetailedView from "./components/DetailedView";
import SplashScreen from "./components/SplashScreen";

const Deals = (props) => {
  const { deal_id } = useParams();

  if (deal_id) {
    return <DetailedView _id={deal_id} />;
  }

  return <SplashScreen />;
};

export default Deals;
