import { connect } from "react-redux";
import Campaign from "./Campaign";
import CampaignInvitations from "./components/CampaignInvitations";
import CampaignsTable from "./components/CampaignsTable";
import EditPostModal from "./modals/EditPostModal";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Campaigns({ loginUser, loading, ...props }) {
  const { campaignAssignment_id } = props.match.params;
  const isMobile = window.innerWidth < 676;

  return (
    <>
      {campaignAssignment_id ? (
        <Campaign campaignAssignment_id={campaignAssignment_id} />
      ) : (
        <div className="container">
          <CampaignInvitations />
          {loading ? (
            <>
              <h1 className="heading title">
                <Skeleton height={43} width={isMobile ? "40%" : "30%"} />
              </h1>
              <p className="subheading">
                <Skeleton height={24} width={"25%"} />
              </p>
            </>
          ) : (
            <>
              <h1 className="heading title">Campaigns</h1>
              <p className="subheading text-grey">
                View your assigned campaigns.
              </p>
            </>
          )}
          <CampaignsTable />
        </div>
      )}
      <EditPostModal />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    loading: state.campaigns.loading,
  };
};
export default connect(mapStateToProps, {})(Campaigns);
