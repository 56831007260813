import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getIncompleteDeliverables } from "./actions";
import DeliverablesTable from "./DeliverablesTable";

const IncompleteDeliverablesTable = ({
  assignment_id,

  incompleteDeliverables,
  incompleteDeliverablesCount,
  loading,

  getIncompleteDeliverables,
  ...props
}) => {
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    getIncompleteDeliverables({
      pageNumber: activePage,
      pageSize,
      assignment_id,
    });
  }, [activePage, assignment_id, getIncompleteDeliverables, pageSize]);

  const columns = ["title", "network", "handle", "screenshots", "postUrl"];
  const headers = [
    { heading: "Title" },
    { heading: "Platform" },
    { heading: "Handle" },
    { heading: "Screenshots", class: "text-right" },
    { heading: "Post URL" },
  ];

  const isMobile = window.innerWidth < 1150;
  // if mobile, only include first 3 columns and headers
  if (isMobile) {
    columns.splice(3);
    headers.splice(3);
  }

  if (!incompleteDeliverables?.length) {
    return null;
  }

  return (
    <DeliverablesTable
      caption={"Due"}
      deliverablesData={incompleteDeliverables}
      deliverablesCount={incompleteDeliverablesCount}
      columns={columns}
      headers={headers}
      activePage={activePage}
      setActivePage={setActivePage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      loading={loading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.deliverables.loading,
    incompleteDeliverables: state.deliverables.incompleteDeliverables,
    incompleteDeliverablesCount: state.deliverables.incompleteDeliverablesCount,
  };
};
export default connect(mapStateToProps, {
  getIncompleteDeliverables,
})(IncompleteDeliverablesTable);
