import { Col, Row, Table } from "reactstrap";
import CampaignRowSkeleton from "./CampaignRowSkeleton";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CampaignTableSkeleton = () => {
  const headers = [
    { heading: "Name", width: "40%", class: "", skeleton: "30%" },
    { heading: "Approved", width: "20%", class: "text-right", skeleton: "60%" },
    { heading: "On Hold", width: "20%", class: "text-right", skeleton: "60%" },
  ];
  const columnHeaders = headers.map((column, index) => (
    <th
      className={headers[index].class}
      width={headers[index].width}
      key={index}
    >
      <Skeleton width={headers[index].skeleton} height={16} />
    </th>
  ));

  return (
    <Row className="search-results-wrap">
      <Col md="12">
        <div className="table-responsive table-outer user-profile-table">
          <Table hover borderless>
            <thead>
              <tr>{columnHeaders}</tr>
            </thead>
            <tbody>
              <CampaignRowSkeleton />
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default CampaignTableSkeleton;
