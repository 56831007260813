import { Badge } from "reactstrap";

const BadgeCompletionCount = ({ incomplete, total }) => {
  return (
    <>
      {!!total && (
        <Badge color={incomplete > 0 ? "danger" : "light"} className={"ml-3"}>
          {incomplete} / {total}
        </Badge>
      )}
    </>
  );
};

export default BadgeCompletionCount;
