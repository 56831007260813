import { createRequestActionTypes, API_URL } from "../../../actions";

export const PAYOUT_READ_MANY_URL = `${API_URL}/api/v1/payouts`;
export const ASSIGNMENT_READ_ONE_URL = `${API_URL}/api/v1/user`;

export const payoutActionTypes = {
  getRecentDeposits: createRequestActionTypes("GET_RECENT_DEPOSITS"),
  getAssignmentOverview: createRequestActionTypes("GET_ASSIGNMENT_OVERVIEW"),
  clearErrorMessages: createRequestActionTypes("CLEAR_PAYOUT_ERROR_MSG"),
};
