import { Col, Container, Row } from "reactstrap";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import OpportunityCardSkeleton from "./OpportunityCardSkeleton";

const SplashScreenSkeleton = () => {
  return (
    <Container className="main-site-content deals-splash">
      <Row>
        <Col className="mb-5">
          <h1 className="heading title mb-0">
            <Skeleton height={44} width={"40%"} />
          </h1>
          <p className="subheading">
            <Skeleton height={24} width={"50%"} />
          </p>
          <Row>
            <OpportunityCardSkeleton />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SplashScreenSkeleton;
