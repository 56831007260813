import { capitalize, formatDate } from "../../../../actions";
import { RenderCurrency } from "../../../../components";

const RecentDepositsRow = ({ deposit, ...props }) => {
  let badgeType;
  let descriptor;
  switch (deposit.status) {
    case "paid":
      badgeType = "badge-success";
      descriptor = "Deposited";
      break;
    case "pending":
    case "in_transit":
      badgeType = "badge-warning";
      descriptor = "Estimated Arrival";
      break;
    case "canceled":
    case "failed":
      badgeType = "badge-danger";
      descriptor = "Deposit";
      break;

    default:
      break;
  }

  const isMobile = window.innerWidth < 676;
  const badgeText = capitalize(deposit.status.replace("_", " "));
  return (
    <tr>
      <td>
        <span className={"mb-2 bold badge " + badgeType}>{badgeText}</span>
      </td>
      <td>
        <span className="light-text bold">
          <span className="descriptor">{descriptor} </span>
          {formatDate(deposit.depositDate, {
            year: "numeric",
            month: "short",
            timeZone: "UTC",
          })}
        </span>
      </td>
      <td className="numeric light-text bold">
        <RenderCurrency
          className="mb-2"
          amount={deposit.amount}
          currency={deposit.currency}
          renderCurrencyCode={!isMobile}
        />
      </td>
    </tr>
  );
};

export default RecentDepositsRow;
