import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Card, CardBody, CardTitle } from "reactstrap";

const chartOptions = (reportData) => {
  const data =
    reportData &&
    reportData.audience_followers &&
    reportData.audience_followers.data &&
    reportData.audience_followers.data.audience_genders_per_age
      ? reportData.audience_followers.data.audience_genders_per_age.map(
          (obj, index) => obj,
        )
      : [];
  const maleData = data.map((obj, index) => [
    obj.code,
    parseFloat((obj.male * 100).toFixed(2)),
  ]);
  const femaleData = data.map((obj, index) => [
    obj.code,
    parseFloat((obj.female * 100).toFixed(2)),
  ]);
  const categories = data.map((obj, index) => obj.code.replace("-", " — "));

  maleData.pop();
  femaleData.pop();
  categories.pop();

  return {
    chart: {
      type: "column",
      height: 250,
      backgroundColor: null,
    },

    title: null,
    xAxis: {
      lineWidth: 0,
      type: "category",
      tickColor: "transparent",
      categories: categories,
      labels: {
        style: {
          color: "#888",
          fontSize: "14px",
          fontFamily: "'HKGrotesk-Regular', Arial, 'sans-serif'",
        },
      },
    },
    yAxis: {
      lineWidth: 0,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      stackLabels: {
        // enabled: props.showStackLabels,
        verticalAlign: "top",
        // allowOverlap: props.showStackLabels,
        formatter: function () {
          return this.total + "%";
        },
        style: {
          color: "#888",
          fontSize: "12px",
          fontWeight: "600",
          fontFamily: "'HKGrotesk-Bold', Arial, 'sans-serif'",
          textOutline: "none",
        },
      },

      title: {
        text: null,
      },
    },
    plotOptions: {
      column: {
        animation: false,
        stacking: "normal",
        borderWidth: 0,
        borderRadius: 2,
        enableMouseTracking: false,
      },
    },
    series: [
      {
        color: "#1f87ee",
        clip: false,
        pointWidth: window.innerWidth < 1024 ? 15 : 20,
        name: "Male",
        stack: "male",
        data: maleData,
      },
      {
        color: "#ff5537",
        clip: false,
        pointWidth: window.innerWidth < 1024 ? 15 : 20,
        name: "Female",
        stack: "female",
        data: femaleData,
      },
    ],
    tooltip: { enabled: false },
    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
  };
};
const FollowersAgeAndGenderSplit = ({ reportData, ...props }) => {
  return (
    <Card className="card-content-custom age-gender">
      <CardBody>
        <CardTitle>
          <span className="tooltip-button-wrap">
            <span className="text-tooltip">Age and Gender Split</span>
            <span
              href="#"
              id="followersAgeAndGenderSplit"
              className="tooltip-icon"
            ></span>
          </span>
        </CardTitle>
        <div className="card-content">
          <div className="graph-wrap">
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions(reportData)}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default FollowersAgeAndGenderSplit;
