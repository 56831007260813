import { useSelector } from "react-redux";

/**
 * Returns the count of all agreements that require action by the logged in user.
 *
 * @returns {number} The count of agreements that require action by the logged in user.
 */
const useLoginUserAgreementsActionRequiredCount = () => {
  return useSelector(
    (state) =>
      state.login?.loginUser?.__agreementMetrics?.creatorActionRequiredCount,
  );
};

export default useLoginUserAgreementsActionRequiredCount;
