import { Row, Col, Form } from "reactstrap";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ContactDetailsSkeleton = () => {
  return (
    <Form className="form-group">
      {/* Contact Details */}
      <h2 className="subheading title">
        <Skeleton height={32} width={"50%"} />
      </h2>
      <Row>
        <Col xs="12">
          <Skeleton
            height={21}
            width={"20%"}
            style={{ marginBottom: "0.25rem" }}
          />
          <Skeleton height={40} style={{ marginBottom: "0.5rem" }} />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <Skeleton
            height={21}
            width={"20%"}
            style={{ marginBottom: "0.25rem" }}
          />
          <Skeleton height={40} style={{ marginBottom: "0.5rem" }} />
        </Col>
        <Col xs="12" md="6">
          <Skeleton
            height={21}
            width={"30%"}
            style={{ marginBottom: "0.25rem" }}
          />
          <Skeleton height={40} style={{ marginBottom: "0.5rem" }} />
        </Col>
      </Row>

      {/* Social Handles and Rates */}
      <div className="subheading mt-5">
        <h2 className="title">
          <Skeleton height={32} width={"50%"} />
        </h2>
      </div>
      <Skeleton height={40} width={124} />

      {/* Notification Settings */}
      <div className="subheading mt-5">
        <h2 className="title">
          <Skeleton height={32} width={"50%"} />
        </h2>
      </div>
      <Skeleton height={24} width={"30%"} />

      {/* Change Password */}
      <h2 className="subheading title mt-5">
        <Skeleton height={32} width={"50%"} />
      </h2>
      <Skeleton height={21} width={"30%"} style={{ marginBottom: "0.25rem" }} />
      <Skeleton height={40} style={{ marginBottom: "0.5rem" }} />
      <Skeleton height={21} width={"20%"} style={{ marginBottom: "0.25rem" }} />
      <Skeleton height={40} style={{ marginBottom: "0.5rem" }} />
      <Skeleton height={21} width={"35%"} style={{ marginBottom: "0.25rem" }} />
      <Skeleton height={40} style={{ marginBottom: "0.5rem" }} />
      <Skeleton height={48} width={134} className="mt-5" />
    </Form>
  );
};

export default ContactDetailsSkeleton;
