import { useCallback, useEffect, useState } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, FormGroup } from "reactstrap";
import {
  RenderTextField,
  RenderTextArea,
  SubmitButton,
  RenderSingleSelect,
} from "../../../components";
import { Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import {
  createContent,
  uploadMedia,
  setContentBeingEdited,
  setEditPostModalVisibility,
  updateContent,
} from "../actions";
import "../index.scss";
// import ImageUploader from "../components/ContentUpload/ImageUploader";
// import VideoUploader from "../components/VideoUploader";
import ContentUpload from "../components/ContentUpload";
import { contentCategoryOptions } from "../constants/contentCategoryOptions";

const EditPostModal = ({
  campaign,
  contentBeingEdited,
  editPostModalVisibility,
  loading,

  createContent,
  updateContent,
  setEditPostModalVisibility,
  setContentBeingEdited,
  uploadMedia,
  ...props
}) => {
  const [savedImageUrls, setSavedImageUrls] = useState(
    contentBeingEdited?.imageUrls ?? [],
  );
  const [savedVideoUrl, setSavedVideoUrl] = useState(
    contentBeingEdited?.videoUrl,
  );
  const [savedThumbnailUrl, setSavedThumbnailUrl] = useState(
    contentBeingEdited?.thumbnailUrl,
  );
  const [unsavedMediaBlobUrls, setUnsavedMediaBlobUrls] = useState([]);

  const [showMediaValidation, setShowMediaValidation] = useState(false);
  const [showCategoryValidation, setShowCategoryValidation] = useState(false);

  const [category, setCategory] = useState(null);

  const handleCategorySelectChange = (value) => {
    setCategory(value);
  };

  const validateMedia = () => {
    const newImages = unsavedMediaBlobUrls.filter(
      (media) => media.mediaType === "image",
    );
    const [newVideo] = unsavedMediaBlobUrls.filter(
      (media) => media.mediaType === "video",
    );
    const combinedImages = [
      ...savedImageUrls.map((x) => {
        return { mediaType: "image", uploaded: 2, url: x };
      }),
      ...newImages,
    ];
    const combinedVideo =
      newVideo ||
      (savedVideoUrl
        ? {
            mediaType: "video",
            uploaded: 2,
            url: savedVideoUrl,
          }
        : null);

    if (!combinedVideo && !combinedImages?.length) {
      return "Post media is required";
    }

    if (!combinedImages?.length || combinedImages?.length < 3) {
      return "At least 3 images are required for each post";
    }
    if (combinedImages?.length > 10) {
      return "No more than 10 images per post";
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validatePost = (values) => {
    const minCaptionWordCount = 50;
    const errors = {};

    const mediaErrors = validateMedia();
    if (mediaErrors) {
      errors.media = mediaErrors;
    }

    if (!values.title) {
      errors.title = "A title is required.";
    }

    if (values.caption?.length) {
      const captionErrors = [];
      const wordCountRegex = /(?:^|\s)(?:#\w+|\w+)|\p{Emoji_Presentation}+/gsu;
      // const hashtagRegex =
      //   /(?:^|\s)(#[a-zA-Z\d-]+(?:_[a-zA-Z\d-]+)*)(?=$|\s)/gm;
      const partnerHashtagRegex = /^(.*?)(?:^|\s)#lemon8partner\b/im;

      const wordCount = values.caption.match(wordCountRegex)?.length ?? 0;
      if (wordCount < minCaptionWordCount) {
        captionErrors.push(
          `Must be at least ${minCaptionWordCount} words (currently ${wordCount})`,
        );
      }

      const match = partnerHashtagRegex.exec(values.caption);

      if (!match) {
        captionErrors.push("Must contain the required hashtag #lemon8partner");
      }

      // const textBeforeLemon8partner = match?.[1];
      // if (!match || hashtagRegex.test(textBeforeLemon8partner)) {
      //   captionErrors.push("Must have #lemon8partner as the first hashtag");
      // }

      // const hashtagCount = values.caption.match(hashtagRegex)?.length ?? 0;
      // if (hashtagCount < 5) {
      //   captionErrors.push(
      //     `Must contain at least 5 hashtags total (currently ${hashtagCount})`,
      //   );
      // }

      if (captionErrors.length) {
        errors.caption = captionErrors;
      }
    } else {
      errors.caption = ["A caption is required."];
    }

    if (!category?.value) {
      errors.category = "A category is required";
    }

    return errors;
  };

  const submitPost = useCallback(
    (values) => {
      // TODO: @content separate logic for images and video uploads
      // TODO: @content fix update logic
      // * update post
      if (contentBeingEdited) {
        // only send fields that have been changed from original contentBeingEdited
        const updatedValues = {};
        Object.keys(values).forEach((key) => {
          if (values[key] !== contentBeingEdited[key]) {
            updatedValues[key] = values[key];
          }
        });
        updateContent({
          ...updatedValues,
          _id: contentBeingEdited._id,
          assignment_id: contentBeingEdited.assignment_id,
          images: unsavedMediaBlobUrls,
          imageUrls: savedImageUrls,
          category: category?.value,
        });
        return;
      }
      // * create post
      createContent({
        ...values,
        assignment_id: campaign?._id,
        images: unsavedMediaBlobUrls,
        postType: "image", //* temp disable video uploads
        category: category?.value,
      });
    },
    [
      contentBeingEdited,
      createContent,
      updateContent,
      unsavedMediaBlobUrls,
      savedImageUrls,
      category,
      campaign,
    ],
  );

  const handleToggleModal = () => {
    setEditPostModalVisibility(!editPostModalVisibility);
  };

  const resetFormDataOnClose = () => {
    unsavedMediaBlobUrls.forEach((dataUrl) => URL.revokeObjectURL(dataUrl));
    console.debug("revoked unsaved media data URLs");
    setShowCategoryValidation(false);
    setShowMediaValidation(false);
    setContentBeingEdited(null);
    setUnsavedMediaBlobUrls([]);
    setSavedImageUrls([]);
    setCategory(null);
  };

  useEffect(() => {
    if (contentBeingEdited) {
      setSavedImageUrls(contentBeingEdited?.imageUrls ?? []);
      const existingCategory = contentCategoryOptions.find(
        (obj) => obj.value === contentBeingEdited?.category,
      );
      setCategory(existingCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentBeingEdited?._id]);

  return (
    <Modal
      id="edit-post-modal"
      isOpen={editPostModalVisibility}
      toggle={handleToggleModal}
      className="modal-dialog-centered edit-post-modal"
      backdrop="static"
      onClosed={resetFormDataOnClose}
      keyboard={false}
    >
      <ModalHeader className="bold" toggle={handleToggleModal}>
        {contentBeingEdited ? "Edit Post" : "New Draft Post"}
      </ModalHeader>
      <ModalBody className="confirm-modal">
        <Formik
          initialValues={{
            title: contentBeingEdited?.title || "",
            caption: contentBeingEdited?.caption || "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            submitPost(values);
            setSubmitting(false);
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validate={validatePost}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            setTouched,
            isSubmitting,
            validateForm,
            // eslint-disable-next-line sonarjs/cognitive-complexity
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup className="px-3">
                {!contentBeingEdited ||
                contentBeingEdited?.status === "draft" ||
                contentBeingEdited?.status === "rejected" ? (
                  <>
                    <Row>
                      {/* <Col xs="12">
                        <Alert color="info">
                          Not sure what to post? Check out the full campaign brief where you can find inspiration and examples.
                          <span
                            onClick={() => {
                              setBriefIsOpen(true);
                            }}
                            className="link-more ml-2"
                          >
                            View Full Brief
                          </span>
                        </Alert>
                      </Col> */}

                      <Col className="mb-3">
                        <label htmlFor="upload" className="form-label">
                          Upload Media
                          {errors.media && showMediaValidation && (
                            <span className="required ml-2">
                              *
                              {errors?.media ||
                                "Post video or images are required"}
                            </span>
                          )}
                        </label>
                        <ContentUpload
                          savedImageUrls={savedImageUrls}
                          setSavedImageUrls={setSavedImageUrls}
                          savedVideoUrl={savedVideoUrl}
                          setSavedVideoUrl={setSavedVideoUrl}
                          savedThumbnailUrl={savedThumbnailUrl}
                          setSavedThumbnailUrl={setSavedThumbnailUrl}
                          unsavedMediaBlobUrls={unsavedMediaBlobUrls}
                          setUnsavedMediaBlobUrls={setUnsavedMediaBlobUrls}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <label htmlFor="category" className="form-label">
                          Select Category
                          {errors?.category && showCategoryValidation && (
                            <span className="required ml-2">
                              *{errors?.category || "Category is required"}
                            </span>
                          )}
                        </label>
                        <Field
                          name="category"
                          className={
                            errors?.category && showCategoryValidation
                              ? "error"
                              : ""
                          }
                          value={category}
                          onChange={(input) => {
                            handleCategorySelectChange(input);
                          }}
                          onBlur={handleBlur}
                          options={contentCategoryOptions}
                          component={RenderSingleSelect}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <label htmlFor="title" className="form-label">
                          Title
                          {errors.title && touched.title && (
                            <span className="required ml-2">
                              *{errors?.title || "Invalid title"}
                            </span>
                          )}
                        </label>
                        <Field
                          name="title"
                          className={
                            errors.title && touched.title ? "error" : ""
                          }
                          component={RenderTextField}
                        />
                      </Col>
                      <Col xs="12">
                        <label htmlFor="caption" className="form-label">
                          Caption
                        </label>
                        <Field
                          name="caption"
                          className={`text-area ${
                            errors.caption && touched.caption ? "error" : ""
                          }`}
                          component={RenderTextArea}
                        />
                        {errors.caption?.length && touched.caption && (
                          <>
                            {errors.caption.map((error, index) => {
                              return (
                                <span
                                  key={index}
                                  className="required ml-2 d-block"
                                >
                                  *{error || "Invalid caption"}
                                </span>
                              );
                            })}
                          </>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col xs="12">
                      <label htmlFor="postUrl" className="form-label">
                        Live Post URL
                        {errors.postUrl && touched.postUrl && (
                          <span className="required ml-2">
                            *{errors?.postUrl || "Invalid post URL"}
                          </span>
                        )}
                      </label>
                      <Field
                        name="postUrl"
                        className={
                          errors.postUrl && touched.postUrl ? "error" : ""
                        }
                        component={RenderTextField}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs="12">
                    {/* replace button text with spinner when loading is true */}
                    <SubmitButton
                      disabled={isSubmitting || loading}
                      className="btn btn-primary btn-default modal-button btn-wide mt-3"
                      onMouseDown={() => {
                        setShowCategoryValidation(true);
                        setShowMediaValidation(true);
                      }}
                    >
                      {/* Spinner */}
                      {loading ? (
                        <div className="d-flex align-items-center justify-content-center">
                          Saving Post
                          <span
                            className="spinner-border spinner-border-sm ml-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </div>
                      ) : (
                        <>
                          {contentBeingEdited ? "Save Changes" : "Create Draft"}
                        </>
                      )}
                    </SubmitButton>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    editPostModalVisibility: state.campaigns.editPostModalVisibility,
    contentBeingEdited: state.campaigns.contentBeingEdited,
    campaign: state.campaigns.campaign,
    loading: state.campaigns.loading,
  };
};

export default connect(mapStateToProps, {
  setEditPostModalVisibility,
  setContentBeingEdited,
  createContent,
  updateContent,
  uploadMedia,
})(EditPostModal);
