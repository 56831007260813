import { API_URL, createRequestActionTypes } from "../../../actions/index";
export const LOGIN_URL = `${API_URL}/api/v1/login`;
export const LOGOUT_URL = `${API_URL}/api/v1/logout`;

export const USER_READ_ONE_URL = `${API_URL}/api/v1/user`;
export const AUTH_LINK_URL = `${API_URL}/api/v1/authLink`;
export const AUTH_LINK_COMPLETE_URL = `${API_URL}/api/v1/authLink/complete`;

export const AUTH_LINK_SEND_SUCCESS_MESSAGE =
  "Email sent. Please check your inbox.";
export const AUTH_LINK_SEND_ERROR_MESSAGE = "Error requesting link.";
export const AUTH_LINK_VERIFY_ERROR_MESSAGE = "Link could not be verified.";
export const INVALID_LOGIN_ERROR_MESSAGE = "Invalid login credentials.";
export const LOGIN_ERROR_MESSAGE = "Something went wrong. Failed to login.";
export const LOGOUT_ERROR_MESSAGE =
  "There was an error logging out. Please clear your cookies then refresh the page.";
export const LOGOUT_SUCCESS_MESSAGE = "You have been logged out.";
export const FETCH_BALANCE_ERROR_MESSAGE =
  "Failed to retrieve available balance.";

export const loginActionTypes = {
  login: createRequestActionTypes("LOGIN"),
  logout: createRequestActionTypes("LOGOUT"),
  requestAuthLink: createRequestActionTypes("REQUEST_AUTH_LINK"),
  verifyAuthLink: createRequestActionTypes("VERIFY_AUTH_LINK"),
  fetchUser: createRequestActionTypes("FETCH_USER"),
  fetchAvailableBalance: createRequestActionTypes("FETCH_AVAILABLE_BALANCE"),
  clearAccountErrors: createRequestActionTypes("CLEAR_LOGIN_ACCOUNT_ERRORS"),
  clearSuccessMessages: createRequestActionTypes("CLEAR_LOGIN_SUCCESS_MSG"),
  storeUpdatedLoginUser: createRequestActionTypes("STORE_UPDATED_LOGIN_USER"),
};
