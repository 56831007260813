import NavLink from "reactstrap/lib/NavLink";
import ActionRequiredIndicator from "../../../components/ActionRequiredIndicator";

/**
 * Renders an icon indicating that an action is required for a given agreement.
 * Intended use is in the agreement list to indicate a particular agreement requires user action.
 * @param {Object} agreement - The agreement object.
 * @returns {JSX.Element|null} The JSX element representing the icon, or null if no action is required.
 */
const AgreementActionRequiredIcon = ({ agreement }) => {
  if (!agreement) return null;
  if (!agreement.__isCreatorActionRequired) {
    return null;
  }
  return (
    <div className="text-right">
      <NavLink to={`/agreements/${agreement._id}`}>
        <ActionRequiredIndicator />
      </NavLink>
    </div>
  );
};

export default AgreementActionRequiredIcon;
