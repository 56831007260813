import { forwardRef } from "react";
import { connect } from "react-redux";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Field, FieldArray, Form, Formik } from "formik";
import { Row, Col } from "reactstrap";
import { RenderButton, RenderSingleSelect } from "../../../components";

const networkOptions = [
  { label: "Instagram", value: "instagram" },
  { label: "TikTok", value: "tiktok" },
  { label: "YouTube", value: "youtube" },
];

const validateUser = (values) => {
  const errors = {};

  if (values?.phone && !/^[+()\d\-\s.ext]{9,}$/im.test(values.phone)) {
    errors.phone = "Invalid phone number";
  }

  if (values?.socialHandles?.length) {
    const socialHandleErrors = validateArrayField(values?.socialHandles);
    if (socialHandleErrors) {
      errors.socialHandles = socialHandleErrors;
    }
  }

  return errors;
};

function validateArrayField(fieldValues) {
  const errors = {};
  for (let i = 0; i < fieldValues.length; i++) {
    const arrayItemErrors = {};
    const arrayItem = fieldValues[i];

    if (!arrayItem.handle?.trim()) {
      arrayItemErrors.handle = "Required";
    }
    if (!arrayItem.network?.value?.trim()) {
      arrayItemErrors.network = "Required";
    }
    if (!arrayItem.rate?.trim()) {
      arrayItemErrors.rate = "Required";
    }

    if (Object.keys(arrayItemErrors).length > 0) {
      errors[i] = arrayItemErrors;
    }
  }
  if (Object.keys(errors).length > 0) {
    return errors;
  }
}

const connectorWithRef = connect(null, null, null, {
  forwardRef: true,
});

const AdditionalInfoForm = connectorWithRef(
  // eslint-disable-next-line react/display-name
  forwardRef(({ loginUser, updatePayoutUser, ...props }, ref) => {
    const initialUserValues = {
      phone: loginUser?.phone,
      socialHandles: loginUser?.socialHandles || [],
    };

    return (
      <Formik
        innerRef={ref}
        initialValues={initialUserValues}
        validate={validateUser}
        onSubmit={async (values, { setSubmitting }) => {
          values.socialHandles = values.socialHandles.map((handle) => {
            return {
              ...handle,
              network: handle.network.value,
            };
          });
          updatePayoutUser(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldError,
          ...props
        }) => (
          <Form className="form-group" onSubmit={props.handleSubmit}>
            <>
              {!loginUser.phone && (
                <Row className="mb-4">
                  <Col xs="12">
                    <label className="form-label">
                      <h4 className="subheading mt-3">Contact Info</h4>
                    </label>
                  </Col>
                  <Col xs="12" md="6">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                      <span className="ml-2 mb-1">{"(Optional)"}</span>
                    </label>

                    <Field
                      id="phone"
                      name="phone"
                      type="phone"
                      autoComplete="tel"
                      className="form-control"
                    />
                    {errors.phone && touched.phone && (
                      <span className="required">*{errors?.phone}</span>
                    )}
                  </Col>
                </Row>
              )}
              {!loginUser.socialHandles?.length && (
                <>
                  <label
                    htmlFor="socialHandles"
                    className="form-label d-inline-flex subheading align-items-center"
                  >
                    <h4>Social Handles and Rates</h4>
                    <span className="ml-2 mb-1">{"(Optional)"}</span>
                  </label>
                  <FieldArray name="socialHandles">
                    {({ insert, remove, push }) => (
                      <div className="social-handles-container mb-5">
                        {values.socialHandles?.map((handle, index) => (
                          <div key={index} className="social-handle">
                            <Row>
                              <Col xs="12" lg="4">
                                <label
                                  htmlFor={`socialHandles.${index}.handle`}
                                  className="form-label"
                                >
                                  Handle
                                  {errors?.socialHandles?.[index]?.handle &&
                                    touched?.socialHandles?.[index]?.handle && (
                                      <span className="required ml-2">
                                        *
                                        {errors?.socialHandles?.[index]
                                          ?.handle || "Required"}
                                      </span>
                                    )}
                                </label>
                                <Field
                                  id={`socialHandles.${index}.handle`}
                                  name={`socialHandles.${index}.handle`}
                                  type="text"
                                  className="form-control"
                                  autoComplete="off"
                                />
                              </Col>
                              <Col xs="12" lg="4">
                                <label
                                  htmlFor={`socialHandles.${index}.network`}
                                  className="form-label"
                                >
                                  Network
                                  {errors?.socialHandles?.[index]?.network &&
                                    touched?.socialHandles?.[index]
                                      ?.network && (
                                      <span className="required ml-2">
                                        *
                                        {errors?.socialHandles?.[index]
                                          ?.network || "Required"}
                                      </span>
                                    )}
                                </label>
                                <Field
                                  as="select"
                                  name="handleNetwork"
                                  className={
                                    errors?.handleNetwork &&
                                    touched?.handleNetwork
                                      ? "error"
                                      : ""
                                  }
                                  onChange={(input) => {
                                    setFieldValue(
                                      `socialHandles.${index}.network`,
                                      input,
                                    );
                                  }}
                                  options={networkOptions}
                                  component={RenderSingleSelect}
                                />
                              </Col>
                              <Col xs="9" lg="3">
                                <label
                                  htmlFor={`socialHandles.${index}.rate`}
                                  className="form-label"
                                >
                                  Est. Price/Post
                                  {errors?.socialHandles?.[index]?.rate &&
                                    touched?.socialHandles?.[index]?.rate && (
                                      <span className="required ml-2">
                                        *
                                        {errors?.socialHandles?.[index]?.rate ||
                                          "Required"}
                                      </span>
                                    )}
                                </label>
                                <Field
                                  id={`socialHandles.${index}.rate`}
                                  name={`socialHandles.${index}.rate`}
                                  type="text"
                                  className="form-control"
                                  autoComplete="off"
                                />
                              </Col>
                              <Col xs="3" lg="1">
                                <RenderButton
                                  type="button"
                                  className="btn btn-outline-primary btn-inline-form-field"
                                  onClick={() => {
                                    // Clear array-like errors before calling remove to workaround a Formik bug that crashes the page. Real fix is in Formik v2
                                    // https://github.com/jaredpalmer/formik/issues/1158
                                    setFieldError("socialHandles", []);
                                    remove(index);
                                  }}
                                >
                                  <TrashIcon
                                    width={20}
                                    height={20}
                                    strokeWidth={2}
                                  ></TrashIcon>
                                </RenderButton>
                              </Col>
                            </Row>
                          </div>
                        ))}
                        {values.socialHandles?.length < 10 && (
                          <RenderButton
                            type="button"
                            className="btn btn-outline-primary mt-2"
                            onClick={() =>
                              push({
                                handle: "",
                                network: {},
                                rate: "",
                              })
                            }
                          >
                            Add a Handle
                          </RenderButton>
                        )}
                      </div>
                    )}
                  </FieldArray>
                </>
              )}
            </>
          </Form>
        )}
      </Formik>
    );
  }),
);

export default AdditionalInfoForm;
