import { Fragment } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { Row, Col } from "reactstrap";
import { RenderButton } from "../../../components";
import AlertBanner from "../../../components/AlertBanner";
import StripeOnboardingSkeleton from "./StripeOnboardingSkeleton";
import { useSelector } from "react-redux";

const StripeOnboardingSection = ({
  showAlert = true,
  loginUser,
  stripeOnboardingUrl,
  ...props
}) => {
  const isLoading = useSelector((state) => state.settings.loading);
  if (isLoading) {
    return <StripeOnboardingSkeleton />;
  }

  return (
    <div className="stripe-onboarding-section">
      <Row>
        <Col xs={12}>
          {loginUser.needsStripeOnboarding ? (
            <>
              {showAlert && (
                <AlertBanner
                  message={
                    <Fragment>
                      <span className="action">Action Needed - </span>
                      Complete Stripe onboarding
                    </Fragment>
                  }
                />
              )}
              <div className="subheading">
                <h2 className="title">Identity Verification</h2>
                <p className="text-grey mb-0">
                  Complete the Stripe onboarding workflow to verify your
                  identity.
                </p>
              </div>
              <RenderButton
                onClick={() => {
                  window.location.replace(stripeOnboardingUrl);
                }}
                className="btn btn-primary btn mt-1 mb-1"
              >
                Continue with Stripe
              </RenderButton>
            </>
          ) : (
            <>
              <div className="subheading d-flex align-items-center justify-content-between">
                <h2 className="title">Identity Information</h2>
                <h3>
                  <span className={"badge badge-success"}>
                    <CheckIcon
                      width={20}
                      height={20}
                      strokeWidth={1.4}
                      className="mr-1"
                    />
                    Complete
                  </span>
                </h3>
              </div>
              <RenderButton
                onClick={() => {
                  window.location.replace(stripeOnboardingUrl);
                }}
                className="btn btn-primary btn mt-1 mb-1"
              >
                Manage in Stripe
              </RenderButton>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default StripeOnboardingSection;
