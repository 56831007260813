import { PlusIcon } from "@heroicons/react/24/outline";
import { connect } from "react-redux";
import { Col, Row, TabPane } from "reactstrap";
import { RenderButton } from "../../../components";
import { setEditPostModalVisibility } from "../actions";
import CampaignContentCard from "./CampaignContentCard";

const CampaignContentTabPane = ({
  tabId,
  campaignAssignment_id,
  contents,

  setEditPostModalVisibility,
  ...props
}) => {
  const getInstructionsByStatus = () => {
    switch (tabId) {
      case "draft":
        return "Posts that have not yet been submitted for approval. You can edit drafts or submit them for approval at any time.";
        break;
      case "rejected":
        return "Posts that have been rejected by an admin. You can view admin feedback, edit rejected posts, and resubmit them for approval at any time.";
        break;
      case "awaiting approval":
        return "Posts that you have submitted for approval.";
        break;
      case "approved":
        return "Posts that an admin has approved and are ready for you to publish live. After the post is live, press the Complete button and enter the live post's URL to mark it as complete.";
        break;
      case "complete":
        return "Posts that are live and have been marked as complete.";
        break;
      default:
        return "";
    }
  };
  return (
    <TabPane tabId={tabId}>
      <Row className="my-3">
        <Col xs="10" md="8">
          <p className="text-grey">{getInstructionsByStatus()}</p>
        </Col>
        {tabId === "draft" ? (
          <Col xs="2" md="4">
            <RenderButton
              className="btn btn-outline-primary float-right"
              onClick={() => setEditPostModalVisibility(true)}
            >
              <PlusIcon width={20} height={20} strokeWidth={3} />
            </RenderButton>
          </Col>
        ) : null}
      </Row>
      {!contents.length && (
        <Row className="d-flex justify-content-center">
          <Col xs="12" md="6" className="text-center">
            <span className="mt-3 text-grey">
              {tabId === "draft" ? (
                <div>
                  <Col xs="12" className="d-flex justify-content-center">
                    <RenderButton
                      className="btn btn-outline-primary my-3"
                      onClick={() => setEditPostModalVisibility(true)}
                    >
                      <PlusIcon width={18} height={18} className="mr-2" />
                      New Draft
                    </RenderButton>
                  </Col>
                  <p>
                    You haven&apos;t added any content yet.
                    <br />
                    Click the New Draft button above to get started!
                  </p>
                </div>
              ) : (
                "There's no content here yet."
              )}
            </span>
          </Col>
        </Row>
      )}
      <Row>
        <Col className="campaign-content-grid">
          {contents?.map((content, index) => (
            <CampaignContentCard content={content} key={`${tabId}-${index}`} />
          ))}
        </Col>
      </Row>
    </TabPane>
  );
};

const mapStateToProps = (state) => {
  return {
    contents: state.campaigns.contents,
  };
};
export default connect(mapStateToProps, {
  setEditPostModalVisibility,
})(CampaignContentTabPane);
