import { Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap";
import "./PayoutOverview.scss";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PayoutOverviewSkeleton = () => {
  return (
    <>
      <h1 className="heading title">
        <Skeleton width={"30%"} />
      </h1>
      <Row>
        <Col sm="12" md="10" lg="8">
          <Card className="card-custom">
            <CardBody>
              <CardTitle tag="h2" className="title">
                <Skeleton width={"30%"} />
              </CardTitle>
              <Table borderless size="sm" className="payouts-table">
                <tbody>
                  <tr className="regular-text bold">
                    <td>
                      <span>
                        <Skeleton />
                      </span>
                    </td>
                    <td className="numeric">
                      <Skeleton width={"40%"} />
                    </td>
                  </tr>
                  <tr className="light-text bold">
                    <td>
                      <Skeleton />
                    </td>
                    <td className="numeric">
                      <Skeleton width={"40%"} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PayoutOverviewSkeleton;
