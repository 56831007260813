import { onboardingActionTypes } from "../constants";
const initialState = {
  loading: false,
  creator: null,
  talentManagementEmail: null,
};
const onboardingReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case onboardingActionTypes.getHubspotCreator.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case onboardingActionTypes.getHubspotCreator.SUCCESS:
      return {
        ...state,
        loading: false,
        creator: payload?.result,
      };
    case onboardingActionTypes.getHubspotCreator.FAILURE:
      return {
        ...state,
        loading: false,
        creator: null,
      };
    case onboardingActionTypes.createTalentEmail.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case onboardingActionTypes.createTalentEmail.SUCCESS:
      return {
        ...state,
        loading: false,
        talentManagementEmail: payload?.result,
      };
    case onboardingActionTypes.createTalentEmail.FAILURE:
      return {
        ...state,
        loading: false,
        talentManagementEmail: null,
      };
    default:
      return state;
  }
};

export default onboardingReducers;
