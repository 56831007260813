import { useEffect, useMemo, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  Alert,
  FormGroup,
} from "reactstrap";
import ImageUploader from "../campaigns/components/ImageUploader";
import { Field, Formik } from "formik";
import {
  RenderButton,
  RenderDateField,
  RenderTextField,
  SubmitButton,
} from "../../components";
import { updateDeliverable } from "./actions";
import { connect } from "react-redux";
import { titlelize } from "../../actions";
import Loader from "../../components/Loader";

const DeliverableModal = ({
  isOpen,
  deliverable_id,
  toggle,
  handleSelectDeliverable,
  onClosed = () => {},

  loading,
  deliverable,

  updateDeliverable,

  ...props
}) => {
  const [existingImageUrls, setExistingImageUrls] = useState(
    deliverable?.imageUrls ?? [],
  );
  const [uploadedImageFiles, setUploadedImageFiles] = useState([]);
  const [showImageValidation, setShowImageValidation] = useState(false);

  const initialValues = useMemo(() => {
    const formattedDate = deliverable?.postDate
      ? new Date(deliverable?.postDate)?.toISOString()?.split("T")[0]
      : "";
    return {
      name: deliverable?.name || "",
      postUrl: deliverable?.postUrl || "",
      handle: deliverable?.handle || "",
      postDate: formattedDate,
    };
  }, [deliverable]);

  useEffect(() => {
    setExistingImageUrls(deliverable?.imageUrls ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverable]);

  const resetFormDataOnClose = () => {
    setUploadedImageFiles([]);
    setShowImageValidation(false);
    onClosed();
  };

  const isMobile = window.innerWidth < 1150;

  const validatePost = (values) => {
    const errors = {};

    const totalImages = uploadedImageFiles?.length + existingImageUrls?.length;
    if (totalImages > 10) {
      errors.images = "No more than 10 screenshots are allowed.";
    }

    if (!values.postUrl && !totalImages) {
      errors.postUrl = "Either a post URL or screenshot is required.";
      errors.images = "Either a post URL or screenshot is required.";
    }

    if (!values?.postUrl && !values?.handle) {
      errors.handle = "Handle is required if no post URL is provided.";
    }

    if (!values?.postUrl && !values?.postDate) {
      errors.postDate = "Post date is required if no post URL is provided.";
    }

    if (values?.postDate && new Date(values?.postDate) > new Date()) {
      errors.postDate = "Post date cannot be in the future.";
    }

    return errors;
  };

  // update post function
  const submitUpdate = async (values) => {
    try {
      const updatedValues = {};
      // only send fields that have been changed from original
      Object.keys(values).forEach((key) => {
        if (values[key] !== deliverable[key]) {
          updatedValues[key] = values[key];
        }
      });
      if (existingImageUrls.length !== deliverable?.imageUrls?.length) {
        updatedValues.imageUrls = existingImageUrls;
      }
      if (uploadedImageFiles.length) {
        updatedValues.images = uploadedImageFiles;
      }
      await updateDeliverable({
        ...updatedValues,
        _id: deliverable?._id,
      });
      toggle();
    } catch (error) {
      // handled in action
    }
  };

  if (!deliverable) return null;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered edit-post-modal"
      backdrop="static"
      onClosed={resetFormDataOnClose}
      unmountOnClose={true}
    >
      <ModalHeader className="bold" toggle={toggle}>
        <div className="d-flex flex-column">
          <span className="text-muted small mb-1">
            {deliverable?.isComplete ? "View" : "Manage"} Deliverable
          </span>
          <span className="title">{deliverable?.name}</span>
        </div>
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          submitUpdate({ ...values, isComplete: true });
          setSubmitting(false);
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validate={validatePost}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleBlur,
          setTouched,
          isSubmitting,
          submitForm,
          validateForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              {loading && <Loader className="loader position-absolute" />}
              <ModalBody>
                {!deliverable?.isComplete && (
                  <Row>
                    <Col>
                      <Alert color="info">
                        <span className="smaller">
                          Please complete this Deliverable once the post is live
                          on <strong>{titlelize(deliverable?.network)}</strong>.
                          Upload a link to the live post and/or screenshots of
                          the content, then submit for approval.
                        </span>
                      </Alert>
                    </Col>
                  </Row>
                )}

                {/* Post URL */}
                <Row>
                  <Col xs="12">
                    <label htmlFor="postUrl" className="form-label">
                      {titlelize(deliverable?.network)} Post URL
                      {errors.postUrl && touched.postUrl && (
                        <span className="required ml-2">
                          *{errors?.postUrl}
                        </span>
                      )}
                    </label>
                    <Field
                      disabled={deliverable?.isComplete}
                      name="postUrl"
                      className={
                        errors.postUrl && touched.postUrl ? "error" : ""
                      }
                      component={RenderTextField}
                    />
                  </Col>
                </Row>

                {/* Screenshots */}
                <Row>
                  <Col className="mb-3">
                    <label htmlFor="images" className="form-label">
                      Screenshots
                      {errors.images && showImageValidation && (
                        <span className="required ml-2">*{errors?.images}</span>
                      )}
                    </label>
                    <ImageUploader
                      uploadedImageFiles={uploadedImageFiles}
                      setUploadedImageFiles={(data) => {
                        setShowImageValidation(false);
                        setUploadedImageFiles(data);
                      }}
                      existingImageUrls={existingImageUrls}
                      setExistingImageUrls={(data) => {
                        setShowImageValidation(false);
                        setExistingImageUrls(data);
                      }}
                      disabled={deliverable?.isComplete}
                    />
                  </Col>
                </Row>

                {/* Handle */}
                <Row>
                  <Col xs="12">
                    <label htmlFor="handle" className="form-label">
                      Handle
                      {errors.handle && touched.handle ? (
                        <span className="required ml-2">*{errors?.handle}</span>
                      ) : (
                        <>
                          {!deliverable?.isComplete && (
                            <span className="ml-2">
                              *Only required if no Post URL is provided
                            </span>
                          )}
                        </>
                      )}
                    </label>
                    <Field
                      name="handle"
                      className={errors.handle && touched.handle ? "error" : ""}
                      component={RenderTextField}
                      disabled={deliverable?.isComplete}
                    />
                  </Col>
                </Row>

                {/* Post Date */}
                <Row>
                  <Col xs="12">
                    <label htmlFor="postDate" className="form-label">
                      Date Posted
                      {errors.postDate && touched.postDate ? (
                        <span className="required ml-2">
                          *{errors?.postDate}
                        </span>
                      ) : (
                        <>
                          {!deliverable?.isComplete && (
                            <span className="ml-2">
                              *Only required if no Post URL is provided
                            </span>
                          )}
                        </>
                      )}
                    </label>
                    <Field
                      name="postDate"
                      className={
                        errors.postDate && touched.postDate ? "error" : ""
                      }
                      component={RenderDateField}
                      disabled={deliverable?.isComplete}
                    />
                  </Col>
                </Row>
              </ModalBody>
              {deliverable?.isComplete ? (
                <ModalBody>
                  <Alert color="info">
                    <span className="smaller">
                      This deliverable has been marked as complete and can not
                      be edited. If you need to make changes, please contact
                      your Campaign Manager.
                    </span>
                  </Alert>
                </ModalBody>
              ) : (
                <ModalFooter>
                  <RenderButton
                    color="primary"
                    disabled={isSubmitting || loading}
                    className="btn btn-outline-primary btn-default modal-button btn-wide mt-3 d-flex justify-content-center align-items-center"
                    onClick={() => {
                      submitUpdate(values);
                    }}
                    onMouseDown={() => {
                      setShowImageValidation(true);
                    }}
                  >
                    Save Draft
                  </RenderButton>
                  <SubmitButton
                    disabled={isSubmitting || loading}
                    className="btn btn-primary btn-default modal-button btn-wide mt-3"
                    onMouseDown={() => {
                      setShowImageValidation(true);
                    }}
                  >
                    {isMobile ? "Complete" : "Save and Mark Complete"}
                  </SubmitButton>
                </ModalFooter>
              )}
            </FormGroup>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.deliverables.loading,
    deliverable: state.deliverables.deliverable,
  };
};

export default connect(mapStateToProps, {
  updateDeliverable,
})(DeliverableModal);
