import { API_URL, createRequestActionTypes } from "../../../actions/index";

export const READ_MANY_DELIVERABLES_URL = `${API_URL}/api/v1/deliverables`;
export const READ_ONE_DELIVERABLE_URL = `${API_URL}/api/v1/deliverable`;
export const UPDATE_ONE_DELIVERABLE_URL = `${API_URL}/api/v1/deliverable`;

export const GET_DELIVERABLES_ERROR = "Error reading deliverable(s)";
export const SAVE_DELIVERABLE_ERROR = "Error updating deliverable";
export const SAVE_DELIVERABLE_SUCCESS = "Deliverable updated successfully";
export const SUBMIT_DELIVERABLE_SUCCESS =
  "Deliverable has been marked as complete and submitted for approval";

export const deliverablesActionTypes = {
  getDeliverable: createRequestActionTypes("GET_DELIVERABLE"),
  getCompleteDeliverables: createRequestActionTypes(
    "GET_COMPLETE_DELIVERABLES",
  ),
  getIncompleteDeliverables: createRequestActionTypes(
    "GET_INCOMPLETE_DELIVERABLES",
  ),
  updateDeliverable: createRequestActionTypes("UPDATE_DELIVERABLE"),
};
