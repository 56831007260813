import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap";
import { MapPinIcon } from "@heroicons/react/20/solid";
import settings from "settings";
import { capitalize, showTitle } from "../../../../../actions";
import ReactMapGL, { Marker, Popup, WebMercatorViewport } from "react-map-gl";

const applyToArray = (func, array) => func.apply(Math, array);

const getBounds = (markers) => {
  const lat = markers.map((marker) => marker.latitude);
  const lng = markers.map((marker) => marker.longitude);

  const cornersLngLat = [
    [applyToArray(Math.min, lng), applyToArray(Math.min, lat)],
    [applyToArray(Math.max, lng), applyToArray(Math.max, lat)],
  ];

  const viewportDimension = new WebMercatorViewport({
    width: 800,
    height: 600,
  }).fitBounds(cornersLngLat, { padding: 150 });

  const { longitude, latitude, zoom } = viewportDimension;
  return { longitude, latitude, zoom };
};

const FollowersLocationByCity = ({ reportData, ...props }) => {
  const data =
    reportData &&
    reportData.audience_followers &&
    reportData.audience_followers.data &&
    reportData.audience_followers.data.audience_geo &&
    reportData.audience_followers.data.audience_geo.cities
      ? reportData.audience_followers.data.audience_geo.cities
          .map((city, index) => ({
            name: city.name,
            weight: (city.weight * 100).toFixed(2) + "%",
            latitude: city.coords.lat,
            longitude: city.coords.lon,
          }))
          .slice(0, 10)
      : [];

  const bounds = getBounds(data);

  const [viewport, setViewport] = useState({
    latitude: bounds.latitude,
    longitude: bounds.longitude,
    zoom: bounds.zoom,
  });

  const [map, setMap] = useState(null);
  useEffect(() => {
    setMap("mapbox://styles/robert-tensor/ckjkxlxoh0dp619sd4ssel3cv");
  }, [map]);

  const [popupInfo, setPopupInfo] = useState(null);
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>
          <span className="tooltip-button-wrap">
            <span className="text-tooltip">Location By City</span>
            <span
              href="#"
              id="followersLocationByCity"
              className="tooltip-icon"
            ></span>
          </span>
        </CardTitle>
        {reportData &&
        reportData.audience_followers &&
        reportData.audience_followers.data &&
        reportData.audience_followers.data.audience_geo &&
        reportData.audience_followers.data.audience_geo.cities ? (
          <Row className="row-xs">
            <Col sm="6" md="8">
              <div className="graph-wrap text-left mapHeight">
                <div className="mapContainer">
                  <ReactMapGL
                    {...viewport}
                    width="100%"
                    height="100%"
                    mapStyle={map}
                    onViewportChange={(nextViewport) =>
                      setViewport(nextViewport)
                    }
                  >
                    {data.map((city, index) => {
                      return (
                        <Marker
                          key={`marker-${index}`}
                          longitude={city.longitude}
                          latitude={city.latitude}
                        >
                          <MapPinIcon
                            height={25}
                            color="#1f87ee"
                            style={{
                              cursor: "pointer",
                              transform: `translate(${-25 / 2}px,-25px)`,
                            }}
                            onClick={() => setPopupInfo(city)}
                          />
                        </Marker>
                      );
                    })}
                    {popupInfo && (
                      <Popup
                        tipSize={15}
                        anchor="top"
                        longitude={popupInfo.longitude}
                        latitude={popupInfo.latitude}
                        closeOnClick={true}
                        onClose={() => setPopupInfo(null)}
                      >
                        <div>
                          <Row>
                            <Col className="text-center">
                              <img
                                src={settings.mainbrand}
                                className="popup_logo"
                                alt="homepage"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {popupInfo.name} | {popupInfo.weight}
                            </Col>
                          </Row>
                        </div>
                      </Popup>
                    )}
                  </ReactMapGL>
                </div>
              </div>
            </Col>

            <Col sm="6" md="4">
              <ListGroup flush className="country-list">
                {reportData &&
                reportData.audience_followers &&
                reportData.audience_followers.data &&
                reportData.audience_followers.data.audience_geo &&
                reportData.audience_followers.data.audience_geo.cities
                  ? reportData.audience_followers.data.audience_geo.cities.map(
                      (city, index) => {
                        if (index < 5) {
                          return (
                            <ListGroupItem key={index}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="country-text-wrap">
                                  <div className="flag-img">
                                    <img
                                      loading="lazy"
                                      src={props.generateImgUrl(
                                        "country",
                                        city.country && city.country.code,
                                      )}
                                      alt="img"
                                    />
                                  </div>
                                  <div
                                    id={`followers_${city.name}`}
                                    title={showTitle(
                                      `followers_${city.name}`,
                                      city.name,
                                    )}
                                    className="country-name"
                                  >
                                    {capitalize(city.name)}
                                  </div>
                                </div>
                                <Badge className="text-grey" color="">
                                  {(city.weight * 100).toFixed(2) + "%"}
                                </Badge>
                              </div>
                            </ListGroupItem>
                          );
                        }
                        return null;
                      },
                    )
                  : null}
              </ListGroup>
            </Col>
          </Row>
        ) : (
          <span className="text-center text-grey ">
            <p className="pt-50">No records</p>
          </span>
        )}
      </CardBody>
    </Card>
  );
};

export default FollowersLocationByCity;
