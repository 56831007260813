import {
  CreditCardIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { titlelize } from "../../../actions";
import { RenderButton } from "../../../components";
import AlertBanner from "../../../components/AlertBanner";
import InternationalAccountForm from "../../settings/components/FinancialAccountSection/InternationalAccountForm";

const FinancialAccountStep = ({
  loginUser,
  initiateFinancialConnectionsModal,
  externalAccount,
  setNextButtonType,
}) => {
  useEffect(() => {
    setNextButtonType(
      loginUser?.needsStripeFinancialConnection ? "skip" : "next",
    );
  }, [loginUser?.needsStripeFinancialConnection, setNextButtonType]);

  return (
    <div className="stripe-onboarding">
      <Row>
        <Col xs={12}>
          <div className="subheading">
            <h3 className="title">Link your bank account</h3>
            <p className="text-grey mb-0">
              Designate a financial account where we should deposit your
              payouts.
            </p>
          </div>
        </Col>
        {loginUser.needsStripeFinancialConnection ? (
          <Col xs="12" md="10" lg="8">
            {loginUser.country && loginUser.country !== "US" ? (
              <InternationalAccountForm />
            ) : (
              <RenderButton
                onClick={() => {
                  initiateFinancialConnectionsModal();
                }}
                disabled={!!loginUser.needsStripeOnboarding}
                className={
                  "btn btn-primary btn mt-1 mb-4" +
                  (loginUser.needsStripeOnboarding ? "disabled" : "")
                }
              >
                Continue with Stripe
              </RenderButton>
            )}
            {loginUser.needsStripeOnboarding && (
              <p className="text-danger text-grey mt-4">
                * Complete Stripe Onboarding before linking a bank account
              </p>
            )}
          </Col>
        ) : (
          <Col xs="12" md="10" lg="6">
            <AlertBanner
              message="Financial account has been linked."
              alertType="success"
            />
            {externalAccount?.object === "bank_account" &&
              externalAccount?.bank_name &&
              externalAccount?.last4 && (
                <div className="d-flex align-items-center external-account mb-4 mt-4">
                  <span className="icon-container mr-3">
                    <BuildingLibraryIcon
                      className="link-icon"
                      width={40}
                      height={40}
                      strokeWidth={1.5}
                    />
                  </span>
                  <div>
                    <h4 className="bold m-0">
                      {titlelize(externalAccount?.bank_name)}
                    </h4>
                    <p className="text-grey m-0">
                      Account ending in {externalAccount?.last4}
                    </p>
                  </div>
                </div>
              )}
            {externalAccount?.object === "card" &&
              externalAccount?.brand &&
              externalAccount?.funding &&
              externalAccount?.last4 &&
              externalAccount?.exp_month &&
              externalAccount?.exp_year && (
                <div className="d-flex align-items-center external-account mb-4 mt-4">
                  <span className="icon-container mr-3">
                    <CreditCardIcon
                      className="link-icon text-grey"
                      width={40}
                      height={40}
                      strokeWidth={1.5}
                    />
                  </span>
                  <div>
                    <h4 className="bold m-0">
                      {titlelize(
                        `${externalAccount?.brand} ${externalAccount?.funding} (...${externalAccount?.last4})`,
                      )}
                    </h4>
                    <p className="text-grey mb-0">
                      Exp {externalAccount?.exp_month}/
                      {externalAccount?.exp_year}
                    </p>
                  </div>
                </div>
              )}
            <p className="text-grey">
              If you need to update the account on file later, you can do so
              from the settings page.
            </p>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default FinancialAccountStep;
