import RecentDepositsTable from "./RecentDepositsTable";
import "./RecentDeposits.scss";
import { connect, useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { getRecentDeposits } from "../../actions";
import { useEffect } from "react";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import RecentDepositsTableSkeleton from "./RecentDepositsTableSkeleton";

const RecentDeposits = ({ getRecentDeposits, deposits, ...props }) => {
  useEffect(() => {
    getRecentDeposits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = useSelector((state) => state.payouts.loading);
  if (isLoading || !deposits) {
    return (
      <Row>
        <Col sm="12" md="10" lg="8">
          <Card className="card-custom recent-deposits">
            <CardBody>
              <CardTitle tag="h2" className="title">
                <Skeleton width={"40%"} />
              </CardTitle>

              <RecentDepositsTableSkeleton />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col sm="12" md="10" lg="8">
        <Card className="card-custom recent-deposits">
          <CardBody>
            <CardTitle tag="h2" className="title">
              Recent Deposits
            </CardTitle>

            <RecentDepositsTable deposits={deposits} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    deposits: state.payouts.deposits,
  };
};
export default connect(mapStateToProps, {
  getRecentDeposits,
})(RecentDeposits);
