import SidebarHeader from "./sidebarHeader";

const HeaderProxy = (props) => {
  // const [windowDimensions, setWindowDimensions] = useState(
  //   getWindowDimensions()
  // );

  // function getWindowDimensions() {
  //   const { innerWidth: width, innerHeight: height } = window;
  //   return {
  //     width,
  //     height,
  //   };
  // }

  // const { setIsExpanded } = props;

  // useEffect(() => {
  //   function handleResize() {
  //     // setWindowDimensions(getWindowDimensions());

  //     if (!!navigator.maxTouchPoints && !window.screen.orientation.angle) {
  //       setIsExpanded(true);
  //       localStorage.removeItem("sidebar");
  //     } else {
  //       setIsExpanded(false);
  //       localStorage.setItem("sidebar", true);
  //     }
  //   }

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [setIsExpanded]);

  return <SidebarHeader {...props} />;
};

export default HeaderProxy;
