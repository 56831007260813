import {
  actionCreator,
  checkHttpStatus,
  checkAuthTokenExpiry,
  privateHeaders,
  showErrorMessage,
  showSuccessMessage,
} from "../../../actions";
import { logout } from "../../login/actions";
import {
  GET_OPPORTUNITIES_ERROR,
  GET_OPPORTUNITY_ERROR,
  opportunityActionTypes,
  READ_MANY_OPPORTUNITIES_URL,
  READ_ONE_OPPORTUNITY_URL,
  STATUS_UPDATE_ERROR,
  SUBMIT_APPROVAL_SUCCESS,
  SUBMIT_REJECTION_SUCCESS,
  UPDATE_ONE_OPPORTUNITY_URL,
} from "../constants";

export const getOpportunities = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(opportunityActionTypes.getOpportunities.REQUEST));
    const url = new URL(READ_MANY_OPPORTUNITIES_URL);
    url.searchParams.append("sortKey", "created");
    fetch(url.toString(), {
      method: "GET",
      headers: privateHeaders(getState),
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error) {
          showErrorMessage(GET_OPPORTUNITIES_ERROR);
          dispatch(
            actionCreator(opportunityActionTypes.getOpportunities.FAILURE),
          );
        } else {
          dispatch(
            actionCreator(
              opportunityActionTypes.getOpportunities.SUCCESS,
              response,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(opportunityActionTypes.getOpportunities.FAILURE),
        );
        showErrorMessage(GET_OPPORTUNITIES_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const getOpportunity = (id) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(opportunityActionTypes.getOpportunity.REQUEST));
    const url = new URL(`${READ_ONE_OPPORTUNITY_URL}/${id}`).toString();
    fetch(url, {
      method: "GET",
      headers: privateHeaders(getState),
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.result) {
          showErrorMessage(GET_OPPORTUNITY_ERROR);
          dispatch(
            actionCreator(opportunityActionTypes.getOpportunity.FAILURE),
          );
        } else {
          dispatch(
            actionCreator(
              opportunityActionTypes.getOpportunity.SUCCESS,
              response,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(opportunityActionTypes.getOpportunity.FAILURE));
        showErrorMessage(GET_OPPORTUNITY_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const updateOpportunityStatus = ({ _id, approvedByCreator }) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(opportunityActionTypes.updateOpportunity.REQUEST));

    fetch(UPDATE_ONE_OPPORTUNITY_URL, {
      method: "PUT",
      headers: privateHeaders(getState),
      credentials: "include",
      body: JSON.stringify({
        _id,
        approvedByCreator,
      }),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.result) {
          showErrorMessage(STATUS_UPDATE_ERROR);
          dispatch(
            actionCreator(opportunityActionTypes.updateOpportunity.FAILURE),
          );
        } else {
          dispatch(
            actionCreator(
              opportunityActionTypes.updateOpportunity.SUCCESS,
              response,
            ),
          );
          showSuccessMessage(
            approvedByCreator
              ? SUBMIT_APPROVAL_SUCCESS
              : SUBMIT_REJECTION_SUCCESS,
          );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(opportunityActionTypes.updateOpportunity.FAILURE),
        );
        showErrorMessage(STATUS_UPDATE_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};
