import { CheckIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Col } from "reactstrap";

const AlertBanner = ({
  message,
  button,
  alertType = "warning",
  children,
  ...props
}) => (
  <div
    className={`alert alert-${alertType} row mx-0 d-flex align-items-center justify-content-between mb-3`}
    role="alert"
  >
    <Col xs="12" md="9" className="p-0">
      <div className="d-flex align-items-center">
        <span className="icon-container">
          {alertType === "success" ? (
            <CheckIcon width={22} height={22} strokeWidth={2} />
          ) : (
            <ExclamationCircleIcon width={22} height={22} strokeWidth={2} />
          )}
        </span>
        <span className="d-inline-block">{message || children}</span>
      </div>
    </Col>
    <Col xs="12" md="3">
      {button && (
        <div className="col d-flex justify-content-center">{button}</div>
      )}
    </Col>
  </div>
);
export default AlertBanner;
