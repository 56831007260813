import { Badge } from "reactstrap";

const AgreementStatusChips = ({ agreement }) => {
  return (
    <>
      {agreement?.status === "InProgress" && (
        <>
          {!agreement?.requiresCreatorSignature ||
          agreement?.creatorHasSigned ? (
            <Badge color="success" className="badge-soft-success">
              Finalizing
            </Badge>
          ) : (
            <Badge color="warning" className="badge-soft-warning">
              In Progress
            </Badge>
          )}
        </>
      )}
      {agreement?.status === "Completed" && (
        <Badge color="success" className="badge-soft-success">
          Completed
        </Badge>
      )}
      {agreement?.status === "Revoked" ||
        agreement?.status === "Declined" ||
        (agreement?.status === "Expired" && (
          <Badge color="danger" className="badge-soft-danger">
            {agreement?.status}
          </Badge>
        ))}
    </>
  );
};

export default AgreementStatusChips;
