import { NavLink } from "react-router-dom";
import { RenderCurrency } from "../../../components";
import { formatDate } from "../../../actions";
import BadgeCompletionCount from "../../../components/BadgeCompletionCount";
import ActionRequiredIndicator from "../../../components/ActionRequiredIndicator";

const CampaignsTableRow = ({ campaign, isMobile, ...props }) => {
  return (
    <tr className="activity-row">
      <th scope="row" className="tb-head text-grey">
        {(campaign.allowContentApproval ||
          campaign.__deliverableMetrics?.total ||
          campaign.__agreementMetrics?.total) &&
        campaign?.campaignInvitationStatus !== "declined" &&
        campaign?.campaignInvitationStatus !== "rejected" ? (
          <NavLink className="link-more" to={`/campaigns/${campaign._id}`}>
            {campaign.campaignName}
          </NavLink>
        ) : (
          <>{campaign.campaignName}</>
        )}
      </th>
      <td>
        <div className="balance text-right text-grey-bold">
          <RenderCurrency amount={campaign.availableBalance} />
        </div>
      </td>
      <td>
        <div className="balance text-right text-grey-bold">
          <RenderCurrency amount={campaign.holdBalance} />
        </div>
      </td>

      {!isMobile && (
        <>
          <td>
            <div className="text-right text-grey-bold">
              <span>
                {formatDate(campaign.nextPayoutDate, { month: "long" })}
              </span>
            </div>
          </td>
          <td>
            <div className="balance text-right text-grey-bold">
              <BadgeCompletionCount
                total={campaign.__agreementMetrics?.total}
                incomplete={
                  campaign.__agreementMetrics?.creatorActionRequiredCount
                }
              />
            </div>
          </td>
          <td>
            <div className="balance text-right text-grey-bold">
              <BadgeCompletionCount
                total={campaign.__deliverableMetrics?.total}
                incomplete={campaign.__deliverableMetrics?.incomplete}
              />
            </div>
          </td>
        </>
      )}

      <td>
        {(!!campaign.__agreementMetrics.creatorActionRequiredCount ||
          !!campaign.__deliverableMetrics.incomplete) && (
          <div className="text-right">
            <NavLink to={`/campaigns/${campaign._id}`}>
              <ActionRequiredIndicator />
            </NavLink>
          </div>
        )}
      </td>
    </tr>
  );
};

export default CampaignsTableRow;
