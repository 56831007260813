import tensorGif from "../assets/img/tensor_logo.gif";
import { Spinner } from "reactstrap";

const Loader = (props) => (
  <div id="loader" className={props.className || "loader"}>
    {process.env.REACT_APP_BRAND === "tensorsocial" ? (
      <img src={tensorGif} className="tensorGif" alt=""></img>
    ) : (
      <Spinner size="xl" />
    )}
  </div>
);
export default Loader;
