import { Card, CardBody, CardTitle } from "reactstrap";
import Highcharts from "highcharts";
import settings from "settings";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import {
  formatNumbersToCommaSeparated,
  formatNumbersToUnits,
} from "../../../../actions";
import "./EarningsTile.scss";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import moment from "moment";
import { RenderCurrency } from "../../../../components";
import { useSelector } from "react-redux";
import EarningsTileSkeleton from "./EarningsTileSkeleton";

const chartOptions = (earningsHistory = []) => {
  const sortedHistory = earningsHistory.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  const data = [];
  sortedHistory.reduce((accumulator, currentValue) => {
    data.push(accumulator + currentValue.value);
    return accumulator + currentValue.value;
  }, 0);
  const categories = sortedHistory.map((e) => moment(e.date).format("MMM D"));

  NoDataToDisplay(Highcharts);
  Highcharts.setOptions({
    lang: {
      noData: "No available earnings data",
    },
  });

  const isMobile = window.innerWidth < 676;

  return {
    chart: {
      height: isMobile ? 190 : 185,
      backgroundColor: null,
    },
    tooltip: {
      backgroundColor: "#17222d",
      borderColor: "transparent",
      borderRadius: 10,
      shadow: false,
      style: {
        color: "white",
        fontSize: "11px",
      },
      positioner: function (labelWidth, labelHeight, point) {
        return { x: point.plotX, y: 0 };
      },
      formatter: function () {
        return [`${this.x}: $${formatNumbersToCommaSeparated(this.y)}`];
      },
    },

    title: "",
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        enabled: !isMobile,
        style: {
          color: "#898e94CC",
        },
        formatter: function () {
          if (this.isLast || this.isFirst) {
            return formatNumbersToUnits(this.value);
          }
        },
      },
      gridLineColor: "#e6e6e680",
    },
    xAxis: {
      crosshair: {
        width: 1,
        color: "#dee2e6",
        label: {
          enabled: true,
          align: "right",
        },
      },
      categories: categories,
      title: {
        text: null,
      },
      labels: {
        autoRotation: [0],
        style: {
          color: "#898e94",
          textOverflow: "none",
          whiteSpace: "nowrap",
        },
        formatter: function () {
          if (this.isLast || this.isFirst) {
            return this.value;
          }
        },
      },
      lineColor: settings.themeColor,
      tickColor: "transparent",
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      spline: {
        width: 1,
        color: settings.themeColor,
        linejoin: "round",
        marker: {
          enabled: false,
        },
      },
    },

    series: [
      {
        type: "spline",
        data: data,
      },
    ],
    credits: {
      enabled: false,
    },
  };
};

const EarningsTile = ({ loginUser }) => {
  const isLoading = useSelector((state) => state.dashboard.loading);
  if (isLoading || !loginUser?.__earningsHistory) {
    return <EarningsTileSkeleton />;
  }

  return (
    <Card className="card-content-custom earnings-tile">
      <CardBody>
        <CardTitle>
          Earnings:
          {loginUser.__earnings >= 0 ? (
            <RenderCurrency
              className="ml-1"
              amount={loginUser.__earnings}
              renderCurrencyCode={loginUser.country !== "US"}
            />
          ) : (
            <Skeleton className="ml-1" height={30} width={"30%"} />
          )}
        </CardTitle>
        <div className="graph-wrap">
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions(loginUser.__earningsHistory)}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default EarningsTile;
