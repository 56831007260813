import { useSelector } from "react-redux";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";

import { RenderCurrency } from "../../../components";
import { capitalize } from "../../../actions";
import history from "../../../history";
import moment from "moment";

const OpportunityCard = ({ opportunity, ...props }) => {
  const loginUser = useSelector((state) => state.login.loginUser);

  return (
    <Col lg="6">
      <Card
        className="card-custom opportunity-card text-left"
        onClick={() => history.push(`/deals/${opportunity._id}`)}
      >
        <CardBody>
          <Row>
            <Col
              xs="3"
              className="d-flex flex-wrap align-items-center justify-content-center"
            >
              <img
                src={opportunity.brandLogoUrl}
                width={75}
                height={75}
                alt="brand logo"
                className="card-brand"
              ></img>
            </Col>
            <Col xs="9">
              <Row>
                <h3 className="bold mr-3 mb-0">{opportunity.brandName}</h3>
                <h4 className="text-grey mb-0">{opportunity.title}</h4>
              </Row>
              <Row className="my-2">
                <Badge color="success" className="badge-success bold mr-1">
                  <RenderCurrency
                    amount={opportunity.amount}
                    renderCurrencyCode={loginUser.country !== "US"}
                  />
                </Badge>

                <Badge color="neutral" className="badge-light bold">
                  Uploaded {moment(opportunity.created).format("MMM DD, YYYY")}
                </Badge>
              </Row>
              <Row>
                <p className="text-grey m-0">
                  {opportunity.status === "pending"
                    ? "Pending your approval"
                    : `${capitalize(opportunity.status)} ${moment(
                        opportunity.updated,
                      ).format("MMM DD, YYYY")}`}
                </p>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default OpportunityCard;
