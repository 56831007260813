import { toast } from "react-toastify";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const API_URL = process.env.REACT_APP_API_URL;
export const COMMON_ERROR_MESSAGE = "Something went wrong, please try again.";
export const AUTHORIZATION_ERROR_MESSAGE = "Authorization error.";
export const LOGGED_OUT_ERROR_MESSAGE =
  "You have been logged out. Please login again.";
export const SIGNUP_SUCCESS_MESSAGE = "Account successfully created.";
const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";
const cookieDomain =
  process.env.REACT_APP_AUTH_COOKIE_DOMAIN ?? ".neoreach.com";
const cookieName = process.env.REACT_APP_AUTH_COOKIE_NAME ?? "__cp_sid_dev";

export function readAuthCookie() {
  return cookies.get(cookieName, { path: "/", domain: cookieDomain });
}

export function deleteAuthCookie() {
  cookies.set(cookieName, "", { path: "/", maxAge: 0, domain: cookieDomain });
  const cookieExists = readAuthCookie();
  if (cookieExists) {
    window.location.href = "/authError";
  }

  return cookieExists ? "err" : "ok";
}

export function checkHttpStatus(response) {
  response.statusCode = response.status;
  if (response.status >= 200 && response.status < 204) {
    return response.json();
  } else if (response.status === 204) {
    return true;
  } else if (
    response.status >= 400 &&
    response.status !== 401 &&
    response.status !== 409 &&
    response.status < 500
  ) {
    response.error = response.error || new Error(response.statusText);
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    error.statusCode = response.status;
    throw error;
  }
}

export const checkAuthTokenExpiry = (response) => {
  const valid = response;
  if (
    response.message &&
    response.message === "Failed to authenticate token."
  ) {
    const error = new Error(response.message);
    error.message = LOGGED_OUT_ERROR_MESSAGE;
    error.statusCode = 401;
    throw error;
  }
  return valid;
};

export const showErrorMessage = (
  message = COMMON_ERROR_MESSAGE,
  closeFunction = () => {},
  toastId,
  autoClose = 7500,
) => {
  typeof closeFunction !== "function" && (closeFunction = () => {});
  toast.error(message, {
    position: "top-center",
    autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    onClose: function () {
      closeFunction();
    },
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId,
  });
};

export const showSuccessMessage = (
  message,
  closeFunction = () => {},
  toastId,
) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 7500,
    hideProgressBar: false,
    closeOnClick: true,
    onClose: function () {
      return closeFunction();
    },
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId,
  });
};

export const dismissMessage = (toastId) => {
  toast.dismiss(toastId);
};

export function actionCreator(actionType, data) {
  return {
    type: actionType,
    payload: data,
  };
}

export function createRequestActionTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((requestTypes, type) => {
    requestTypes[type] = `${base}_${type}`;
    return requestTypes;
  }, {});
}

export const createQueryParams = (searchObj) => {
  let queryParams = "?";
  const queryArray = Object.keys(searchObj).map(
    (key, index) => `${key}=${searchObj[key]}`,
  );
  queryParams = queryParams + (queryArray.length ? queryArray.join("&") : "");
  return queryParams;
};
export const formatNumbersToCommaSeparated = (number) => {
  return number && Math.round(number).toLocaleString().split(",").join(", ");
};

export const formatNumbersToUnits = (count) => {
  const COUNT_ABBRS = ["", "k", "M", "B", "T", "P", "E", "Z", "Y"];

  const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
  let result = parseFloat((count / Math.pow(1000, i)).toFixed(1));

  return (result += `${COUNT_ABBRS[i]}`);
};

export const formatNumbersToIntegerUnits = (count) => {
  const COUNT_ABBRS = ["", "k", "M", "B", "T", "P", "E", "Z", "Y"];

  const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
  let result = parseFloat((count / Math.pow(1000, i)).toFixed(0));

  return (result += `${COUNT_ABBRS[i]}`);
};

export const formatDate = (date, formatOptions, defaultValue = null) => {
  if (!date && typeof date !== "number") return defaultValue;
  formatOptions = {
    month: "long",
    day: "numeric",
    ...formatOptions,
  };
  return new Date(date).toLocaleString("en-US", formatOptions);
};

export const capitalize = (name) => {
  return (
    name &&
    name
      .split(" ")
      .map(
        (word, index) =>
          word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase(),
      )
      .join(" ")
  );
};
export const titlelize = (name) => {
  return (
    name &&
    name
      .split(" ")
      .map(
        (word, index) =>
          (index < 1 ? word.slice(0, 1).toUpperCase() : word.slice(0, 1)) +
          word.slice(1).toLowerCase(),
      )
      .join(" ")
  );
};
export const formatName = (name) => {
  let formatedName = name && name.replace(/[A-Z]|-|_/, " ");
  if (formatedName) {
    formatedName = capitalize(formatedName);
  }
  return formatedName;
};
export const privateHeaders = (getState, contentType) => {
  const headers = {
    Authorization:
      getState()?.login?.authToken || getState()?.signup?.authToken,
  };
  if (contentType !== "none") {
    headers["Content-Type"] = contentType || "application/json";
  }
  return headers;
};
export const publicHeaders = {
  "Content-Type": "application/json",
};

export const showTitle = (id, name) =>
  document.getElementById(id) &&
  document.getElementById(id).offsetWidth <
    document.getElementById(id).scrollWidth
    ? capitalize(name)
    : "";
