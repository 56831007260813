import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "./campaignBrief.scss";

export const CampaignBrief = () => {
  return (
    <div className="campaign-brief">
      <p className="subheading subtitle">
        Your draft submissions{" "}
        <span className="bold">
          must be approved before being published on Lemon8.
        </span>{" "}
        Your drafts will be approved or rejected by the client approval team.
        Please login for updates regularly. If a post is rejected you can view
        admin feedback, make requested changes, and resubmit. Once a draft
        submission is approved you may publish it to Lemon8. After the post is
        live please submit the link/URL of the published post and mark it as
        complete. You will receive a notification each time a post is reviewed
        and action is required.
      </p>
      <p>
        If you need assistance or have any questions, please contact your
        campaign manager.
      </p>

      <h2 className="heading title">Overall Requirements</h2>
      <p className="subheading subtitle">
        Before you begin creating, please consider the following for your
        content:
      </p>
      <ul>
        <li>
          Quality matters - Lemon8’s standards for approvable content are high -
          so bring your best stuff!
        </li>
        <li>
          Each submission should feature original pictures (no reusing others
          intellectual property)
        </li>
        <li>
          Using ChatGPT, Gemini, or any other AI-tool to generate captions is
          prohibited and will be detected
        </li>
        <li>
          Must have 3+ original pics (3:4 ratio, cover pic and 2+ content
          slides) with simple editing (adding titles, text, etc.)
        </li>
        <li>
          Don&apos;t over-edit the pictures. Avoid large fonts and too many
          decorative stickers. Avoid dark aesthetics or masking on your pics.
        </li>
        <li>
          Caption must be over 50 words - no AI generated text allowed; use more
          personalized language to show authenticity
        </li>
        <li>Post title must be different from the cover pic text overlay</li>
        <li>
          Make sure to meet any other requirements specific to the post&apos;s
          category (listed below).
        </li>
      </ul>

      <h2 className="heading title">Requirements and Examples by Category</h2>

      <Accordion
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
        className="mb-5 accordion-custom"
      >
        <AccordionItem className="card">
          <AccordionItemHeading className="card-header">
            <AccordionItemButton>
              <h3 className="my-1">Relationship Content</h3>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="card-body">
            <h4 className="heading bold mt-2">Relationship Advice</h4>
            <p className="mb-1">Requirements</p>
            <ul>
              <li>
                Must have 3+ original pics (cover pic and 2+ content slides)
                with simple editing (adding titles, text, etc.)
              </li>
              <li>
                Caption must be over 50 words – no AI generated text allowed
              </li>
              <li>
                Title must be different from the text on the cover picture
              </li>
              <li>
                Cover picture must be relevant (holding hands, hugs, dating,
                etc.)
              </li>
              <li>Avoid sex-related content</li>
              <li>
                Add more details (reasons, your story, experience, steps,
                how-to, examples) in the caption to be more authentic
              </li>
            </ul>
            <p className="mb-1">Examples and Ideas</p>
            <ul>
              <li>Relationship green/red flags</li>
              <li>Tips and advice (how to communicate certain topics)</li>
              <li>How to plan money as a couple</li>
              <li>Couple therapy topics</li>
              <li>Relationship pillars</li>
              <li>How to build trust</li>
              <li>How to show empathy to your loved ones</li>
              <li>Boundaries</li>
              <li>Conflicts</li>
              <li>Honesty</li>
              <li>Intimacy</li>
            </ul>

            <h4 className="heading bold mt-5">Relationship Stories</h4>
            <p className="mb-1">Requirements</p>
            <ul>
              <li>
                Must have 3+ original pics (cover pic and 2+ content slides)
                with simple editing (add titles, text, etc.)
              </li>
              <li>
                Caption must be over 50 words – no AI generated text allowed
              </li>
              <li>Title must be different from the text on the cover pic</li>
              <li>
                Cover picture must be relevant (holding hands, hugs, dating,
                couple things/mugs/matching, etc.)
              </li>
              <li>
                Content slides must be relevant to show the story (pics of first
                date, selfies, travels, love letters, etc.)
              </li>
              <li>Avoid sex-related content</li>
              <li>
                Write more details of your story in the caption (timeline, what
                happened, how did you react, how did you feel, what did you do,
                how did it end up, your takeaways, etc.)
              </li>
            </ul>
            <p className="mb-1">Examples and Ideas</p>
            <ul>
              <li>Breakup stories and lessons you’ve learned</li>
              <li>Dating stories</li>
              <li>Dramatic dates</li>
              <li>Traumatic relationships (what you’ve learned)</li>
              <li>Cheating</li>
              <li>Loyalty (developing loyal relationships)</li>
              <li>Touching and establishing healthy affection behaviors</li>
              <li>Loving</li>
              <li>Creating special moments in your relationship</li>
              <li>Processing and healing in relationships</li>
              <li>Couples therapy and its benefits</li>
              <li>Getting back together</li>
              <li>How to process divorce</li>
            </ul>

            <h4 className="heading bold mt-5">Weddings</h4>
            <p className="mb-1">Requirements</p>
            <ul>
              <li>
                Must have 3+ original pics (cover pic and 2+ content slides)
                with simple editing (adding titles, text, etc.)
              </li>
              <li>
                Caption must be over 50 words – no AI generated text allowed
              </li>
              <li>Title must be different from the text on the cover pic</li>
              <li>
                Cover picture must be wedding relevant (gown fitting, ring
                fitting, planner, venue, day of wedding, etc.)
              </li>
              <li>
                Add more details in the caption (tips, why, your own experience,
                how-to, examples, etc.)
              </li>
              <li>
                Inspo posts should all be your original pics (wedding dress
                inspo, venue inspo, decoration inspo, destination inspo)
              </li>
            </ul>
            <p className="mb-1">Examples and Ideas</p>
            <ul>
              <li>Wedding planning</li>
              <li>Guest list preparation</li>
              <li>Getting your finances together to plan for wedding</li>
              <li>Budgeting for your wedding</li>
              <li>Planning a bridal shower for your bestie</li>
              <li>Wedding recaps</li>
              <li>Experience as a maid of honor</li>
              <li>How to be a great bridesmaid</li>
              <li>Tips/advice before you get married</li>
              <li>GRWM wedding edition</li>
              <li>Wedding inspiration</li>
              <li>Top wedding ideas</li>
              <li>Wedding themes</li>
              <li>What not to do at your wedding</li>
              <li>Creating experiences for wedding guests</li>
            </ul>

            <h4 className="heading bold mt-5">Family & Friends</h4>
            <p className="mb-1">Requirements</p>
            <ul>
              <li>
                Must have 3+ original pics (cover pic and 2+ content slides)
                with simple editings (adding titles, text, etc.)
              </li>
              <li>
                Caption must be over 50 words – no AI generated text allowed
              </li>
              <li>Title must be different from the text on the cover pic</li>
              <li>
                Cover picture and content slides must be relevant to show the
                post topic (e.g., friendship, family, siblings, etc.)
              </li>
              <li>
                Add more details in the caption (e.g., what happened, timeline,
                how did you feel, what did you do, what did you learn, how this
                impacted you, etc.)
              </li>
            </ul>
            <p className="mb-1">Examples and Ideas</p>
            <ul>
              <li>
                Creating friendships with your parents/siblings/other family
                members
              </li>
              <li>Forgiving your parents</li>
              <li>Bandaging relationships with siblings</li>
              <li>How to be a supportive sibling</li>
              <li>How to break up with a friend</li>
              <li>Signs they’re your best friend</li>
              <li>Growing up with siblings</li>
              <li>Unleashing inner child</li>
              <li>Different family dynamics</li>
              <li>Improving communication within your family dynamic</li>
              <li>Family settings</li>
              <li>Processing childhood trauma</li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="card">
          <AccordionItemHeading className="card-header">
            <AccordionItemButton>
              <h3 className="my-1">Home Content</h3>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="card-body">
            <h4 className="heading bold mt-2">Apartment/Dorm Room</h4>
            <p className="mb-1">Requirements</p>
            <ul>
              <li>
                Must have 3+ original pictures (cover picture and 2+ content
                slides) with simple editing (adding titles, text, etc.)
              </li>
              <li>Caption must be over 50 words - no ChatGPT allowed</li>
              <li>
                Post title must be different from the text on the cover picture
              </li>
              <li>
                Cover picture must be showing the room overall or a collage of
                different rooms; content pictures should show different corners
                of a room or different rooms
              </li>
              <li>
                Add simple text to explain key decor or furniture pieces (brand,
                price, etc.)
              </li>
              <li>
                Include more details in the caption to talk about why and how
                did you decorate, what do you like or dislike of the current
                room, apartment, or dorm
              </li>
            </ul>

            <p className="mb-1">Examples and Ideas</p>
            <ul>
              <li>
                Apartment tour, living space and layout, workspace corner, desk
                setup and storage solutions, bed area [focusing on bedding,
                pillows, and any decor], wall art, focus on key pieces like the
                sofa or coffee table, bathroom tour
              </li>
              <li>
                How to make your dorm feel like home, making your own space in
                your dorm, highlight your study area and storage, dorm closet
                tour, dorm hacks
              </li>
            </ul>

            <h4 className="heading bold mt-5">Home Hacks</h4>
            <p className="mb-1">Requirements</p>
            <ul>
              <li>
                Must have 3+ original pictures (cover picture and 2+ content
                slides) with simple editing (adding titles, text, etc.)
              </li>
              <li>Caption must be over 50 words - no ChatGPT allowed</li>
              <li>
                Title must be different from the text on the cover picture
              </li>
              <li>
                Pictures should show step by step tutorials, how-to’s, products
                involved, etc.
              </li>
              <li>Add simple text to content slides to explain key tips</li>
              <li>
                Include more detail in the caption to talk about how does this
                help, why does it help, what are the steps, how to do it,
                examples, etc.
              </li>
            </ul>

            <p className="mb-1">Examples and Ideas</p>
            <ul>
              <li>
                Organization and Storage Hacks [Maximizing closet space, drawer
                dividers, under-bed storage solutions, pantry organization,
                small space hacks]
              </li>
              <li>
                Decor and aesthetic hacks [DIY Wall art, budget-friendly decor,
                lighting hacks, accent walls, plant decor]
              </li>
              <li>
                Furniture and layout hacks [multipurpose furniture, rearranging
                tips, hidden storage, cable management]
              </li>
              <li>Bathroom organization hacks, etc.</li>
            </ul>

            <h4 className="heading bold mt-5">
              Home Finds: Product Reviews and Recommendations
            </h4>
            <p className="mb-1">Requirements</p>
            <ul>
              <li>
                Must have 3+ original pictures (cover picture and 2+ content
                slides) with simple editing (adding titles, text, etc.)
              </li>
              <li>Caption must be over 50 words - no ChatGPT allowed</li>
              <li>
                Title must be different from the text on the cover picture
              </li>
              <li>
                Post must show the products that you own; topics can be
                &rsquo;what I got vs. how I style&rsquo;, &rsquo;what I got from
                Amazon&rsquo;, product reviews
              </li>
              <li>
                Pictures must be original, real pictures; you can add stickers
                of the product as a comparison to what you actually got
              </li>
              <li>
                Include brand, price, and other thoughts of this product in the
                caption
              </li>
            </ul>

            <p className="mb-1">Examples and Ideas</p>
            <ul>
              <li>
                Furniture finds and reviews [best budget-friendly furniture,
                top-rated mattresses, space-saving furniture, stylish and
                affordable dining sets, comfortable office chairs, trendy wall
                art, cozy throw blankets, indoor plants, stylish rugs,
                decorative pillows, must-have kitchen gadgets, best non-stick
                pans, quality knife sets, innovative food storage solutions,
                functional dinnerware, best closet organizers, efficient kitchen
                storage, under bed storage containers, bathroom storage
                solutions, entryway organization, etc.]
              </li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="card">
          <AccordionItemHeading className="card-header">
            <AccordionItemButton>
              <h3 className="my-1">Travel Content</h3>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="card-body">
            <p className="bold italic mb-4">
              ALL travel content must be US-based!
            </p>
            <h4 className="heading bold mt-2">Local Travel Attractions</h4>
            <p className="mb-1">Requirements</p>
            <ul>
              <li>
                Must have 3+ original pics (cover pic and 2+ content slides)
                with simple editing (adding titles, text, etc.)
              </li>
              <li>Caption must be over 50 words - no ChatGPT allowed</li>
              <li>Title must be different from the text on the cover pic</li>
              <li>
                Pics must be able to show this place and must add simple texts
                to explain e.g. ticket price, key things to see/do, etc.
              </li>
              <li>
                Must include more useful information in the caption e.g. the
                ticket, reservation, how to get there, hours
              </li>
              <li>
                Must use &rsquo;Places&rsquo; feature to add specific address of
                this place
              </li>
            </ul>

            <p className="mb-1">Examples and Ideas</p>
            <ul>
              <li>
                Local travel attractions – museums, historical sites, etc
                [hidden gem museums, interactive science museums, art museum
                highlights, historic landmarks and monuments, local history
                museums, cultural heritage sites]
              </li>
              <li>
                Gardens and parks [top botanical gardens, urban oasis parks,
                historic gardens, picnic spots, garden tours]
              </li>
              <li>
                Day trips to small towns, state parks, springs, waterfalls, etc.
                [charming small towns, state park adventures, natural springs,
                waterfall hikes, scenic drives, local wineries and vineyards,
                beach day trips]
              </li>
            </ul>

            <h4 className="heading bold mt-5">Outdoors</h4>
            <p className="mb-1">Requirements</p>
            <ul>
              <li>
                Must have 3+ original pics (cover pic and 2+ content slides)
                with simple editing (adding titles, text, etc.)
              </li>
              <li>Caption must be over 50 words - no ChatGPT allowed</li>
              <li>Title must be different from the text on the cover pic</li>
              <li>
                Pics must be able to show this place and must add simple texts
                to explain e.g. difficulty level, hiking trail elevation, key
                things to see/do, photo-worthy spots, etc.
              </li>
              <li>
                Must include more useful information in the caption e.g. tips,
                reminders, how to get there, hours, elevation, difficulty level,
                guides, must-bring essentials, etc.
              </li>
              <li>
                Must use &rsquo;Places&rsquo; feature to add specific address of
                this place
              </li>
            </ul>

            <p className="mb-1">Examples and Ideas</p>
            <ul>
              <li>
                Hike/campground recommendation and reviews, trail guides [day
                hike itineraries, multi-day backpacking trips, seasonal hiking
                guides, wildlife watching trails, sunrise and sunset hikes],
                etc.
              </li>
              <li>
                Top hiking trails, waterfalls and natural springs, camping
                spots, scenic drives
              </li>
              <li>
                Outdoor activity guides [rock climbing spots, water sport
                adventures (kayaking, paddle boarding, canoeing), biking trails,
                fishing locations, bird watching sites]
              </li>
              <li>
                Nature Reserves and parks [best local nature reserves, national
                park highlights, state park exploration, urban nature trails,
                botanical gardens]
              </li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="card">
          <AccordionItemHeading className="card-header">
            <AccordionItemButton>
              <h3 className="my-1">Photography Content</h3>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="card-body">
            <h4 className="heading bold mt-2">Lightroom</h4>
            <p className="mb-1">Requirements</p>
            <ul>
              <li>
                Must have 3+ original pics (cover pic and 2+ content slides)
                with simple editing (adding titles, text, etc.)
              </li>
              <li>Caption must be over 50 words - no ChatGPT allowed</li>
              <li>Title must be different from the text on the cover pic</li>
              <li>
                Cover pic must be before/after collage style to show the
                contrast
              </li>
              <li>Content slides must show the Lightroom settings</li>
              <li>
                Must include more useful information in the caption e.g.
                settings, tips and tricks, explain thinking process, etc.
              </li>
            </ul>

            <p className="mb-1">Examples and Ideas</p>
            <ul>
              <li>
                Lightroom tutorials (must be mobile app version), settings tips
                for photo editors, prepping in Lightroom for photo shoots,
                Lightroom tips for those getting started in photography, style
                tutorials and Lightroom presets, before and after edits (for
                portraits, still pictures, food, night photos, airy look,
                vintage look, overexposure, etc.)
              </li>
            </ul>

            <h4 className="heading bold mt-5">Photo Taking Techniques</h4>
            <p className="mb-1">Requirements</p>
            <ul>
              <li>
                Must have 3+ original pics (cover pic and 2+ content slides)
                with simple editing (adding titles, text, etc.)
              </li>
              <li>Caption must be over 50 words - no ChatGPT allowed</li>
              <li>Title must be different from the text on the cover pic</li>
              <li>Cover pic must be relevant</li>
              <li>
                Content slides should add editing e.g. texts, arrows, dotted
                lines to explain the tips or hacks and show how-to
              </li>
              <li>Must include more useful information in the caption</li>
            </ul>

            <p className="mb-1">Examples and Ideas</p>
            <ul>
              <li>
                Photo taking techniques / ideas / poses / compositions
                (ideas/poses/techniques to look taller, slimmer, prettier;
                ideas/poses/techniques for couples, travel pic inspo, beach
                photo shoot inspo, nailing the group pic, how to create exciting
                solo travel photos; photo composition tutorial, perfecting your
                lighting game, etc.)
              </li>
              <li>How to book your first photo job</li>
              <li>Top 5 cameras for beginners</li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
