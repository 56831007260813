import mainbrand from "./NeoReach-Icon-Teal.png";
import favicon from "./favicon.ico";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  versionName: "NeoReach",
  mainbrand,
  favicon,
  themeColor: "#5925DC",
};
