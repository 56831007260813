import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Table, Row, Col } from "reactstrap";
import { getCampaigns } from "../actions";
import CampaignTableSkeleton from "../CampaignsTableSkeleton";
import CampaignsTableRow from "./CampaignsTableRow";
import Paginator from "../../../components/Paginator";

const CampaignsTable = ({
  campaigns,
  totalCampaignsCount,
  getCampaigns,
  ...props
}) => {
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    getCampaigns({ pageNumber: activePage, pageSize });
  }, [activePage, getCampaigns, pageSize]);

  let headers = [
    { heading: "Name", width: "20%", class: "" },
    { heading: "Approved", width: "10%", class: "text-right" },
    { heading: "On Hold", width: "10%", class: "text-right" },
    { heading: "Next Payout", width: "10%", class: "text-right" },
    { heading: "Agreements (Due/Total)", width: "10%", class: "text-right" },
    {
      heading: "Deliverables (Due/Total)",
      width: "10%",
      class: "text-right",
    },
    { heading: "Action Required", width: "10%", class: "text-right" },
  ];

  const isMobile = window.innerWidth < 1150;
  if (isMobile) {
    headers = [
      { heading: "Name", width: "20%", class: "" },
      { heading: "Approved", width: "10%", class: "text-right" },
      { heading: "On Hold", width: "10%", class: "text-right" },
      // { heading: "Agreements (Due/Total)", width: "10%", class: "text-right" },
      // {
      //   heading: "Deliverables (Due/Total)",
      //   width: "10%",
      //   class: "text-right",
      // },
      { heading: "Action Required", width: "10%", class: "text-right" },
    ];
  }

  const columnHeaders = headers.map((header, index) => (
    <th scope="col" key={index} className={header.class} width={header.width}>
      {header.heading}
    </th>
  ));

  const tableData = campaigns.map((campaign) => (
    <CampaignsTableRow
      key={campaign._id}
      campaign={campaign}
      isMobile={isMobile}
    ></CampaignsTableRow>
  ));

  const isLoading = useSelector((state) => state.campaigns.loading);

  if (isLoading) return <CampaignTableSkeleton />;

  return (
    <Row className="search-results-wrap">
      <Col md="12">
        <div className="table-responsive table-outer">
          <Table hover borderless>
            <caption className="sr-only">Your Campaigns</caption>
            <thead>
              <tr>{columnHeaders}</tr>
            </thead>
            <tbody>
              {campaigns && campaigns.length ? (
                tableData
              ) : (
                <tr>
                  <td colSpan={headers.length} align="center">
                    <h3 className="title bold mb-1 mt-5">
                      No campaign assignments found.
                    </h3>
                    <p className="text-grey mb-5">
                      Your campaigns will be displayed here once you are added
                      to a campaign.
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {totalCampaignsCount > 10 && (
            <Paginator
              activePage={activePage}
              pageSize={pageSize}
              totalItems={totalCampaignsCount}
              handlePageChange={(pageNumber) => {
                setActivePage(pageNumber);
              }}
              handlePageSizeChange={(size) => {
                setPageSize(size);
              }}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    campaigns: state.campaigns.campaigns,
    totalCampaignsCount: state.campaigns.totalCampaignsCount,
  };
};
export default connect(mapStateToProps, {
  getCampaigns,
})(CampaignsTable);
