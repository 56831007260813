import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCompleteDeliverables } from "./actions";
import DeliverablesTable from "./DeliverablesTable";

const CompleteDeliverablesTable = ({
  assignment_id,

  completeDeliverables,
  completeDeliverablesCount,
  loading,

  getCompleteDeliverables,
  ...props
}) => {
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    getCompleteDeliverables({
      pageNumber: activePage,
      pageSize,
      assignment_id,
    });
  }, [activePage, assignment_id, getCompleteDeliverables, pageSize]);

  const columns = [
    "title",
    "network",
    "handle",
    "screenshots",
    "postUrl",
    "completedBy",
  ];
  const headers = [
    { heading: "Title" },
    { heading: "Platform" },
    { heading: "Handle" },
    { heading: "Screenshots", class: "text-right" },
    { heading: "Post URL" },
    { heading: "Completed By" },
  ];
  const isMobile = window.innerWidth < 1150;
  // if mobile, only include first 3 columns and headers
  if (isMobile) {
    columns.splice(3);
    headers.splice(3);
  }

  if (!completeDeliverables?.length) {
    return null;
  }

  return (
    <DeliverablesTable
      caption={"Completed"}
      deliverablesData={completeDeliverables}
      deliverablesCount={completeDeliverablesCount}
      columns={columns}
      headers={headers}
      activePage={activePage}
      setActivePage={setActivePage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      loading={loading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.deliverables.loading,
    completeDeliverables: state.deliverables.completeDeliverables,
    completeDeliverablesCount: state.deliverables.completeDeliverablesCount,
  };
};
export default connect(mapStateToProps, {
  getCompleteDeliverables,
})(CompleteDeliverablesTable);
