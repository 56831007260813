import { API_URL, createRequestActionTypes } from "../../../actions/index";
export const USER_API_URL = `${API_URL}/api/v1/user`;
export const CAMPAIGN_API_URL = `${API_URL}/api/v1/campaign/display`;
export const COMPLETE_INVITATION_URL = `${API_URL}/api/v1/user/invitation`;

export const REGISTER_DUPLICATE_ERROR_MESSAGE = "Account already exists.";
export const REGISTER_ERROR_MESSAGE = "Failed to create account.";
export const INVITATION_REGISTER_ERROR_MESSAGE =
  "Failed to register account. Invitation link is invalid or has already been used.";
export const REGISTER_SUCCESS_MESSAGE = "Account successfully created.";

export const signupActionTypes = {
  register: createRequestActionTypes("REGISTER"),
  getCampaignDisplayInfo: createRequestActionTypes("GET_CAMPAIGN_DISPLAY_INFO"),
  completeInvitation: createRequestActionTypes("COMPLETE_INVITATION"),
  clearErrorMessages: createRequestActionTypes("CLEAR_SIGNUP_ERROR_MSG"),
  clearAccountErrors: createRequestActionTypes("CLEAR_ACCOUNT_ERRORS"),
};
