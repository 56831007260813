import { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { RenderButton } from "../../../components";
import AlertBanner from "../../../components/AlertBanner";

const StripeOnboardingStep = ({
  loginUser,
  stripeOnboardingUrl,
  setNextButtonType,
}) => {
  useEffect(() => {
    setNextButtonType(loginUser?.needsStripeOnboarding ? "skip" : "next");
  }, [loginUser?.needsStripeOnboarding, setNextButtonType]);

  return (
    <div className="stripe-onboarding">
      <Row>
        <Col xs="12">
          <div className="subheading">
            <h3 className="title">Verify your identity with Stripe</h3>
            <p className="mb-0 text-grey">
              Complete the Stripe onboarding workflow to verify your identity.
            </p>
          </div>
        </Col>
        {loginUser.needsStripeOnboarding ? (
          <Col xs="12">
            <RenderButton
              onClick={() => {
                window.location.replace(stripeOnboardingUrl);
              }}
              className="btn btn-primary btn mt-1 mb-4"
            >
              Continue with Stripe
            </RenderButton>
          </Col>
        ) : (
          <Col xs="12" md="9">
            <AlertBanner
              message="Stripe onboarding and identity verification is complete."
              alertType="success"
            />
            <p className="text-grey">
              If you need to update this information later, you can do so from
              the settings page.
            </p>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default StripeOnboardingStep;
