import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Badge, Col, Container, Row } from "reactstrap";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { getOpportunity } from "../actions";

import { RenderCurrency } from "../../../components";
import { capitalize } from "../../../actions";
import moment from "moment";
import ActionButtons from "./ActionButtons";
import DetailedViewSkeleton from "./DetailedViewSkeleton";

const DetailedView = ({ _id, loginUser, opportunity, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOpportunity(_id));
  }, [dispatch, _id]);

  const isLoading = useSelector((state) => state.opportunities.loading);
  if (isLoading) return <DetailedViewSkeleton />;

  return (
    <Container className="main-site-content deals-splash detailed-view d-flex flex-column">
      {loginUser && (
        <NavLink to="/deals" className="nav-back-arrow">
          <div>
            <ArrowLeftIcon height={24} width={24} />
          </div>
        </NavLink>
      )}
      <img
        src={opportunity?.brandLogoUrl}
        width={75}
        height={75}
        alt="brand logo"
        className="brand-logo mt-5"
      ></img>
      <h1 className="heading title">{opportunity?.brandName}</h1>
      <Row>
        <Col xs="12">
          <h2 className="text-grey mb-3">
            {opportunity?.title}
            <Badge color="success" className="badge-success ml-3 bold">
              <RenderCurrency
                amount={opportunity?.amount}
                renderCurrencyCode={loginUser.country !== "US"}
              />
            </Badge>
          </h2>
        </Col>
        <Col xs="12" md="6">
          <p className="text-grey mb-5">{opportunity?.description}</p>
          <Badge color="neutral" className="badge-light bold mb-3">
            {opportunity?.status === "pending"
              ? `Uploaded ${moment(opportunity?.created).format(
                  "MMM DD, YYYY",
                )}`
              : `${capitalize(opportunity?.status)} ${moment(
                  opportunity?.updated,
                ).format("MMM DD, YYYY")}`}
          </Badge>
          {opportunity?.status === "pending" ? (
            <p className="text-grey mb-5">
              This opportunity is pending your approval
            </p>
          ) : null}
        </Col>
      </Row>

      <div className="sticky-container mt-auto">
        <ActionButtons _id={_id} status={opportunity?.status} />
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    opportunity: state.opportunities.opportunity,
    loginUser: state.login.loginUser,
  };
};

export default connect(mapStateToProps, {})(DetailedView);
