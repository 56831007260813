const AgreementLabel = ({ children, className, ...props }) => {
  return (
    <dt
      {...props}
      className={`col-sm-4 bold d-flex justify-content-sm-end ${className}`}
    >
      {children}
    </dt>
  );
};

export default AgreementLabel;
