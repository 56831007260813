import {
  actionCreator,
  checkHttpStatus,
  checkAuthTokenExpiry,
  privateHeaders,
  showErrorMessage,
  dismissMessage,
} from "../../../actions";
import { logout } from "../../login/actions";
import {
  CREATE_EMAIL_ERROR,
  CREATE_TALENT_EMAIL_URL,
  EMAIL_UNAVAILABLE_ERROR,
  GET_HUBSPOT_CREATOR_ERROR,
  onboardingActionTypes,
  READ_ONE_HUBSPOT_CREATOR_URL,
} from "../constants";

export const getHubspotCreator = (hubspotCreatorId) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(onboardingActionTypes.getHubspotCreator.REQUEST));
    const url = new URL(
      `${READ_ONE_HUBSPOT_CREATOR_URL}/${hubspotCreatorId}`,
    ).toString();
    fetch(url, {
      method: "POST",
      headers: privateHeaders(getState),
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.result) {
          showErrorMessage(GET_HUBSPOT_CREATOR_ERROR);
          dispatch(
            actionCreator(onboardingActionTypes.getHubspotCreator.FAILURE),
          );
        } else {
          dispatch(
            actionCreator(
              onboardingActionTypes.getHubspotCreator.SUCCESS,
              response,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(onboardingActionTypes.getHubspotCreator.FAILURE),
        );
        showErrorMessage(GET_HUBSPOT_CREATOR_ERROR);
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};

export const createTalentEmail = (creatorData, _next) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(onboardingActionTypes.createTalentEmail.REQUEST));
    fetch(CREATE_TALENT_EMAIL_URL, {
      method: "POST",
      headers: privateHeaders(getState),
      credentials: "include",
      body: JSON.stringify(creatorData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error || !response?.result) {
          showErrorMessage(CREATE_EMAIL_ERROR);
          dispatch(
            actionCreator(onboardingActionTypes.createTalentEmail.FAILURE),
          );
        } else {
          dispatch(
            actionCreator(
              onboardingActionTypes.createTalentEmail.SUCCESS,
              response,
            ),
          );
          dismissMessage("create-talent-email");
          _next();
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(onboardingActionTypes.createTalentEmail.FAILURE),
        );

        switch (error.statusCode) {
          case 401:
            dispatch(logout(error));
            break;
          case 409:
            showErrorMessage(
              EMAIL_UNAVAILABLE_ERROR,
              null,
              "create-talent-email",
            );
            break;
          default:
            break;
        }
      });
  };
};
