import { Fragment } from "react";
import { connect } from "react-redux";
import { RenderButton } from "../../../../components";
import AlertBanner from "../../../../components/AlertBanner";
import InternationalAccountForm from "./InternationalAccountForm";

const NeedsFinancialAccount = ({
  loginUser,
  initiateFinancialConnectionsModal,
  ...props
}) => {
  return (
    <>
      <AlertBanner
        message={
          <Fragment>
            <span className="action">Action Needed - </span>
            Link your bank account with Stripe
          </Fragment>
        }
      />
      <div className="subheading">
        <h2 className="title">Link Bank Account</h2>
        <p className="text-grey mb-0">
          Payouts will be deposited to the account on file here.
        </p>
        <p className="text-grey">
          Account information is stored securely in Stripe.
        </p>
      </div>
      {loginUser.country && loginUser.country !== "US" ? (
        <InternationalAccountForm />
      ) : (
        <RenderButton
          onClick={() => {
            initiateFinancialConnectionsModal();
          }}
          disabled={!!loginUser.needsStripeOnboarding}
          className={
            "btn btn-primary btn mt-1 mb-1" +
            (loginUser.needsStripeOnboarding ? "disabled" : "")
          }
        >
          Continue with Stripe
        </RenderButton>
      )}
      {loginUser.needsStripeOnboarding && (
        <p className="text-danger text-grey mt-4">
          * Complete Onboarding and Identity Verification before linking a bank
          account
        </p>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
  };
};
export default connect(mapStateToProps, {})(NeedsFinancialAccount);
