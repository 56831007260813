import { useCallback } from "react";
import { Button } from "reactstrap";
import { titlelize } from "../../actions";

const DeliverablesTableRow = ({
  deliverable,
  handleSelectDeliverable,
  ...props
}) => {
  const handleClick = useCallback(() => {
    handleSelectDeliverable(deliverable?._id);
  }, [deliverable?._id, handleSelectDeliverable]);

  const isMobile = window.innerWidth < 1150;

  return (
    <tr className="activity-row">
      <th scope="row" className="tb-head">
        <Button color="link" className="link-more" onClick={handleClick}>
          {deliverable?.name}
        </Button>
      </th>
      <td>
        <div className="text-grey-bold">
          <span>{titlelize(deliverable?.network)}</span>
        </div>
      </td>
      <td>
        <div className="text-grey-bold">
          <span>{deliverable?.handle}</span>
        </div>
      </td>
      {!isMobile && (
        <>
          <td>
            <div className="text-grey-bold text-right">
              <span>{deliverable.imageUrls?.length ?? 0}</span>
            </div>
          </td>
          <td style={{ maxWidth: "200px" }}>
            <div className="link-more">
              <span className="text-truncate d-block">
                {deliverable.postUrl}
              </span>
            </div>
          </td>
          {!!deliverable?.isComplete && (
            <td>
              <div className="text-grey-bold">
                <span>
                  {deliverable?.markedCompleteByType === "user"
                    ? "Creator"
                    : "Admin"}
                </span>
              </div>
            </td>
          )}
        </>
      )}
    </tr>
  );
};

export default DeliverablesTableRow;
