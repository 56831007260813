import { API_URL, createRequestActionTypes } from "../../../actions/index";

export const MEDIA_KITS_API_URL = `${API_URL}/api/v1/mediaKit`;
export const USER_API_URL = `${API_URL}/api/v1/user`;

export const GET_MEDIA_KITS_ERROR = "Error reading Media Kits";
export const GET_MEDIA_KIT_ERROR = "Error reading Media Kit";
export const CONFIRM_MEDIA_KIT_ERROR = "Error updating Media Kit";

export const mediaKitsActionTypes = {
  getMediaKits: createRequestActionTypes("GET_MEDIA_KITS"),
  getMediaKit: createRequestActionTypes("GET_MEDIA_KIT"),
  getSocialHandle: createRequestActionTypes("GET_SOCIAL_HANDLE"),
  confirmSocialHandle: createRequestActionTypes("CONFIRM_SOCIAL_HANDLE"),
};
