import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Col, Row } from "reactstrap";

const LinkedBankAccountSkeleton = () => {
  return (
    <Row className="stripe-account">
      <Col xs="12">
        <div className="subheading">
          <h2 className="title">
            <Skeleton
              height={32}
              width={"50%"}
              style={{ marginBottom: "0.5rem" }}
            />
          </h2>
        </div>
      </Col>
      <Col xs="12" md="8">
        <Skeleton height={72} className="mb-4" />
        <Skeleton height={48} width={156} />
      </Col>
    </Row>
  );
};

export default LinkedBankAccountSkeleton;
