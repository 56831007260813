import { CardSubtitle, CardTitle, Col, Row } from "reactstrap";
import settings from "settings";

const PublicCardTitleSection = ({ title, subtitle, ...props }) => (
  <>
    <Row className="justify-content-center">
      <Col xs="3">
        <img
          src={settings.mainbrand}
          className="brand_logo mt-4"
          alt="brand logo"
          width={"100%"}
        />
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col xs="12" className="mb-5 text-center">
        <CardTitle tag="h1" className="heading title">
          {title}
        </CardTitle>
        <CardSubtitle tag="h3" className="text-grey">
          {subtitle}
        </CardSubtitle>
      </Col>
    </Row>
  </>
);
export default PublicCardTitleSection;
