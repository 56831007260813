import { useEffect } from "react";
import { connect } from "react-redux";
import { getContentPreview } from "./actions";
import CampaignContentCard from "./components/CampaignContentCard";

function CampaignContentPreview({
  contentPreview,
  getContentPreview,
  ...props
}) {
  const { campaignContent_id } = props.match.params;
  useEffect(() => {
    getContentPreview(campaignContent_id);
  }, [campaignContent_id, getContentPreview]);

  return (
    <>
      <CampaignContentCard content={contentPreview} isPreview={true} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    contentPreview: state.campaigns.contentPreview,
  };
};
export default connect(mapStateToProps, {
  getContentPreview,
})(CampaignContentPreview);
