import { signupActionTypes } from "../constants";
const initialState = {
  loading: false,
  stripeRedirectUrl: null,
  signupFailed: null,
  invitationFailed: null,
  duplicateAccountError: null,
  duplicateAccountEmail: null,
  campaignName: null,
};
const signupReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case signupActionTypes.register.REQUEST:
      return {
        ...state,
        loading: true,
        signupFailed: null,
        duplicateAccountError: null,
        duplicateAccountEmail: null,
      };
    case signupActionTypes.register.SUCCESS:
      return {
        ...state,
        loading: false,
        stripeRedirectUrl: payload?.result?.stripeRedirectUrl,
        signupFailed: false,
        duplicateAccountError: null,
        duplicateAccountEmail: null,
      };
    case signupActionTypes.register.FAILURE:
      return {
        ...state,
        loading: false,
        stripeRedirectUrl: null,
        signupFailed: true,
        duplicateAccountError: !!payload?.duplicateAccountEmail,
        duplicateAccountEmail: payload?.duplicateAccountEmail || null,
      };
    case signupActionTypes.completeInvitation.REQUEST:
      return {
        ...state,
        loading: true,
        invitationFailed: null,
      };
    case signupActionTypes.completeInvitation.SUCCESS:
      return {
        ...state,
        registeredUser: payload?.result,
        stripeRedirectUrl: payload?.result?.stripeRedirectUrl,
        loading: false,
        invitationFailed: false,
      };
    case signupActionTypes.completeInvitation.FAILURE:
      return {
        ...state,
        loading: false,
        stripeRedirectUrl: null,
        invitationFailed: true,
      };
    case signupActionTypes.getCampaignDisplayInfo.REQUEST:
      return {
        ...state,
        loading: true,
        campaignName: "",
      };
    case signupActionTypes.getCampaignDisplayInfo.SUCCESS:
      return {
        ...state,
        loading: false,
        campaignName: payload.name,
      };
    case signupActionTypes.getCampaignDisplayInfo.FAILURE:
      return {
        ...state,
        loading: false,
        campaignName: null,
      };
    case signupActionTypes.clearAccountErrors.SUCCESS:
      return {
        ...state,
        signupFailed: null,
        invitationFailed: null,
        duplicateAccountError: null,
        duplicateAccountEmail: null,
      };
    default:
      return state;
  }
};

export default signupReducers;
