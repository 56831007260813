import { dashboardActionTypes } from "../constants";
import { COMMON_ERROR_MESSAGE } from "../../../actions";
const initialState = {
  loading: false,
  assignments: [],
  errorMessage: null,
  successMessage: null,
};
const dashboardReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case dashboardActionTypes.getCampaigns.REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case dashboardActionTypes.getCampaigns.SUCCESS:
      return {
        ...state,
        loading: false,
        assignments: payload?.results,
        errorMessage: null,
      };
    case dashboardActionTypes.getCampaigns.FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: COMMON_ERROR_MESSAGE,
      };
    case dashboardActionTypes.createInstantPayout.REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: null,
        successMessage: null,
      };
    case dashboardActionTypes.createInstantPayout.SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: "Available balance has been paid out",
        errorMessage: null,
      };
    case dashboardActionTypes.createInstantPayout.FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: COMMON_ERROR_MESSAGE,
        successMessage: null,
      };
    case dashboardActionTypes.clearErrorMessages.SUCCESS:
      return {
        ...state,
        errorMessage: null,
      };
    case dashboardActionTypes.clearSuccessMessages.SUCCESS:
      return {
        ...state,
        successMessage: null,
      };

    default:
      return state;
  }
};

export default dashboardReducers;
