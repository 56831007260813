// Used for content approval/Lemon8

export const contentCategoryOptions = [
  // * here lies the legacy categories from the old campaign
  // { label: "Fashion", value: "fashion" },
  // { label: "Travel", value: "travel" },
  // { label: "Beauty", value: "beauty" },
  // { label: "Wellness/Fitness", value: "wellnessfitness" },
  // { label: "Food", value: "food" },
  // { label: "Home", value: "home" },
  // { label: "Productivity", value: "productivity" },
  // { label: "Reading", value: "reading" },
  // * new categories 2024 Lemon8
  { label: "Photography", value: "photography" },
  { label: "Travel", value: "travel" },
  { label: "Relationship", value: "relationship" },
  { label: "Home", value: "home" },
];
