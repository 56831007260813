import { Table, Row, Col } from "reactstrap";
import RecentDepositsRow from "./RecentDepositsRow";

const RecentDepositsTable = ({ deposits, ...props }) => {
  const columns = ["status", "description", "amount"];

  return (
    <Row className="search-results-wrap">
      <Col md="12">
        <div className="table-responsive table-outer user-profile-table">
          <Table borderless size="sm" className="payouts-table">
            <thead>
              <tr>
                <th width="10%"></th>
                <th width="60%"></th>
                <th width="20%"></th>
              </tr>
            </thead>
            <tbody>
              {deposits?.length ? (
                deposits?.map((deposit) => (
                  <RecentDepositsRow
                    key={deposit?.id}
                    deposit={deposit}
                  ></RecentDepositsRow>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length} align="center">
                    <p className="text-grey">No recent deposits found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default RecentDepositsTable;
