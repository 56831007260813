import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { setLoginUserFromCookie } from "../modules/login/actions";

const PublicRoute = ({
  component: Component,
  userCookie,
  loginUser,
  setLoginUserFromCookie,
  ...rest
}) => {
  if (userCookie && !loginUser) {
    setLoginUserFromCookie();
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return userCookie && loginUser ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
  };
};

export default connect(mapStateToProps, {
  setLoginUserFromCookie,
})(PublicRoute);
