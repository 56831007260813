import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";

import { getHubspotCreator } from "./actions";
import { RenderButton } from "../../components";
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import StepThree from "./components/StepThree";
import StepFour from "./components/StepFour";
import StepFive from "./components/StepFive";
import StepSix from "./components/StepSix";
import PhoneContainer from "../../components/PhoneContainer";

const Onboarding = ({ ...props }) => {
  const { hubspotCreatorId } = useParams();

  const dispatch = useDispatch();
  const creator = useSelector((state) => state.onboarding.creator);

  useEffect(() => {
    dispatch(getHubspotCreator(hubspotCreatorId));
  }, [dispatch, hubspotCreatorId]);

  const onboardingSlider = useRef();
  const talentEmailRef = useRef();
  const portalAccountRef = useRef();
  const lottieRef = useRef();

  const [selected, setSelected] = useState(0);
  const settings = {
    adaptiveHeight: true,
    arrows: false,
    beforeChange: (_, next) => setSelected(next),
    dots: true,
    focusOnSelect: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
  };

  const actionCopy = [
    "Let’s Get Started",
    "Next",
    "Next",
    "Next",
    "On To The Final Step",
    "Take Me To The Portal",
  ];

  const _next = () => {
    if (onboardingSlider.current) {
      onboardingSlider.current.slickNext();
    }

    if (lottieRef.current) {
      lottieRef.current.play();
    }
  };

  const handleNext = () => {
    switch (selected) {
      // Create Talent Email
      case 4:
        if (!!creator?.talentManagementEmailExists) {
          _next();
          break;
        }

        if (talentEmailRef.current) {
          talentEmailRef.current.handleSubmit();
        }
        break;

      // Register Portal Account
      case 5:
        if (portalAccountRef.current) {
          portalAccountRef.current.handleSubmit();
        }
        break;

      default:
        _next();
    }
  };
  return (
    <PhoneContainer>
      <Container className="onboarding-container px-0">
        <Row>
          <Col sm="12" className="px-3">
            <Slider
              ref={(slider) => (onboardingSlider.current = slider)}
              {...settings}
            >
              <StepOne />
              <StepTwo lottieRef={lottieRef} />
              <StepThree />
              <StepFour />
              <StepFive talentEmailRef={talentEmailRef} _next={_next} />
              <StepSix portalAccountRef={portalAccountRef} />
            </Slider>
          </Col>
        </Row>
        <div className="sticky-container mt-auto">
          <div className="button-container">
            <RenderButton
              className="btn btn-primary btn w-100 p-3"
              onClick={handleNext}
            >
              {actionCopy[selected]}
            </RenderButton>
          </div>
        </div>
      </Container>
    </PhoneContainer>
  );
};

export default Onboarding;
