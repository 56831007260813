import { API_URL, createRequestActionTypes } from "../../../actions/index";

export const READ_MANY_CAMPAIGNS_URL = `${API_URL}/api/v1/assignments`;
export const READ_ONE_CAMPAIGN_URL = `${API_URL}/api/v1/assignment`;
export const UPDATE_ONE_ASSIGNMENT_URL = `${API_URL}/api/v1/assignment`;

export const CREATE_ONE_CONTENT_URL = `${API_URL}/api/v1/campaignContent`;
export const READ_MANY_CONTENTS_URL = `${API_URL}/api/v1/campaignContents`;
export const READ_ONE_CONTENT_URL = `${API_URL}/api/v1/campaignContent`;
export const READ_ONE_CONTENT_PREVIEW_URL = `${API_URL}/api/v1/campaignContent/preview`;
export const UPDATE_ONE_CONTENT_URL = `${API_URL}/api/v1/campaignContent`;

export const GET_PRESIGNED_UPLOAD_URL = `${API_URL}/api/v1/campaignContent/getPresignedUpload`;
export const COMPLETE_PRESIGNED_UPLOAD_URL = `${API_URL}/api/v1/campaignContent/completePresignedUpload`;

export const GET_CAMPAIGNS_ERROR = "Error reading campaigns";
export const GET_CAMPAIGN_ERROR = "Error reading campaign";

export const GET_INVITATIONS_ERROR = "Error reading campaign invitations";

export const GET_CONTENTS_ERROR = "Error reading posts";
export const GET_CONTENT_ERROR = "Error reading post";
export const SAVE_CONTENT_ERROR = "Error saving post";
export const UPLOAD_IMAGE_ERROR = "Error uploading 1 or more files";

export const UPLOAD_MEDIA_ERROR = "Error uploading media";

export const SUBMIT_APPROVAL_SUCCESS = "Post submitted for admin approval";
export const SUBMIT_APPROVAL_ERROR = "Error submitting post for admin approval";
export const SAVE_CONTENT_SUCCESS = "Post details saved successfully";

export const CAMPAIGN_JOIN_SUCCESS = "Congrats - You just joined a campaign!";
export const CAMPAIGN_INVITE_REJECT_SUCCESS = "Campaign invitation rejected";
export const CAMPAIGN_INVITE_ERROR = "Error responding to campaign invitation";

export const ASSIGNMENT_SAVE_SUCCESS = "Campaign saved successfully";
export const ASSIGNMENT_SAVE_ERROR = "Error saving Campaign";

export const campaignsActionTypes = {
  getCampaigns: createRequestActionTypes("GET_CAMPAIGNS"),
  getCampaign: createRequestActionTypes("GET_CAMPAIGN"),
  getCampaignInvitations: createRequestActionTypes("GET_CAMPAIGN_INVITATIONS"),
  updateCampaignAssignment: createRequestActionTypes(
    "UPDATE_CAMPAIGN_ASSIGNMENT",
  ),
  answerCampaignInvitation: createRequestActionTypes(
    "ANSWER_CAMPAIGN_INVITATION",
  ),
  createContent: createRequestActionTypes("CREATE_CONTENT"),
  getContents: createRequestActionTypes("GET_CONTENTS"),
  getContentPreview: createRequestActionTypes("GET_CONTENT_PREVIEW"),
  updateContent: createRequestActionTypes("UPDATE_CONTENT"),
  uploadMedia: createRequestActionTypes("UPLOAD_MEDIA"),
  setEditPostModalVisibility: createRequestActionTypes(
    "SET_EDIT_POST_MODAL_VISIBILITY",
  ),
  setContentBeingEdited: createRequestActionTypes("SET_CONTENT_TO_EDIT"),
};
