import { NavLink } from "react-router-dom/cjs/react-router-dom";

const CampaignLink = ({ campaign_id, campaignName }) => {
  return (
    <NavLink className="link-more" to={`/campaigns/${campaign_id}`}>
      {campaignName}
    </NavLink>
  );
};

export default CampaignLink;
