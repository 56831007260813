import { COMMON_ERROR_MESSAGE } from "../../../actions";
import { payoutActionTypes } from "../constants";

const initialState = {
  loading: false,
  loadingDeposits: false,
  loadingAssignments: false,
  deposits: [],
  assignment: null,
  errorMessage: null,
};

const payoutsReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case payoutActionTypes.getRecentDeposits.REQUEST:
      return {
        ...state,
        loading: true,
        loadingDeposits: true,
        deposits: [],
      };
    case payoutActionTypes.getRecentDeposits.SUCCESS:
      return {
        ...state,
        loading: false,
        loadingDeposits: false,
        deposits: payload?.results,
        errorMessage: null,
      };
    case payoutActionTypes.getRecentDeposits.FAILURE:
      return {
        ...state,
        loading: false,
        loadingDeposits: false,
        deposits: null,
        errorMessage: COMMON_ERROR_MESSAGE,
      };
    case payoutActionTypes.getAssignmentOverview.REQUEST:
      return {
        ...state,
        loading: true,
        loadingAssignments: true,
      };
    case payoutActionTypes.getAssignmentOverview.SUCCESS:
      return {
        ...state,
        loading: false,
        loadingAssignments: false,
        assignment: payload,
        errorMessage: null,
      };
    case payoutActionTypes.getAssignmentOverview.FAILURE:
      return {
        ...state,
        loading: false,
        loadingAssignments: false,
        assignment: null,
        errorMessage: COMMON_ERROR_MESSAGE,
      };
    case payoutActionTypes.clearErrorMessages.SUCCESS:
      return {
        ...state,
        errorMessage: null,
      };
    default: {
      return state;
    }
  }
};

export default payoutsReducers;
