import {
  EyeIcon,
  EyeSlashIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import ReactSelect from "react-select";
import "./index.scss";
import {
  Button,
  Input,
  InputGroup,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import { titlelize } from "../actions";

export const RenderButton = (props) => {
  return (
    <Button {...props} type="button">
      {props.children}
    </Button>
  );
};

export const RenderCurrency = ({
  amount = 0,
  currency = "USD",
  locale = "en-US",
  country,
  renderCurrencyCode,
  renderConversionTooltip,
  ...customProps
}) => {
  locale = country ? `en-${country}` : locale;
  currency = currency.toUpperCase();
  const options = {
    style: "currency",
    currency,
    trailingZeroDisplay: "stripIfInteger",
  };
  customProps.className = "currency-display".concat(" ", customProps.className);
  return (
    <span {...customProps}>
      <div className="currency">
        <span className="value">
          {new Intl.NumberFormat(locale, options).format(amount)}
        </span>
        {renderCurrencyCode && <span className="code">&nbsp;{currency}</span>}
      </div>
      {renderConversionTooltip && currency !== "USD" && (
        <div className="icon-container">
          <InformationCircleIcon
            id="tooltip-target-currency-conversion"
            className="tooltip-target-icon"
            width={16}
            height={16}
          />
          <UncontrolledTooltip
            placement="auto"
            autohide={false}
            target="tooltip-target-currency-conversion"
          >
            The amount that will be sent to your bank when initiating a balance
            payout. Funds are converted from USD to {currency} when added to
            your available balance.
          </UncontrolledTooltip>
        </div>
      )}
    </span>
  );
};

export const RenderSingleSelect = ({
  field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {
  customProps = customProps || {};
  const customStyles = {
    option: (provided, state) => {
      provided[":hover"] = {
        ...provided[":hover"],
        backgroundColor: "#DEEBFF",
      };
      provided[":active"] = {
        ...provided[":active"],
        backgroundColor: "#B2D4FF !important",
      };

      return {
        ...provided,
        backgroundColor: state.isSelected ? "#DEEBFF" : "#fff",
        color: "#696C6F",
      };
    },
  };
  customProps.className = "single-select".concat(" ", customProps.className);
  return (
    <ReactSelect
      {...field}
      {...customProps}
      isMulti={false}
      placeholder={
        customProps.placeholder && titlelize(customProps.placeholder)
      }
      isClearable={false}
      isSearchable={false}
      styles={customStyles}
      backspaceRemovesValue={true}
      deleteRemoves={true}
      menuPlacement="auto"
    />
  );
};

export const RenderCountrySelect = ({
  field, // { name, value, onChange, onBlur }
  ...customProps
}) => {
  return (
    <CountryDropdown
      {...field}
      {...customProps}
      name="country"
      valueType="short"
      defaultOptionLabel="United States"
      // className={errors?.country && touched?.country ? "error" : ""}
      // value={values.country}
      // onChange={handleChange}
      // onBlur={handleBlur}
    />
  );
};

export const RenderDateField = ({
  field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {
  delete customProps.form;
  return <Input {...field} {...customProps} placeholder="" type="date" />;
};

export const RenderTextField = ({
  field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {
  delete customProps.form;
  return <Input {...field} {...customProps} type="text" />;
};

export const RenderPasswordTextField = ({ field, ...customProps }) => {
  delete customProps.form;
  return (
    <Input
      {...field}
      {...customProps}
      autoComplete="new-password"
      type="password"
    />
  );
};

export const RenderSplitTextField = ({ field, children, ...customProps }) => {
  delete customProps.form;
  return (
    <InputGroup className="input-with-addon">
      <Input {...field} {...customProps} type="text" />
      <div className={`input-addon-text ${customProps.className}`}>
        {children}
      </div>
    </InputGroup>
  );
};

export const RenderTextFieldWithoutAutoComplete = ({
  field,
  ...customProps
}) => {
  let currentValue;
  if (customProps.customValue !== null) {
    currentValue = customProps.customValue;
  } else {
    currentValue = field.value;
  }

  delete field.value;
  return (
    <Input
      {...field}
      {...customProps}
      type="text"
      autoComplete="off"
      value={currentValue}
    />
  );
};

export const RenderTextFieldWithoutAutoCompleteGlobal = ({
  field,
  ...customProps
}) => {
  delete field.value;
  return (
    <Input
      {...field}
      {...customProps}
      type="text"
      autoComplete="off"
      value={customProps.customValue || ""}
      autoFocus
    />
  );
};

export const RenderNumericField = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {
  delete customProps.form;
  return (
    <Input
      {...field}
      {...customProps}
      onChange={(input) =>
        form.setFieldValue(
          field.name,
          (input.target &&
            input.target.value &&
            input.target.value.replace(/[^0-9]/, "")) ||
            "",
        )
      }
      type="text"
      autoComplete="off"
    />
  );
};

export const RenderPhoneField = ({
  field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {
  delete customProps.form;
  return <Input {...field} {...customProps} type="tel" autoComplete="tel" />;
};

export const SubmitButton = ({ ...props }) => {
  return (
    <Button {...props} type="submit" color={props.color || "primary"}>
      {props.children}
    </Button>
  );
};
export const RenderPasswordField = ({
  field, // { name, value, onChange, onBlur }
  ...customProps
}) => {
  delete customProps.form;
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  return (
    <InputGroup className="input-with-addon">
      <Input
        autoComplete="current-password password"
        type={passwordIsVisible ? "text" : "password"}
        {...field}
        {...customProps}
      />
      <Button
        outline
        onClick={() => setPasswordIsVisible(!passwordIsVisible)}
        className="btn input-addon-btn"
      >
        {passwordIsVisible ? (
          <EyeSlashIcon
            width={25}
            height={25}
            strokeWidth={2}
            className="text-grey"
          />
        ) : (
          <EyeIcon
            width={25}
            height={25}
            strokeWidth={2}
            className="text-grey"
          />
        )}
      </Button>
    </InputGroup>
  );
};

export const RenderTextArea = ({
  field, // { name, value, onChange, onBlur }
  ...customProps
}) => {
  delete customProps.form;
  return <Input {...field} {...customProps} type="textarea" />;
};
export const RenderRadioButton = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  return <Input {...field} {...props} type="radio" />;
};
export const RenderLabel = ({ ...props }) => {
  return <Label {...props}>{props.children}</Label>;
};
export const RenderCheckBox = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  return <Input {...field} {...props} type="checkbox" />;
};
