import {
  actionCreator,
  checkHttpStatus,
  checkAuthTokenExpiry,
  privateHeaders,
  COMMON_ERROR_MESSAGE,
} from "../../../actions";
import { logout, fetchAvailableBalance } from "../../login/actions";
import {
  dashboardActionTypes,
  READ_MANY_ASSIGNMENTS_URL,
  CREATE_PAYOUT_URL,
} from "../constants";

export const getCampaigns = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(dashboardActionTypes.getCampaigns.REQUEST));
    fetch(READ_MANY_ASSIGNMENTS_URL, {
      method: "GET",
      headers: privateHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error) {
          const errorMsg =
            response.error[Object.keys(response.error)[0]] ||
            COMMON_ERROR_MESSAGE;
          dispatch(
            actionCreator(dashboardActionTypes.getCampaigns.FAILURE, {
              errorMsg: errorMsg,
            }),
          );
        } else {
          dispatch(
            actionCreator(dashboardActionTypes.getCampaigns.SUCCESS, response),
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(dashboardActionTypes.getCampaigns.FAILURE));
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};
export const createInstantPayout = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(dashboardActionTypes.createInstantPayout.REQUEST));
    fetch(`${CREATE_PAYOUT_URL}?isInstant=true`, {
      method: "POST",
      headers: privateHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error) {
          const errorMsg =
            response.error[Object.keys(response.error)[0]] ||
            COMMON_ERROR_MESSAGE;
          dispatch(
            actionCreator(dashboardActionTypes.createInstantPayout.FAILURE, {
              errorMsg: errorMsg,
            }),
          );
        } else {
          dispatch(
            actionCreator(
              dashboardActionTypes.createInstantPayout.SUCCESS,
              response,
            ),
          );
          dispatch(fetchAvailableBalance());
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(dashboardActionTypes.createInstantPayout.FAILURE),
        );
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};
export const clearErrorMessages = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(dashboardActionTypes.clearErrorMessages.SUCCESS));
  };
};
export const clearSuccessMessages = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(dashboardActionTypes.clearSuccessMessages.SUCCESS));
  };
};
